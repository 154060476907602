export const routes = {
  githubRepo: "/github/:owner/:repo",
  github: "/github/:owner/:repo/*branch",
  devbox: "/devbox/*id",
  sandbox: "/sandbox/*id",
  redirectToProjectEditor: "/redirect-to-project-editor/:branchId",
  vscode: "/vscode",
  inlinePreview: "/devtool/inline-preview/:host",
  preview: "/devtool/preview/:owner/:repo/*branch",
  previewPath: "/devtool/preview",
  liveEnd: "/live/end/:vmId",
  live: "/live/:id",
} as const;
