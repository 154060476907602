import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";

/**
 * Return the correct CSB API URL based on the
 * custom `API_DOMAIN` environmental variable.
 */
export function getCsbApiUrl(): string {
  const apiDomainUrl = new URL(env.PUBLIC_API_DOMAIN);

  const csbApiUrl =
    apiDomainUrl.hostname === "localhost"
      ? /**
         * @note If the API is served from localhost,
         * respect the custom base path. Otherwise such
         * local API would return 404 when using a custom base path.
         */
        new URL(withBasePath(env, "/api"), env.PUBLIC_API_DOMAIN)
      : new URL("/api", env.PUBLIC_API_DOMAIN);

  return csbApiUrl.href;
}
