import type { Navigation } from "environment-interface/navigation";

export const createNavigation = (): Navigation => ({
  openUrl(url) {
    window.open(url);
  },
  changeUrl(url) {
    // eslint-disable-next-line
    location.href = url;
  },
  getOrigin() {
    // eslint-disable-next-line
    return location.origin;
  },
  getProtocol() {
    // eslint-disable-next-line
    return location.protocol;
  },
  reload() {
    // eslint-disable-next-line
    location.reload();
  },
});
