import { font } from "prism-react/tokens/fonts";

import { globalCss } from "./stitches";

export const globalStyles = (withBasePath: (url: string) => string) =>
  globalCss({
    "@font-face": [
      {
        fontFamily: font.display,
        src: `url('${withBasePath(
          "/fonts/Everett-Medium.woff",
        )}') format("woff")`,
      },
    ],
    ":root": {
      /**
       * To make it easier to translate rem values to their corresponding px values we use a trick to
       * translate 1rem to 10px (depending on user settings). Check out the following link for more on
       * the how and why: https://www.aleksandrhovhannisyan.com/blog/62-5-percent-font-size-trick/
       */
      fontSize: `${(10 / 16) * 100}%`,
      "--csb-cursor": "pointer",
    },
    "*, *:after, *:before": {
      boxSizing: "border-box",
      scrollbarWidth: "thin",
      scrollbarColor: "$support-system-scrollbar transparent", // future support: https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color
    },

    ".monaco-workbench": {
      backgroundColor: "$neutral-bg-low !important",
    },

    "#root, #__next": {
      position: "relative",
      zIndex: 0, // this is a root div and this ensures it's bellow react portals
    },

    html: {
      overflow: "hidden",
      userSelect: "none",

      /**
       * @link https://gist.github.com/dpschen/aebfd3e140333fdfd5d4f9a79eb3dd2b
       */
      "@highDpr": {
        "&.dark": {
          /* Usually one should use this on dark background only
      http://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
      But: because we have enough pixels here (retina!) it shouldn't be a problem.
      It just renders more beautiful */
          "-moz-osx-font-smoothing": "grayscale",
          "-webkit-font-smoothing": "antialiased",
        },
      },
    },

    body: {
      fontFamily: "$base",
      fontSize: "$base",
      fontWeight: "$regular",

      color: "$neutral-fg-base",

      margin: "0",

      overflow: "hidden",
    },

    button: {
      fontFamily: "$base",
      background: "none",
      border: "none",
      padding: "0",
      cursor: "var(--csb-cursor)",
    },

    /**
     * These following two blocks are for backwards compatibility for focus-visible
     * https://css-tricks.com/focus-visible-and-backwards-compatibility/
     * Focus-visible ships in Safari from 15.4 so this can be removed soon
     */
    "button:focus, a:focus, [role='tabpanel']:focus, [role='button']:focus": {
      outline: "2px solid $accent-secondary-base",
      outlineOffset: "-2px",
    },

    "button:focus:not(:focus-visible), a:focus:not(:focus-visible), [role='tabpanel']:focus:not(:focus-visible), [role='button']:focus:not(:focus-visible)":
      {
        outline: "none",
      },
    /** End of fallback for Safari */

    "button:focus-visible, a:focus-visible, [role='tabpanel']:focus-visible, [role='button']:focus-visible":
      {
        outline: "2px solid $accent-secondary-base",
        outlineOffset: "-2px",
      },

    "::selection": {
      background: "$support-system-selection",
    },

    /* width */
    "::-webkit-scrollbar": {
      width: "$1",
      height: "$1",
    },

    /* Track */
    "::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },

    "::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },

    /* Handle */
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "$support-system-scrollbar",
      borderRadius: "$2",
    },

    /* Handle on hover */
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "$neutral-fg-base",
    },
  });
