import * as SwitchUI from "@radix-ui/react-switch";
import { styled } from "prism-react/theme";
import { forwardRef } from "react";

const SwitchRoot = styled(SwitchUI.Root, {
  all: "unset",
  width: 32,
  height: 20,
  backgroundColor: "$neutral-bg-high",
  transition: "background-color 100ms",
  borderRadius: "9999px",
  position: "relative",
  cursor: "var(--csb-cursor)",
  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
  '&[data-state="checked"]': { backgroundColor: "$accent-primary-base" },
  "&[data-disabled]": {
    cursor: "not-allowed",
    opacity: "0.7",
  },
});

const SwitchThumb = styled(SwitchUI.Thumb, {
  display: "block",
  width: 16,
  height: 16,
  backgroundColor: "$neutral-fg-base",
  borderRadius: "9999px",
  transition: "transform 100ms, background-color 100ms",
  transform: "translateX(2px)",
  willChange: "transform",
  cursor: "var(--csb-cursor)",
  '&[data-state="checked"]': {
    transform: "translateX(14px)",
    backgroundColor: "$neutral-bg-base",
  },
  "&[data-disabled]": {
    cursor: "not-allowed",
    opacity: "0.7",
  },
});

export const Switch = forwardRef<HTMLButtonElement, SwitchUI.SwitchProps>(
  ({ defaultChecked, checked, onCheckedChange, disabled }, ref) => {
    return (
      <SwitchRoot
        ref={ref}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onCheckedChange={onCheckedChange}
      >
        <SwitchThumb />
      </SwitchRoot>
    );
  },
);
