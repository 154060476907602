import { Disposable } from "@codesandbox/pitcher-common";
import * as rpc from "vscode-jsonrpc/node";
export class RPCReader {
    constructor(shellProcess) {
        this.shellProcess = shellProcess;
    }
    onClose() {
        return new Disposable();
    }
    onData(listener) {
        this.shellProcess.stdout.on("data", listener);
        const disposeable = new Disposable();
        disposeable.onWillDispose(() => {
            this.shellProcess.stdout.off("data", listener);
        });
        return disposeable;
    }
    onEnd() {
        return new Disposable();
    }
    onError() {
        return new Disposable();
    }
}
export class RPCWriter {
    constructor(shellProcess) {
        this.shellProcess = shellProcess;
    }
    write(msg) {
        return this.shellProcess.stdin.write(msg).catch((err) => {
            // eslint-disable-next-line
            console.error(err);
        });
    }
    end() { }
    onClose() {
        return new Disposable();
    }
    onEnd() {
        return new Disposable();
    }
    onError() {
        return new Disposable();
    }
}
export function createRpcConnection(shellProcess) {
    return rpc.createMessageConnection(new rpc.ReadableStreamMessageReader(new RPCReader(shellProcess)), new rpc.WriteableStreamMessageWriter(new RPCWriter(shellProcess)));
}
export function getFullRange(content) {
    if (!content.length) {
        return {
            start: {
                line: 0,
                character: 0,
            },
            end: {
                line: 0,
                character: 0,
            },
        };
    }
    const lines = content.split("\n");
    const lastLine = lines[lines.length - 1];
    return {
        start: {
            line: 0,
            character: 0,
        },
        end: {
            line: lines.length - 1,
            character: lastLine.length,
        },
    };
}
