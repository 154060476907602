import type { Cuid } from "@codesandbox/pitcher-client";
import {
  Emitter,
  Disposable,
  protocol,
  Debouncer,
} from "@codesandbox/pitcher-client";
import type {
  ActionsChangedEvent,
  ClientLocationsUpdatedEvent,
  EditorOpenedEvent,
  FileEditorOptions,
  IMonacoFileEditor,
  SelectionUpdatedEvent,
} from "environment-interface/monacoEditor";
import logger from "features/utils/logger";
import type * as Monaco from "monaco-editor";
import * as monaco from "monaco-editor";
import type { ITextFileEditorModel } from "vscode/monaco";

import type { ICodeEditor } from "../../../types";
import {
  getConflictBlocks,
  getConflictDecorations,
} from "../../utils/conflict-resolution";
import { createSelection } from "../../utils/selections";

import { IGNORED_ACTIONS, MAX_FILE_LENGTH } from "./constants";

import type { EditorService } from ".";

type DisposeFn = () => void;

const cancelableTimeout = (callback: () => void, ms: number): DisposeFn => {
  const timeoutRef = setTimeout(() => {
    callback();
  }, ms);

  return () => {
    clearTimeout(timeoutRef);
  };
};

export class MonacoFileEditor extends Disposable implements IMonacoFileEditor {
  private fileId: Cuid;
  // @ts-ignore
  private readOnly: boolean;
  private readOnlyMessage: Monaco.IMarkdownString | undefined;
  private wordWrap: boolean;
  // @ts-ignore
  private fonts: Record<string, string>;
  private hasConflicts: boolean;
  private domNodeId: string;
  private aiToolbarTimeout: NodeJS.Timeout | undefined;

  private _editorInstance: Monaco.editor.IStandaloneCodeEditor | undefined;
  private scrollTop = 0;
  private scrollDebounceDisposer: DisposeFn | null = null;
  private previousConflictDecorations: string[] = [];
  private actions: Map<string, Monaco.editor.IEditorAction> = new Map();

  private onEditorMountedEmitter = new Emitter<Monaco.editor.ICodeEditor>();
  onEditorMounted = this.onEditorMountedEmitter.event;

  private onEditorOpenedEmitter = new Emitter<EditorOpenedEvent>();
  onEditorOpened = this.onEditorOpenedEmitter.event;
  private onDocumentSavingEmitter = new Emitter<void>();
  onDocumentSaving = this.onDocumentSavingEmitter.event;
  private onDocumentSavedEmitter = new Emitter<void>();
  onDocumentSaved = this.onDocumentSavedEmitter.event;
  private onSelectionUpdatedEmitter = new Emitter<SelectionUpdatedEvent>();
  onSelectionUpdated = this.onSelectionUpdatedEmitter.event;

  private onFocusEmitter = new Emitter<void>();
  onFocus = this.onFocusEmitter.event;

  private onActionsChangedEmitter = new Emitter<ActionsChangedEvent>();
  onActionsChanged = this.onActionsChangedEmitter.event;
  private onClientLocationsUpdatedEmitter =
    new Emitter<ClientLocationsUpdatedEvent>();
  onClientLocationsUpdated = this.onClientLocationsUpdatedEmitter.event;
  private onScrollPositionUpdatedEmitter = new Emitter<void>();
  onScrollPositionUpdated = this.onScrollPositionUpdatedEmitter.event;
  private handleResizeDebouncer: Debouncer;

  model: ITextFileEditorModel;

  constructor(
    {
      fileId,
      hasConflicts,
      readOnly,
      readOnlyMessage,
      wordWrap,
      fonts,
      domNodeId,
      model,
    }: FileEditorOptions,
    private editorService: EditorService,
  ) {
    super();
    this.model = model;
    this.fileId = fileId;
    this.editorService = editorService;
    this.readOnly = readOnly;
    this.readOnlyMessage = readOnlyMessage;
    this.wordWrap = wordWrap;
    this.fonts = fonts;
    this.hasConflicts = hasConflicts;
    this.domNodeId = domNodeId;

    this.handleResizeDebouncer = new Debouncer(50, 100, () => {
      const editorInstance = this._editorInstance;
      if (!editorInstance) {
        return;
      }

      editorInstance.layout();
      editorInstance.updateOptions({
        wordWrap: this.wordWrap ? "on" : "off",
      });
    });

    const disposable = this.editorService.onEditorConfigChange(() => {
      if (this._editorInstance) {
        const formattingOptions =
          this.editorService.getFormattingOptions(fileId);
        this._editorInstance.updateOptions(formattingOptions);
        this._editorInstance.getModel()?.updateOptions({
          tabSize: formattingOptions.tabSize,
        });
      }
    });
    this.onDidDispose(() => {
      disposable.dispose();
      this.handleResizeDebouncer.clear();
    });
  }

  get editorInstance(): ICodeEditor {
    if (!this._editorInstance) {
      throw new Error("Editor instance is not defined");
    }
    return this._editorInstance as ICodeEditor;
  }

  handleResize(): void {
    this.handleResizeDebouncer.debounce();
  }

  private lastSelectionContent: string | undefined;

  private registerSelectionHandler() {
    this.lastSelectionContent = this.editorInstance.getValue();

    const changeCursorDisposable =
      this.editorInstance.onDidChangeCursorSelection((event) => {
        const model = this.editorInstance.getModel();

        if (!model) return;

        const lines = model.getLinesContent();
        const eolLength = model.getEOL().length;
        const monacoSelections = [
          event.selection,
          ...event.secondarySelections,
        ];
        const otSelections = createSelection(
          lines,
          monacoSelections,
          eolLength,
        );

        this.editorInstance.setSelections(monacoSelections);

        const isCodeChange = this.lastSelectionContent !== model.getValue();
        this.lastSelectionContent = model.getValue();

        this.onSelectionUpdatedEmitter.fire({
          cuid: this.fileId,
          selections: monacoSelections.map((selection) => ({
            endColumn: selection.endColumn,
            endLineNumber: selection.endLineNumber,
            startColumn: selection.startColumn,
            startLineNumber: selection.startLineNumber,
          })),
          otSelections,
          reason: isCodeChange
            ? protocol.file.SelectionsUpdateReason.CONTENT_CHANGE
            : protocol.file.SelectionsUpdateReason.SELECTION,
        });
      });
    this.onDispose(changeCursorDisposable.dispose);
  }

  private registerSaveHandler() {
    const keyDownDisposable = this.editorInstance.onKeyDown((ev) => {
      if ((ev.metaKey || ev.ctrlKey) && ev.code === "KeyS") {
        ev.preventDefault();

        // We do not run any formatting or saving when the contents is the same
        if (!this.model.isDirty()) {
          return;
        }

        this.onDocumentSavingEmitter.fire();
        // Run formatDocument which gets picked up by the language provider
        // When format is done, run the usualy save operation
        const formatAction = this.editorInstance.getAction(
          "editor.action.formatDocument",
        );

        if (formatAction) {
          formatAction
            .run()
            .then(() => this.model.save())
            .finally(() => {
              this.onDocumentSavedEmitter.fire();
            });
        } else {
          this.model.save()?.finally(() => {
            this.onDocumentSavedEmitter.fire();
          });
        }
      }
    });
    this.onDispose(keyDownDisposable.dispose);
  }

  private registerScrollHandler() {
    const scrollDisposable = this.editorInstance.onDidScrollChange(() => {
      if (this.scrollDebounceDisposer) {
        this.scrollDebounceDisposer();
      }

      this.scrollDebounceDisposer = cancelableTimeout(() => {
        this.handleScroll();
      }, 5);
    });
    this.onDispose(scrollDisposable.dispose);
  }

  private registerConflictsHandler() {
    if (!this.hasConflicts) {
      return;
    }

    const updateDecorations = () => {
      const content = this.editorInstance.getModel()?.getValue();
      if (content) {
        const decorations = getConflictDecorations(
          this.editorService.monaco,
          getConflictBlocks(content),
        );

        this.previousConflictDecorations = this.editorInstance.deltaDecorations(
          this.previousConflictDecorations,
          decorations,
        );
      }
    };

    // Update decorations when file content is updated
    const modelChangeListener =
      this.editorInstance.onDidChangeModelContent(updateDecorations);

    updateDecorations();
    this.onDispose(modelChangeListener.dispose);
  }

  getSelections(): Monaco.IRange[] | undefined {
    const selections = this._editorInstance?.getSelections();

    if (selections) {
      return selections.map((selection) => selection.toJSON());
    }

    return;
  }

  private createEditorInstance(
    domElement: HTMLElement,
    model: ITextFileEditorModel,
  ) {
    const editor = monaco.editor.create(domElement, {
      model: model.textEditorModel,
    });

    const onFocus = () => {
      this.onFocusEmitter.fire();
    };
    domElement.addEventListener("click", onFocus);

    this.onWillDispose(() => {
      domElement.removeEventListener("click", onFocus);
    });

    return editor;
  }

  async mount(): Promise<void> {
    if (this.model.isDisposed()) {
      throw new Error("This file does not exist anymore");
    }

    // TODO: Move this to be more global?
    const domElement = document.getElementById(this.domNodeId);

    if (!domElement) {
      throw new Error(
        `Invalid dom node ${this.domNodeId} used for mounting a new monaco editor instance`,
      );
    }

    const modelCharCount = this.model.textEditorModel?.getValueLength() ?? 0;
    if (modelCharCount > MAX_FILE_LENGTH) {
      throw new Error(
        `File is too large to display (${modelCharCount} characters), limit is ${MAX_FILE_LENGTH} characters`,
      );
    }

    this._editorInstance = this.createEditorInstance(domElement, this.model);

    this.registerSelectionHandler();
    this.registerSaveHandler();
    this.registerScrollHandler();
    this.registerConflictsHandler();
    this.registerActionFetcher();
    this.registerAIToolBar();

    this.handleResize();

    if (this.readOnly) {
      this._editorInstance.updateOptions({
        readOnly: this.readOnly,
        readOnlyMessage: this.readOnlyMessage,
      });
    }

    const viewState = this.editorService.getViewState(this.fileId);

    if (viewState) {
      this.editorInstance.restoreViewState(viewState);
    }

    this.onEditorMountedEmitter.fire(this.editorInstance);
  }

  registerActionFetcher() {
    // There is no way to detect if a new action is registered in monaco, so we poll it
    // Not great for performance but at least it works
    const intervalRef = setInterval(() => {
      const actions = this.editorInstance.getSupportedActions();
      let hasChanged = false;
      const newActionsMap: Map<string, Monaco.editor.IEditorAction> = new Map();
      for (const action of actions) {
        // Skip unsupported actions?
        // Not sure what this does, it's not documented anywhere.
        if (!action.isSupported() || IGNORED_ACTIONS.has(action.id)) {
          continue;
        }

        if (!hasChanged) {
          const foundAction = this.actions.get(action.id);
          if (!foundAction || foundAction.run !== action.run) {
            hasChanged = true;
          }
        }

        newActionsMap.set(action.id, action);
      }

      if (hasChanged) {
        this.actions = newActionsMap;
        this.onActionsChangedEmitter.fire({
          actions: Array.from(this.actions.values()),
        });
      }
    }, 1000);
    this.onWillDispose(() => clearInterval(intervalRef));
  }

  registerAIToolBar() {
    const COMPONENT_SIZE = { height: 31, width: 110 };
    const aiToolbar = document.getElementById(this.domNodeId + "-ai-toolbar");

    if (!aiToolbar) return;

    let lineNumber = 0;
    let endLineNumber = 0;
    let endColumn = 0;
    let scrollLeft = 0;
    let scrollTop = 0;

    const hideToolbar = () => {
      aiToolbar.style.display = "none";
    };

    const updateToolbarPosition = ({
      topEndLine,
      leftOffset,
      left,
      top,
    }: Record<string, number>) => {
      let x = Math.max(
        leftOffset,
        left + leftOffset - COMPONENT_SIZE.width - scrollLeft,
      );

      const topEndLineOffset = topEndLine + COMPONENT_SIZE.height + 10;

      // Default case, where the tooltip should be above code selection
      let y = top - COMPONENT_SIZE.height - 5 - scrollTop;

      if (scrollTop > topEndLine) {
        /**
         * It stickies to the scroll because the selection is no longer on the viewport
         */
        y = topEndLineOffset - scrollTop;
        x = leftOffset;
      } else if (scrollTop + COMPONENT_SIZE.height + 10 > top) {
        /**
         * It stickies to the bottom because the top selection is not visible
         */
        y = topEndLineOffset - scrollTop;
        x = leftOffset;
      }

      aiToolbar.style.left = `${x}px`;
      aiToolbar.style.top = `${y}px`;

      aiToolbar.style.display = "flex";
    };

    const getPosition = (model: Monaco.editor.ITextModel) => {
      const colCount =
        lineNumber === endLineNumber
          ? endColumn
          : model.getLineMaxColumn(lineNumber) ?? 0;
      const top = this.editorInstance.getTopForLineNumber(lineNumber);
      const left = this.editorInstance.getOffsetForColumn(lineNumber, colCount);
      const layoutInfo = this.editorInstance.getLayoutInfo();
      const leftOffset =
        layoutInfo.decorationsLeft + layoutInfo.decorationsWidth;
      const topEndLine = this.editorInstance.getTopForLineNumber(endLineNumber);

      return { top, left, leftOffset, topEndLine };
    };

    const shouldProceedWithEffects = (
      model: Monaco.editor.ITextModel | null,
    ): model is Monaco.editor.ITextModel => {
      hideToolbar();

      const selection = this.editorInstance.getSelection();

      if (!model || !selection) {
        hideToolbar();
        return false;
      }

      const selectedText = model.getValueInRange(selection);
      if (selectedText === "") {
        hideToolbar();
        return false;
      }

      return true;
    };

    this.editorInstance.onDidChangeCursorSelection((evt) => {
      const model = this.editorInstance.getModel();
      if (!shouldProceedWithEffects(model)) return;

      lineNumber = Math.min(
        evt.selection.startLineNumber,
        model.getLineCount(),
      );
      endLineNumber = Math.min(
        evt.selection.endLineNumber,
        model.getLineCount(),
      );
      endColumn = evt.selection.endColumn;

      const { top, left, leftOffset, topEndLine } = getPosition(model);

      if (this.aiToolbarTimeout) {
        clearTimeout(this.aiToolbarTimeout);
      }

      /**
       * Set position
       */
      this.aiToolbarTimeout = setTimeout(() => {
        updateToolbarPosition({ top, left, leftOffset, topEndLine });
      }, 1000);
    });

    this.editorInstance.onDidScrollChange((evt) => {
      // Update this everytime!
      scrollLeft = evt.scrollLeft;
      scrollTop = evt.scrollTop;

      if (this.aiToolbarTimeout) {
        clearTimeout(this.aiToolbarTimeout);
      }

      // Hide it first to avoid scroll trap
      hideToolbar();

      this.aiToolbarTimeout = setTimeout(() => {
        const model = this.editorInstance.getModel();
        if (!shouldProceedWithEffects(model)) return;

        const { top, left, leftOffset, topEndLine } = getPosition(model);

        // Ensure lines don't go out of bound
        lineNumber = Math.min(lineNumber, model.getLineCount());
        endLineNumber = Math.min(endLineNumber, model.getLineCount());

        updateToolbarPosition({ top, left, leftOffset, topEndLine });
      }, 1000);
    });
  }

  getVisibleLines(): [number, number] {
    if (!this.editorInstance) {
      return [0, 0];
    }

    const [visibleRange] = this.editorInstance.getVisibleRanges();

    if (!visibleRange) {
      return [0, 0];
    }

    return [visibleRange.startLineNumber, visibleRange.endLineNumber];
  }

  getCurrentLine(): number {
    if (!this.editorInstance) {
      return 0;
    }

    return this.editorInstance.getSelection()?.startLineNumber ?? 0;
  }

  handleScroll(): void {
    if (!this.editorInstance) {
      return;
    }
    // Only run scroll logic if the scroll position actually changed
    const scrollTop = this.editorInstance.getScrollTop();
    if (scrollTop === this.scrollTop) return;

    this.scrollTop = scrollTop;
    this.onScrollPositionUpdatedEmitter.fire();
  }

  revealLines([start, end]: [number, number], current: number): void {
    if (!this.editorInstance) {
      return;
    }

    if (current > start && current < end) {
      this.editorInstance.revealLineInCenter(current);
    } else {
      this.editorInstance.revealLinesInCenter(start, end);
    }
  }

  setSelection(selections: Monaco.IRange[]): void {
    if (!this.editorInstance || selections.length === 0) {
      return;
    }

    try {
      this.editorInstance.setSelections(
        selections.map(
          ({ endColumn, endLineNumber, startLineNumber, startColumn }) => {
            return {
              selectionStartColumn: startColumn,
              selectionStartLineNumber: startLineNumber,
              positionColumn: endColumn,
              positionLineNumber: endLineNumber,
            };
          },
        ),
      );
    } catch (err) {
      logger.error(err);
    }
  }

  updateOptions(newOptions: Monaco.editor.IEditorOptions): void {
    if (!this.editorInstance) {
      return;
    }

    this.editorInstance.updateOptions(newOptions);
  }

  toggleWordWrap(): void {
    if (!this.editorInstance) {
      return;
    }

    this.wordWrap = !this.wordWrap;
    this.editorInstance.updateOptions({
      wordWrap: this.wordWrap ? "on" : "off",
    });
  }

  focus() {
    this.editorInstance.focus();
  }

  dispose(): void {
    super.dispose();

    if (this.scrollDebounceDisposer != null) {
      this.scrollDebounceDisposer();
      this.scrollDebounceDisposer = null;
    }

    // Dispose of the editor instance
    if (this.editorInstance) {
      const savedViewState = this.editorInstance.saveViewState();

      if (savedViewState) {
        this.editorService.setViewState(this.fileId, savedViewState);
      }

      this.editorInstance.dispose();
    }
  }
}
