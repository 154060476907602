import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import React from "react";

import { styled } from "../../theme";
import { Box } from "../Box";
import { Icon } from "../Icon";
import type { IconType } from "../Icon";

export interface CollapsibleTriggerProps
  extends CollapsiblePrimitive.CollapsibleProps {
  iconType?: IconType;
  css?: React.ComponentProps<typeof StyledCollapsibleTrigger>["css"];
  contentProps?: React.ComponentProps<typeof StyledTriggerContent>;
}

const StyledCollapsibleTrigger = styled(CollapsiblePrimitive.Trigger, {
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "$1",
  borderRadius: "$1",
  color: "$neutral-fg-base",
  transition: "color $fast",
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:hover:not(:disabled)": {
    color: "$neutral-fg-medium",
  },
  "&:focus:not(:disabled)": {
    outlineOffset: "2px",
  },
});

const StyledTriggerContent = styled(Box, {});

const StyledTriggerIcon = styled(Icon, {});

const StyledIconContainer = styled(Box, {
  width: "$4",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform $fast",
});

export const CollapsibleTrigger: React.FC<CollapsibleTriggerProps> = ({
  children,
  iconType = "chevronSmallDown",
  open,
  css,
  contentProps,
  disabled,
}) => {
  return (
    <StyledCollapsibleTrigger css={css} disabled={disabled}>
      <StyledIconContainer
        style={{
          transform: open ? "rotate(0deg)" : "rotate(-90deg)",
        }}
      >
        <StyledTriggerIcon
          size={4}
          style={{
            opacity: disabled ? 0.5 : 1,
          }}
          type={iconType}
        />
      </StyledIconContainer>
      <StyledTriggerContent {...contentProps}>{children}</StyledTriggerContent>
    </StyledCollapsibleTrigger>
  );
};
