import type {
  AbsoluteWorkspacePath,
  IPitcherClient,
} from "@codesandbox/pitcher-client";
import { Disposable } from "@codesandbox/pitcher-client";
import type * as vscode from "vscode";

import { createMonacoGitUri } from "../../../../../utils/uri-utils";

export class QuickDiffProvider
  extends Disposable
  implements vscode.QuickDiffProvider
{
  constructor(private readonly pitcherClient: IPitcherClient) {
    super();
  }

  provideOriginalResource(uri: vscode.Uri) {
    const relativePath =
      this.pitcherClient.clients.fs.absoluteToRelativeWorkspacePath(
        uri.path as AbsoluteWorkspacePath,
      );

    return createMonacoGitUri(relativePath, "HEAD");
  }
}
