import type { Env } from "./types";

export const withBasePath = (env: Env, url: string): string => {
  const basePath = env.PUBLIC_BASE_PATH;

  if (basePath) return `${basePath}${url}`;

  return url;
};

/**
 * Given a Sandbox, it returns the title and description of the original template if necessary
 */
export const getSandboxTitleAndDescription = (sandbox: {
  title: string | null;
  alias: string | null;
  id: string;
  description?: string | null;
  git?: {
    username: string;
    repo: string;
    path?: string;
  } | null;
}) => {
  const { title, alias, id, description, git } = sandbox;

  const actualTitle = `${title || alias || id}`;

  let usedDescription = description;
  if (git && !description) {
    usedDescription = `Synced template from GitHub @${git.username}/${git.repo}`;
    if (git.path) {
      usedDescription += ` at /${git.path}`;
    }
  }

  return {
    title: actualTitle,
    description: usedDescription ?? "",
  };
};
