import logger from "../../features/utils/logger";

export const getLastTimeEventSent = (): number => {
  try {
    const lastTime = localStorage.getItem("csb-last-event-sent");

    if (lastTime === null) {
      return 0;
    }

    return +lastTime;
  } catch (e) {
    return 0;
  }
};

export const markLastTimeEventSent = (): void => {
  try {
    localStorage.setItem("csb-last-event-sent", Date.now().toString());
  } catch (e) {
    logger.warn(e);
  }
};
