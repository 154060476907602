import { createStitches } from "@stitches/react";
import type { CSS as StitchesCss } from "@stitches/react";
import type { ConfigType } from "@stitches/react/types/config";
import { base } from "prism-react/tokens/themes/base";

import { breakpoints } from "../tokens/breakpoints";
import { typography as typographyTokens } from "../tokens/typography";

type $Space = `$${keyof (typeof base)["space"]}`;

export const stitches = createStitches({
  media: breakpoints,
  theme: base,
  utils: {
    mx: (value: $Space) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: $Space) => ({
      marginTop: value,
      marginBottom: value,
    }),
    px: (value: $Space) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: $Space) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    typography: (value: `$${keyof typeof typographyTokens}`) => {
      return typographyTokens[
        value.replace("$", "") as keyof typeof typographyTokens
      ];
    },
    truncateText: (shouldTruncate: boolean) => {
      if (!shouldTruncate) return {};

      return {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      };
    },
    strokeText: (shouldStroke: boolean) => {
      if (!shouldStroke) return {};

      return {
        /**
         * Add stroke to text, where supported
         * @see https://css-tricks.com/adding-stroke-to-web-text/
         */
        "-webkit-text-stroke-width": "1px",
        "-webkit-text-stroke-color": "white", // TODO: token doesn't work here
        "-webkit-text-fill-color": "transparent",
      };
    },
  },
});

export type CSS = StitchesCss<typeof stitches>;
// Require all stitches theme fields, ensuring a string is always used.
export type StitchesTheme = Record<
  keyof ConfigType.Theme,
  Record<string, string>
>;

export const {
  config: themeConfig,
  createTheme,
  css: addStyles,
  getCssText,
  globalCss,
  keyframes,
  styled,
} = stitches;
