import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";

import { keyframes, styled } from "../../theme";

const slideDown = keyframes({
  "0%": {
    height: 0,
  },
  "100%": {
    height: "var(--radix-collapsible-content-height)",
  },
});

const slideUp = keyframes({
  "0%": {
    height: "var(--radix-collapsible-content-height)",
  },
  "100%": {
    height: 0,
  },
});

const StyledCollapsibleContent = styled(CollapsiblePrimitive.Content, {
  overflow: "hidden",

  "&[data-state='open']": {
    animation: `${slideDown} 125ms ease-in`,
  },

  "&[data-state='closed']": {
    animation: `${slideUp} 125ms ease-in`,
  },
});

export const CollapsibleContent: React.FC<
  CollapsiblePrimitive.CollapsibleProps
> = ({ children }) => {
  return <StyledCollapsibleContent>{children}</StyledCollapsibleContent>;
};
