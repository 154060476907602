import { useReducer } from "react";

import { createHookContext } from "utils/createHookContext";

import { reducer } from "./reducer";
import type { State } from "./types";

interface Props {
  initialState?: State;
}

export const useNotifications = createHookContext(
  ({ initialState = { state: "READY", items: {} } }: Props) => {
    const feature = useReducer(reducer, initialState);

    return feature;
  },
);

export const NotificationsConsumer = useNotifications.Consumer;

export const NotificationsProvider = useNotifications.Provider;
