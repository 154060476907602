export type Breakpoint = "sm" | "md" | "lg";

export const breakpointSizes: Record<Breakpoint, number> = {
  sm: 374,
  md: 836,
  lg: 1440,
};

export const breakpoints: Record<Breakpoint | "highDpr", string> = {
  sm: `(max-width: ${breakpointSizes.sm}px)`,
  md: `(min-width: ${breakpointSizes.sm}px) and (max-width: ${breakpointSizes.md}px)`,
  lg: `(min-width: ${breakpointSizes.md}px) and (max-width: ${breakpointSizes.lg}px)`,
  highDpr: `
  only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (-o-min-device-pixel-ratio: 13/10),
  only screen and (min-resolution: 120dpi)
  `,
};
