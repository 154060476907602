import { ot, Emitter } from "@codesandbox/pitcher-common";
import { isResultPayload, PitcherResponseStatus, } from "@codesandbox/pitcher-protocol";
import { PitcherMessageError } from "../../../common/PendingPitcherMessage";
/*
  This is the client version of the Model. It uses the ot.Client
  and the "sendOperation" + "onOperation" callbacks to communicate
  operational changes to the model, which can then be passed to
  Pitcher or the code editor respectively. It also stores the current
  selections, which can be used to render selections in the
  code editor
  
  CodeEditor <-> Document <-> Pitcher <-> FSProvider <-> Document
*/
export class Document {
    constructor(file, doc, messageHandler) {
        this.onIncomingOperationEmitter = new Emitter();
        this.onIncomingOperation = this.onIncomingOperationEmitter.event;
        this.onSendOperationEmitter = new Emitter();
        this.onSendOperation = this.onSendOperationEmitter.event;
        this.onOperationAckEmitter = new Emitter();
        this.onOperationAck = this.onOperationAckEmitter.event;
        this.onSelectionEmitter = new Emitter();
        this.onSelection = this.onSelectionEmitter.event;
        this.onErrorEmitter = new Emitter();
        this.onError = this.onErrorEmitter.event;
        this.onResyncEmitter = new Emitter();
        this.onResync = this.onResyncEmitter.event;
        this.clientSelections = new Map();
        this.syncState = "SYNCED";
        this.file = file;
        this.messageHandler = messageHandler;
        this.clientSelections = new Map(Object.entries(doc.clients).map(([clientId, selection]) => {
            return [clientId, selection];
        }));
        this.file.onWillClientJoin(({ clientId }) => {
            this.clientSelections.set(clientId, null);
        });
        this.file.onWillClientLeave(({ clientId }) => {
            this.clientSelections.delete(clientId);
        });
        this.otClient = this.createOTClient(this.file.content, doc.revision);
        function isResolvedDocumentOperationResult(message) {
            return (isResultPayload(message) &&
                message.method === "file/documentOperation" &&
                message.status === PitcherResponseStatus.RESOLVED);
        }
        /**
         * We have to ensure that the response from a document operation
         * happens synchronously with any other incoming operations which
         * comes in as notifications. Requests/responses are promises
         * and can cause "out of order" handling of these critical "IN ORDER" OT
         * messages
         */
        messageHandler.onMessage((message) => {
            if (isResolvedDocumentOperationResult(message) &&
                // This is for backwards compatability
                Boolean("id" in message.result ? message.result.id === this.file.id : true)) {
                this.otClient.serverAck();
            }
        });
        messageHandler.onNotification("file/documentOperation", ({ id, operation, revision, reason }) => {
            // We do not apply server operations while resyncing as message order ensures that
            // the response of the resync holds the latest state of the document and any operations
            // after that we will be in a synced state again. We do handle operations
            // from the editor though as those changes will be applid on top of the resynced
            // server document
            if (id !== this.file.id || this.syncState === "RESYNCING")
                return;
            try {
                const op = ot.TextOperation.fromJSON(operation);
                this.otClient.applyServerOperation(op, reason);
            }
            catch (err) {
                this.syncState = "RESYNCING";
                this.file.resync().catch((err) => {
                    this.onErrorEmitter.fire({
                        error: err.message,
                        metadata: {
                            type: "ot-resync-error",
                        },
                    });
                });
                this.onErrorEmitter.fire({
                    error: err.message,
                    metadata: {
                        type: "ot-received-invalid-operation",
                        operation: JSON.stringify(operation, null, 2),
                        revision,
                    },
                });
            }
        });
        messageHandler.onNotification("file/documentSelection", ({ id, selections, reason }) => {
            if (id !== this.file.id)
                return;
            Object.entries(selections).forEach(([clientId, selection]) => {
                if (this.clientSelections.has(clientId)) {
                    this.clientSelections.set(clientId, selection);
                }
            });
            this.onSelectionEmitter.fire({
                clientSelections: this.clientSelections,
                reason,
            });
        });
    }
    createOTClient(content, revision) {
        const otClient = new ot.Client(content, revision, (revision, operation) => {
            // We should not wait for this to resolve before handling
            // as it makes incoming operations while waiting for
            // response invalid
            this.sendDocumentOperation(operation, revision)
                // We do not "serverAck" here as it would be an async resolvement, we rather
                // serverAck listening to the response message directly (Defined in the constructor), which avoid race condition
                // with new incoming operations which are synchronously emitted
                .catch((err) => {
                // We do not trigger resyncs while already resyncing or we get an error that does not
                // come from Pitcher itself. These Pitcher unrelated errors are either because of disconnects
                // or we have manually disposed the message related to seamless forking. In these scenarios
                // a resync will happen when we reconnect
                if (this.syncState === "RESYNCING" ||
                    !(err instanceof PitcherMessageError)) {
                    return;
                }
                this.syncState = "RESYNCING";
                this.file.resync().catch((err) => {
                    this.onErrorEmitter.fire({
                        error: err.message,
                        metadata: {
                            type: "ot-resync-error",
                        },
                    });
                });
                this.onErrorEmitter.fire({
                    error: err.message,
                    metadata: {
                        type: "ot-send-document-operation",
                        operation: JSON.stringify(operation.toJSON(), null, 2),
                        code: err.code,
                        revision,
                    },
                });
            });
        }, async (revision) => {
            try {
                await this.sendDocumentAck(revision);
            }
            catch (err) {
                this.onErrorEmitter.fire({
                    error: err.message,
                    metadata: {
                        type: "ot-send-document-ack",
                        revision,
                    },
                });
            }
        });
        otClient.onDocumentChange(({ newContent }) => {
            this.file.updateContent(newContent);
        });
        otClient.onIncomingOperation((evt) => {
            // This callback is called when a new operation is
            // received from Pitcher and has been successfully
            // handled by the ot.Client. This is basically what
            // the code editor listens to, to update the document
            // and selections
            this.onIncomingOperationEmitter.fire({
                revision: evt.revision,
                clientSelections: this.clientSelections,
                operation: evt.operation,
                reason: evt.reason,
            });
        });
        otClient.onOperationAck(({ revision }) => {
            this.onOperationAckEmitter.fire({
                clientSelections: this.clientSelections,
                revision,
            });
        });
        return otClient;
    }
    /**
     * Resync is used when there is an internal issue with the OT Document, which means we can gracefully recover
     * changes made on the server with any pending/queued operations from the client
     */
    resync(content, revision) {
        const didChange = content !== this.file.content;
        this.otClient.syncServerDocument(content, revision);
        this.syncState = "SYNCED";
        this.onResyncEmitter.fire(didChange);
    }
    /**
     * In extreme cases we want to be able to reset the otClient to the server state. We do this when the code editor
     * is not able to apply its operations to the OT Document or it has problems applying server operations to the code editor. There
     * is not good way to recover from this and it should really never happen. But if it does happen we can completely reset the OTClient with
     * the current server document and its revision
     */
    reset(content, revision) {
        this.otClient.dispose();
        this.otClient = this.createOTClient(content, revision);
    }
    sendDocumentAck(revision) {
        return this.messageHandler.request({
            method: "file/documentAck",
            params: {
                id: this.file.id,
                revision,
            },
        });
    }
    sendDocumentOperation(operation, revision) {
        this.onSendOperationEmitter.fire({ operation, revision });
        return this.messageHandler.request({
            method: "file/documentOperation",
            params: {
                id: this.file.id,
                operation: operation.toJSON(),
                revision,
            },
        }, 
        // OT Operations should never be queued. When they fail the OT Client will resend them on resync. On seamless
        // fork we just dispose of the message immediately as a resync happens after the fork is done
        {
            seamlessForkStrategy: "dispose",
            queueForReconnect: false,
        });
    }
    /**
     * Apply a text operation to the ot-client
     *
     * @param operation the operation to apply
     */
    applyClient(operation) {
        this.otClient.applyClientOperation(operation);
    }
    sendSelection(selection, reason) {
        return this.messageHandler
            .request({
            method: "file/documentSelection",
            params: {
                id: this.file.id,
                selection,
                reason,
            },
        }, 
        // Selections happens so often and are immutable, so we do not need to queue them
        {
            queueForReconnect: false,
        })
            .then(() => {
            return;
        })
            .catch(() => {
            // Selections are immutable and happens very often,
            // no need to deal with any errors here
        });
    }
    dispose() {
        this.onIncomingOperationEmitter.dispose();
        this.onOperationAckEmitter.dispose();
        this.onErrorEmitter.dispose();
        this.onSelectionEmitter.dispose();
    }
}
