export const PitcherFeatureToVersionMap = {
    GIT_BRANCH_OUT_OF_SYNC: "0.209.11",
    SANDBOX_TO_REPOSITORY: "0.209.9",
    VM_METRICS: "0.212.0",
    GIT_LOGS_ON_PROTECTED_BRANCHES: "0.233.0",
    REMOTE_BRANCH_PRESENCE_DETECTION: "0.242.0",
    SET_BRANCH_PROTECTION: "0.248.4",
    SHELL_RENAME: "0.254.0",
    SEARCH_CASE_SENSITIVITY: "0.308.6",
    SETUP_ENV_DEVTOOL: "0.313.0",
};
