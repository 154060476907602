import * as RadixSelect from "@radix-ui/react-select";

import { separator } from "../../css/menu";
import type { CSS } from "../../theme";
import { styled } from "../../theme";
import { Icon } from "../Icon";

import { SelectItem } from "./SelectItem";

const StyledContent = styled(RadixSelect.Content, {
  backgroundColor: "$neutral-bg-medium",
  paddingTop: "$1",
  paddingBottom: "$1",
  borderRadius: "$2",
  boxShadow: "$popup",
  minWidth: "200px",
});

const StyledSeparator = styled(RadixSelect.Separator, separator);

const scrollButtonStyle: CSS = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "$4",
  transition: "color $fast",
  "&:hover": {
    color: "$neutral-fg-high",
  },
};

const StyledScrollUpButton = styled(
  RadixSelect.ScrollUpButton,
  scrollButtonStyle,
);

const StyledScrollDownButton = styled(
  RadixSelect.ScrollDownButton,
  scrollButtonStyle,
);

const StyledIcon = styled(Icon, {
  transition: "translate $slow",
});

const StyledTrigger = styled(RadixSelect.Trigger, {
  gap: "$1",
  height: "$7",
  px: "$2",
  borderRadius: "$2",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  color: "$neutral-fg-subtle",
  transition: "color $fast",

  truncateText: true,

  "> span": {
    truncateText: true,
  },

  "&:hover": {
    color: "$neutral-fg-medium",
  },

  [`&:hover ${StyledIcon}`]: {
    translate: "0 2px",
  },

  "&:disabled": {
    color: "$neutral-fg-low",
    cursor: "not-allowed",
  },

  variants: {
    variant: {
      solid: {
        background: "$neutral-bg-low",
        borderRadius: "$1",
      },
    },
  },
});

interface SelectProps extends RadixSelect.SelectProps {
  placeholder?: string;
  variant?: "solid";
  css?: CSS;
}

interface SelectComponent extends React.FC<SelectProps> {
  Item: typeof SelectItem;
  Separator: typeof StyledSeparator;
}

export const Select: SelectComponent = ({
  placeholder,
  children,
  variant,
  css,
  ...otherProps
}) => (
  <RadixSelect.Root {...otherProps}>
    <StyledTrigger css={css} variant={variant}>
      <RadixSelect.Value placeholder={placeholder} />
      <StyledIcon size={3} type="chevronDown" />
    </StyledTrigger>
    <RadixSelect.Portal>
      <StyledContent>
        <StyledScrollUpButton>
          <Icon size={3} type="chevronUp" />
        </StyledScrollUpButton>
        <RadixSelect.Viewport>{children}</RadixSelect.Viewport>
        <StyledScrollDownButton>
          <Icon size={3} type="chevronDown" />
        </StyledScrollDownButton>
      </StyledContent>
    </RadixSelect.Portal>
  </RadixSelect.Root>
);

Select.Item = SelectItem;
Select.Separator = StyledSeparator;
