import type { Event } from "vscode/vscode/vs/base/common/event";
import { Emitter } from "vscode/vscode/vs/base/common/event";
import { Disposable } from "vscode/vscode/vs/base/common/lifecycle";
import type {
  ITunnel,
  ITunnelProvider,
} from "vscode/vscode/vs/workbench/browser/web.api";

import type { MonacoConfig } from "../../../..";

export const createTunnelProvider = (
  getLatestMonacoConfig: () => MonacoConfig,
): ITunnelProvider => {
  class Tunnel extends Disposable implements ITunnel {
    public localAddress: string;
    public protocol = "https";

    private onDidDisposeEmitter = new Emitter<void>();
    public onDidDispose: Event<void> = this.onDidDisposeEmitter.event;

    constructor(
      public remoteAddress: { port: number; host: string },
      public portUrl: string,
    ) {
      super();

      this._register(
        getLatestMonacoConfig().pitcher.clients.port.onPortsUpdated(() => {
          if (
            !getLatestMonacoConfig()
              .pitcher.clients.port.getPorts()
              .find((p) => p.port === remoteAddress.port)
          ) {
            this.dispose();
          }
        }),
      );

      this.localAddress = `https://${portUrl}`;
      this._register({
        dispose: () => {
          this.onDidDisposeEmitter.fire();
          this.onDidDisposeEmitter.dispose();
        },
      });
    }
  }

  return {
    features: {
      privacyOptions: [],
      protocol: true,
      elevation: true,
    },
    showPortCandidate: async (_host: string, port: number, _detail: string) => {
      return getLatestMonacoConfig()
        .pitcher.clients.port.getPorts()
        .some((p) => p.port === port);
    },

    tunnelFactory: (tunnelOptions, _tunnelCreationOptions) => {
      const port = getLatestMonacoConfig()
        .pitcher.clients.port.getPorts()
        .find((port) => port.port === tunnelOptions.remoteAddress.port);

      if (port) {
        return Promise.resolve(
          new Tunnel(tunnelOptions.remoteAddress, port.url),
        );
      }

      return undefined;
    },
  };
};
