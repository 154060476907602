import type { clients } from "@codesandbox/pitcher-client";
import type { PitcherEvents } from "environment-interface/pitcher";
import type { SetupApi } from "environment-interface/pitcher/setup";

export const createSetupApi = (
  setupClient: clients.ISetupClient,
  pitcherEvents: PitcherEvents,
): SetupApi => {
  setupClient.onSetupProgressUpdate((progress) => {
    pitcherEvents.emit({
      type: "PITCHER:SETUP:PROGRESS",
      progress,
    });
  });

  setupClient.onSetupProgressError((error) => {
    pitcherEvents.emit({
      type: "PITCHER:SETUP:ERROR",
      error,
    });
  });

  return {
    getProgress() {
      return setupClient.getProgress();
    },
    skipStep(stepIndex) {
      setupClient
        .skipStep(stepIndex)
        .then((progress) => {
          pitcherEvents.emit({
            type: "PITCHER:SETUP:PROGRESS",
            progress,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SETUP:ERROR",
            error: error.message,
          });
        });
    },
    skipAll() {
      setupClient
        .skipAll()
        .then((progress) => {
          pitcherEvents.emit({
            type: "PITCHER:SETUP:PROGRESS",
            progress,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SETUP:ERROR",
            error: error.message,
          });
        });
    },
    restartFrom(stepIndex) {
      setupClient
        .setStep(stepIndex)
        .then((progress) => {
          pitcherEvents.emit({
            type: "PITCHER:SETUP:PROGRESS",
            progress,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:SETUP:ERROR",
            error: error.message,
          });
        });
    },
  };
};
