import { gql } from "@codesandbox/api";
import type { Branch } from "features/types/branch";
import type { ForkedSandbox, TemplateType } from "features/types/sandbox";
import { createCapabilitiesObject } from "features/utils/dto";

import type { BranchEditor, SandboxEditor } from "../types/editor";

export const handleBranchRename = (
  project: BranchEditor,
  newName: string,
): BranchEditor => {
  return {
    ...project,
    branch: newName,
  };
};

export const updateSandboxEditorData = (
  editor: SandboxEditor,
  newSandbox: ForkedSandbox,
): SandboxEditor => {
  const newSandboxEditor: SandboxEditor = {
    ...editor,
    ...newSandbox,
    workspace: newSandbox.team,
    isFrozen: false,
    title: newSandbox.title || newSandbox.alias || newSandbox.id,
    // We always have the alias
    alias: newSandbox.alias!,
    forkCount: 0,
    template: newSandbox.template as TemplateType,
  };

  return {
    ...newSandboxEditor,
    allowedCapabilities: createCapabilitiesObject(newSandboxEditor),
  };
};

// We optimistally update some fields here to avoid refetching the full
// project data
export const updateBranchEditorData = (
  editor: BranchEditor,
  newBranch: Pick<Branch, "id" | "contribution" | "name">,
): BranchEditor => {
  // You can not update the branch without moving away from protected
  const protectedBranch = false;

  // You can not update a branch without having write access to it
  const authorization = gql.ProjectAuthorization.WRITE;

  const oldBranchName = editor.branch;
  const newBranchEditor = {
    ...editor,
    id: newBranch.id,
    aiConsent: editor.aiConsent,
    branch: newBranch.name,
    contribution: newBranch.contribution,
    projectId: editor.projectId,

    protectedBranch,
    authorization,
    repoPermission: editor.repoPermission,

    subscriptionType: null,

    pullRequest: editor.pullRequest,

    // Old branch becomes the source/target for git flows
    sourceBranchName: oldBranchName,
    targetBranchName: oldBranchName,

    // These fields do not change when updating the branch
    defaultBranchName: editor.defaultBranchName,
    githubAppInstalled: editor.githubAppInstalled,
    workspace: editor.workspace,

    // These fields will change later when we handle the fork
    isPrivate: editor.isPrivate,
    owner: editor.owner,
    repo: editor.repo,
    parentRepo: editor.parentRepo,
    type: editor.type,
    resources: editor.resources,
  };

  return {
    ...newBranchEditor,
    allowedCapabilities: createCapabilitiesObject(newBranchEditor),
  };
};
