import { Disposable, Emitter } from "@codesandbox/pitcher-common";
export class NotificationClient extends Disposable {
    constructor(messageHandler) {
        super();
        this.onNotificationEmitter = new Emitter();
        this.onNotification = this.onNotificationEmitter.event;
        this.notifications = {};
        this.onDismissNotificationEmitter = new Emitter();
        this.onDismissNotification = this.onDismissNotificationEmitter.event;
        this.addDisposable(messageHandler.onMessage((message) => {
            if (message.method === "notification/notify") {
                const notification = new Notification(message.params);
                this.notifications[notification.id] = notification;
                this.addDisposable(notification.onNotificationResponse((response) => {
                    // We got a response for the notification, send it back to the host
                    delete this.notifications[notification.id];
                    messageHandler.request({
                        method: "notification/notifyResponse",
                        params: {
                            notificationId: notification.id,
                            response,
                        },
                    });
                }));
                this.onNotificationEmitter.fire(notification);
            }
            else if (message.method === "notification/dismiss") {
                const notification = this.notifications[message.params.notificationId];
                if (notification) {
                    notification.dismiss();
                    delete this.notifications[notification.id];
                    this.onDismissNotificationEmitter.fire(message.params);
                }
            }
        }));
    }
}
export class Notification extends Disposable {
    constructor(notification) {
        super();
        this.onNotificationResponseEmitter = this.addDisposable(new Emitter());
        this.onNotificationResponse = this.onNotificationResponseEmitter.event;
        this.onNotificationDismissEmitter = this.addDisposable(new Emitter());
        this.onNotificationDismiss = this.onNotificationDismissEmitter.event;
        this.id = notification.notificationId;
        this.type = notification.type;
        this.message = notification.message;
        this.actions = notification.actions;
    }
    /**
     * Called when Pitcher wants to dismiss the notification, this should not be used
     * by clients that implement the pitcher-client. To listen to dismiss events, use
     * the `onNotificationDismiss` event instead.
     */
    dismiss() {
        this.onNotificationDismissEmitter.fire();
    }
    respond(label) {
        this.onNotificationResponseEmitter.fire(label);
    }
}
