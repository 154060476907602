import React, { forwardRef } from "react";

import type { CSS } from "../../theme";
import { styled } from "../../theme";
import { toShadowRing } from "../../tokens/shadow";
import { onGridPx } from "../../tokens/space";
import type { IconType } from "../Icon";
import { Icon } from "../Icon";
import { Stack } from "../Stack";

const IconBox = styled("div", {
  display: "flex",
  alignItems: "center",
  transition: "color $fast",
});

const TransparentInput = styled("input", {
  background: "transparent",
  border: 0,
  cursor: "inherit",
  color: "inherit",
  outline: 0,
  px: "$0",
  width: "100%",

  "&:focus": {
    outlineColor: "transparent !important",
  },

  /**
   * Remove the icon in the search fields
   * which comes in the webkit default style
   */
  [`&[type="search"]::-webkit-search-decoration`]: {
    "-webkit-appearance": "none",
  },
  [`&[type="search"]::-webkit-search-results-button`]: {
    "-webkit-appearance": "none",
  },
  [`&[type="search"]::-webkit-search-results-decoration`]: {
    "-webkit-appearance": "none",
  },
  [`&[type="search"]::-webkit-search-cancel-button`]: {
    "-webkit-appearance": "none",
  },

  /**
   * Remove the input background color for webkit autocomplete
   */
  [`&:-webkit-autofill`]: {
    transition: "background-color 5000s ease-in-out 0s",
  },
  [`&:-webkit-autofill:hover`]: {
    transition: "background-color 5000s ease-in-out 0s",
  },
  [`&:-webkit-autofill:focus`]: {
    transition: "background-color 5000s ease-in-out 0s",
  },
  [`&:-webkit-autofill:active`]: {
    transition: "background-color 5000s ease-in-out 0s",
  },
});

const ALERT_OUTLINE = "1px solid $informative-alert-base";
const FOCUS_OUTLINE = "1px solid $accent-secondary-base";
const ERROR_OUTLINE = "1px solid $informative-error-base";

const styles: Record<
  "alert" | "base" | "error",
  Record<"hover" | "focus", CSS>
> = {
  alert: {
    hover: {
      outline: ALERT_OUTLINE,
      color: "$neutral-fg-high",
    },
    focus: {
      outline: ALERT_OUTLINE,
      color: "$neutral-fg-high",
    },
  },
  base: {
    hover: {
      boxShadow: toShadowRing("thick", "neutral-bg-focus"),
    },
    focus: {
      outline: FOCUS_OUTLINE,
    },
  },
  error: {
    hover: {
      outline: ERROR_OUTLINE,
      color: "$neutral-fg-high",
    },
    focus: {
      outline: ERROR_OUTLINE,
      color: "$neutral-fg-high",
    },
  },
};

const InputWrapper = styled("div", {
  background: "$neutral-bg-low",
  border: 0,
  borderRadius: "$1",
  color: "$neutral-fg-subtle",
  fontFamily: "$base",
  fontSize: "$base",
  height: "$7",
  lineHeight: onGridPx(4),
  outlineOffset: "0",
  overflow: "hidden",
  px: "$2",
  transition: "box-shadow $fast, color $fast",

  variants: {
    state: {
      disabled: {
        cursor: "not-allowed",
        color: "$neutral-fg-low",
      },
      readonly: {
        cursor: "default",
      },
      enabled: {
        [`&:hover ${IconBox}`]: {
          color: "$neutral-fg-high",
        },
        [`&:focus-within ${IconBox}`]: {
          color: "$neutral-fg-high",
        },
      },
    },
    variant: {
      alert: {
        "&:focus": styles.alert.focus,
        "&:focus-within": styles.alert.focus,
      },
      base: {
        "&:focus": styles.base.focus,
        "&:focus-within": styles.base.focus,
      },
      error: {
        "&:focus": styles.error.focus,
        "&:focus-within": styles.error.focus,
      },
    },
    width: {
      auto: {
        width: "auto",
      },
      full: {
        width: "100%",
      },
    },
  },

  compoundVariants: [
    {
      state: "enabled",
      variant: "alert",
      css: {
        outline: ALERT_OUTLINE,
        "&:hover:not(:focus)": styles.alert.hover,
        "&:hover:not(:focus-within)": styles.alert.hover,
      },
    },
    {
      state: "enabled",
      variant: "base",
      css: {
        "&:hover:not(:focus)": styles.base.hover,
        "&:hover:not(:focus-within)": styles.base.hover,
      },
    },
    {
      state: "enabled",
      variant: "error",
      css: {
        outline: ERROR_OUTLINE,
        "&:hover:not(:focus)": styles.error.hover,
        "&:hover:not(:focus-within)": styles.error.hover,
      },
    },
  ],
});

type WrapperProps = React.ComponentProps<typeof InputWrapper>;

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "width"> {
  css?: CSS;
  type?: "text" | "email" | "password" | "search";
  icon?: IconType | React.ReactElement;
  iconPosition?: "start" | "end";
  variant?: WrapperProps["variant"];
  width?: WrapperProps["width"];
}

const getInputState = (
  disabled?: boolean,
  readOnly?: boolean,
): WrapperProps["state"] => {
  if (disabled) {
    return "disabled";
  }

  if (readOnly) {
    return "readonly";
  }

  return "enabled";
};

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled,
      type = "text",
      icon,
      iconPosition = "start",
      width,
      css,
      variant = "base",
      readOnly,
      ...inputProps
    },
    ref,
  ) => {
    const state = getInputState(disabled, readOnly);

    if (!icon) {
      return (
        // Stitches issue
        // @ts-ignore
        <InputWrapper
          ref={ref}
          css={css}
          state={state}
          variant={variant}
          width={width}
          {...inputProps}
          as="input"
          disabled={disabled}
          readOnly={readOnly}
          spellCheck="false"
          type={type}
        />
      );
    }

    const renderIcon = (
      <IconBox>
        {typeof icon === "string" ? (
          <Icon size={4} type={icon as IconType} />
        ) : (
          icon
        )}
      </IconBox>
    );

    return (
      <InputWrapper
        as={Stack}
        css={css}
        gap={1}
        state={state}
        variant={variant}
        width={width}
        horizontal
      >
        {iconPosition === "start" && renderIcon}
        {/* @ts-ignore */}
        <TransparentInput
          ref={ref}
          {...inputProps}
          disabled={disabled}
          readOnly={readOnly}
          spellCheck="false"
          type={type}
        />
        {iconPosition === "end" && renderIcon}
      </InputWrapper>
    );
  },
);
