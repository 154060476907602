import { Emitter } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../common/AsyncValueStore";
export class SystemClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.hibernateEmitter = new Emitter();
        this.onHibernate = this.hibernateEmitter.event;
        this.metricsEmitter = new Emitter();
        this.onMetricsUpdated = this.metricsEmitter.event;
        this.initStatusEmitter = new Emitter();
        this.onInitStatusUpdate = this.initStatusEmitter.event;
        this.metrics = this.createMetricsValue();
        messageHandler.onNotification("system/hibernate", () => {
            this.hibernateEmitter.fire({});
        });
        messageHandler.onNotification("system/metrics", (metrics) => {
            this.metrics.set(metrics);
        });
        messageHandler.onNotification("system/initStatus", (statusUpdate) => {
            this.initStatusEmitter.fire(statusUpdate);
        });
    }
    createMetricsValue() {
        const metrics = new AsyncValueStore(undefined, () => this.messageHandler
            .request({
            method: "system/metrics",
            params: {},
        })
            .then((metrics) => metrics));
        metrics.onChange(({ value }) => this.metricsEmitter.fire(value));
        return metrics;
    }
    getMetrics() {
        return this.metrics.get();
    }
    startMetricsPollingAtInterval(interval = 5000) {
        const update = () => {
            this.messageHandler
                .request({
                method: "system/metrics",
                params: {},
            }, {
                // As this is an immutable request and it happens quite often we do not queue them
                queueForReconnect: false,
            })
                .then((m) => this.metrics.set(m))
                .catch(() => {
                // Happens in intervals, do not care about any failing metrids
            });
        };
        // Initial call to avoid waiting for the interval timeout
        update();
        const intervalRef = setInterval(update, interval);
        return () => {
            clearInterval(intervalRef);
        };
    }
    resync() {
        return;
    }
    update() {
        return this.messageHandler.request({
            method: "system/update",
            params: {},
        });
    }
    hibernate() {
        return this.messageHandler.request({
            method: "system/hibernate",
            params: {},
        });
    }
}
