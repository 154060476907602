import { Emitter, SliceList } from "@codesandbox/pitcher-client";
import type {
  Cuid,
  clients,
  RcRef,
  RelativeWorkspacePath,
} from "@codesandbox/pitcher-client";
import { DisposableStore } from "@codesandbox/pitcher-common";
import type {
  DiffEditorOptions,
  FileEditorOptions,
  MonacoConfig,
} from "environment-interface/monacoEditor";
import {
  getLatestMonacoConfig,
  onMonacoConfigChange,
} from "environment-interface/monacoEditor/browser/services/editor/vscode/monaco";
import type * as Monaco from "monaco-editor";
import QuickLRU from "quick-lru";

import { MonacoDiffEditor } from "./MonacoDiffEditor";
import { MonacoFileEditor } from "./MonacoFileEditor";
import { EditorConfig } from "./editor-config/EditorConfig";

export class EditorService {
  readonly monaco: typeof Monaco;
  monacoConfig: MonacoConfig;

  private fileEditors: SliceList<MonacoFileEditor> = new SliceList();
  private diffEditors: SliceList<MonacoDiffEditor> = new SliceList();

  private viewStates: QuickLRU<Cuid, Monaco.editor.ICodeEditorViewState> =
    new QuickLRU({ maxSize: 100 });

  editorConfigChangedEmitter = new Emitter<EditorConfig>();
  onEditorConfigChange = this.editorConfigChangedEmitter.event;

  private editorConfigFileRef?: RcRef<clients.IFile>;
  editorConfig = new EditorConfig();
  private lastEditorConfig = "";

  constructor(monaco: typeof Monaco) {
    this.monaco = monaco;

    let configCleanupStore = new DisposableStore();
    onMonacoConfigChange((newConfig) => {
      this.monacoConfig = newConfig;

      configCleanupStore.dispose();
      configCleanupStore = new DisposableStore();
      configCleanupStore.add(
        newConfig.pitcher.clients.fs.onFSSync(() => {
          try {
            this.readEditorConfig();
          } catch (err) {
            // console.error(err);
          }
        }),
      );
    });

    this.monacoConfig = getLatestMonacoConfig();
    this.readEditorConfig();
  }

  async readEditorConfig(): Promise<void> {
    const pitcher = this.monacoConfig.pitcher;
    const fileId = pitcher.clients.file.getFileIdFromPath(
      "/.editorconfig" as RelativeWorkspacePath,
    );

    // Handle file removal
    if (
      this.editorConfigFileRef &&
      this.editorConfigFileRef?.object.id !== fileId
    ) {
      this.editorConfigFileRef.dispose();
      this.editorConfigFileRef = undefined;
    }

    if (fileId && !this.editorConfigFileRef) {
      // Fetch the file
      const fileRef = await pitcher.clients.file.openFile(fileId);

      this.editorConfigFileRef = fileRef;

      // Attach the event listeners
      this.editorConfigFileRef.object.onDidContentChange((evt) => {
        if (typeof evt.newContent === "string") {
          this.handleEditorConfig(evt.newContent);
        }
      });
      if (typeof this.editorConfigFileRef.object.content === "string") {
        this.handleEditorConfig(this.editorConfigFileRef.object.content);
      }
    }
  }

  handleEditorConfig(newEditorConfig: string) {
    if (newEditorConfig !== this.lastEditorConfig) {
      this.lastEditorConfig = newEditorConfig;
      try {
        this.editorConfig = EditorConfig.parse(newEditorConfig);
        this.editorConfigChangedEmitter.fire(this.editorConfig);
      } catch (err) {
        // do nothing... user might be editing the editorconfig, we just keep using the last one
      }
    }
  }

  createFileEditor(options: FileEditorOptions): MonacoFileEditor {
    const editor = new MonacoFileEditor(options, this);
    const editorIdx = this.fileEditors.add(editor);
    editor.onWillDispose(() => {
      this.fileEditors.remove(editorIdx);
    });
    return editor;
  }

  createDiffEditor(options: DiffEditorOptions): MonacoDiffEditor {
    const editor = new MonacoDiffEditor(options, this);
    const editorIdx = this.diffEditors.add(editor);
    editor.onWillDispose(() => {
      this.diffEditors.remove(editorIdx);
    });
    return editor;
  }

  setViewState(
    modelCuid: Cuid,
    state: Monaco.editor.ICodeEditorViewState,
  ): void {
    this.viewStates.set(modelCuid, state);
  }

  getViewState(
    modelCuid: Cuid,
  ): Monaco.editor.ICodeEditorViewState | undefined {
    return this.viewStates.get(modelCuid);
  }

  getFormattingOptions(fileId: Cuid) {
    const filepath =
      this.monacoConfig.pitcher.clients.file.getPathFromFileId(fileId);
    if (filepath) {
      const options = this.editorConfig.getConfig(filepath);
      return {
        detectIndentation: !options.indentSize,
        insertSpaces: options.indentStyle !== "tab",
        tabSize: options.indentSize,
      };
    } else {
      return {
        detectIndentation: true,
        insertSpaces: undefined,
        tabSize: undefined,
      };
    }
  }

  dispose() {
    for (const fileEditor of this.fileEditors.values()) {
      fileEditor.dispose();
    }
    this.fileEditors = new SliceList();

    for (const diffEditor of this.diffEditors.values()) {
      diffEditor.dispose();
    }
    this.diffEditors = new SliceList();
  }
}
