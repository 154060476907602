import { Disposable, Emitter, GlobCache, sleep, } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../../common/AsyncValueStore";
import { BrowserLSP } from "./BrowserLSP";
import { LANGUAGES } from "./language-configs";
export class BrowserLanguageClient extends Disposable {
    constructor(fsClient, fileClient, npmRegistries) {
        super();
        this.globCache = new GlobCache();
        this.lspNotificationEmitter = this.addDisposable(new Emitter());
        this.onLspNotification = this.lspNotificationEmitter.event;
        this.lspServerRequestEmitter = this.addDisposable(new Emitter());
        this.onLspServerRequest = this.lspServerRequestEmitter.event;
        this.languagesUpdatedEmitter = this.addDisposable(new Emitter());
        this.onLanguagesUpdated = this.languagesUpdatedEmitter.event;
        this.languagesErrorEmitter = this.addDisposable(new Emitter());
        this.onLanguagesError = this.languagesErrorEmitter.event;
        this.languages = this.createLanguagesValue();
        this.browserLSP = this.addDisposable(new BrowserLSP(fsClient, fileClient, npmRegistries));
        this.addDisposable(this.browserLSP.onNotification((notification) => {
            this.lspNotificationEmitter.fire(notification);
        }));
        this.addDisposable(this.browserLSP.onRequest((request) => {
            this.lspServerRequestEmitter.fire(request);
        }));
    }
    updateRegistries(registries) {
        this.browserLSP.updateRegistries(registries);
    }
    createLanguagesValue() {
        const languages = new AsyncValueStore(LANGUAGES, async () => {
            await sleep(1);
            return LANGUAGES;
        });
        languages.onChange(({ value }) => {
            this.globCache.clear();
            this.languagesUpdatedEmitter.fire(value);
        });
        languages.onError((error) => this.languagesErrorEmitter.fire(error));
        return languages;
    }
    getLanguages() {
        return this.languages.get();
    }
    resync() {
        // Some places actually rely on this actually updating the language value async...
        setTimeout(() => {
            this.languages.set(LANGUAGES);
        }, 1);
    }
    sendLSPNotification(params) {
        this.browserLSP.sendLSPNotification(params);
    }
    // eslint-disable-next-line
    sendLSPRequest(params) {
        return this.browserLSP.sendLSPRequest(params);
    }
    sendLSPServerResponse(params) {
        return this.browserLSP.sendLSPServerResponse(params);
    }
    getLanguageId(filepath) {
        for (const language of this.languages.get()) {
            for (const pattern of language.globs) {
                const regex = this.globCache.get(pattern);
                if (filepath.match(regex)) {
                    return language.id;
                }
            }
        }
        // fallback to text
        return "text";
    }
}
