import { Disposable, Emitter } from "@codesandbox/pitcher-common";
import { States } from "class-states";
import { Channel } from "./Channel";
/**
 * Used to allow others to follow this client and follow other clients
 */
export class FollowChannel extends Disposable {
    constructor(name, messageHandler, currentClient) {
        super();
        this.name = name;
        this.messageHandler = messageHandler;
        this.currentClient = currentClient;
        this.onFollowMessageEmitter = this.addDisposable(new Emitter());
        this.onFollowMessage = this.onFollowMessageEmitter.event;
        this.onUnfollowMessageEmitter = this.addDisposable(new Emitter());
        this.onUnfollow = this.onUnfollowMessageEmitter.event;
        this.state = {
            following: new States({
                state: "NOT_FOLLOWING",
            }),
            followers: new States({
                state: "NO_FOLLOWERS",
            }),
        };
        this.hostChannel = new Channel(`${name}-${currentClient.clientId}`, messageHandler);
        this.hostChannel.subscribe();
        this.onFollowerJoin = this.hostChannel.onSubscribe;
        this.onFollowerLeave = this.hostChannel.onUnsubscribe;
        this.hostChannel.onSubscribe((clientId) => {
            const followingState = this.state.following.get();
            if (followingState.state === "FOLLOWING" &&
                followingState.clientId === clientId) {
                this.unfollow();
            }
            this.state.followers.match({
                FOLLOWERS: ({ clientIds }) => this.state.followers.set({
                    state: "FOLLOWERS",
                    clientIds: clientIds.concat(clientId),
                }),
                NO_FOLLOWERS: () => this.state.followers.set({
                    state: "FOLLOWERS",
                    clientIds: [clientId],
                }),
            });
        });
        this.hostChannel.onUnsubscribe((clientId) => {
            this.state.followers.match({
                FOLLOWERS: ({ clientIds }) => {
                    const updatedFollowers = clientIds.filter((currentClientId) => currentClientId !== clientId);
                    if (updatedFollowers.length) {
                        this.state.followers.set({
                            state: "FOLLOWERS",
                            clientIds: updatedFollowers,
                        });
                    }
                    else {
                        this.state.followers.set({
                            state: "NO_FOLLOWERS",
                        });
                    }
                },
                NO_FOLLOWERS: () => {
                    // Invalid state
                },
            });
        });
        this.onWillDispose(() => {
            this.hostChannel.dispose();
            this.followChannel?.dispose();
        });
    }
    sendAllFollowers(message) {
        if (this.hostChannel.subscribers.size > 1) {
            return this.hostChannel.sendAll(message);
        }
    }
    sendFollower(clientId, message) {
        if (this.hostChannel.subscribers.has(clientId)) {
            return this.hostChannel.send(new Set([clientId]), message);
        }
    }
    // We do not deal with the host leaving and joining, this is something the consuming client
    // can handle by subscribing to the client updates
    follow(clientId) {
        if (clientId === this.currentClient.clientId) {
            return;
        }
        // You can only follow one user at a time
        if (this.followChannel) {
            this.followChannel.unsubscribe();
        }
        this.followChannel = new Channel(`${this.name}-${clientId}`, this.messageHandler);
        this.followChannel.subscribe();
        this.followChannel.onMessage((message) => {
            this.onFollowMessageEmitter.fire(message);
        });
        this.state.following.set({
            state: "FOLLOWING",
            clientId,
        });
    }
    unfollow() {
        if (this.followChannel) {
            this.followChannel.dispose();
            delete this.followChannel;
            this.onUnfollowMessageEmitter.fire();
            this.state.following.set({
                state: "NOT_FOLLOWING",
            });
        }
    }
    resync() {
        this.followChannel?.resync();
        this.hostChannel.resync();
    }
}
