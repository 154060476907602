import { RadioItem, RadioGroup } from "@radix-ui/react-dropdown-menu";
import type { ComponentProps } from "react";
import type React from "react";

import { item } from "../../css/menu";
import { styled } from "../../theme";

const Item = styled(RadioItem, item);

type DropdownRadioProps = ComponentProps<typeof RadioItem>;

export const DropdownRadio: React.FC<DropdownRadioProps> = Item;
export const DropdownRadioGroup = RadioGroup;
