import { StandaloneServices } from "vscode/services";
import { IExtensionManagementService } from "vscode/vscode/vs/platform/extensionManagement/common/extensionManagement.service";
import { IWorkbenchExtensionEnablementService } from "vscode/vscode/vs/workbench/services/extensionManagement/common/extensionManagement.service";

export async function disableExtension(id: string) {
  const extensionEnablementService = StandaloneServices.get(
    IWorkbenchExtensionEnablementService,
  );
  const extensionService = StandaloneServices.get(IExtensionManagementService);
  const extensions = await extensionService.getInstalled();

  const extension = extensions.find((f) => f.identifier.id === id);

  if (extension) {
    extensionEnablementService.setEnablement([extension], 7);
  }
}

export async function enableExtension(id: string) {
  const extensionEnablementService = StandaloneServices.get(
    IWorkbenchExtensionEnablementService,
  );
  const extensionService = StandaloneServices.get(IExtensionManagementService);
  const extensions = await extensionService.getInstalled();

  const extension = extensions.find((f) => f.identifier.id === id);

  if (extension) {
    extensionEnablementService.setEnablement([extension], 9);
  }
}
