import type { VariantProps } from "@stitches/react";
import { palette } from "prism-react/tokens/palette";
import React, { forwardRef } from "react";

import { styled } from "../../theme";
import type { CSS } from "../../theme";
import { toShadowRing } from "../../tokens/shadow";
import { onGridPx } from "../../tokens/space";
import { Icon } from "../Icon";
import type { IconType, IconSize } from "../Icon";
import { LoadingDotsOverlay } from "../LoadingDots";
import { Stack } from "../Stack";
import { Text } from "../Text";

const BaseButton = styled("button", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  position: "relative",

  px: "$2",
  py: "$1",

  fontSize: "$base",
  fontFamily: "$base",
  fontWeight: "$medium",
  letterSpacing: "$base",
  lineHeight: onGridPx(4),
  textDecoration: "none",
  border: "1px solid transparent",

  height: "$7",
  minWidth: "$7", // works well with square icon-based buttons
  maxWidth: "100%", // prevent buttons from breaking out of parent

  outline: "none",
  borderRadius: "$2",
  transition: "$background, box-shadow $fast, border-color $fast, color $fast",

  variants: {
    variant: {
      primary: {
        backgroundColor: "$accent-primary-base",
        borderColor: "$neutral-bg-low",
        color: "$neutral-bg-subtle",
        "&:focus-visible": {
          outlineOffset: 1,
        },
      },
      secondary: {
        background: "$neutral-bg-focus",
        color: "$neutral-fg-medium",
        border: "1px solid $neutral-bg-high",
      },
      ghost: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        color: "$neutral-fg-subtle",
        px: "$2",
      },

      "ghost-inverted": {
        backgroundColor: "transparent",
        borderColor: "transparent",
        color: palette.purple[4],
        px: "$2",
        fontWeight: "normal",
      },
    },

    disabled: {
      true: { cursor: "not-allowed", opacity: 0.6 },
    },
  },

  compoundVariants: [
    {
      variant: "primary",
      disabled: false,
      css: {
        "&:hover": {
          borderColor: "transparent",
          boxShadow: toShadowRing("thick", "neutral-bg-focus"),
        },
        "&:active": {
          borderColor: "transparent",
          boxShadow: "none",
        },
      },
    },
    {
      variant: "secondary",
      disabled: false,
      css: {
        "&:hover": {
          background: "$neutral-bg-high",
          color: "$neutral-fg-high",
        },
        "&:active": {
          borderColor: "transparent",
          boxShadow: "none",
        },
        "&[aria-expanded='true']": {
          background: "$neutral-bg-medium",
          color: "$neutral-fg-high",
        },
      },
    },
    {
      variant: "ghost",
      disabled: false,
      css: {
        "&:hover": {
          background: "$neutral-bg-focus",
          color: "$neutral-fg-subtle",
        },
        "&:active": {
          borderColor: "$neutral-bg-focus",
        },
      },
    },
    {
      variant: "ghost-inverted",
      disabled: false,
      css: {
        "&:hover": {
          background: "$neutral-fg-medium",
        },
        "&:active": {
          borderColor: "$neutral-fg-medium",
        },
      },
    },
  ],
});

type StyleProps = Omit<VariantProps<typeof BaseButton>, "states">;

interface BaseProps extends StyleProps {
  children?: React.ReactNode;
  disabled?: boolean;
  css?: CSS;
  loading?: boolean;
  truncate?: boolean;
}

type AnchorElProps = React.AnchorHTMLAttributes<HTMLElement>;
type ButtonElProps = React.ButtonHTMLAttributes<HTMLElement>;

interface TextButtonProps {
  icon?: never;
  iconPosition?: never;
  iconSize?: never;
  gap?: 2;
}

interface IconButtonProps {
  icon: IconType;
  iconPosition?: "left" | "right";
  gap?: 0 | 1 | 2;
  iconSize?: IconSize;
}

type ButtonType = "button" | "submit";

export type ButtonProps = BaseProps &
  (
    | ({ as?: "button"; type?: ButtonType } & ButtonElProps & TextButtonProps)
    | ({ as?: "button"; type?: ButtonType } & ButtonElProps & IconButtonProps)
    | ({ as: "a"; type?: never } & AnchorElProps & TextButtonProps)
    | ({ as: "a"; type?: never } & AnchorElProps & IconButtonProps)
  );

const isIconButton = (icon: IconType | undefined): icon is IconType => {
  return icon !== undefined;
};

const IconWrapper = styled("span", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // Prevent icons from being squashed by long text
  flexShrink: 0,
});

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as: el = "button",
      css,
      children,
      disabled = false,
      icon = undefined,
      iconPosition = undefined,
      loading = false,
      variant = "ghost",
      gap = 2,
      iconSize = 4,
      truncate = true,
      onClick,
      onDoubleClick,
      ...buttonProps
    },
    ref,
  ) => {
    const sharedProps: Partial<ButtonProps> = {
      disabled: disabled || loading,
      type: el === "button" ? buttonProps.type : undefined,
      variant,
      "aria-disabled": disabled,
      onClick: disabled ? undefined : onClick,
      onDoubleClick: disabled ? undefined : onDoubleClick,
    };

    if (isIconButton(icon)) {
      const hasChildren = children !== undefined && children !== null;
      const iconEl = <Icon size={iconSize} type={icon} />;

      return (
        // Stitches issue
        // @ts-ignore
        <BaseButton
          ref={ref}
          as={el}
          {...sharedProps}
          {...buttonProps}
          css={{
            ...css,
            ...(hasChildren
              ? {}
              : {
                  px: "$1",
                }),
            /**
             * Ajust button to not have a fix height when the icon size is bigger than 28px
             **/
            ...(iconSize > 4 && { height: "auto" }),
          }}
        >
          <Stack
            css={{
              visibility: loading ? "hidden" : "visible",
              width: "100%",
              justifyContent: "center",
            }}
            gap={hasChildren ? gap : 0}
            horizontal
          >
            {iconPosition !== "right" && <IconWrapper>{iconEl}</IconWrapper>}
            {hasChildren && <Text truncate={truncate}>{children}</Text>}
            {iconPosition === "right" && <IconWrapper>{iconEl}</IconWrapper>}
          </Stack>
          {loading && <LoadingDotsOverlay />}
        </BaseButton>
      );
    } else {
      return (
        // Stitches issue
        // @ts-ignore
        <BaseButton
          ref={ref}
          as={el}
          css={css}
          {...sharedProps}
          {...buttonProps}
        >
          <Text
            style={{ visibility: loading ? "hidden" : "visible" }}
            truncate={truncate}
          >
            {children}
          </Text>
          {loading && <LoadingDotsOverlay />}
        </BaseButton>
      );
    }
  },
);
