import { env } from "environment-interface/env";
import { useCopyToClipboard } from "features/common/useCopyToClipboard";
import { Box, Button, styled, useTheme } from "prism-react";

export const ErrorDetails: React.FC<{
  versions?: Array<[string, string | undefined]>;
  error?: string;
}> = ({ versions = [], error }) => {
  const copy = useCopyToClipboard();

  const validVersions = [
    ["Version", env.PUBLIC_VERSION],
    ["Environment", env.PUBLIC_ENVIRONMENT],
    ...versions,
  ].filter((item): item is [string, string] => item[1] !== undefined);

  const { theme } = useTheme();
  const isDarkTheme = theme.type === "dark";

  return (
    <Box
      css={{
        marginTop: "$6",
        padding: "$6",
        backgroundColor: isDarkTheme ? "$neutral-bg-base" : "$neutral-bg-low",
        borderRadius: "$2",
        position: "relative",
      }}
    >
      <Button
        css={{ position: "absolute", top: "$1", right: "$1" }}
        icon="copy"
        onClick={() => {
          const formatVersions = validVersions
            .map(([name, version]) => `${name}: ${version}`)
            .join("\n");

          copy(
            `Error: ${error}
${formatVersions}`,
          );
        }}
      />
      <Box css={{ maxHeight: 140, overflow: "auto" }}>
        <code>{error}</code>
      </Box>

      <code>
        <Table>
          {validVersions.map(([name, version]) => (
            <tr key={`version-list-item-${name}`}>
              <td>{name}:</td> <TableValue>{version}</TableValue>
            </tr>
          ))}
        </Table>
      </code>
    </Box>
  );
};

const Table = styled("table", {
  width: "60%",
  marginTop: "$4",
});
const TableValue = styled("td", {
  color: "$neutral-fg-base",
});
