import type { WorkspaceContentFragment } from "gql-types/documents/web-client";
import { useWorkspacesQuery } from "queries/shared";
import { useEffect, useState } from "react";

import { useSession } from "./api/useSession";

type UseUserWorkspacesReturn = {
  workspaces: WorkspaceContentFragment[];
  primaryWorkspaceId: string | null;
  refetch: () => void;
};

export const useUserWorkspaces = (): UseUserWorkspacesReturn => {
  const [session] = useSession();
  const [shouldFetch, refetch] = useState(true);
  const isAuthenticated = session.state === "AUTHENTICATED";
  const { data } = useWorkspacesQuery(isAuthenticated && shouldFetch);

  useEffect(() => {
    /**
     * Hack: trigger a new react re-render to force the
     * useWorkspacesQuery to refetch and update the data
     */
    if (shouldFetch === false) {
      refetch(true);
    }
  }, [shouldFetch]);

  return {
    primaryWorkspaceId: data?.me?.primaryWorkspaceId || null,
    workspaces: data?.me?.workspaces || [],
    refetch: () => refetch(false),
  };
};
