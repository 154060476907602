/**
 * DTO = Data Transfer Object.
 *
 * These utils map Data Transfer Objects, to related React state.
 */

import type { protocol } from "@codesandbox/pitcher-client";
import {
  hasBranchWriteAccess,
  hasSandboxPermission,
} from "isomorphic/permission";

import type { Branch, BranchDTO } from "../types/branch";
import type { Capability, Editor } from "../types/editor";
import type { CommandShell, TerminalShell, Shell } from "../types/shell";

export const fromClientsDTO = (
  clients: protocol.client.ClientJSON[],
  currentClient: protocol.client.ClientJSON,
): Record<string, protocol.client.ClientJSON> => {
  return clients.reduce(
    (
      acc: Record<string, protocol.client.ClientJSON>,
      client: protocol.client.ClientJSON,
    ) => {
      /*
       * We can infer this's the current client, and prevent
       * it being anonymous when the currentClient is authenticated
       */
      if (
        client.clientId === currentClient.clientId &&
        client.username === "anonymous"
      ) {
        acc[client.clientId] = currentClient;
      } else {
        acc[client.clientId] = client;
      }

      return acc;
    },
    {},
  );
};

export const fromBranchDTO = (branchDTO: BranchDTO): Branch => ({
  id: branchDTO.id,
  name: branchDTO.branch,
  lastAccessedAt: branchDTO.lastAccessedAt,
  contribution: branchDTO.contribution,
});

export const createCapabilitiesObject = (
  project: Editor,
): Record<Capability, boolean> => {
  const writeAccess =
    project.type === "branch"
      ? hasBranchWriteAccess(project.authorization)
      : hasSandboxPermission(project.authorization, "write_project");
  const fullAccess =
    writeAccess && !(project.type === "branch" && project.protectedBranch);
  const isDevbox = project.type === "sandbox" && project.isCloud;

  return {
    branch: writeAccess && !(project.type === "branch" && project.contribution),
    sandbox: writeAccess && project.type === "sandbox",
    vscode: fullAccess,
    secrets: writeAccess,
    restartContainer: writeAccess,
    renameProject: fullAccess,
    createLiveSession: isDevbox && fullAccess,
  };
};

export const fromCommandShellDTO = (
  commandShell: protocol.shell.CommandShellDTO,
): CommandShell => ({
  id: commandShell.shellId,
  name: commandShell.name,
  type: commandShell.shellType,
  status: commandShell.status,
  command: commandShell.startCommand,
  exitCode: commandShell.exitCode,
});

export const fromTerminalShellDTO = (
  terminalShell: protocol.shell.TerminalShellDTO,
): TerminalShell => ({
  id: terminalShell.shellId,
  name: terminalShell.name,
  type: terminalShell.shellType,
  status: terminalShell.status,
  owner: terminalShell.ownerUsername,
  exitCode: terminalShell.exitCode,
});

export const fromShellDTO = (shell: protocol.shell.ShellDTO): Shell => {
  return shell.shellType === "COMMAND"
    ? fromCommandShellDTO(shell)
    : fromTerminalShellDTO(shell);
};
