import type { StitchesTheme } from "../../theme/stitches";
import * as colors from "../colors";
import { fontWeights } from "../fontWeights";
import { space } from "../space";
import { fontSizes, letterSpacings, lineHeights, fonts } from "../typography";

// Enforce theme restriction and return restricted tokens as well
export const createBaseTokens = <Tokens extends StitchesTheme>(
  themeTokens: Tokens,
): Tokens => themeTokens;

// We use the same unit for size and space
const sizes = space;

export const base = createBaseTokens({
  borderStyles: {},
  borderWidths: {},
  colors: {
    ...colors.accent,
    ...colors.neutralBg,
    ...colors.neutralFg,
    ...colors.informative,
    ...colors.support,
  },
  fontSizes,
  fontWeights,
  fonts,
  letterSpacings,
  lineHeights,
  radii: {
    1: "2px",
    2: "4px",
    3: "8px",
  },
  shadows: {
    popup:
      "0px 2px 7px rgba(0, 0, 0, 0.15), 0px -2px 7px rgba(0, 0, 0, 0.15), 0px 5px 17px rgba(0, 0, 0, 0.3)",
  },
  sizes,
  space,
  transitions: {
    fast: "125ms ease-in",
    slow: "300ms cubic-bezier(0.2, 1, 0.2, 1)",

    // Reusable transition combinations
    transform: "transform $slow",
    background: "background $fast",
    opacity: "opacity $fast",
  },
  zIndices: {
    base: "1",
    /** an overlay behind menus */
    menuOverlay: "2",
    /** slide out menus */
    menu: "3",
    /** a generic overlay */
    overlay: "4",
    /**
     * dialog/modals
     * Higher than any Monaco z-index, which is 10
     */
    dialog: "11",
    /** command palette should be above almost everything else */
    commandPalette: "12",
    /** nothing should be above this. Please don't use 9999 or similar.. */
    top: "20",
  },
});

// All design tokens
export type Tokens = typeof base;

// Tokens that can be customised per theme
export type ThemeTokenOverrides = Pick<Tokens, "colors">;
