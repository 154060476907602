import React from "react";

import { Box } from "../Box";
import { Icon } from "../Icon";
import { LoadingDotsOverlay } from "../LoadingDots";
import { Stack } from "../Stack";
import { Text } from "../Text";

import { ComboButton } from "./ComboButton";
import { StyledButton, StyledMessage } from "./elements";

export type Variant = "trial" | "warning" | "error" | "info";
type Corners = "rounded" | "straight" | "rounded-bottom";

type AnchorElProps = React.AnchorHTMLAttributes<HTMLElement>;
type ButtonElProps = React.ButtonHTMLAttributes<HTMLElement>;

type ButtonProps =
  | ({ as?: "button"; loading?: boolean } & ButtonElProps)
  | ({ as: "a"; type?: never } & AnchorElProps);

type MessageActionProps = {
  children: string;
  variant?: Variant;
} & ButtonProps;

export const MessageAction: React.FC<MessageActionProps> = ({
  variant = "trial",
  children,
  ...buttonProps
}) => {
  const props = buttonProps ?? {};

  return (
    <Box css={{ position: "relative", flexShrink: 0 }}>
      {/* @ts-ignore */}
      <StyledButton variant={variant} {...props}>
        {children}
      </StyledButton>
      {"loading" in buttonProps && buttonProps.loading ? (
        <LoadingDotsOverlay />
      ) : null}
    </Box>
  );
};

type MessageMultiActionsProps = React.ComponentProps<typeof ComboButton>;

export const MessageMultiActions = ({
  children,
  variant,
  ...props
}: MessageMultiActionsProps) => {
  return (
    <ComboButton variant={variant} {...props}>
      {children}
    </ComboButton>
  );
};

export interface MessageStripeProps {
  children: React.ReactNode;
  justify?: "center" | "space-between";
  onDismiss?: () => void;
  variant?: Variant;
  corners?: Corners;
}

const MessageStripe = ({
  children,
  justify = "center",
  onDismiss,
  variant = "trial",
  corners = "straight",
}: MessageStripeProps) => {
  let hasAction = false;

  const augmentedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === MessageAction) {
      hasAction = true;

      // eslint-disable-next-line
      // @ts-ignore
      return React.cloneElement<Partial<MessageActionProps>>(child, {
        variant,
      });
    }

    if (React.isValidElement(child) && child.type === MessageMultiActions) {
      hasAction = true;

      // eslint-disable-next-line
      // @ts-ignore
      return React.cloneElement<Partial<MessageMultiActionsProps>>(child, {
        variant,
      });
    }

    return <Text>{child}</Text>;
  });

  return (
    <StyledMessage corners={corners} hasAction={hasAction} variant={variant}>
      <Stack
        css={{
          alignItems: "center",
          justifyContent: justify,
          marginRight: onDismiss ? "$11" : 0,
        }}
        gap={2}
        horizontal
        selectable
      >
        {augmentedChildren}
      </Stack>
      {onDismiss ? (
        <StyledButton
          css={{
            position: "absolute",
            right: "$4",
            top: "50%",
            transform: "translateY(-50%)",
            color: "currentColor",
          }}
          onClick={onDismiss}
          title="Dismiss"
        >
          <Icon size={4} type="close" />
        </StyledButton>
      ) : null}
    </StyledMessage>
  );
};

MessageStripe.Action = MessageAction;
MessageStripe.MultiActions = MessageMultiActions;
MessageStripe.MultiActionsItem = ComboButton.Item;

export { MessageStripe };
