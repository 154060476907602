/* eslint-disable */
import React, {
  ComponentProps,
  Context,
  createContext,
  useContext,
} from "react";

type Hook<T extends {}> = (props: T) => any;

type HookContext<T extends Hook<any>> = T extends Hook<infer P>
  ? {
      context: Context<ReturnType<T>>;
      Provider: React.FC<P & { children: React.ReactNode }>;
      Consumer: Context<ReturnType<T>>["Consumer"];
    }
  : never;

/**
 * Allows you to create a hook with an accompanying context, guaranteeing that all consumers
 * of the hook consumes the same instance
 *
 * const useSomething = createHookContext((props: { foo: string }) => useState(props.foo))
 *
 * <useSomething.Provider foo="bar">
 *
 * </useSomething.Provider>
 */
export const createHookContext = <T extends Hook<any>>(
  hook: T,
): (() => ReturnType<T>) & HookContext<T> => {
  const context = createContext(null);

  const contextHook = () => useContext(context);

  contextHook.Provider = (props: any) => (
    <context.Provider value={hook(props)}>{props.children}</context.Provider>
  );

  contextHook.context = context;

  contextHook.Consumer = context.Consumer;

  return contextHook as any;
};

/**
 * Allows you to compose multiple context hooks together into a single
 * Provider.
 *
 * const Provider = createComposedHookContextsProvider({ useSomething, useSomethingElse })
 *
 * <Provider useSomething={{foo: 'bar' }} useSomethingElse={{}}>
 *
 * </Provider>
 */
export const createComposedHookContextsProvider = <
  T extends {
    [name: string]: HookContext<Hook<any>>;
  },
>(
  hooks: T,
): React.FC<
  {
    [K in keyof T]: Omit<ComponentProps<T[K]["Provider"]>, "children">;
  } & {
    children: React.ReactNode;
  }
> => {
  return (props) => {
    return Object.keys(hooks).reduceRight(
      (acc, hookName) =>
        // @ts-ignore
        React.createElement(hooks[hookName]!.Provider, props[hookName], acc),
      props.children,
    ) as any;
  };
};
