import { track } from "@amplitude/analytics-browser";
import { createEmitter } from "@codesandbox/environment-interface";
import type { IPitcherClient } from "@codesandbox/pitcher-client";
import {
  PitcherVMClient,
  protocol,
  PitcherMessageError,
  initPitcherClient,
} from "@codesandbox/pitcher-client";
import { initPitcherBrowserClient } from "@codesandbox/pitcher-client/dist/esm/browser";
import { captureException } from "@sentry/browser";
import type { CsbApi } from "environment-interface/csbApi";
import { getDevPitcherManagerURL, env } from "environment-interface/env";
import { PitcherClientConnectionState } from "environment-interface/pitcher";
import type {
  LegacyPitcherClient,
  PitcherEvent,
  PitcherInstanceManager,
} from "environment-interface/pitcher";
import type { BranchDTO } from "features/types/branch";
import logger from "features/utils/logger";

import { csbApi } from "utils/csbApi";

import { createClientApi } from "./createClients";
import { createFileApi } from "./createFile";
import { createFsApi } from "./createFs";
import { createGitApi } from "./createGit";
import { createLanguageApi } from "./createLanguage";
import { createPortApi } from "./createPort";
import { createSetupApi } from "./createSetup";
import { createShellApi } from "./createShell";
import { createTaskApi } from "./createTask";
import { createVersionsApi } from "./createVersions";

interface PitcherContainerConfig {
  csbApi: CsbApi;
  usePitcherManagerThroughApi: boolean;
  pitcherManagerURL: string;
}

const toSeconds = (time: number) => {
  return Math.round(time / 1000);
};

const createPitcherApi = (
  pitcherClient: IPitcherClient,
  csbApi: CsbApi,
): {
  client: IPitcherClient;
  legacyPitcherClient: LegacyPitcherClient;
} => {
  const pitcherEvents = createEmitter<PitcherEvent>();

  let connectionState: PitcherClientConnectionState =
    PitcherClientConnectionState.Connecting;

  const apis = {
    file: createFileApi(pitcherClient.clients.file, pitcherEvents),
    fs: createFsApi(pitcherClient.clients.fs, pitcherEvents),
    shell: createShellApi(
      pitcherClient.clients.shell,
      pitcherClient.workspacePath,
      pitcherEvents,
    ),
    port: createPortApi(pitcherClient.clients.port),
    language: createLanguageApi(pitcherClient.clients.language, pitcherEvents),
    clients: createClientApi(pitcherClient.clients.client, pitcherEvents),
    git: createGitApi(pitcherClient.clients.git, pitcherEvents),
    setup: createSetupApi(pitcherClient.clients.setup, pitcherEvents),
    task: createTaskApi(pitcherClient.clients.task, pitcherEvents),
    versions: createVersionsApi(env.PUBLIC_PITCHER_MANAGER_URL, pitcherEvents, {
      pitcher: pitcherClient.pitcherVersion,
      pitcherManager: pitcherClient.pitcherManagerVersion,
    }),
  };

  const currentClient = pitcherClient.currentClient;

  if (pitcherClient instanceof PitcherVMClient) {
    pitcherClient.onMessageError(({ message, extras }) => {
      captureException(message, {
        extra: extras,
      });
    });

    pitcherClient.onMessage((msg: unknown) => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      if ((window as any).__CSB__SHOW_PITCHER_MESSAGES) {
        logger.log(`[pitcher-message]:`, msg);
      }
    });
  }

  function emitConnected() {
    pitcherEvents.emit({
      type: "PITCHER:CONNECTED",
      shells: apis.shell.getShells(),
      ports: apis.port.getPorts(),
      currentClient: pitcherClient.currentClient,
      clients: apis.clients.getClients(),
      setupProgress: apis.setup.getProgress(),
      tasks: apis.task.getTasks().tasks,
      cluster: pitcherClient.cluster,
      versions: {
        pitcher: pitcherClient.pitcherVersion,
        pitcherManager: pitcherClient.pitcherManagerVersion,
      },
    });
  }

  pitcherClient.onStateChange(async (state, prevState) => {
    if (prevState.state === "RECONNECTING" && state.state === "CONNECTED") {
      const now = Date.now();
      track("reconnected", {
        event_source: "editor",
        attempts: prevState.attempt,
        disconnectReason: prevState.disconnectReason,
        lastActivityInSeconds: toSeconds(now - state.lastActivity),
        lastFocusInSeconds: toSeconds(now - state.lastFocus),
        wasFocused: prevState.wasFocused,
        disconnectedAt: toSeconds(now - prevState.disconnectedAt),
      });
    }

    switch (state.state) {
      case "CONNECTED": {
        connectionState = PitcherClientConnectionState.Connected;
        emitConnected();
        break;
      }
      case "DISCONNECTED": {
        pitcherEvents.emit({
          type: "PITCHER:DISCONNECTED",
          wasClean: state.wasClean,
          wasNotFound: state.reason.toLowerCase().includes("not found"),
        });
        connectionState = PitcherClientConnectionState.Disconnected;
        break;
      }
      case "RECONNECTING": {
        pitcherEvents.emit({
          type: "PITCHER:RECONNECTING",
          attempt: state.attempt,
        });

        break;
      }
    }
  });

  connectionState = PitcherClientConnectionState.Connected;
  emitConnected();

  return {
    client: pitcherClient,
    legacyPitcherClient: {
      events: pitcherEvents,
      get currentClient() {
        return currentClient;
      },
      get fs() {
        return apis.fs;
      },
      get file() {
        return apis.file;
      },
      get shell() {
        return apis.shell;
      },
      get port() {
        return apis.port;
      },
      get language() {
        return apis.language;
      },
      get clients() {
        return apis.clients;
      },
      get git() {
        return apis.git;
      },
      get setup() {
        return apis.setup;
      },
      get connectionState() {
        return connectionState;
      },
      get versions() {
        return apis.versions;
      },
      get task() {
        return apis.task;
      },
      dispose() {
        pitcherClient.dispose();
      },
      reconnect() {
        pitcherClient.reconnect().catch(() => {
          // This can throw an error, but is handled by state changes
        });
      },
      async renameBranch({ branchId, oldName, newName }) {
        pitcherEvents.emit({
          type: "PITCHER:SET_BRANCH_NAME",
          name: newName,
        });

        const pitcherGitClient = pitcherClient.clients.git;

        if (!pitcherGitClient) {
          throw new Error("No Pitcher client available");
        }

        /*
          First we rename the branch on the BE
        */
        try {
          await csbApi.rest.patch<BranchDTO>({
            path: `/beta/sandboxes/branches/${branchId}`,
            data: {
              name: newName,
            },
          });

          try {
            /*
              Second step is to rename the branch on the VM
            */

            await pitcherGitClient.renameBranch(oldName, newName);
          } catch (error) {
            // Revert UI + pitcher operation
            pitcherEvents.emit({
              type: "PITCHER:SET_BRANCH_NAME",
              name: oldName,
            });

            if (
              PitcherMessageError.matchCode(
                error,
                protocol.PitcherErrorCode.GIT_OPERATION_IN_PROGRESS,
              )
            ) {
              pitcherEvents.emit({
                type: "PITCHER:BRANCH_RENAME_ERROR",
                error: "Another git operation is in progress, try again later.",
              });
            } else if (error instanceof Error) {
              // TODO: This should not happen unless there's a catastrophic error,
              // but we need to handle it somehow if it remains here
              pitcherEvents.emit({
                type: "PITCHER:BRANCH_RENAME_ERROR",
                error: error.message,
              });
            }
          }

          // Success flow
          pitcherEvents.emit({ type: "PITCHER:BRANCH_RENAME_FINISHED" });
        } catch (error) {
          await pitcherGitClient.renameBranch(newName, oldName);
          pitcherEvents.emit({
            type: "PITCHER:SET_BRANCH_NAME",
            name: oldName,
          });

          // Ideally, we should have error codes to map
          // the specific errors. While it's not implemented
          // we specifically handle renaming to an already
          // existing name. Otherwise, we should a default
          // message.
          if (
            error instanceof Error &&
            error.message.includes("has already been taken")
          ) {
            pitcherEvents.emit({
              type: "PITCHER:BRANCH_RENAME_TAKEN_ERROR",
              nameTaken: newName,
            });
          } else {
            pitcherEvents.emit({
              type: "PITCHER:BRANCH_RENAME_ERROR",
              error: "Cannot persist rename operation",
            });
          }
        }
      },
    },
  };
};

type PitcherInstanceRef = {
  id: string;
  client?: IPitcherClient;
  legacyPitcherClient?: LegacyPitcherClient;
  promise: Promise<IPitcherClient>;
};

/**
 * This whole thing is a legacy thing. It also has a lot of hacks as we have not been able to take the time to remove this legacy layer
 */
export const pitcherInstanceManager = (
  config: PitcherContainerConfig,
): PitcherInstanceManager => {
  // Instances indexed by branchId
  let instance: PitcherInstanceRef | undefined;

  return {
    getBrowserInstance(sandboxId, seamlessFork) {
      if (instance && sandboxId !== instance.id) {
        // We dispose of the resolved client, as it might still be resolving
        instance.promise.then((client) => client.dispose());
      }

      const newInstance: PitcherInstanceRef = {
        id: sandboxId,
        promise: initPitcherBrowserClient(csbApi, sandboxId, seamlessFork).then(
          (client) => {
            newInstance.client = client;

            return client;
          },
        ),
      };

      instance = newInstance;

      return newInstance.promise;
    },
    getInstance(opts, initStatusCb) {
      if (instance && opts.instanceId === instance.id) {
        return instance.promise;
      }

      if (instance && opts.instanceId !== instance.id) {
        // We dispose of the resolved client, as it might still be resolving
        instance.promise.then((client) => client.dispose());
      }

      const newInstance: PitcherInstanceRef = {
        id: opts.instanceId,
        promise: initPitcherClient(opts, initStatusCb).then((client) => {
          newInstance.client = client;

          return client;
        }),
      };

      instance = newInstance;

      return newInstance.promise;
    },
    getLegacyPitcherClient() {
      if (!instance?.client) {
        throw new Error(
          "You are requesting the legacy pitcher client from an unresolved pitcher client",
        );
      }

      if (!instance.legacyPitcherClient) {
        return (instance.legacyPitcherClient = createPitcherApi(
          instance.client,
          config.csbApi,
        ).legacyPitcherClient);
      }

      return instance.legacyPitcherClient;
    },
    async stopPitcherEnvironment(instanceId) {
      if (instance?.id !== instanceId) {
        throw new Error(
          "You are trying to reastart a non active Pitcher instance",
        );
      }

      const deletePromise = (() => {
        if (config.usePitcherManagerThroughApi) {
          const pitcherManagerURLOverride = getDevPitcherManagerURL();
          const query: Record<string, string> = pitcherManagerURLOverride
            ? { pitcherManagerURL: pitcherManagerURLOverride }
            : {};
          return config.csbApi.rest.delete({
            path: `/beta/sandboxes/branches/${instanceId}/instance`,
            query: query,
          });
        }

        const devJwt = config.csbApi.devJwt.get();

        return fetch(
          `${
            config.pitcherManagerURL.includes("api/v1")
              ? config.pitcherManagerURL
              : config.pitcherManagerURL + "/api/v1"
          }/sandboxes/branches/${instanceId}`,
          {
            headers: devJwt ? { Authorization: `Bearer ${devJwt}` } : {},
            method: "DELETE",
          },
        );
      })();

      deletePromise
        .catch(() => {
          if (instance?.id === instanceId) {
            track("restart_failed");
          }
          return;
        })
        .finally(() => {
          // We will reload regardless of successful stop or not
          document.location.reload();
        });
    },
    revertSeamlessFork() {
      if (!instance || !instance.client) {
        throw new Error(
          "You are trying to change Pitcher instance, but there is no instance",
        );
      }

      instance.client.revertSeamlessFork();
    },
    changeInstance(_, data) {
      if (!instance || !instance.client) {
        throw new Error(
          "You are trying to change Pitcher instance, but there is no instance",
        );
      }

      instance.id = data.data.id;

      instance.client
        .changeInstance(data.data.id)
        .then(() => {
          if (instance?.id === data.data.id) {
            instance?.legacyPitcherClient?.events.emit({
              type: "PITCHER:CONNECTION_CHANGED_SUCCESS",
              data,
            });
          }
        })
        .catch((error: Error) => {
          if (instance?.id === data.data.id) {
            instance?.legacyPitcherClient?.events.emit({
              type: "PITCHER:CONNECTION_CHANGED_ERROR",
              error: error.message,
            });
          }
        });
    },
  };
};
