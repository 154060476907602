import { Disposable, Emitter, Debouncer } from "@codesandbox/pitcher-client";
import type {
  SelectionUpdatedEvent,
  IMonacoDiffEditor,
  DiffEditorOptions,
} from "environment-interface/monacoEditor";
import type * as Monaco from "monaco-editor";
import { editor } from "monaco-editor";
import type { ITextFileEditorModel } from "vscode/monaco";
import { createConfiguredDiffEditor } from "vscode/monaco";

import { MAX_FILE_LENGTH } from "./constants";
import { getEditorOptions } from "./options";

import type { EditorService } from ".";

type DisposeFn = () => void;

const cancelableTimeout = (callback: () => void, ms: number): DisposeFn => {
  const timeoutRef = setTimeout(() => {
    callback();
  }, ms);

  return () => {
    clearTimeout(timeoutRef);
  };
};

export class MonacoDiffEditor extends Disposable implements IMonacoDiffEditor {
  filepath: string;
  readOnly: boolean;
  wordWrap: boolean;
  fonts: Record<string, string>;
  renderSideBySide: boolean;
  readonly domNodeId: string;

  private _editorInstance: Monaco.editor.IDiffEditor | null = null;

  baseModel: editor.ITextModel;
  headModel: editor.ITextModel | ITextFileEditorModel;

  private scrollTop = 0;
  private scrollDebounceDisposer: DisposeFn | null = null;

  private onEditorMountedEmitter = new Emitter<Monaco.editor.IDiffEditor>();
  onEditorMounted = this.onEditorMountedEmitter.event;

  private onDocumentSavingEmitter = new Emitter<void>();
  onDocumentSaving = this.onDocumentSavingEmitter.event;
  private onDocumentSavedEmitter = new Emitter<void>();
  onDocumentSaved = this.onDocumentSavedEmitter.event;
  private onFocusEmitter = new Emitter<void>();
  onFocus = this.onFocusEmitter.event;
  private onScrollPositionUpdatedEmitter = new Emitter<Monaco.IScrollEvent>();
  onScrollPositionUpdated = this.onScrollPositionUpdatedEmitter.event;
  private onDidOriginalLayoutChangeEmitter =
    new Emitter<Monaco.editor.EditorLayoutInfo>();
  onDidOriginalLayoutChange = this.onDidOriginalLayoutChangeEmitter.event;

  private onSelectionUpdatedEmitter = new Emitter<SelectionUpdatedEvent>();
  onSelectionUpdated = this.onSelectionUpdatedEmitter.event;

  private handleResizeDebouncer: Debouncer;

  constructor(
    {
      filepath,
      readOnly,
      wordWrap,
      fonts,
      domNodeId,
      head,
      baseModel,
      headModel,
      renderSideBySide,
    }: DiffEditorOptions,
    // @ts-ignore
    private editorService: EditorService,
  ) {
    super();

    this.filepath = filepath;
    this.readOnly = readOnly;
    this.readOnly = readOnly || !!head;
    this.wordWrap = wordWrap;
    this.fonts = fonts;
    this.domNodeId = domNodeId;
    this.baseModel = baseModel;
    this.headModel = headModel;
    this.renderSideBySide = renderSideBySide;

    this.handleResizeDebouncer = new Debouncer(50, 100, () => {
      const editorInstance = this._editorInstance;
      if (!editorInstance) {
        return;
      }

      editorInstance.layout();
      editorInstance.updateOptions({
        wordWrap: this.wordWrap ? "on" : "off",
      });
    });

    this.onWillDispose(() => {
      if (this._editorInstance) {
        this._editorInstance.dispose();
      }

      this.handleResizeDebouncer.clear();
    });
  }

  handleResize(): void {
    this.handleResizeDebouncer.debounce();
  }

  getModifiedEditor() {
    return this.editorInstance.getModifiedEditor();
  }

  getOriginalEditor() {
    return this.editorInstance.getOriginalEditor();
  }

  getModel() {
    return this.editorInstance.getModel();
  }

  getSelections() {
    return this.editorInstance.getModifiedEditor().getSelections() ?? undefined;
  }

  private registerScrollHandler() {
    const scrollDisposable = this.editorInstance
      .getModifiedEditor()
      .onDidScrollChange((event) => {
        if (this.scrollDebounceDisposer) {
          this.scrollDebounceDisposer();
        }

        this.scrollDebounceDisposer = cancelableTimeout(() => {
          this.handleScroll(event);
        }, 0);
      });
    this.onDispose(scrollDisposable.dispose);
  }

  private registerLayoutHandler() {
    this.editorInstance.getOriginalEditor().onDidLayoutChange((event) => {
      this.onDidOriginalLayoutChangeEmitter.fire(event);
    });
  }

  get editorInstance(): Monaco.editor.IDiffEditor {
    if (!this._editorInstance) {
      throw new Error("Editor is undefined");
    }
    return this._editorInstance;
  }

  private createEditorInstance(
    domElement: HTMLElement,
    baseModel: editor.ITextModel,
    headModel: editor.ITextModel,
  ) {
    // throw new Error("not supported right now");
    const diffEditor = createConfiguredDiffEditor(domElement, {
      ...getEditorOptions({ fonts: this.fonts, wordWrap: this.wordWrap }),
      readOnly: this.readOnly,
      renderSideBySide: this.renderSideBySide,
      glyphMargin: true,
      smoothScrolling: true,
    });

    diffEditor.setModel({
      original: baseModel,
      modified: headModel,
    });

    const onFocus = () => {
      this.onFocusEmitter.fire();
    };
    domElement.addEventListener("click", onFocus);

    this.onWillDispose(() => {
      domElement.removeEventListener("click", onFocus);
    });

    return diffEditor;
  }

  // This allows changing & saving the right hand side of the diff.
  private registerSaveHandler() {
    const modifiedKeyDownDisposable = this.editorInstance
      .getModifiedEditor()
      .onKeyDown((ev) => {
        if ((ev.metaKey || ev.ctrlKey) && ev.code === "KeyS") {
          ev.preventDefault();

          // We only format and save if we have an actual model that can save and it is dirty
          if (!("isDirty" in this.headModel)) {
            return;
          }

          const model = this.headModel;

          this.onDocumentSavingEmitter.fire();
          const formatAction = this.editorInstance
            .getModifiedEditor()
            .getAction("editor.action.formatDocument");
          if (formatAction) {
            formatAction
              .run()
              .then(() => model.save())
              .finally(() => {
                this.onDocumentSavedEmitter.fire();
              });
          } else {
            model.save()?.finally(() => {
              this.onDocumentSavedEmitter.fire();
            });
          }
        }
      });

    const originalKeyDownDisposable = this.editorInstance
      .getOriginalEditor()
      .onKeyDown((ev) => {
        if ((ev.metaKey || ev.ctrlKey) && ev.code === "KeyS") {
          ev.preventDefault();
        }
      });

    this.onDispose(modifiedKeyDownDisposable.dispose);
    this.onDispose(originalKeyDownDisposable.dispose);
  }

  async mount(): Promise<void> {
    if (this.baseModel.isDisposed() || this.headModel.isDisposed()) {
      throw new Error("This file does not exist anymore");
    }

    const domElement = document.getElementById(this.domNodeId);

    if (!domElement) {
      throw new Error(
        `Invalid dom node ${this.domNodeId} used for mounting a new monaco editor instance`,
      );
    }

    const headTextModel =
      "isDirty" in this.headModel
        ? this.headModel.textEditorModel!
        : this.headModel;
    const modelCharCount = Math.max(
      this.baseModel.getValueLength(),
      headTextModel.getValueLength(),
    );
    if (modelCharCount > MAX_FILE_LENGTH) {
      throw new Error(
        `File is too large to display (${modelCharCount} characters), limit is ${MAX_FILE_LENGTH} characters`,
      );
    }

    this._editorInstance = this.createEditorInstance(
      domElement,
      this.baseModel,
      headTextModel,
    );

    this.registerSaveHandler();
    this.registerScrollHandler();
    this.registerLayoutHandler();

    this.editorInstance.focus();

    this.onEditorMountedEmitter.fire(this.editorInstance);
  }

  setRenderSideBySide(renderSideBySide: boolean) {
    if (!this._editorInstance) {
      return;
    }

    this._editorInstance.updateOptions({
      renderSideBySide,
    });
  }

  focus() {
    this.editorInstance.getModifiedEditor().focus();
  }

  getVisibleLines(): [number, number] {
    if (!this.editorInstance) {
      return [0, 0];
    }

    const [visibleRange] = this.editorInstance
      .getModifiedEditor()
      .getVisibleRanges();

    if (!visibleRange) {
      return [0, 0];
    }

    return [visibleRange.startLineNumber, visibleRange.endLineNumber];
  }

  getCurrentLine(): number {
    if (!this.editorInstance) {
      return 0;
    }

    return (
      this.editorInstance.getModifiedEditor().getSelection()?.startLineNumber ??
      0
    );
  }

  revealFirstDiffLine() {
    if (!this.editorInstance) {
      return;
    }

    const revealFirstDiffLine = (lineChanges: Monaco.editor.ILineChange[]) => {
      const firstLineChange = lineChanges?.[0];

      if (!firstLineChange) {
        return;
      }

      this.revealLines(
        [
          firstLineChange.modifiedStartLineNumber - 10,
          firstLineChange.modifiedStartLineNumber + 10,
        ],
        firstLineChange.modifiedStartLineNumber,
      );
    };

    const lineChanges = this.editorInstance.getLineChanges();

    if (lineChanges) {
      revealFirstDiffLine(lineChanges);
    } else {
      const disposer = this.addDisposable(
        // Getting the diff is async so if we do not have any line changes yet, we wait for them
        this.editorInstance.onDidUpdateDiff(() => {
          disposer.dispose();
          const lineChanges = this.editorInstance.getLineChanges();

          if (!lineChanges) {
            return;
          }

          revealFirstDiffLine(lineChanges);
        }),
      );
    }
  }

  revealLines([start, end]: [number, number], current: number): void {
    if (!this.editorInstance) {
      return;
    }

    const modifiedEditor = this.editorInstance.getModifiedEditor();

    if (current > start && current < end) {
      modifiedEditor.revealLineInCenter(current, editor.ScrollType.Smooth);
    } else {
      modifiedEditor.revealLinesInCenter(start, end, editor.ScrollType.Smooth);
    }
  }

  revealLine(line: number): void {
    if (!this.editorInstance) {
      return;
    }

    this.editorInstance.revealLine(line);
  }

  handleScroll(event: Monaco.IScrollEvent): void {
    if (!this.editorInstance) {
      return;
    }
    // Only run scroll logic if the scroll position actually changed
    const scrollTop = this.editorInstance.getModifiedEditor().getScrollTop();
    if (scrollTop === this.scrollTop) return;

    this.scrollTop = scrollTop;
    this.onScrollPositionUpdatedEmitter.fire(event);
  }

  dispose(): void {
    super.dispose();

    // Dispose of the editor instance
    if (this.editorInstance) {
      this.editorInstance.dispose();
    }
  }
}
