import type { Environment } from "environment-interface";
import { EnvironmentInterfaceProvider } from "environment-interface";
import {
  env,
  setDevPitcherManagerURL,
  getUsePitcherManagerThroughApi,
  getDevPitcherManagerURL,
} from "environment-interface/env";

import { getCsbApiUrl } from "utils/getCsbApiUrl";

import { createAmplitude } from "./amplitude/browser";
import { createApi } from "./api/browser";
import { createClipboard } from "./clipboard/browser";
import { createCsbApi } from "./csbApi/browser";
import { createMonacoEditor } from "./monacoEditor/browser";
import { createNavigation } from "./navigation/browser";
import { pitcherInstanceManager } from "./pitcher/browser";
import { createSentry } from "./sentry/browser";
import { createStorage } from "./storage/browser";
import { createTerminal } from "./terminal/browser";
import { createVisibility } from "./visibility/browser";

/**
 * Sets the pitcher manager url from the URL into localStorage if it's available
 */
function applyPitcherManagerURLFromSearchParams() {
  const urlInfo = new URL(window.document.location.href);
  const pitcherManagerURL = urlInfo.searchParams.get("pitcherManagerURL");
  if (pitcherManagerURL) {
    setDevPitcherManagerURL(
      pitcherManagerURL.startsWith("https://")
        ? pitcherManagerURL
        : `https://${pitcherManagerURL}`,
    );
  }
}
applyPitcherManagerURLFromSearchParams();

const csbApi = createCsbApi(getCsbApiUrl());
/*
const auth = createAuth({
  basePath: BASE_PATH,
  csbApi,
  initialDevJwt: cookies.get("devJwt"),
});
*/
const sentry = createSentry(env.PUBLIC_SENTRY_DSN);
const api = createApi({ csbApi });

const environment: Environment = {
  amplitude: createAmplitude(env.PUBLIC_AMPLITUDE_API_KEY),
  api,
  csbApi,
  clipboard: createClipboard(),
  sentry,
  storage: createStorage(),
  terminal: createTerminal(),
  pitcherInstanceManager: pitcherInstanceManager({
    csbApi,
    pitcherManagerURL:
      getDevPitcherManagerURL() ?? env.PUBLIC_PITCHER_MANAGER_URL,
    usePitcherManagerThroughApi: getUsePitcherManagerThroughApi(),
  }),
  monacoEditor: createMonacoEditor(),
  navigation: createNavigation(),
  visibility: createVisibility(),
};

const BrowserEnvironment: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <EnvironmentInterfaceProvider environment={environment}>
      {children}
    </EnvironmentInterfaceProvider>
  );
};

export default BrowserEnvironment;
