import React from "react";

import { Collapsible as Root } from "./Collapsible";
import type { CollapsibleProps } from "./Collapsible";
import { CollapsibleContent } from "./CollapsibleContent";
import { CollapsibleTrigger } from "./CollapsibleTrigger";

const Collapsible: ICollapsible = (props) => <Root {...props} />;

interface ICollapsible extends React.FC<CollapsibleProps> {
  Trigger: typeof CollapsibleTrigger;
  Content: typeof CollapsibleContent;
}

Collapsible.Trigger = CollapsibleTrigger;
Collapsible.Content = CollapsibleContent;

export { Collapsible };
