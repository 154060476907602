import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";
import { registerExtension } from "vscode/extensions";

export const CSB_THEME_NAME = "CodeSandbox";

export function initializeCodeSandboxTheme() {
  // eslint-disable-next-line
  const config: any = {
    name: "codesandbox-projects-theme",
    displayName: "CodeSandbox Theme",
    description: "A CodeSandbox theme for Visual Studio Code",
    version: "1.1.0",
    publisher: "CodeSandbox-io",
    license: "Apache-2.0",
    contributes: {
      themes: [
        {
          label: CSB_THEME_NAME + " Dark",
          uiTheme: "vs-dark",
          path: "./themes/codesandbox-dark.json",
        },
        {
          label: CSB_THEME_NAME + " Dark Classic",
          uiTheme: "vs-dark",
          path: "./themes/codesandbox-dark-classic.json",
        },
        {
          label: CSB_THEME_NAME + " Dark Classic+",
          uiTheme: "vs-dark",
          path: "./themes/codesandbox-dark-classic-plus.json",
        },
        {
          label: CSB_THEME_NAME + " Light",
          uiTheme: "vs",
          path: "./themes/codesandbox-light.json",
        },
      ],
    },
  };
  const { registerFileUrl } = registerExtension(config, 2);

  registerFileUrl(
    "./themes/codesandbox-dark.json",
    withBasePath(env, "/vscode-extensions/themes/csb-dark.json"),
  );
  registerFileUrl(
    "./themes/codesandbox-dark-classic.json",
    withBasePath(env, "/vscode-extensions/themes/csb-dark-classic.json"),
  );
  registerFileUrl(
    "./themes/codesandbox-dark-classic-plus.json",
    withBasePath(env, "/vscode-extensions/themes/csb-dark-classic-plus.json"),
  );
  registerFileUrl(
    "./themes/codesandbox-light.json",
    withBasePath(env, "/vscode-extensions/themes/csb-light.json"),
  );
}
