import { bedrockFS } from "@codesandbox/pitcher-client";
import type {
  AbsoluteWorkspacePath,
  IPitcherClient,
} from "@codesandbox/pitcher-client";
import type { fs as fsProtocol } from "@codesandbox/pitcher-protocol";
import {
  FileSystemProviderError,
  FileSystemProviderErrorCode,
} from "@codingame/monaco-vscode-files-service-override";
import type {
  FileChangeType,
  IFileChange,
  IFileSystemProviderWithFileReadWriteCapability,
  IStat,
  FileSystemProviderCapabilities,
} from "@codingame/monaco-vscode-files-service-override";
import { FS_SCHEME } from "environment-interface";
import { onMonacoInitialized } from "environment-interface/monacoEditor/browser/services/editor/vscode/monaco";
import * as vscode from "vscode";

import { executeVSCodeCommand } from "utils/vscode";

import { getLatestMonacoConfig, onMonacoConfigChange } from "../../monaco";

import { errnos } from "./errno";
import { transformUri } from "./uri-transformer";

class ErrnoError extends Error {
  code: string;
  constructor(errno: number | null) {
    if (errno === null) {
      super("Unknown error");
      this.code = "UNKNOWN";
      return;
    }

    super(errnos[errno]?.message ?? "Unknown error");
    this.code = errnos[errno]?.code ?? "UNKNOWN";
  }
}

function errnoErrorToVSCodeError(err: ErrnoError): vscode.FileSystemError {
  let code: FileSystemProviderErrorCode;
  switch (err.code) {
    case "ENOENT":
      code = FileSystemProviderErrorCode.FileNotFound;
      break;
    case "EEXIST":
      code = FileSystemProviderErrorCode.FileExists;
      break;
    case "EISDIR":
      code = FileSystemProviderErrorCode.FileIsADirectory;
      break;
    case "ENOTDIR":
      code = FileSystemProviderErrorCode.FileNotADirectory;
      break;
    case "EACCES":
      code = FileSystemProviderErrorCode.NoPermissions;
      break;
    case "EPERM":
      code = FileSystemProviderErrorCode.NoPermissions;
      break;
    case "ENOSPC":
      code = FileSystemProviderErrorCode.FileExceedsStorageQuota;
      break;
    default:
      code = FileSystemProviderErrorCode.Unknown;
      break;
  }

  return FileSystemProviderError.create(err, code);
}

function transformBedrockToVSCode(
  entry: bedrockFS.NodeType,
  isSymlink: boolean,
): vscode.FileType {
  if (entry === bedrockFS.NodeType.Directory) {
    return (
      vscode.FileType.Directory | (isSymlink ? vscode.FileType.SymbolicLink : 0)
    );
  }

  return vscode.FileType.File | (isSymlink ? vscode.FileType.SymbolicLink : 0);
}

export class CSBFileSystemProvider
  implements IFileSystemProviderWithFileReadWriteCapability
{
  private file: IPitcherClient["clients"]["file"];
  private fs: IPitcherClient["clients"]["fs"];
  private workspacePath: string;
  private pitcherType: "browser" | "vm";

  private onDidChangeFileEmitter = new vscode.EventEmitter<IFileChange[]>();
  onDidChangeFile = this.onDidChangeFileEmitter.event;

  // Hardcoded from FileSystemProviderCapabilities
  readonly capabilities: FileSystemProviderCapabilities = 2 | 8;
  private onDidChangeCapabilitiesEmitter = new vscode.EventEmitter<void>();
  readonly onDidChangeCapabilities = this.onDidChangeCapabilitiesEmitter.event;

  private onDidWatchErrorEmitter = new vscode.EventEmitter<string>();
  readonly onDidWatchError = this.onDidWatchErrorEmitter.event;

  constructor() {
    // Whenever changing Pitcher or reconnecting we need to reset
    // file watchers and refresh the explorer
    const reset = () => {
      this.reinitializeWatchers();
      executeVSCodeCommand("workbench.files.action.refreshFilesExplorer");
    };

    onMonacoConfigChange((newConfig) => {
      const pitcher = newConfig.pitcher;
      this.workspacePath = pitcher.workspacePath;
      this.file = pitcher.clients.file;
      this.fs = pitcher.clients.fs;
      this.pitcherType = pitcher.type;

      reset();
    });

    const pitcher = getLatestMonacoConfig().pitcher;
    this.workspacePath = pitcher.workspacePath;
    this.file = pitcher.clients.file;
    this.fs = pitcher.clients.fs;
    this.pitcherType = pitcher.type;

    onMonacoInitialized(() => {
      vscode.commands.registerCommand(
        "workbench.files.action.enableReadOnly",
        () => {
          // @ts-ignore
          this.capabilities = 2 | 8 | 2048;
          this.onDidChangeCapabilitiesEmitter.fire();
        },
      );

      vscode.commands.registerCommand(
        "workbench.files.action.disableReadOnly",
        () => {
          // @ts-ignore
          this.capabilities = 2 | 8;
          this.onDidChangeCapabilitiesEmitter.fire();
        },
      );
    });

    pitcher.onReconnected(reset);
  }

  private getOpenFileFromUri(uri: vscode.Uri) {
    const relativePath = this.fs.absoluteToRelativeWorkspacePath(
      uri.path as AbsoluteWorkspacePath,
    );
    const fileId = this.file.getFileIdFromPath(relativePath);
    const openFile = fileId ? this.file.getOpenedFile(fileId) : undefined;

    return openFile;
  }

  private watchers = new Map<
    string,
    {
      uri: vscode.Uri;
      options: {
        readonly recursive: boolean;
        readonly excludes: readonly string[];
      };
      disposable: vscode.Disposable;
    }
  >();

  private getWatcherKey(
    uri: vscode.Uri,
    recursive: boolean,
    excludes: readonly string[],
  ): string {
    return `${uri.toString()}-${recursive ? "recursive" : ""}-${excludes.join(
      ",",
    )}`;
  }

  private addWatcher(
    uri: vscode.Uri,
    recursive: boolean,
    excludes: readonly string[],
    disposable: vscode.Disposable,
  ) {
    const key = this.getWatcherKey(uri, recursive, excludes);
    if (this.watchers.has(key)) {
      // Dispose existing watcher
      this.watchers.get(key)?.disposable.dispose();
    }

    this.watchers.set(key, {
      options: { recursive, excludes },
      disposable,
      uri,
    });
  }

  private removeWatcher(
    uri: vscode.Uri,
    recursive: boolean,
    excludes: readonly string[],
  ) {
    const key = this.getWatcherKey(uri, recursive, excludes);
    const watcher = this.watchers.get(
      this.getWatcherKey(uri, recursive, excludes),
    );

    if (watcher) {
      this.watchers.delete(key);
      watcher.disposable.dispose();
    }
  }

  /**
   * Reinitializes the watchers for a new pitcher instance that just got
   * created.
   */
  private reinitializeWatchers() {
    for (const watcher of this.watchers.values()) {
      // Create new watcher, which automatically disposes the old ones
      this.watch(watcher.uri, {
        recursive: watcher.options.recursive,
        excludes: watcher.options.excludes,
      });
    }
  }

  private createWatcher(
    uri: vscode.Uri,
    options: {
      readonly recursive: boolean;
      readonly excludes: readonly string[];
    },
  ): vscode.Disposable {
    const mapEvent = (event: fsProtocol.FSWatchEvent): IFileChange[] =>
      event.paths.map((path) => {
        const resource = vscode.workspace.workspaceFolders![0].uri.with({
          path,
        });

        // Hardcoded from FileChangeType
        let eventType: FileChangeType;
        if (event.type === "add") {
          eventType = 1;
        } else if (event.type === "change") {
          eventType = 0;
        } else {
          eventType = 2;
        }

        return { resource, type: eventType };
      });

    const disposablePromise = this.fs.watch(
      uri.path,
      {
        recursive: options.recursive,
        excludes: options.excludes,
      },
      (event) => {
        this.onDidChangeFileEmitter.fire(mapEvent(event));
      },
    );

    const disposable = new vscode.Disposable(async () => {
      const res = await disposablePromise;
      if (res.type === "success") {
        return res.dispose();
      }
    });

    return disposable;
  }

  watch(
    resource: vscode.Uri,
    options: {
      readonly recursive: boolean;
      readonly excludes: readonly string[];
    },
  ): vscode.Disposable {
    const uri = transformUri(resource);
    const watcher = this.createWatcher(uri, options);
    this.addWatcher(uri, options.recursive, options.excludes, watcher);
    return new vscode.Disposable(async () => {
      this.removeWatcher(uri, options.recursive, options.excludes);
    });
  }

  async stat(resource: vscode.Uri): Promise<IStat> {
    const uri = transformUri(resource);
    if (uri.path === "/workspace.code-workspace") {
      return {
        type: vscode.FileType.File,
        ctime: 0,
        mtime: 0,
        size: 0,
      };
    }

    const path = uri.path;
    const stat = await this.fs.stat(path);

    if (stat.type === "error") {
      throw errnoErrorToVSCodeError(new ErrnoError(stat.errno));
    }

    return {
      type: transformBedrockToVSCode(stat.result.type, stat.result.isSymlink),
      ctime: stat.result.ctime,
      mtime: stat.result.mtime,
      size: stat.result.size,
    };
  }

  async readdir(
    resource: vscode.Uri,
  ): Promise<Array<[string, vscode.FileType]>> {
    const uri = transformUri(resource);
    const path = uri.path;
    const readdir = await this.fs.readdir(path);

    if (readdir.type === "error") {
      throw errnoErrorToVSCodeError(new ErrnoError(readdir.errno));
    }

    const { entries } = readdir.result;
    return entries.map((entry) => [
      entry.name,
      transformBedrockToVSCode(entry.type, entry.isSymlink),
    ]);
  }

  async mkdir(resource: vscode.Uri): Promise<void> {
    const uri = transformUri(resource);
    const res = await this.fs.mkdir(uri.path);
    if (res.type === "error") {
      throw errnoErrorToVSCodeError(new ErrnoError(res.errno));
    }
    return;
  }

  async readFile(resource: vscode.Uri): Promise<Uint8Array> {
    const uri = transformUri(resource);
    if (uri.path === "/workspace.code-workspace") {
      return new TextEncoder().encode(
        JSON.stringify({
          folders: [
            {
              path: this.workspacePath,
            },
          ],
          settings: {},
        }),
      );
    }

    const path = uri.path;
    const readFile = await this.fs.readFile(path);

    if (readFile.type === "error") {
      throw errnoErrorToVSCodeError(new ErrnoError(readFile.errno));
    }

    return readFile.result.content;
  }

  async cloneFile(from: vscode.Uri, to: vscode.Uri): Promise<void> {
    // TODO: add proper btrfs clone support in our raw fs
    const fromUri = transformUri(from);
    const toUri = transformUri(to);
    const res = await this.fs.copy(fromUri.path, toUri.path, false, true);

    if (res.type === "error") {
      throw errnoErrorToVSCodeError(new ErrnoError(res.errno));
    }
  }

  async writeFile(
    resource: vscode.Uri,
    content: Uint8Array,
    options: { readonly create: boolean; readonly overwrite: boolean },
  ): Promise<void> {
    const uri = transformUri(resource);
    if (uri.scheme === FS_SCHEME && this.pitcherType === "browser") {
      const relativePath = this.fs.absoluteToRelativeWorkspacePath(
        uri.path as AbsoluteWorkspacePath,
      );

      try {
        const openFile = await this.file.openFileByPath(relativePath);

        openFile.object.updateContent(new TextDecoder().decode(content));
        openFile.object.save();

        return;
      } catch (e) {
        // File does not exist? let's use raw fs
      }
    }

    const openFile = this.getOpenFileFromUri(uri);

    // We only want to do a Pitcher save on files that we have opened in the editor already. All other
    // writes we consider normal FS writes. Saving on Pitcher ensures that we can tag revisions
    // as FS saves
    if (openFile) {
      try {
        await openFile.save();
        return;
      } catch {
        // Fall back to using raw fs. It can go wrong in two scenarios:
        // 1. It just fails on Pitcher side, for an unknown reason
        // 2. The file is not a shared document (e.g. in `node_modules`, essentially all
        //    files that are gitignored)
      }
    }

    const res = await this.fs.writeFile(
      uri.path,
      content,
      options.create,
      options.overwrite,
    );

    if (res.type === "error") {
      // Unknown error
      if (res.errno === -1) {
        throw FileSystemProviderError.create(
          res.error,
          FileSystemProviderErrorCode.Unknown,
        );
      }

      throw errnoErrorToVSCodeError(new ErrnoError(res.errno));
    }
  }

  async delete(
    resource: vscode.Uri,
    options: { readonly recursive: boolean },
  ): Promise<void> {
    const uri = transformUri(resource);
    const res = await this.fs.remove(uri.path, options.recursive);

    if (res.type === "error") {
      throw errnoErrorToVSCodeError(new ErrnoError(res.errno));
    }
  }

  async rename(
    oldResource: vscode.Uri,
    newResource: vscode.Uri,
    options: { readonly overwrite: boolean },
  ): Promise<void> {
    const oldUri = transformUri(oldResource);
    const newUri = transformUri(newResource);
    const res = await this.fs.rename(
      oldUri.path,
      newUri.path,
      options.overwrite,
    );

    if (res.type === "error") {
      throw errnoErrorToVSCodeError(new ErrnoError(res.errno));
    }
  }

  async copy(
    _source: vscode.Uri,
    _destination: vscode.Uri,
    options: { readonly overwrite: boolean },
  ): Promise<void> {
    const source = transformUri(_source);
    const destination = transformUri(_destination);
    const res = await this.fs.copy(
      source.path,
      destination.path,
      true,
      options.overwrite,
    );

    if (res.type === "error") {
      throw errnoErrorToVSCodeError(new ErrnoError(res.errno));
    }
  }
}
