import type * as Monaco from "monaco-editor";
import { Uri } from "vscode";

interface IGitUrlParams {
  filepath: string;
  ref: string;
}

export const GIT_SCHEME = "git";
export function createMonacoGitUri(
  filepath: string,
  gitReference: string,
): Monaco.Uri {
  const params: IGitUrlParams = {
    filepath,
    ref: gitReference,
  };

  return Uri.from({
    scheme: GIT_SCHEME,
    path: filepath,
    query: JSON.stringify(params),
  });
}

export function parseMonacoGitUri(uri: Monaco.Uri): {
  filepath: string;
  gitReference: string;
} {
  const parsedParams: IGitUrlParams = JSON.parse(uri.query);
  return {
    filepath: parsedParams.filepath,
    gitReference: parsedParams.ref,
  };
}
