import type { TEmitter } from "@codesandbox/environment-interface";
import type { protocol, initPitcherClient } from "@codesandbox/pitcher-client";
import type { initPitcherBrowserClient } from "@codesandbox/pitcher-client/dist/esm/browser";
import type { SeamlessInstanceData } from "environment-interface/api";
import type { PitcherVersions } from "features/types/pitcher";
import type { Task } from "features/types/task";

import type { ClientsApi, ClientsEvent } from "./clients";
import type { FileEvent, FileApi } from "./file";
import type { FSEvent, FSApi } from "./fs";
import type { GitEvent, GitApi } from "./git";
import type { LanguageApi, LanguageEvent } from "./language";
import type { PortApi } from "./port";
import type { SetupEvent, SetupApi } from "./setup";
import type { ShellEvent, ShellApi } from "./shell";
import type { TaskApi, TaskEvent } from "./task";
import type { VersionsEvent, VersionsApi } from "./versions";

export type { IDocumentSelections } from "./file";

export type ConnectionEvent =
  | {
      type: "PITCHER:CONNECTED";
      shells: protocol.shell.ShellDTO[];
      ports: protocol.port.Port[];
      setupProgress: protocol.setup.SetupProgress;
      tasks: Record<string, Task>;
      currentClient: protocol.client.ClientJSON;
      clients: protocol.client.ClientJSON[];
      versions: PitcherVersions;
      cluster: string;
    }
  | {
      type: "PITCHER:INITIAL_DATA_ERROR";
      error: string;
    }
  | {
      type: "PITCHER:INIT_STATUS_UPDATE";
      status: protocol.system.InitStatus;
    }
  | { type: "PITCHER:ERROR"; error: string }
  | { type: "PITCHER:CONTAINER_DELETED" }
  | { type: "PITCHER:RECONNECT_ATTEMPTED" }
  | { type: "PITCHER:DISCONNECTED"; wasClean: boolean; wasNotFound: boolean }
  | { type: "PITCHER:RECONNECTING"; attempt: number }
  | {
      type: "PITCHER:CONNECTION_CHANGED_SUCCESS";
      data: SeamlessInstanceData;
    }
  | { type: "PITCHER:CONNECTION_CHANGED_ERROR"; error: string }
  | { type: "PITCHER:SET_BRANCH_NAME"; name: string }
  | { type: "PITCHER:BRANCH_RENAME_FINISHED" }
  | { type: "PITCHER:BRANCH_RENAME_ERROR"; error: string }
  | { type: "PITCHER:BRANCH_RENAME_TAKEN_ERROR"; nameTaken: string }
  | {
      type: "PITCHER:STOP_SUCCESS";
    }
  | {
      type: "PITCHER:STOP_ERROR";
    };

export type PitcherEvent =
  | ConnectionEvent
  | FSEvent
  | FileEvent
  | ShellEvent
  | LanguageEvent
  | ClientsEvent
  | GitEvent
  | SetupEvent
  | VersionsEvent
  | TaskEvent;

export type PitcherEvents = TEmitter<PitcherEvent>;

export enum PitcherClientConnectionState {
  Connecting,
  Connected,
  Disconnected,
}

export interface BranchRenameParams {
  branchId: string;
  oldName: string;
  newName: string;
}

export interface LegacyPitcherClient {
  events: PitcherEvents;
  currentClient?: protocol.client.ClientJSON;
  fs: FSApi;
  file: FileApi;
  shell: ShellApi;
  port: PortApi;
  language: LanguageApi;
  clients: ClientsApi;
  task: TaskApi;
  git: GitApi;
  setup: SetupApi;
  connectionState: PitcherClientConnectionState;
  versions: VersionsApi;
  dispose: () => void;
  reconnect: () => void;
  renameBranch: (params: BranchRenameParams) => void;
}

/**
 * Handles multiple pitcher client instances in memory.
 */
export interface PitcherInstanceManager {
  getLegacyPitcherClient(): LegacyPitcherClient;
  stopPitcherEnvironment(instanceId: string): void;
  changeInstance(oldInstanceId: string, data: SeamlessInstanceData): void;
  revertSeamlessFork(): void;
  getInstance(
    ...params: Parameters<typeof initPitcherClient>
  ): ReturnType<typeof initPitcherClient>;
  getBrowserInstance(
    sandboxId: string,
    seamlessFork: boolean,
  ): ReturnType<typeof initPitcherBrowserClient>;
}
