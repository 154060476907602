import type { IDisposable, IPitcherClient } from "@codesandbox/pitcher-client";
import { Disposable } from "@codesandbox/pitcher-client";
import { DisposableStore } from "@codesandbox/pitcher-common";
import { FS_SCHEME } from "environment-interface";
import * as vscode from "vscode";
import {
  ILanguageService,
  IModelService,
  StandaloneServices,
} from "vscode/services";
import { ILifecycleService } from "vscode/vscode/vs/workbench/services/lifecycle/common/lifecycle.service";
import type { LanguageClientOptions } from "vscode-languageclient";
import type {
  NotificationMessage,
  RequestMessage,
  ResponseMessage,
} from "vscode-languageclient/browser";
import {
  CloseAction,
  ErrorAction,
  LanguageClient,
} from "vscode-languageclient/browser";
import { ErrorCodes } from "vscode-languageserver-protocol";

import { runWithLatestValue } from "../tools/run-with-latest-value";
import { runWithLatestMonacoConfig } from "../tools/with-monaco-config";

export function initializeLanguages(): IDisposable {
  return runWithLatestMonacoConfig((config) => {
    return registerLanguages(config.pitcher);
  });
}

function registerLanguages(pitcher: IPitcherClient): IDisposable {
  return runWithLatestValue(
    pitcher.clients.language.onLanguagesUpdated,
    (availableLanguages) => {
      const disposableStore = new DisposableStore();
      const languageServers = StandaloneServices.get(ILanguageService);
      const lifecycleService = StandaloneServices.get(ILifecycleService);

      availableLanguages.forEach((language) => {
        disposableStore.add(
          languageServers.registerLanguage({
            id: language.id,
            filenamePatterns: language.globs,
            extensions: language.extensions,
          }),
        );
      });

      const serversToLanguages = new Map<string, typeof availableLanguages>();
      availableLanguages.forEach((language) => {
        language.languageServerIds.forEach((serverId) => {
          if (!serversToLanguages.has(serverId)) {
            serversToLanguages.set(serverId, []);
          }

          serversToLanguages.get(serverId)!.push(language);
        });
      });

      [...serversToLanguages.entries()].forEach(([serverId, langs]) => {
        const worker = new FakeLSPWorker(
          pitcher.clients.language,
          serverId,
        ) as unknown as Worker & IDisposable;

        // Options to control the language client
        const clientOptions: LanguageClientOptions = {
          documentSelector: langs.flatMap((lang) =>
            lang.globs.map((glob) => ({
              scheme: FS_SCHEME,
              language: lang.id,
              // Combine all globs into one
              pattern: glob,
            })),
          ),

          errorHandler: {
            error: (err, _msg, count) => {
              // eslint-disable-next-line
              console.warn(`LSP Error (count ${count})`, err);

              return {
                action: ErrorAction.Continue,
                // We don't show errors to the user _yet_ from the LSP. We should introduce
                // here a check for known errors (e.g. connection issues), and decide when
                // to show a notification and when not.
                handled: true,
              };
            },
            closed: () => ({
              action: CloseAction.Restart,
              handled: true,
            }),
          },
          uriConverters: {
            code2Protocol: (uri) => {
              return uri.with({ scheme: "file", authority: "" }).toString();
            },
            protocol2Code: (uri) => {
              return vscode.Uri.parse(uri).with({
                scheme: FS_SCHEME,
                authority: pitcher.instanceId,
              });
            },
          },
          workspaceFolder: {
            index: 0,
            name: "root",
            uri: vscode.Uri.from({
              scheme: FS_SCHEME,
              authority: pitcher.instanceId,
              path: pitcher.workspacePath,
            }),
          },
          revealOutputChannelOn: 4,
        };

        // Create the language client and start the client.
        const client = new LanguageClient(
          serverId,
          "CodeSandbox LSP: " + serverId,
          clientOptions,
          worker,
        );

        const modelService = StandaloneServices.get(IModelService);
        const hasLanguageOpen = modelService
          .getModels()
          .some((model) => langs.find((l) => l.id === model.getLanguageId()));

        let disposed = false;
        disposableStore.add({
          dispose() {
            disposed = true;
          },
        });
        async function startClient() {
          // Wait until we've reached LifecyclePhase.Ready, to ensure that the editor has loaded
          // before we load all LSPs, which can be quite heavy.
          await lifecycleService.when(2);
          if (disposed) {
            return;
          }

          await client.start();
        }

        if (hasLanguageOpen) {
          startClient();
        } else {
          const listenerDisposable = vscode.workspace.onDidOpenTextDocument(
            (evt) => {
              if (langs.find((l) => l.id === evt.languageId)) {
                startClient();
                listenerDisposable.dispose();
              }
            },
          );
          disposableStore.add(listenerDisposable);
        }

        disposableStore.add(worker);
        disposableStore.add(client);
      });

      return disposableStore;
    },
    pitcher.clients.language.getLanguages(),
    (a, b) => a.sort().join(",") !== b.sort().join(","),
  );
}

class FakeLSPWorker extends Disposable {
  errorListeners: Array<(msg: unknown) => void> = [];

  constructor(
    private lspClient: IPitcherClient["clients"]["language"],
    private serverId: string,
  ) {
    super();

    this.addDisposable(
      this.lspClient.onLspServerRequest((req) => {
        if (req.serverId === this.serverId) {
          this.sendMessage({
            jsonrpc: "2.0",
            id: req.message.id,
            method: req.message.method,
            params: req.message.params,
          });
        }
      }),
    );
    this.addDisposable(
      this.lspClient.onLspNotification((notif) => {
        if (notif.serverId === this.serverId) {
          this.sendMessage({
            jsonrpc: "2.0",
            method: notif.message.method,
            params: notif.message.params,
          });
        }
      }),
    );
  }

  onmessage?: (message: unknown) => void;

  terminate() {
    // noop
  }

  private sendMessage(message: unknown) {
    setTimeout(() => {
      if (this.onmessage) {
        this.onmessage({ data: message });
      }
    }, 0);
  }

  async postMessage(
    msg: RequestMessage | NotificationMessage | ResponseMessage,
  ) {
    if ("id" in msg && "method" in msg && msg.method === "shutdown") {
      // The client is asking for shutdown, but that could also be because we just disposed
      // the editor. So we just send a null response, Pitcher will decide when to shutdown
      // the LSP server.
      this.sendMessage({
        jsonrpc: "2.0",
        id: msg.id,
        result: null,
      });
      return;
    }

    if (this.isDisposed) {
      return;
    }

    if ("id" in msg) {
      if (!("method" in msg)) {
        // Response to server for a server request to the client
        // This is a response, so if it errors we don't have a way
        // to let the client know it went wrong.
        this.sendLSPServerResponse(msg);

        return;
      }

      // This fixes an issue with special characters in URIs on Pitcher

      // eslint-disable-next-line
      // @ts-ignore
      if (msg.params?.textDocument?.uri) {
        // eslint-disable-next-line
        // @ts-ignore
        const uri = msg.params.textDocument.uri;
        // eslint-disable-next-line
        // @ts-ignore
        msg.params.textDocument.uri = decodeURIComponent(uri.toString());
      }

      const { id, method, params } = msg;

      const response = await this.sendLSPRequest(method, params);

      if (response.type === "ok") {
        this.sendMessage({
          jsonrpc: "2.0",
          id,
          result: response.result,
        });
      } else {
        this.sendMessage({
          jsonrpc: "2.0",
          id,
          error: response.error,
        });
      }
    } else {
      if (this.lspClient.sendLSPNotification) {
        this.lspClient.sendLSPNotification({
          languageId: this.serverId,
          serverId: this.serverId,
          message: {
            method: msg.method,
            params: msg.params,
          },
        });
      }
    }
  }

  addEventListener(type: string, callback: (msg: unknown) => void) {
    if (type === "error") {
      this.errorListeners.push(callback);
    }
  }

  private async sendLSPRequest(method: string, params: unknown) {
    try {
      return await this.lspClient.sendLSPRequest({
        languageId: this.serverId,
        serverId: this.serverId,
        message: {
          method,
          params,
        },
      });
    } catch (e) {
      return this.pitcherErrorAsLsp(e);
    }
  }

  private async sendLSPServerResponse(message: ResponseMessage) {
    try {
      return await this.lspClient.sendLSPServerResponse({
        languageId: this.serverId,
        serverId: this.serverId,
        // @ts-expect-error our id typing is different
        message,
      });
    } catch (e) {
      return this.pitcherErrorAsLsp(e);
    }
  }

  private pitcherErrorAsLsp(e: unknown) {
    let errorMessage: unknown;
    if (e instanceof Error) {
      errorMessage = e.message;
    } else {
      errorMessage = e;
    }

    return {
      type: "error" as const,
      error: {
        code: ErrorCodes.MessageWriteError,
        message: "Failed to send request: " + errorMessage,
      },
    };
  }
}
