import { Item } from "@radix-ui/react-dropdown-menu";

import { item } from "../../css/menu";
import { styled } from "../../theme";

export const DropdownItem = styled(Item, {
  ...item,
  variants: {
    variant: {
      selected: {
        color: "$neutral-fg-high",
      },
      deselected: {
        color: "$neutral-fg-subtle",

        "&:hover": {
          color: "$neutral-fg-high",
        },
      },
    },
  },
});
