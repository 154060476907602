import type React from "react";
import { useEffect, useRef } from "react";
import type { KeyBindingMap } from "tinykeys";
import tinykeys from "tinykeys";

import type { Command } from "commands/types";

export interface KeyBindingOptions {
  preventDefault?: boolean;
  stopPropagation?: boolean;
}

/**
 * This function registers keyboard bindings with listeners and ensures
 * listeners are refreshed any time dependencies change or new functions are passed
 * TODO: Revisit this logic a bit later and see if we can avoid adding too many
 * useCallbacks in the command hooks or have a different strategy for registering the listeners
 */
export const useKeyboardShortcuts = (
  bindings: KeyBindingMap,
  dependencies: unknown[] = [],
  options: KeyBindingOptions = {},
  scopedElement?: HTMLElement | null,
) => {
  // If listeners attached to bindings change, reattach the event listeners
  const listenerReferences = Object.values(bindings);

  useEffect(() => {
    const attachedNode = scopedElement === undefined ? window : scopedElement;
    if (!attachedNode) {
      return;
    }

    let keyboardBindings = bindings;
    if (options.preventDefault || options.stopPropagation) {
      keyboardBindings = {};
      Object.keys(bindings).forEach((key) => {
        keyboardBindings[key] = (event: KeyboardEvent) => {
          if (options.preventDefault) {
            event.preventDefault();
          }

          if (options.stopPropagation) {
            event.stopPropagation();
          }

          bindings[key](event);
        };
      });
    }

    const unsubscribe = tinykeys(attachedNode, keyboardBindings);
    return () => {
      unsubscribe();
    };
  }, [
    scopedElement,
    listenerReferences,
    options.preventDefault,
    options.stopPropagation,
    ...dependencies,
  ]);
};

/**
 *
 */

/**
 * Each command passed will have its binding and altbinding
 * attached as event listeners on the window object.
 */
export const useRegisterGlobalKeyboardBindingsForCommands = (
  commands: Command[],
  dependencies?: unknown[],
): void => {
  const bindings = commands.reduce((res: KeyBindingMap, cmd) => {
    if (cmd.binding) {
      res[cmd.binding] = cmd.perform;
    }

    if (cmd.altBinding) {
      res[cmd.altBinding] = cmd.perform;
    }

    return res;
  }, {});

  useKeyboardShortcuts(
    bindings,
    dependencies,
    { preventDefault: true },
    undefined,
  );
};

/**
 * This hook also returns a ref for the element which scopes the event listener.
 * By default, keyboard bindings prevent default browser shortcuts.
 * Scoped bindings also stopPropagation to ensure unwanted commands are not executed.
 */
export const useRegisterScopedKeyboardBindingsForCommands = <
  T extends HTMLElement,
>(
  commands: Command[],
  dependencies?: unknown[],
): React.RefObject<T> => {
  const domNodeRef = useRef<T>(null);

  const bindings = commands.reduce((res: KeyBindingMap, cmd) => {
    if (cmd.binding) {
      res[cmd.binding] = cmd.perform;
    }

    if (cmd.altBinding) {
      res[cmd.altBinding] = cmd.perform;
    }

    return res;
  }, {});

  useKeyboardShortcuts(
    bindings,
    dependencies,
    { preventDefault: true },
    domNodeRef.current,
  );

  return domNodeRef;
};
