import type { SandboxEditor } from "features/types/editor";

const buildEncodedUri = (strings: TemplateStringsArray, ...values: string[]) =>
  strings[0] +
  values
    .map((value, i) => `${encodeURIComponent(value)}${strings[i + 1]}`)
    .join("");

export const getSandboxGitIdentifier = (editor: SandboxEditor) =>
  editor.git
    ? buildEncodedUri`github/${editor.git.username}/${editor.git.repo}/tree/${editor.git.branch}/` +
      editor.git.path
    : null;

export const getSandboxUrlIdentifier = (editor: SandboxEditor) =>
  getSandboxGitIdentifier(editor) || editor.alias || editor.id;
