import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden";
import React from "react";

import { keyframes } from "../../theme";
import { Box } from "../Box";

const transition = keyframes({
  "0%": { opacity: 0.6 },
  "50%": { opacity: 1 },
  "100%": { opacity: 0.6 },
});

const Dot: React.FC<{ delay: string }> = ({ delay }) => (
  <Box
    css={{
      fontSize: "$heading3",
      animation: `${transition} 1.5s ease-out infinite`,
      animationDelay: delay,
    }}
  >
    ·
  </Box>
);

export const LoadingDots: React.FC = () => {
  return (
    <>
      <VisuallyHidden>Loading</VisuallyHidden>
      <Box
        css={{ display: "flex" }}
        data-testid="LoadingDots"
        role="presentation"
      >
        <Dot delay="0ms" />
        <Dot delay="200ms" />
        <Dot delay="400ms" />
      </Box>
    </>
  );
};
