import { transition } from "@codesandbox/states";
import { nanoid } from "nanoid";

import type { Action, Notification, State } from "./types";

function getID() {
  return nanoid();
}

export const reducer = (prevState: State, action: Action) =>
  transition(prevState, action, {
    READY: {
      ADD_NOTIFICATION: (state, action) => {
        const id = getID();

        const { notification } = action;

        // if the new notification has a uniqueName, we check wether there is already
        // a notification with the same name, in which case we do nothing.
        // this effectively works as a debounce for status notifications (since they are self dismissed)
        // and as a unique-at-any-time system for alerts.
        const newUniqueName = notification.uniqueName;
        if (
          newUniqueName &&
          newUniqueName !== "" &&
          Object.values(state.items).some(
            ({ uniqueName }) => uniqueName === newUniqueName,
          )
        ) {
          return state;
        }

        /**
         * This type casting is necessary as there's some conflict from using Omit in the action definition
         * TODO Find out if there's a mistake
         */
        const newNotification = { ...notification, id } as Notification;

        return {
          state: "READY",
          items: {
            ...state.items,
            [id]: newNotification,
          },
        };
      },
      DISMISS_NOTIFICATION: (state, action) => {
        const { id, uniqueName } = action;
        let newItems: Record<string, Notification> = state.items;

        if (id) {
          const { [id]: _, ...items } = state.items;
          newItems = items;
        }

        if (uniqueName) {
          newItems = Object.values(state.items).reduce(
            (acc: Record<string, Notification>, item) => {
              if (item?.uniqueName === uniqueName) {
                return acc;
              }
              acc[item.id] = item;
              return acc;
            },
            {},
          );
        }

        return {
          state: "READY",
          items: newItems,
        };
      },
    },
    ERROR: {},
  });
