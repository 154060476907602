import type { IPitcherClient } from "@codesandbox/pitcher-client";
import { Disposable, Emitter } from "@codesandbox/pitcher-client";
import Cache from "quick-lru";
import * as vscode from "vscode";

import { parseMonacoGitUri } from "../../../../../utils/uri-utils";

export class GitDocumentContentProvider
  extends Disposable
  implements vscode.TextDocumentContentProvider
{
  private onDidChangeEmitter = this.addDisposable(new Emitter<vscode.Uri>());
  onDidChange = this.onDidChangeEmitter.event;

  private openedDocuments = new Set<string>();
  private remoteDocContents = new Cache<string, { content: string }>({
    maxSize: 20,
  });

  constructor(private readonly pitcherClient: IPitcherClient) {
    super();

    this.addDisposable(
      this.pitcherClient.clients.git.onStatusUpdated(() => {
        // TODO: make this more finegrained and only send changes for removed & changed & added
        // files in git status compared to previous status. Now VSCode will reload _all_ git files
        // it has open.
        for (const document of this.openedDocuments) {
          this.onDidChangeEmitter.fire(vscode.Uri.parse(document));
        }
      }),
    );
  }

  async provideTextDocumentContent(
    uri: vscode.Uri,
    token: vscode.CancellationToken,
  ) {
    const parsedUri = parseMonacoGitUri(uri);

    const result =
      this.remoteDocContents.get(uri.toString()) ||
      ((await Promise.race([
        eventToPromise(token.onCancellationRequested),
        this.pitcherClient.clients.git.remoteContent({
          filepath: parsedUri.filepath,
          reference: parsedUri.gitReference,
        }),
      ])) as undefined | { content: string });

    if (token.isCancellationRequested || this.isDisposed) {
      return null;
    }

    if (
      result !== undefined &&
      "content" in result &&
      typeof result.content === "string"
    ) {
      if (parsedUri.gitReference !== "HEAD") {
        this.remoteDocContents.set(uri.toString(), result);
      }

      this.openedDocuments.add(uri.toString());

      const disposable = this.addDisposable(
        vscode.workspace.onDidCloseTextDocument((document) => {
          if (document.uri.toString() === uri.toString()) {
            this.openedDocuments.delete(uri.toString());
            disposable.dispose();
          }
        }),
      );

      return result.content;
    }

    return null;
  }
}

function eventToPromise<T>(event: Emitter<T>["event"]): Promise<T> {
  return new Promise((resolve) => {
    const disposable = event((e) => {
      disposable.dispose();
      resolve(e);
    });
  });
}
