import { Disposable } from "@codesandbox/pitcher-common";
import { Channel } from "./Channel";
/**
 * Creates a channel where all subscribers are equal, able to send messages
 * to all or a targeted user. You can also listen to subscribers joining/leaving
 */
export class SharedChannel extends Disposable {
    constructor(name, messageHandler) {
        super();
        this.channel = new Channel(name, messageHandler);
        this.channel.subscribe();
        this.onWillDispose(() => {
            this.channel.dispose();
        });
    }
    onJoin(cb) {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        return this.channel.onSubscribe(cb);
    }
    onLeave(cb) {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        return this.channel.onUnsubscribe(cb);
    }
    sendAll(message) {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        if (this.channel.subscribers.size > 1) {
            return this.channel.sendAll(message);
        }
    }
    send(clientId, message) {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        if (this.channel.subscribers.has(clientId)) {
            this.channel.send(new Set([clientId]), message);
        }
    }
    onMessage(cb) {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        return this.channel.onMessage(cb);
    }
    resync() {
        this.channel.resync();
    }
}
