import { env } from "environment-interface/env";
import React, { lazy, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { router } from "router";

import { App } from "./components/App";

if (env.PUBLIC_ENVIRONMENT === "development") {
  import("impact-react-debugger");
}

const Editor = lazy(() => import("./vite-pages/Editor"));
const InlinePreview = lazy(() => import("./vite-pages/InlinePreview"));
const Preview = lazy(() => import("./vite-pages/Preview"));
const PreviewPath = lazy(() => import("./vite-pages/PreviewPath"));
const RedirectToProjectEditor = lazy(
  () => import("./vite-pages/RedirectToProjectEditor"),
);
const VsCode = lazy(() => import("./vite-pages/VsCode"));
const LivePage = lazy(() => import("./vite-pages/LivePage"));
const LivePageEnd = lazy(() => import("./vite-pages/LivePageEnd"));

function Entry() {
  const [renderBrowser, setRenderBrowser] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(router.current);

  useEffect(() => {
    setRenderBrowser(true);
  }, []);

  useEffect(function subscribeRouteChange() {
    router.listen(setCurrentRoute);
  }, []);

  if (!renderBrowser) {
    return null;
  }

  if (!currentRoute) {
    // This can not happen as we use the same routes on the server, it will result in a "404" and static page
    return;
  }

  const type = currentRoute.name;
  switch (type) {
    case "liveEnd": {
      return (
        <App>
          <LivePageEnd vmId={currentRoute.params.vmId} />
        </App>
      );
    }
    case "live": {
      return (
        <App>
          <LivePage id={currentRoute.params.id} />
        </App>
      );
    }
    case "githubRepo":
    case "github":
    case "devbox":
    case "sandbox": {
      return (
        <App manuallyUnmountSsrLoader>
          <Editor />
        </App>
      );
    }
    case "inlinePreview": {
      return <InlinePreview />;
    }
    case "preview": {
      // Need to put the data fetching into this component
      return (
        <App>
          <Preview
            branch={currentRoute.params.branch}
            owner={currentRoute.params.owner}
            repo={currentRoute.params.repo}
          />
        </App>
      );
    }
    case "previewPath": {
      return (
        <App>
          <PreviewPath />
        </App>
      );
    }
    case "redirectToProjectEditor": {
      return (
        <App>
          <RedirectToProjectEditor branchId={currentRoute.params.branchId} />
        </App>
      );
    }
    case "vscode": {
      return (
        <App>
          <VsCode />
        </App>
      );
    }
    default: {
      try {
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = type;
        throw new Error(`Unhandled tab type: ${exhaustiveCheck}`);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);

        return null;
      }
    }
  }
}

ReactDOM.hydrateRoot(document.getElementById("root")!, <Entry />);
