import type {
  AbsoluteWorkspacePath,
  IDisposable,
  IPitcherClient,
} from "@codesandbox/pitcher-client";
import { FS_SCHEME } from "environment-interface";
import * as vscode from "vscode";
import { registerExtension } from "vscode/extensions";
import { DisposableStore } from "vscode/monaco";

import { GIT_SCHEME, createMonacoGitUri } from "../../../../../utils/uri-utils";
import { runWithLatestMonacoConfig } from "../../tools/with-monaco-config";

import { GitDocumentContentProvider } from "./document-content-provider";
import { FileDecorationProvider } from "./file-decoration-provider";
import { QuickDiffProvider } from "./quick-diff-provider";

export function activate() {
  const disposableStore = new DisposableStore();
  const { dispose } = registerExtension(
    {
      name: "codesandbox-git",
      publisher: "codesandbox",
      engines: {
        vscode: "*",
      },
      version: "1.0.0",
      contributes: {
        commands: [
          {
            command: "git.revertChange",
            title: "Revert Change",
            category: "Git",
            // @ts-ignore
            icon: "$(discard)",
            enablement: "!operationInProgress",
          },
          {
            command: "codesandbox.git.resetLocalToRemote",
            title: "Reset to remote",
            // @ts-ignore
            icon: "$(discard)",
          },
        ],
        menus: {
          commandPalette: [{ command: "git.revertChange", when: "false" }],
          "scm/change/title": [
            {
              command: "git.revertChange",
              when: "originalResourceScheme == git",
            },
          ],
          "view/title": [
            {
              command: "codesandbox.git.resetLocalToRemote",
              group: "navigation",
              when: "view == codesandbox.git.view && codesandbox.git.resetLocalToRemote == true",
            },
          ],
        },
        colors: [
          {
            id: "gitDecoration.addedResourceForeground",
            description: "%colors.added%",
            defaults: {
              light: "#587c0c",
              dark: "#81b88b",
              highContrast: "#a1e3ad",
            },
          },
          {
            id: "gitDecoration.modifiedResourceForeground",
            description: "%colors.modified%",
            defaults: {
              light: "#895503",
              dark: "#E2C08D",
              highContrast: "#E2C08D",
            },
          },
          {
            id: "gitDecoration.deletedResourceForeground",
            description: "%colors.deleted%",
            defaults: {
              light: "#ad0707",
              dark: "#c74e39",
              highContrast: "#c74e39",
            },
          },
          {
            id: "gitDecoration.renamedResourceForeground",
            description: "%colors.renamed%",
            defaults: {
              light: "#007100",
              dark: "#73C991",
              highContrast: "#73C991",
            },
          },
          {
            id: "gitDecoration.untrackedResourceForeground",
            description: "%colors.untracked%",
            defaults: {
              light: "#007100",
              dark: "#73C991",
              highContrast: "#73C991",
            },
          },
          {
            id: "gitDecoration.ignoredResourceForeground",
            description: "%colors.ignored%",
            defaults: {
              light: "#8E8E90",
              dark: "#8C8C8C",
              highContrast: "#A7A8A9",
            },
          },
          {
            id: "gitDecoration.stageModifiedResourceForeground",
            description: "%colors.stageModified%",
            defaults: {
              light: "#895503",
              dark: "#E2C08D",
              highContrast: "#E2C08D",
            },
          },
          {
            id: "gitDecoration.stageDeletedResourceForeground",
            description: "%colors.stageDeleted%",
            defaults: {
              light: "#ad0707",
              dark: "#c74e39",
              highContrast: "#c74e39",
            },
          },
          {
            id: "gitDecoration.conflictingResourceForeground",
            description: "%colors.conflict%",
            defaults: {
              light: "#ad0707",
              dark: "#e4676b",
              highContrast: "#c74e39",
            },
          },
          {
            id: "gitDecoration.submoduleResourceForeground",
            description: "%colors.submodule%",
            defaults: {
              light: "#1258a7",
              dark: "#8db9e2",
              highContrast: "#8db9e2",
            },
          },
        ],
      },
    },
    1,
  );
  disposableStore.add({ dispose });
  disposableStore.add(
    runWithLatestMonacoConfig((config) => runExtension(config.pitcher)),
  );

  return disposableStore;
}

function runExtension(pitcher: IPitcherClient): IDisposable {
  const disposableStore = new DisposableStore();
  const fileDecorationProvider = disposableStore.add(
    new FileDecorationProvider(pitcher),
  );

  const scm = disposableStore.add(
    vscode.scm.createSourceControl(
      "git",
      "Git",
      vscode.Uri.from({
        scheme: FS_SCHEME,
        authority: pitcher.instanceId,
        path: pitcher.workspacePath,
      }),
    ),
  );

  scm.quickDiffProvider = disposableStore.add(new QuickDiffProvider(pitcher));
  disposableStore.add(
    vscode.window.registerFileDecorationProvider(fileDecorationProvider),
  );
  disposableStore.add(
    vscode.workspace.registerTextDocumentContentProvider(
      GIT_SCHEME,
      new GitDocumentContentProvider(pitcher),
    ),
  );

  disposableStore.add(
    vscode.commands.registerCommand(
      "git.revertChange",
      async (uri: vscode.Uri, changes: vscode.LineChange[], index: number) => {
        if (!uri) {
          return;
        }

        const textEditor = vscode.window.visibleTextEditors.filter(
          (e) => e.document.uri.toString() === uri.toString(),
        )[0];

        if (!textEditor) {
          return;
        }

        await _revertChanges(
          textEditor,
          [...changes.slice(0, index), ...changes.slice(index + 1)],
          pitcher,
        );

        const firstStagedLine = changes[index].modifiedStartLineNumber;
        textEditor.selections = [
          new vscode.Selection(firstStagedLine, 0, firstStagedLine, 0),
        ];
      },
    ),
  );

  return disposableStore;
}

async function _revertChanges(
  textEditor: vscode.TextEditor,
  changes: vscode.LineChange[],
  pitcher: IPitcherClient,
): Promise<void> {
  const modifiedDocument = textEditor.document;
  const modifiedUri = modifiedDocument.uri;

  if (modifiedUri.scheme !== FS_SCHEME) {
    return;
  }

  const modifiedPath = modifiedUri.path;
  const relativeModifiedPath =
    pitcher.clients.fs.absoluteToRelativeWorkspacePath(
      modifiedPath as AbsoluteWorkspacePath,
    );

  const originalUri = createMonacoGitUri(relativeModifiedPath, "HEAD");
  const originalDocument = await vscode.workspace.openTextDocument(originalUri);
  const visibleRangesBeforeRevert = textEditor.visibleRanges;
  const result = applyLineChanges(originalDocument, modifiedDocument, changes);

  const edit = new vscode.WorkspaceEdit();
  edit.replace(
    modifiedUri,
    new vscode.Range(
      new vscode.Position(0, 0),
      modifiedDocument.lineAt(modifiedDocument.lineCount - 1).range.end,
    ),
    result,
  );
  vscode.workspace.applyEdit(edit);

  await modifiedDocument.save();

  textEditor.revealRange(visibleRangesBeforeRevert[0]);
}

function applyLineChanges(
  original: vscode.TextDocument,
  modified: vscode.TextDocument,
  diffs: vscode.LineChange[],
): string {
  const result: string[] = [];
  let currentLine = 0;

  for (const diff of diffs) {
    const isInsertion = diff.originalEndLineNumber === 0;
    const isDeletion = diff.modifiedEndLineNumber === 0;

    let endLine = isInsertion
      ? diff.originalStartLineNumber
      : diff.originalStartLineNumber - 1;
    let endCharacter = 0;

    // if this is a deletion at the very end of the document,then we need to account
    // for a newline at the end of the last line which may have been deleted
    // https://github.com/microsoft/vscode/issues/59670
    if (isDeletion && diff.originalEndLineNumber === original.lineCount) {
      endLine -= 1;
      endCharacter = original.lineAt(endLine).range.end.character;
    }

    result.push(
      original.getText(new vscode.Range(currentLine, 0, endLine, endCharacter)),
    );

    if (!isDeletion) {
      let fromLine = diff.modifiedStartLineNumber - 1;
      let fromCharacter = 0;

      // if this is an insertion at the very end of the document,
      // then we must start the next range after the last character of the
      // previous line, in order to take the correct eol
      if (isInsertion && diff.originalStartLineNumber === original.lineCount) {
        fromLine -= 1;
        fromCharacter = modified.lineAt(fromLine).range.end.character;
      }

      result.push(
        modified.getText(
          new vscode.Range(
            fromLine,
            fromCharacter,
            diff.modifiedEndLineNumber,
            0,
          ),
        ),
      );
    }

    currentLine = isInsertion
      ? diff.originalStartLineNumber
      : diff.originalEndLineNumber;
  }

  result.push(
    original.getText(new vscode.Range(currentLine, 0, original.lineCount, 0)),
  );

  return result.join("");
}
