import type { clients } from "@codesandbox/pitcher-client";
import { protocol } from "@codesandbox/pitcher-client";
import type { PitcherEvents } from "environment-interface/pitcher";
import type { GitApi } from "environment-interface/pitcher/git";

export const createGitApi = (
  gitClient: clients.IGitClient,
  pitcherEvents: PitcherEvents,
): GitApi => {
  gitClient.onStatusUpdated((newStatus) => {
    pitcherEvents.emit({
      type: "PITCHER:GIT:STATUS",
      status: newStatus,
    });
  });

  gitClient.onPullStarted(() => {
    pitcherEvents.emit({
      type: "PITCHER:GIT:PULL_STARTED",
    });
  });

  gitClient.onPullFinished(() => {
    pitcherEvents.emit({
      type: "PITCHER:GIT:PULL_FINISHED",
    });
  });

  gitClient.onBranchRenamed(({ newBranch }) => {
    pitcherEvents.emit({
      type: "PITCHER:SET_BRANCH_NAME",
      name: newBranch,
    });
  });

  gitClient.onCommitStarted(({ shellId, message }) => {
    pitcherEvents.emit({
      type: "PITCHER:GIT:COMMIT_STARTED",
      shellId,
      message,
    });
  });

  gitClient.onCommitFinished((notification) => {
    if (notification.exitCode === 0 && "status" in notification) {
      pitcherEvents.emit({
        type: "PITCHER:GIT:COMMIT_FINISHED",
        status: notification.status,
      });
    } else {
      pitcherEvents.emit({
        type: "PITCHER:GIT:COMMIT_FINISHED_ERROR",
      });
    }
  });

  return {
    pull(branch?: string) {
      gitClient
        .pull(branch)
        .then(() => {
          pitcherEvents.emit({
            type: "PITCHER:GIT:PULL_SUCCESS",
          });
        })
        .catch((error) => {
          if (
            error.code === protocol.PitcherErrorCode.GIT_OPERATION_IN_PROGRESS
          ) {
            pitcherEvents.emit({
              type: "PITCHER:GIT:LOCKED_ERROR",
            });
          } else {
            pitcherEvents.emit({
              type: "PITCHER:GIT:PULL_ERROR",
              error: error.message,
            });
          }
        });
    },
    commit({ message, paths, push }) {
      gitClient
        .commit(message, paths, push)
        .then(({ shellId }) => {
          pitcherEvents.emit({
            type: "PITCHER:GIT:COMMIT_SUCCESS",
            shellId,
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:GIT:COMMIT_ERROR",
            error: error.message,
          });
        });
    },
    discard(paths) {
      gitClient
        .discard(paths)
        .then(() => {
          pitcherEvents.emit({
            type: "PITCHER:GIT:DISCARD_SUCCESS",
          });
        })
        .catch((error) => {
          pitcherEvents.emit({
            type: "PITCHER:GIT:DISCARD_ERROR",
            error: error.message,
          });
        });
    },
    remoteContent({ reference, filepath }) {
      return gitClient
        .remoteContent({
          filepath,
          reference,
        })
        .then((v) => v.content);
    },
  };
};
