import { Emitter } from "@codesandbox/pitcher-common";
export class BrowserSystemClient {
    constructor() {
        this.hibernateEmitter = new Emitter();
        this.onHibernate = this.hibernateEmitter.event;
        this.metricsEmitter = new Emitter();
        this.onMetricsUpdated = this.metricsEmitter.event;
        this.initStatusEmitter = new Emitter();
        this.onInitStatusUpdate = this.initStatusEmitter.event;
    }
    resync() {
        return;
    }
    getMetrics() {
        return undefined;
    }
    startMetricsPollingAtInterval() {
        return () => { };
    }
    update() {
        return Promise.reject(new Error("Not implemented"));
    }
    hibernate() {
        return Promise.reject(new Error("Not implemented"));
    }
}
