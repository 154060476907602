import type { PitcherEvents } from "environment-interface/pitcher";
import type { VersionsApi } from "environment-interface/pitcher/versions";
import type { PitcherVersions } from "features/types/pitcher";

export const createVersionsApi = (
  pitcherManagerURL: string,
  pitcherEvents: PitcherEvents,
  currentVersions: PitcherVersions,
): VersionsApi => {
  return {
    getCurrent() {
      return currentVersions;
    },
    fetch: async () => {
      try {
        const response = await fetch(`${pitcherManagerURL}/versions`);

        if (response.status !== 200) {
          pitcherEvents.emit({
            type: "PITCHER:VERSIONS:FETCH_ERROR",
            error: "Failed to fetch latest pitcher version",
          });
          return;
        }

        const versions: PitcherVersions = await response.json();

        currentVersions = versions;

        pitcherEvents.emit({
          type: "PITCHER:VERSIONS:FETCH_SUCCESS",
          versions,
        });
      } catch (error) {
        pitcherEvents.emit({
          type: "PITCHER:VERSIONS:FETCH_ERROR",
          error: "Failed to fetch latest pitcher version",
        });
      }
    },
  };
};
