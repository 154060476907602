import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";
import { registerExtension } from "vscode/extensions";

export function initializeOneDarkProThemes() {
  // eslint-disable-next-line
  const config: any = {
    name: "material-theme",
    displayName: "One Dark Pro",
    description: "Atom's iconic One Dark theme for Visual Studio Code",
    version: "3.16.2",
    publisher: "zhuangtongfa",
    engines: {
      vscode: "*",
    },
    contributes: {
      themes: [
        {
          label: "One Dark Pro",
          uiTheme: "vs-dark",
          path: "./themes/OneDark-Pro.json",
        },
      ],
    },
  };

  const { registerFileUrl } = registerExtension(config, 2);
  registerFileUrl(
    "./themes/OneDark-Pro.json",

    withBasePath(env, "/vscode-extensions/themes/OneDark-Pro.json"),
  );

  return config.contributes.themes;
}
