import type { CSS } from "../theme";

export const item: CSS = {
  px: "$3",
  py: "$2",
  outline: "none",
  color: "$neutral-fg-high",
  cursor: "var(--csb-cursor)",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",

  "&:hover": {
    background: "$neutral-bg-focus",
    outlineColor: "transparent !important",
  },

  "&:focus": {
    background: "$neutral-bg-focus",
    outlineColor: "transparent !important",
  },

  "&[data-disabled]": {
    opacity: 0.4,
    cursor: "not-allowed",

    "&:hover": {
      background: "transparent",
    },
  },
};

export const label: CSS = {
  px: "$3",
  py: "$2",
  color: "$neutral-fg-subtle",
  fontFamily: "$base",
  fontSize: "$caption2",
  fontWeight: "$medium",
  margin: 0,
};

export const separator: CSS = {
  backgroundColor: "$neutral-bg-focus",
  width: "100%",
  height: "1px",
  margin: "$1 0",
};
