import type { Terminal } from "environment-interface/terminal";

/**
 * This could be just a single fn that imports all of them and Promise.all them
 */
const importXTerm = () =>
  import("@xterm/xterm").then((module) => module.Terminal);
const importFitAddon = () => import("@xterm/addon-fit").then((m) => m.FitAddon);
const importGlAddon = () =>
  import("@xterm/addon-webgl").then((m) => m.WebglAddon);

const importPathsAndLinksAddon = () =>
  import("./PathsAndLinksAddon").then((m) => m.PathsAndLinksAddon);

export const createTerminal = (): Terminal => ({
  async createTerminal(handlers, options = {}) {
    const [XTerminal, FitAddon, PathsAndLinksAddon, WebglAddon] =
      await Promise.all([
        importXTerm(),
        importFitAddon(),
        importPathsAndLinksAddon(),
        importGlAddon(),
      ]);

    const terminal = new XTerminal(options);
    const fitAddon = new FitAddon();

    const webLinksAddon = new PathsAndLinksAddon({
      path: handlers.onPath,
      url: handlers.onUrl,
    });

    terminal.loadAddon(fitAddon);
    terminal.loadAddon(webLinksAddon);
    const webglAddon = new WebglAddon();

    // The browser can loose its webgl context, we fall back
    // to normal rendering when that happens. You can not recover
    // from this
    webglAddon.onContextLoss(() => {
      webglAddon.dispose();
    });

    terminal.loadAddon(webglAddon);

    return {
      term: terminal,
      fit: () => {
        fitAddon.fit();
      },
    };
  },
});
