import { toShadowRing } from "prism-react/tokens/shadow";
import React, { forwardRef } from "react";

import { styled } from "../../theme";
import { Tooltip } from "../Tooltip";

import { Badge } from "./Badge";
import type { BadgeProps } from "./Badge";

export interface ButtonBadgeProps extends Omit<BadgeProps, "css"> {
  title?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const StyledButtonWrapper = styled("button", {
  border: "none",
  appearance: "none",
  outline: 0,
  borderRadius: "999px",
  transition: "background $fast, color $fast",

  "&:focus-visible": {
    outlineOffset: 2,
  },

  variants: {
    variant: {
      accentSecondary: {},
      accentPrimary: {},
      accentTrial: {},
      error: {},
      warning: {},
      neutral: {},
    },
    disabled: {
      true: {
        "& .badge-container": {
          cursor: "not-allowed",
          color: "$neutral-fg-base",
        },
      },
    },
  },
  compoundVariants: [
    {
      variant: "accentPrimary",
      disabled: false,
      css: {
        "&:hover .badge-container": {
          opacity: 0.9,
          boxShadow: toShadowRing("thin", "neutral-bg-focus"),
        },
      },
    },
    {
      variant: "accentSecondary",
      disabled: false,
      css: {
        "&:hover .badge-container": {
          background: "$accent-secondary-subtle",
        },
      },
    },
    {
      variant: "accentTrial",
      disabled: false,
      css: {
        "&:hover .badge-container": {
          background: "$accent-trial-subtle",
        },
      },
    },
    {
      variant: "error",
      disabled: false,
      css: {
        "&:hover .badge-container": {
          background: "$informative-error-subtle",
        },
      },
    },
    {
      variant: "warning",
      disabled: false,
      css: {
        "&:hover .badge-container": {
          background: "$informative-warning-base",
        },
      },
    },
    {
      variant: "neutral",
      disabled: false,
      css: {
        "&:hover .badge-container": {
          color: "$neutral-fg-high",
          background: "$neutral-bg-high",
        },
      },
    },
  ],
});

export const ButtonBadge = forwardRef<HTMLButtonElement, ButtonBadgeProps>(
  (
    {
      children,
      title,
      onClick,
      variant = "neutral",
      disabled = false,
      ...badgeProps
    },
    ref,
  ) => {
    const content = (
      <StyledButtonWrapper
        ref={ref}
        aria-disabled={disabled}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        variant={variant}
      >
        <Badge {...badgeProps} variant={variant}>
          {children}
        </Badge>
      </StyledButtonWrapper>
    );

    return title ? <Tooltip content={title}>{content}</Tooltip> : content;
  },
);
