// Based on iniparser by shockie <https://npmjs.org/package/iniparser>
// Taken from <https://yarnpkg.com/package/editorconfig> with some
// modifications to run in the browser

/**
 * define the possible values:
 * section: [section]
 * param: key=value
 * comment: ;this is a comment
 */
const regex = {
  section: /^\s*\[(([^#;]|\\#|\\;)+)\]\s*([#;].*)?$/,
  param: /^\s*([\w.\-_]+)\s*[=:]\s*(.*?)\s*([#;].*)?$/,
  comment: /^\s*[#;].*$/,
};

export type SectionName = string | null;
export type SectionBody = Record<string, string>;
export type ParseStringResult = Array<[SectionName, SectionBody]>;

export function parseString(data: string): ParseStringResult {
  let sectionBody: SectionBody = {};
  let sectionName: SectionName = null;
  const value: ParseStringResult = [[sectionName, sectionBody]];
  const lines = data.split(/\r\n|\r|\n/);
  lines.forEach((line) => {
    let match: RegExpMatchArray | null;
    if (regex.comment.test(line)) {
      return;
    }
    if (regex.param.test(line)) {
      match = line.match(regex.param);
      sectionBody[(match as RegExpMatchArray)[1]] = (
        match as RegExpMatchArray
      )[2];
    } else if (regex.section.test(line)) {
      match = line.match(regex.section);
      sectionName = (match as RegExpMatchArray)[1];
      sectionBody = {};
      value.push([sectionName, sectionBody]);
    }
  });
  return value;
}
