import { opaqueHex, palette } from "./palette";

// ========================================
//  NEW Tokens

/**
 * Name structure
 * {category}-{variant}-{qualifier}
 */

// TODO: decide if we want this type ⬇️
// type ScaleCategory<Category extends string> = Record<
//   `${Category}-${string}-${string}`,
//   string
// >;

/**
 * Accent
 *
 * Used in logical ways throughout the product to guide the eye and highlight
 * the important bits and create brand awareness.
 * ----------------------------
 */

export const accent = {
  "accent-primary-base": palette.lime[7],
  "accent-secondary-base": palette.purple[7],
  "accent-secondary-subtle": palette.purple[8],
  "accent-secondary-high": palette.purple[9],
  "accent-trial-base": palette.purple[7],
  "accent-trial-subtle": palette.purple[8],
};

/**
 * Neutral
 *
 * Varying degrees of saturation to be used across text and backgrounds,
 * they are neutral tone to help set a calm and not distract environment.
 * ----------------------------
 */

// Background
export const neutralBg = {
  "neutral-bg-low": palette.neutral[0],
  "neutral-bg-base": palette.neutral[1],
  "neutral-bg-subtle": palette.neutral[2],
  "neutral-bg-medium": palette.neutral[3],
  "neutral-bg-high": palette.neutral[4],
  "neutral-bg-contrast": palette.neutral[10],

  // Modifiers
  "neutral-bg-focus": opaqueHex(palette.neutral[10], 0.1),
  "neutral-bg-focus_contrast": opaqueHex(palette.neutral[0], 0.1),
  "neutral-bg-base_transparent": opaqueHex(palette.neutral[1], 0),
};

// Foreground
export const neutralFg = {
  "neutral-fg-low": palette.neutral[6],
  "neutral-fg-base": palette.neutral[7],
  "neutral-fg-subtle": palette.neutral[8],
  "neutral-fg-medium": palette.neutral[9],
  "neutral-fg-high": palette.neutral[10],
};

/**
 * Informative
 *
 * Used purposefully to communicate and inform users. Should be used carefully
 * when the brand awareness are not present.
 * ----------------------------
 */

export const informative = {
  "informative-warning-base": palette.yellow[7],
  "informative-alert-base": palette.orange[7],
  "informative-error-base": palette.red[7],
  "informative-error-subtle": palette.red[8],
  "informative-success-base": palette.green[7],
  "informative-info-base": palette.blue[7],
};

/**
 * Support
 *
 * To be used in specific scenarios, the colors that are available in this category
 * have a unique purpose and should not be used in other places.
 * ----------------------------
 */

export const support = {
  // Git
  "support-git-modified": palette.yellow[7],
  "support-git-untracked": palette.green[7],
  "support-git-deleted": palette.red[7],
  "support-git-renamed": palette.neutral[7],
  "support-git-conflict": palette.orange[7],

  // System
  "support-system-selection": opaqueHex(palette.purple[6], 0.2),
  "support-system-terminal": palette.neutral[0],
  "support-system-scrollbar": opaqueHex(palette.neutral[9], 0.2), // Opacity so we can see ruler items still in the editor
};
