import type { clients } from "@codesandbox/pitcher-client";
import type { PitcherEvents } from "environment-interface/pitcher";
import type { ClientsApi } from "environment-interface/pitcher/clients";

export const createClientApi = (
  clientsClient: clients.IClientClient,
  pitcherEvents: PitcherEvents,
): ClientsApi => {
  clientsClient.onClientConnected((client) => {
    pitcherEvents.emit({
      type: "PITCHER:CLIENTS:CLIENT_CONNECTED",
      client,
    });
  });

  clientsClient.onClientDisconnected(({ clientId, reason }) => {
    pitcherEvents.emit({
      type: "PITCHER:CLIENTS:CLIENT_DISCONNECTED",
      clientId,
      reason,
    });
  });

  clientsClient.onClientUpdated((client) => {
    pitcherEvents.emit({
      type: "PITCHER:CLIENTS:CLIENT_UPDATED",
      client,
    });
  });

  clientsClient.onClientPermissionsUpdated((permissions) => {
    pitcherEvents.emit({
      type: "PITCHER:CLIENTS:CLIENT_PERMISSIONS_UPDATED",
      ...permissions,
    });
  });

  clientsClient.onClientsUpdated((clients) => {
    pitcherEvents.emit({
      type: "PITCHER:CLIENTS:CLIENTS_UPDATED",
      clients,
    });
  });

  clientsClient.onClientsError((error) => {
    pitcherEvents.emit({
      type: "PITCHER:CLIENTS:ERROR",
      error,
    });
  });

  return {
    getClients() {
      return clientsClient.getClients();
    },
  };
};
