import { Emitter } from "@codesandbox/pitcher-common";
export class BrowserGitClient {
    constructor() {
        this.statusUpdatedEmitter = new Emitter();
        this.onStatusUpdated = this.statusUpdatedEmitter.event;
        this.checkoutPreventedEmitter = new Emitter();
        this.onCheckoutPrevented = this.checkoutPreventedEmitter.event;
        this.targetDiffEmitter = new Emitter();
        this.onTargetDiffUpdated = this.targetDiffEmitter.event;
        this.remotesUpdatedEmitter = new Emitter();
        this.onRemotesUpdated = this.remotesUpdatedEmitter.event;
        this.remotesErrorEmitter = new Emitter();
        this.onRemotesError = this.remotesErrorEmitter.event;
        this.pullStartedEmitter = new Emitter();
        this.onPullStarted = this.pullStartedEmitter.event;
        this.pullFinishedEmitter = new Emitter();
        this.onPullFinished = this.pullFinishedEmitter.event;
        this.commitStartedEmitter = new Emitter();
        this.onCommitStarted = this.commitStartedEmitter.event;
        this.commitFinishedEmitter = new Emitter();
        this.onCommitFinished = this.commitFinishedEmitter.event;
        this.branchRenamedEmitter = new Emitter();
        this.onBranchRenamed = this.branchRenamedEmitter.event;
    }
    pushToRemote() {
        return Promise.reject(new Error("Not implemented"));
    }
    resetLocalWithRemote() {
        return Promise.reject(new Error("Not implemented"));
    }
    getStatus() {
        return Promise.resolve({
            changedFiles: {},
            deletedFiles: [],
            conflicts: false,
            localChanges: false,
            remote: {
                head: "",
                branch: "",
                ahead: 0,
                behind: 0,
                safe: true,
            },
            target: {
                head: "",
                branch: "",
                ahead: 0,
                behind: 0,
                safe: true,
            },
            commits: [],
            branch: null,
            isMerging: false,
        });
    }
    getRemotes() {
        return {
            origin: "",
            upstream: "",
        };
    }
    getTargetDiffStatus() {
        return Promise.resolve({
            files: [],
        });
    }
    resync() {
        return;
    }
    compare() {
        return Promise.reject(new Error("Not implemented"));
    }
    pull() {
        return Promise.reject(new Error("Not implemented"));
    }
    commit() {
        return Promise.reject(new Error("Not implemented"));
    }
    push() {
        return Promise.reject(new Error("Not implemented"));
    }
    discard() {
        return Promise.reject(new Error("Not implemented"));
    }
    renameBranch() {
        return Promise.reject(new Error("Not implemented"));
    }
    remoteContent() {
        return Promise.reject(new Error("Not implemented"));
    }
    checkoutInitialBranch() {
        return Promise.reject(new Error("Not implemented"));
    }
    transposeLines() {
        return Promise.reject(new Error("Not implemented"));
    }
}
