import type {
  CsbApi,
  GraphQlOptions,
  GraphQlResult,
} from "environment-interface/csbApi";

import { csbApi } from "utils/csbApi";

/**
 * Creates a http interface for a given endpoint
 */
export const createCsbApi = (endpoint: string): CsbApi => ({
  graphQL: {
    query: async <Data extends unknown>({
      accessToken,
      body,
    }: GraphQlOptions): Promise<GraphQlResult<Data>> => {
      const sameOriginRequest = endpoint.includes(window.location.origin);
      const headers = withAuthorization(accessToken, {
        ...(sameOriginRequest ? { "x-codesandbox-client": "web" } : {}),
        "Content-Type":
          typeof body === "object" ? "application/json" : "text/plain",
      });

      /**
       * @fixme Always send a JSON with the correct "Content-Type"
       * to form a valid GraphQL POST request;
       * @see https://graphql.org/learn/serving-over-http/#post-request
       *
       * Right now leaving the string "body" for backwards compatibility.
       */
      const bodyString = typeof body === "object" ? JSON.stringify(body) : body;

      return fetch(`${endpoint}/graphql`, {
        method: "POST",
        body: bodyString,
        headers,
      }).then(async (response) => {
        const json = await response.json();

        if (json.errors) {
          return {
            type: "ERROR",
            errors: json.errors as Error[],
          };
        }

        return {
          type: "SUCCESS",
          data: json.data as Data,
        };
      });
    },
  },
  /**
   * This is a legacy API until we can get all calls to use
   * CodeSandboxApi directly
   */
  rest: {
    post: (options) => csbApi.post(options),
    put: (options) => csbApi.put(options),
    patch: (options) => csbApi.patch(options),
    get: (options) => csbApi.get(options),
    delete: (options) =>
      csbApi.delete({
        path: options.path,
      }),
  },
  devJwt: {
    get: () => csbApi.session.bearerToken,
  },
});

const withAuthorization = (
  accessToken: string | undefined,
  headers: Record<string, string>,
) => {
  if (!accessToken) return headers;

  return {
    ...headers,
    Authorization: `Bearer ${accessToken}`,
  };
};
