import { env } from "environment-interface/env";
import { NotificationsProvider } from "features/notifications/Feature";
import { MotionConfig } from "framer-motion";
import { useCsbApi } from "hooks/api/useCsbApi";
import { withBasePath } from "isomorphic/utils";
import { Box, TooltipProvider, ThemeProvider } from "prism-react";
import React, { memo, useLayoutEffect } from "react";

import { useTrackPageView } from "hooks/useAnalytics";
import { useCookieConsent } from "hooks/useCookieConsent";

import { Debug } from "../Debug";
import { GlobalError } from "../components/Error/GlobalError";
import EnvironmentInterfaceProvider from "../environment-interface/browser";
import { initializeSentry } from "../sentry.client.config";

import { ErrorBoundary } from "./ErrorBoundary";

import "../utils/cookieConsent/cookieConsentTheme.css";
import "vanilla-cookieconsent/dist/cookieconsent.css";

initializeSentry();

const PageViewTracker = () => {
  useTrackPageView();

  return null;
};

const CookieConsent = () => {
  useCookieConsent();
  return null;
};

// Due to us quite frequently updating queries, the Next JS router causes
// a reconciliation here, which is why we make sure to check if the props are
// actually changed
export const App = memo(function App({
  children,
  manuallyUnmountSsrLoader = false,
}: {
  children: React.ReactNode;
  manuallyUnmountSsrLoader?: boolean;
}): React.ReactElement {
  useLayoutEffect(() => {
    if (manuallyUnmountSsrLoader) {
      return;
    }
    document.querySelector("#ssr-loading")?.remove();
  }, []);

  return (
    <EnvironmentInterfaceProvider>
      <TooltipProvider delayDuration={600}>
        <useCsbApi.Provider>
          <CookieConsent />
          <PageViewTracker />
          <Debug>
            <NotificationsProvider>
              <ErrorBoundary
                fallback={({ error }) => {
                  return (
                    <Box
                      css={{
                        height: "100vh",
                      }}
                    >
                      <GlobalError
                        context={{
                          context: "ERROR_BOUNDARY",
                          error: error.message,
                          originalError: error,
                        }}
                        errorCode="RUNTIME_ERROR"
                      />
                    </Box>
                  );
                }}
              >
                <_ThemeProvider>
                  <MotionConfig
                    reducedMotion="user"
                    transition={{
                      type: "spring",
                      stiffness: 150,
                      damping: 20,
                      mass: 0.7,
                    }}
                  >
                    {children}
                  </MotionConfig>
                </_ThemeProvider>
              </ErrorBoundary>
            </NotificationsProvider>
          </Debug>
        </useCsbApi.Provider>
      </TooltipProvider>
    </EnvironmentInterfaceProvider>
  );
});

const _ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ThemeProvider withBasePath={(path) => withBasePath(env, path)}>
      {children}
    </ThemeProvider>
  );
};
