/* eslint-disable no-console */

import { env } from "environment-interface/env";
import { useEffect } from "react";

export type ServiceWorkerMessage =
  | {
      type: "CACHE_EXPIRED";
    }
  | {
      type: "CACHE_UPDATED";
    }
  | {
      type: "CACHE_IS_LATEST";
    }
  | {
      type: "CACHE_NOT_AVAILABLE";
    }
  | {
      type: "CACHE_CREATED";
    }
  | {
      type: "CACHE_UPDATE_FAILED";
      error: string;
    }
  | {
      type: "CLEAR_CACHE_SUCCESS";
    }
  | {
      type: "CLEAR_CACHE_ERROR";
    };

const updateListeners = new Set<(message: ServiceWorkerMessage) => void>();

let lastServiceWorkerMessage: ServiceWorkerMessage | undefined;

function notify(message: ServiceWorkerMessage) {
  lastServiceWorkerMessage = message;
  updateListeners.forEach((listener) => listener(message));
}

export const onUpdate = (
  updateListener: (message: ServiceWorkerMessage) => void,
) => {
  updateListeners.add(updateListener);

  return () => {
    updateListeners.delete(updateListener);
  };
};

export const getLastServiceWorkerMessage = () => lastServiceWorkerMessage;

// Because we call this from a React useEffect there is some risk it will be called twice, we do not want that
let isRegistered = false;
let registeredWorker: ServiceWorker | undefined;

export const registerEditorServiceWorker = async () => {
  if (
    (env.PUBLIC_ENVIRONMENT === "production" ||
      env.PUBLIC_ENVIRONMENT === "staging") &&
    !isRegistered &&
    "serviceWorker" in navigator
  ) {
    const shouldDebug = Boolean(
      localStorage.getItem("CSB_DEBUG_SERVICE_WORKER"),
    );

    isRegistered = true;
    try {
      if (shouldDebug) {
        console.log("[EDITOR-SERVICE-WORKER]: Initializing");
      }

      const registration = await navigator.serviceWorker.register(
        (env.PUBLIC_BASE_PATH || "") + "/editor-sw.js",
        {
          scope: (env.PUBLIC_BASE_PATH || "") + "/",
        },
      );

      let isActive = false;
      const connectServiceWorker = (sw: ServiceWorker) => {
        // For some reason the state change can trigger twice for an active service worker
        if (isActive) {
          return;
        }

        if (shouldDebug) {
          console.log("[EDITOR-SERVICE-WORKER]: Activated");
        }

        isActive = true;

        navigator.serviceWorker.addEventListener(
          "message",
          function handler(event) {
            if (event.source !== sw) {
              return;
            }

            if (shouldDebug) {
              console.log("[EDITOR-SERVICE-WORKER]: Got message", event.data);
            }

            if (event.data.type === "CACHE_UPDATE_FAILED") {
              notify({
                type: "CACHE_UPDATE_FAILED",
                error: event.data.error,
              });
            } else {
              notify({
                type: event.data.type,
              });
            }
          },
        );

        sw.postMessage({
          type: "INIT",
          url: window.location.href,
          debug: shouldDebug,
        });

        registeredWorker = sw;
      };

      if (registration.installing) {
        if (shouldDebug) {
          console.log("[EDITOR-SERVICE-WORKER]: Installing");
        }
        registration.installing.addEventListener("statechange", () => {
          if (registration.active) {
            connectServiceWorker(registration.active);
          }
        });
      } else if (registration.active) {
        connectServiceWorker(registration.active);
      } else {
        console.error(
          "[EDITOR-SERVICE-WORKER] Invalid registration state",
          registration,
        );
      }

      registration.installing?.addEventListener("error", (event) => {
        // Will happen when there is a runtime error loading the new service worker script
        // eslint-disable-next-line
        console.error(
          "[EDITOR-SERVICE-WORKER] Error installing service worker",
        );
        notify({
          type: "CACHE_UPDATE_FAILED",
          error: event.error,
        });
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(`Service worker registration failed with ${error}`);
    }
  }
};

export const useEditorServiceWorker = () => {
  useEffect(() => {
    if (
      window.location.origin === "https://codesandbox.io" ||
      window.location.origin === "https://codesandbox.stream"
    ) {
      registerEditorServiceWorker();
    }
  }, []);
};

export const clearCache = () => {
  if (!registeredWorker) {
    throw new Error(
      "Can not clear the cache of a service worker not registered yet",
    );
  }

  const worker = registeredWorker;

  return new Promise<void>((resolve) => {
    const dispose = onUpdate((message) => {
      // We'll resolve regardless as we can not do much different if it fails for some reason
      if (
        message.type === "CLEAR_CACHE_SUCCESS" ||
        message.type === "CLEAR_CACHE_ERROR"
      ) {
        dispose();
        resolve();
      }
    });
    worker.postMessage({
      type: "CLEAR_CACHE",
      debug: true,
    });
  });
};
