import lexicalComposer from "@lexical/react/LexicalComposer";
import lexicalComposerContext from "@lexical/react/LexicalComposerContext";
import lexcialContentEditable from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import lexicalOnChangePlugin from "@lexical/react/LexicalOnChangePlugin";
import lexicalPlainTextPlugin from "@lexical/react/LexicalPlainTextPlugin";
import lexical from "lexical";
import { styled } from "prism-react/theme";
import type { CSS } from "prism-react/theme/stitches";
import { useState, useEffect } from "react";

import { TextArea } from "../TextArea/TextArea";

import { MentionNode } from "./nodes/MentionNode";
import type { MentionUsers } from "./plugins/MentionsPlugin";
import { MentionsPlugin } from "./plugins/MentionsPlugin";
import { transformFromStringToRichText } from "./transformFromStringToRichText";

const { LexicalComposer } = lexicalComposer;
const { useLexicalComposerContext } = lexicalComposerContext;
const { ContentEditable } = lexcialContentEditable;
const { OnChangePlugin } = lexicalOnChangePlugin;
const { PlainTextPlugin } = lexicalPlainTextPlugin;
const { $getRoot } = lexical;

type Props = {
  placeholder?: string;
  onChange?: (textContext: string) => void;
  defaultValue?: string;
  mentionUsers: MentionUsers;
  layoutMode?: "expand-on-focus";
  autoFocus?: boolean;
  textareaCss?: CSS;
};

/**
 * Prototype: https://codesandbox.io/s/lexical-example-88qyx2?file=/src/utils/fromStringToRichText.ts:0-1548
 */
export const TextAreaRichText = ({
  placeholder,
  onChange,
  defaultValue = "",
  mentionUsers,
  layoutMode,
  autoFocus,
  textareaCss,
  ...props
}: Props) => {
  const [focused, setFocused] = useState(false);
  const expandOnFocus = !focused && layoutMode === "expand-on-focus";

  return (
    <LexicalComposer
      initialConfig={{
        namespace: "textarea-rich-text",
        theme: {},
        onError(error) {
          throw error;
        },
        editorState: () => transformFromStringToRichText(defaultValue),
        nodes: [MentionNode],
      }}
    >
      <TextArea
        as="div"
        css={{
          color: "$neutral-fg-subtle",
          position: "relative",
          transition: "height $slow",
          height: expandOnFocus ? 30 : "$25",
          ".mention": {
            color: "$neutral-fg-high",
          },
          ...textareaCss,
        }}
        {...props}
        onKeyDown={(event) => event.stopPropagation()}
      >
        <PlainTextPlugin
          contentEditable={<StyledContentEditable />}
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={<StyledEditorPlaceholder children={placeholder} />}
        />
        <OnChangePlugin
          onChange={(editorState) => {
            editorState.read(() => {
              const root = $getRoot();
              const textContent = root.getTextContent();

              setFocused(true);

              onChange?.(textContent);
            });
          }}
        />
        <AutoFocusPlugin autoFocus={autoFocus} />
        <MentionsPlugin users={mentionUsers} />
      </TextArea>
    </LexicalComposer>
  );
};

function AutoFocusPlugin({ autoFocus }: { autoFocus?: boolean }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (autoFocus) editor.focus();
  }, [editor, autoFocus]);

  return null;
}

const StyledContentEditable = styled(ContentEditable, {
  outline: 0,
  height: "100%",

  p: {
    margin: 0,
  },
});

const StyledEditorPlaceholder = styled("div", {
  color: "$neutral-fg-base",
  fontFamily: "$base",
  fontSize: "$base",
  overflow: "hidden",
  position: "absolute",
  textOverflow: "ellipsis",
  top: 6,
  left: 8,
  userSelect: "none",
  display: "inline-block",
  pointerEvents: "none",
});
