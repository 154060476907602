import type { VariantProps, CSS } from "@stitches/react";
import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";

import { styled } from "../../theme";

interface ParagraphProps
  extends VariantProps<typeof StyledParagraph>,
    ComponentPropsWithoutRef<"p"> {
  // Allow everything for children
  children: React.ReactNode;
  css?: CSS;
}

export const StyledParagraph = styled("p", {
  // Reset
  margin: 0,

  // Set
  fontFamily: "$base",
  fontWeigt: "$base",

  variants: {
    size: {
      // ❓ map through theme variants or do manually?
      xs: {
        typography: "$xs",
      },
      base: {
        typography: "$base",
      },
      sm: {
        typography: "$sm",
      },
      md: {
        typography: "$md",
      },
      lg: {
        typography: "$lg",
      },
      xl: {
        typography: "$xl",
      },
      xxl: {
        typography: "$xxl",
      },
    },
  },
  defaultVariants: {
    size: "base",
  },
});

// Custom component wrapper to restrict using another html element
export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ children, size, css, ...restProps }, ref) => {
    return (
      <StyledParagraph ref={ref} size={size} {...restProps} css={css}>
        {children}
      </StyledParagraph>
    );
  },
);
