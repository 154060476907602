import type { CSSProperties } from "@stitches/react";

/**
 * This utility creates Stitches variants for Stitches components based on a set of theme tokens.
 * @param tokens An object with tokens from the theme
 * @param cssProperty The CSS property that we want to create the variant for
 * @returns An object with the variants for the given property and its corresponding tokens
 */
export function createVariants<T extends Record<string, string | number>>(
  tokens: T,
  cssProperty: keyof CSSProperties,
): CreatedVariants<T> {
  const keys = Object.keys(tokens) as Array<keyof T>;
  return keys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        [cssProperty]: "$" + String(key),

        ...(String(key).startsWith("informative-")
          ? {
              "> a": {
                color: "$" + String(key),
                textDecoration: "underline !important",

                "&:hover": {
                  textDecoration: "none  !important",
                },
              },
            }
          : {}),
      },
    };
  }, {} as CreatedVariants<T>);
}

type CreatedVariants<T> = keyof T extends string
  ? Record<keyof T, Record<keyof CSSProperties, `$${keyof T}`>>
  : never;
