import { useSyncExternalStore } from "react";

import { useCsbApi } from "./useCsbApi";

export const useSession = () => {
  const csbApi = useCsbApi();

  const state = useSyncExternalStore(
    (setState) => csbApi.session.onSessionChange(setState),
    () => csbApi.session.state.get(),
  );

  return [state, csbApi.session] as const;
};
