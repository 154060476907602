import { ApiResponseError } from "@codesandbox/api";
import { Emitter } from "@codesandbox/pitcher-common";
import { version as protocolVersion } from "@codesandbox/pitcher-protocol";
import { States } from "class-states";
import { compare } from "semver";
import { CancellationError, retryPromise } from "../common/retryPromise";
import { PitcherFeatureToVersionMap } from "../common/versions";
import { PitcherMessageHandler } from "./PitcherMessageHandler";
import { createWebSocketClient } from "./WebSocketClient";
import { AiClient } from "./clients/AiClient";
import { ChannelClient } from "./clients/ChannelClient";
import { ClientClient } from "./clients/ClientClient";
import { CommandClient } from "./clients/CommandClient";
import { ContainerClient } from "./clients/ContainerClient";
import { FSClient } from "./clients/FSClient";
import { FileClient } from "./clients/FileClient";
import { GitClient } from "./clients/GitClient";
import { LanguageClient } from "./clients/LanguageClient";
import { NotificationClient } from "./clients/NotificationClient";
import { PortClient } from "./clients/PortClient";
import { SetupClient } from "./clients/SetupClient";
import { ShellClient } from "./clients/ShellClient";
import { SystemClient } from "./clients/SystemClient";
import { TaskClient } from "./clients/TaskClient";
let INITIAL_MAX_CONNECTION_TRIES = 3;
let INITIAL_CONNECTION_DELAY_MS = 500;
let INITIAL_CONNECTION_TIMEOUT_MS = 90000;
const RECONNECT_MAX_CONNECTION_TRIES = 3;
const RECONNECT_CONNECTION_DELAY_MS = 500;
const RECONNECT_CONNECTION_TIMEOUT_MS = 10000;
// Minimum timeout before trying a new reconnect. Multiplied by attempts
// Timeout for detecting a pong response, leading to a forced disconnect
let PONG_DETECTION_TIMEOUT = 15000;
// When focusing the app we do a lower timeout to more quickly detect a potential disconnect
const FOCUS_PONG_DETECTION_TIMEOUT = 5000;
if (typeof process !== "undefined" && process.env.NODE_ENV === "test") {
    // eslint-disable-next-line no-console
    console.log("Using test timeouts");
    PONG_DETECTION_TIMEOUT = 200;
    INITIAL_MAX_CONNECTION_TRIES = 3;
    INITIAL_CONNECTION_DELAY_MS = 10;
    INITIAL_CONNECTION_TIMEOUT_MS = 1000;
}
function createConnectionString(hostResponse, reconnectToken) {
    return `${hostResponse.pitcherURL}/?token=${hostResponse.pitcherToken}${reconnectToken ? `&reconnectToken=${reconnectToken}` : ""}`;
}
/**
 * This is the main object where clients consume Pitcher. It is instantiated with a connected connection
 * to Pitcher. This way you have a guarantee, also through typing, to be able to use its full API from the "get go".
 *
 * Its main state (connection state) is driven by the current connection, which can be changed out related to seamless branching.
 */
export class PitcherVMClient {
    get bootupType() {
        return this.hostResponse.bootupType;
    }
    get currentClient() {
        return this.joinResult.client;
    }
    get capabilities() {
        return this.joinResult.capabilities;
    }
    get permissions() {
        return this.joinResult.permissions;
    }
    get pitcherVersion() {
        return this.joinResult.version;
    }
    get pitcherManagerVersion() {
        return this.hostResponse.pitcherManagerVersion;
    }
    get pitcherProtocolVersion() {
        return this.joinResult.protocolVersion;
    }
    get workspacePath() {
        return this.hostResponse.workspacePath;
    }
    get userWorkspacePath() {
        return this.hostResponse.userWorkspacePath;
    }
    get cluster() {
        return this.hostResponse.cluster;
    }
    get reconnectToken() {
        return this.joinResult.reconnectToken;
    }
    constructor(opts) {
        this.type = "vm";
        this.lastFocusTimestamp = Date.now();
        this.isFocused = true;
        this.state = new States({
            // We always instantiate with a connected connection
            state: "CONNECTED",
            lastActivity: Date.now(),
            lastFocus: Date.now(),
            connectedAt: Date.now(),
        });
        this.onStateChange = this.state.onTransition;
        this.onReconnectedEmitter = new Emitter();
        this.onReconnected = this.onReconnectedEmitter.event;
        this.onInstanceChangedEmitter = new Emitter();
        this.onInstanceChanged = this.onInstanceChangedEmitter.event;
        this.sessionStartTime = Date.now();
        this.instanceId = opts.instanceId;
        this.hostResponse = opts.hostResponse;
        this.joinResult = opts.joinResult;
        this.messageHandler = opts.messageHandler;
        this.onFocusChange = opts.onFocusChange;
        /**
         * This event can trigger multiple times, but should only result in a single seamless fork. That means the consumer needs
         * to unsubscribe from this event when a new branch is being created
         */
        this.onInstanceChangeRequired =
            this.messageHandler.onInstanceChangeRequired;
        this.appId = opts.appId;
        this.subscriptions = opts.subscriptions;
        this.requestPitcherInstance = opts.requestPitcherInstance;
        this.hostResponse = opts.hostResponse;
        this.subscribeConnection(opts.initialConnection);
        this.connection = opts.initialConnection;
        const fsClient = new FSClient(this.workspacePath, this.userWorkspacePath, this.messageHandler);
        const fileClient = new FileClient(this.messageHandler, fsClient, this.workspacePath);
        const clientClient = new ClientClient(this.messageHandler);
        this.clients = {
            fs: fsClient,
            client: clientClient,
            shell: new ShellClient(this.messageHandler),
            port: new PortClient(this.messageHandler),
            file: fileClient,
            language: new LanguageClient(this.messageHandler, fileClient),
            git: new GitClient(this.messageHandler),
            setup: new SetupClient(this.messageHandler),
            channel: new ChannelClient({
                clientClient,
                currentClient: this.currentClient,
                messageHandler: this.messageHandler,
            }),
            task: new TaskClient(this.messageHandler),
            system: new SystemClient(this.messageHandler),
            command: new CommandClient(this.messageHandler),
            notification: new NotificationClient(this.messageHandler),
            ai: new AiClient(this.messageHandler),
            container: new ContainerClient(this.messageHandler),
        };
        this.onMessageError = this.messageHandler.onError;
        this.onMessage = (cb) => this.messageHandler.onMessage(cb);
        // When requiring an instance change we immediately stop handling requests by throwing an error. This will now
        // queue any requests eligable for queuing
        this.messageHandler.onInstanceChangeRequired(() => {
            this.messageHandler.setOnSendRequest(() => {
                throw new Error("Instance change required");
            });
        });
        /**
         * We want to do a clean disconnect and put the client into a HIBERNATED state
         * when Pitcher intends to hibernate. This helps us understand the difference between
         * true disconnects and hibernation related disconnects
         */
        this.clients.system.onHibernate(() => {
            this.state.set({
                state: "HIBERNATED",
                wasFocused: this.isFocused,
                disconnectedAt: Date.now(),
            });
            this.connection.dispose("Hibernation");
        });
        /**
         * The name and avatarUrl is lazily updated by Pitcher so we update the
         * "currentClient" with it whenever we have updates
         */
        this.clients.client.onClientsUpdated((clients) => {
            const currentClient = clients.find((client) => client.clientId === this.currentClient.clientId);
            // We only update the client when the name changes or we have no avatar
            if (currentClient &&
                (this.currentClient.name !== currentClient.name ||
                    !this.currentClient.avatarUrl)) {
                this.currentClient.name = currentClient.name;
                this.currentClient.avatarUrl = currentClient.avatarUrl;
            }
        });
        /**
         * We listen to focus changes to evaluate:
         *  - Sending an immedate PING to quickly detect any disconnected state
         *  - Do a reconnect if we are hibernated or disconnected
         */
        this.onFocusChangeDisposer = this.onFocusChange((isFocused) => {
            this.isFocused = isFocused;
            const state = this.state.get();
            if (isFocused) {
                this.lastFocusTimestamp = Date.now();
            }
            // We immediately ping the connection when focusing, so that
            // we detect a disconnect as early as possible
            if (isFocused && state.state === "CONNECTED") {
                this.connection.ping(FOCUS_PONG_DETECTION_TIMEOUT);
                // If we happen to be disconnected when focusing we try to reconnect, but only if we are currently
                // hibernated and we did not do a manual disconnect
            }
            else if (isFocused &&
                (state.state === "DISCONNECTED" || state.state === "HIBERNATED")) {
                this.attemptReconnect({
                    disconnectReason: state.state === "DISCONNECTED" ? state.reason : "HIBERNATED",
                    disconnectedAt: state.disconnectedAt,
                }).catch(() => {
                    // We do not care about failed attempts in this scenario
                });
            }
        });
    }
    subscribeConnection(ws) {
        // First we ensure messages from the WebSocket is passed to PitcherMessageHandler
        const onMessageDisposer = ws.onMessage((message) => {
            this.messageHandler.receiveMessage(message);
        });
        // Then we ensure messages from PitcherMessageHandler is sent on the connection
        this.messageHandler.setOnSendRequest((request) => {
            const state = this.state.get();
            // We only send messages if we are already connected, or if we are trying to join Pitcher, which is
            // internal part of the reconnect flow
            if (state.state === "CONNECTED" || request.method === "client/join") {
                ws.send(request.message);
                return;
            }
            // If we are not connected we typically want to attempt a reconnect, but only if we are currently
            // focusing the application
            if (this.isFocused &&
                (state.state === "DISCONNECTED" || state.state === "HIBERNATED")) {
                this.attemptReconnect({
                    disconnectReason: state.state === "DISCONNECTED" ? state.reason : "HIBERNATED",
                    disconnectedAt: state.disconnectedAt,
                }).catch(() => {
                    // We do not care about the failed promise here as this is an internal reconnect attempt
                });
            }
            // At this point we are not focused or in a reconnecting state. In this scenario we just throw, because
            // PitcherMessageHandler will now handle this request related to its configuration of being queued or not
            throw new Error("Not able to send message in state " + state.state);
        });
        const onDisconnectedDisposer = ws.onDisconnected(({ reason, code, wasClean }) => {
            const state = this.state.get();
            // When the connection is disconnected we attempt a reconnect given that we are
            // focusing the app
            if (this.isFocused && state.state === "CONNECTED") {
                this.attemptReconnect({
                    disconnectReason: reason,
                    disconnectedAt: Date.now(),
                }).catch(() => {
                    // We do not care about the failed promise here as this is an internal reconnect attempt
                });
            }
            else {
                this.state.set({
                    state: "DISCONNECTED",
                    reason,
                    code,
                    wasClean,
                    wasFocused: this.isFocused,
                    disconnectedAt: Date.now(),
                });
            }
        });
        // When the connection disposes due to a disconnect, or explicit disposal, we ensure to not pass
        // any messages from it to PitcherMessageHandler anymore
        ws.onWillDispose(() => {
            onMessageDisposer.dispose();
            onDisconnectedDisposer.dispose();
        });
    }
    async resolveCurrentConnection() {
        // Subscribe to connection so that we pass messages from it to PitcherMessageHandler and
        // allow PitcherMessageHandler to send messages on the connection
        this.subscribeConnection(this.connection);
        // eslint-disable-next-line
        console.log("[pitcher-client]: Joining");
        // Then we join Pitcher
        this.joinResult = await this.clients.client.join({
            clientInfo: {
                protocolVersion,
                appId: this.appId,
            },
            asyncProgress: false,
            subscriptions: this.subscriptions,
        });
        // Finally resolve to CONNECTED state, which informs the consuming client that we are ready to roll
        this.state.set({
            state: "CONNECTED",
            lastActivity: this.connection.lastActivity,
            lastFocus: this.lastFocusTimestamp,
            connectedAt: Date.now(),
        });
    }
    async reconnect() {
        return this.state.match({
            DISCONNECTED: ({ reason, disconnectedAt }) => this.attemptReconnect({
                disconnectReason: reason,
                disconnectedAt,
            }),
            CONNECTED: () => this.attemptReconnect({
                disconnectReason: "MANUAL_RECONNECT",
                disconnectedAt: Date.now(),
            }),
            _: () => { },
        });
    }
    async attemptReconnect({ disconnectReason, disconnectedAt, }) {
        if (this.state.is("RECONNECTING")) {
            return Promise.reject("Already reconnecting");
        }
        // eslint-disable-next-line
        console.log("[pitcher-client]: Attempting reconnect");
        this.connection.dispose(disconnectReason);
        // When we attempt a reconnect we want to reject all pending messages,
        // as they will never resolve anyways
        this.messageHandler.getPendingMessages().forEach((message) => {
            message.reject(new Error("Attempting reconnect"));
        });
        let attempt = 1;
        // Did the disconnect happen being focused in the app?
        const wasFocused = this.isFocused;
        try {
            await retryPromise(async (cancellationToken) => {
                this.state.set({
                    state: "RECONNECTING",
                    disconnectReason,
                    wasFocused,
                    attempt: attempt++,
                    lastActivity: this.connection.lastActivity,
                    disconnectedAt,
                    lastFocus: this.lastFocusTimestamp,
                });
                // eslint-disable-next-line
                console.log("[pitcher-client]: Resolving instance");
                try {
                    this.hostResponse = await this.requestPitcherInstance(this.instanceId);
                }
                catch (error) {
                    // We do not want to retry again if it is a frozen workspace
                    if (error instanceof ApiResponseError &&
                        error.type === "WORKSPACE_FROZEN") {
                        // Cancellation errors prevents it from retrying
                        throw new CancellationError(error);
                    }
                    throw error;
                }
                // This happens if we time out
                cancellationToken.throwErrorIfCancelled();
                // eslint-disable-next-line
                console.log("[pitcher-client]: Connecting to instance");
                const connection = await createWebSocketClient(createConnectionString(this.hostResponse, this.reconnectToken));
                // This happens if we time out
                cancellationToken.throwErrorIfCancelled(() => {
                    connection.dispose("Reconnect timed out");
                });
                this.connection = connection;
                await this.resolveCurrentConnection();
                // This happens if we time out
                cancellationToken.throwErrorIfCancelled(() => {
                    connection.dispose("Reconnect timed out");
                });
                // eslint-disable-next-line
                console.log("[pitcher-client]: Connected, resyncing ");
            }, RECONNECT_MAX_CONNECTION_TRIES, RECONNECT_CONNECTION_DELAY_MS, RECONNECT_CONNECTION_TIMEOUT_MS);
            // First we pass any queued messages for as quick as possible response to any requests
            // made during reconnect
            await this.messageHandler.flushReconnectQueue();
            // Then we resync all the clients
            await Promise.all([
                this.clients.client.resync(),
                // TODO: We should not use permission here, but capability. But currently Pitcher returns true on
                // this, even though it does not handle it
                this.permissions.git?.status
                    ? this.clients.git.resync()
                    : Promise.resolve(),
                this.clients.port.resync(),
                this.clients.setup.resync(),
                this.clients.shell.resync(),
                this.clients.language.resync(),
                this.clients.task.resync(),
                this.clients.system.resync(),
                this.clients.command.resync(),
                this.clients.fs.resync(),
                this.clients.file.resync(),
                this.clients.channel.resync(),
            ]);
            // eslint-disable-next-line
            console.log("[pitcher-client]: Resynced ");
            this.onReconnectedEmitter.fire();
        }
        catch (error) {
            // eslint-disable-next-line
            console.log("[pitcher-client]: Unable to connect - " + String(error));
            this.state.set({
                state: "DISCONNECTED",
                code: -1,
                disconnectedAt,
                reason: String(error),
                wasClean: false,
                wasFocused: this.isFocused,
            });
            throw error;
        }
    }
    hasFeature(feature) {
        const result = compare(this.pitcherVersion, PitcherFeatureToVersionMap[feature]);
        return result === -1 ? false : true;
    }
    revertSeamlessFork() {
        this.onInstanceChangedEmitter.fire({ instanceId: this.instanceId });
    }
    toggleSeamlessFork(value) {
        this.messageHandler.toggleSeamlessFork(value);
    }
    async changeInstance(instanceId) {
        return this.state.match({
            CONNECTED: async () => {
                // During a VM fork we might loose connection, but we do not want to react to this. But we do not want to dispose of the connection either,
                // cause we want to keep the same client as we reconnect. By silencing the connection it will not fire any events, but keep open. We'll dispose
                // of it after we have gotten the new VM
                this.connection.silence();
                this.instanceId = instanceId;
                await retryPromise(async (cancellationToken) => {
                    try {
                        this.hostResponse = await this.requestPitcherInstance(instanceId);
                    }
                    catch (error) {
                        // We do not want to retry again if it is a frozen workspace
                        if (error instanceof ApiResponseError &&
                            error.type === "WORKSPACE_FROZEN") {
                            // Cancellation errors prevents it from retrying
                            throw new CancellationError(error);
                        }
                        throw error;
                    }
                    // This happens if we time out
                    cancellationToken.throwErrorIfCancelled();
                    // Now we have gotten our new branch/sandbox and want to connect to it. First we disconnect from the old
                    // branch/sandbox
                    this.connection.dispose("Seamless forking");
                    const connection = await createWebSocketClient(createConnectionString(this.hostResponse, this.reconnectToken));
                    // This happens if we time out
                    cancellationToken.throwErrorIfCancelled(() => {
                        connection.dispose("Seamless connection timed out");
                    });
                    this.connection = connection;
                    await this.resolveCurrentConnection();
                    // This happens if we time out
                    cancellationToken.throwErrorIfCancelled(() => {
                        connection.dispose("Seamless connection timed out");
                    });
                }, RECONNECT_MAX_CONNECTION_TRIES, RECONNECT_CONNECTION_DELAY_MS, RECONNECT_CONNECTION_TIMEOUT_MS);
                // When changing an instance we are always forking a VM and there will not be a heavy initialization of Pitcher,
                // so we immediately move into aggressive disconnect detection
                this.connection.setPongDetectionTimeout(PONG_DETECTION_TIMEOUT);
                this.messageHandler.disableSeamlessFork();
                // We have to await resyncing the files so we ensure they are open on Pitcher
                // before sending any OT operations
                await Promise.all([
                    this.clients.file
                        .resync()
                        .then(() => this.messageHandler.flushReconnectQueue()),
                    // We need to wait for the new git status or we show that the branch is out of sync
                    // TODO: We should not use permission here, but capability. But currently Pitcher returns true on
                    // this, even though it does not handle it
                    this.permissions.git?.status
                        ? this.clients.git.resync()
                        : Promise.resolve(),
                ]);
                this.clients.task.resync();
                this.clients.port.resync();
                this.clients.client.resync();
                this.clients.shell.resync();
                this.clients.language.resync();
                this.clients.channel.resync();
                this.onInstanceChangedEmitter.fire({ instanceId: this.instanceId });
            },
            _: () => {
                throw new Error("You can not change instance when: " + this.state.get().state);
            },
        });
    }
    isUpToDate() {
        return (this.hostResponse.pitcherVersion ===
            this.hostResponse.latestPitcherVersion);
    }
    disconnect() {
        this.state.set({
            state: "DISCONNECTED",
            code: -1,
            disconnectedAt: Date.now(),
            reason: "Manual disconnect",
            wasClean: true,
            wasFocused: this.isFocused,
        });
        this.connection.dispose("Manual disconnect");
    }
    dispose() {
        // Do not act on any focus changes anymore
        this.onFocusChangeDisposer();
        this.messageHandler.dispose();
        this.state.dispose();
        this.connection.dispose("Dispose");
    }
}
// Since most of the time is spent booting the VM, we offset the
// progress coming from Pitcher after boot to be between the range
// 60-100
const INIT_PROGRESS_START = 60;
function offsetPitcherInitProgress(oldProgress) {
    return ((oldProgress * (100 - INIT_PROGRESS_START)) / 100 + INIT_PROGRESS_START);
}
export function createInitialStatus(message = "Preparing MicroVM...") {
    return {
        message,
        progress: 0,
        nextProgress: INIT_PROGRESS_START - 5,
    };
}
/**
 * We use an async initializer function to return a new instance of Pitcher Client. The reason
 * we do this is because the consumable API surface of PitcherClient makes no sense without the
 * initial connection. After that though, the connection can drop, reconnect and even be changed into
 * a completely different connection (seamless branching)... but still, the API surface exposed is consistent
 * and available.
 *
 * We also create each of the clients of PitcherClient in this async initializer as we also depend on certain
 * of those to async resolve before PitcherClient itself is ready to be consumed.
 */
export async function initPitcherClient(opts, initStatusCb, hostResponseCb) {
    if (globalThis.__CSB__SHOW_PITCHER_MESSAGES) {
        // eslint-disable-next-line
        console.log("[pitcher-client]: Connecting to pitcher");
    }
    let hasEmittedConnectingStatus = false;
    const { connection, hostResponse } = await retryPromise(async (cancellationToken) => {
        let hostResponse;
        try {
            hostResponse = await opts.requestPitcherInstance(opts.instanceId);
            hostResponseCb?.(hostResponse);
        }
        catch (error) {
            // We do not want to retry again if it is a frozen workspace
            if (error instanceof ApiResponseError &&
                error.type === "WORKSPACE_FROZEN") {
                // Cancellation errors prevents it from retrying
                throw new CancellationError(error);
            }
            throw error;
        }
        // This happens if we time out
        cancellationToken.throwErrorIfCancelled();
        if (!hasEmittedConnectingStatus) {
            hasEmittedConnectingStatus = true;
            initStatusCb({
                message: "MicroVM started, connecting...",
                // We give 5 percent to connect to VM, where Pitcher init messages
                // will start at INIT_PROGRESS_START
                progress: INIT_PROGRESS_START - 5,
                nextProgress: INIT_PROGRESS_START,
            });
        }
        const connection = await createWebSocketClient(createConnectionString(hostResponse));
        // This happens if we time out
        cancellationToken.throwErrorIfCancelled(() => {
            connection.dispose("Initial connection timed out");
        });
        return { connection, hostResponse };
    }, INITIAL_MAX_CONNECTION_TRIES, INITIAL_CONNECTION_DELAY_MS, INITIAL_CONNECTION_TIMEOUT_MS);
    if (globalThis.__CSB__SHOW_PITCHER_MESSAGES) {
        // eslint-disable-next-line
        console.log("[pitcher-client]: Setting up message handler and clients");
    }
    const messageHandler = new PitcherMessageHandler((request) => {
        connection.send(request.message);
    }, Boolean(opts.seamlessFork));
    const onMessageDisposer = connection.onMessage((message) => {
        messageHandler.receiveMessage(message);
    });
    /**
     * As part of instantiating the Pitcher Client we need to join the process. We
     * create a Clients client to send the request to join as part of the initialization
     */
    const clientClient = new ClientClient(messageHandler);
    const systemClient = new SystemClient(messageHandler);
    const initStatusUpdateDisposer = systemClient.onInitStatusUpdate((status) => {
        status.progress = offsetPitcherInitProgress(status.progress);
        status.nextProgress = offsetPitcherInitProgress(status.nextProgress);
        initStatusCb(status);
    });
    const joinResult = await clientClient.join({
        clientInfo: {
            protocolVersion,
            appId: opts.appId,
        },
        asyncProgress: true,
        subscriptions: opts.subscriptions,
    });
    // These where temporary subscriptions to get the initial state
    initStatusUpdateDisposer.dispose();
    onMessageDisposer.dispose();
    const client = new PitcherVMClient({
        instanceId: opts.instanceId,
        appId: opts.appId,
        subscriptions: opts.subscriptions,
        requestPitcherInstance: opts.requestPitcherInstance,
        onFocusChange: opts.onFocusChange,
        seamlessFork: opts.seamlessFork,
        messageHandler,
        joinResult,
        initialConnection: connection,
        hostResponse,
    });
    if (globalThis.__CSB__SHOW_PITCHER_MESSAGES) {
        // eslint-disable-next-line
        console.log("[pitcher-client]: Awaiting fs initialization");
    }
    // Wait for all promises from initializing pitcher clients to be resolved
    await Promise.all([
        client.clients.fs.readyPromise,
        client.clients.port.readyPromise,
        client.clients.task.readyPromise,
        client.clients.setup.readyPromise,
        client.clients.shell.readyPromise,
    ]);
    // Now that we have initialized we set an appropriate timeout to more efficiently detect disconnects
    connection.setPongDetectionTimeout(PONG_DETECTION_TIMEOUT);
    // We expose the client on window to be able to debug it state
    if (typeof window !== "undefined") {
        // eslint-disable-next-line
        // @ts-ignore
        window._DEBUG_PITCHER_CLIENT = client;
    }
    return client;
}
