import { useEnvironmentInterface } from "environment-interface";
import { useEditor } from "features/editor/Feature";
import { useEffect } from "react";

import { useUserWorkspaces } from "./useUserWorkspaces";

export const useActiveWorkspace = () => {
  const { storage } = useEnvironmentInterface();

  const { primaryWorkspaceId, workspaces } = useUserWorkspaces();
  const { workspace } = useEditor();

  const storedWorkspaceId = storage.get("codesandbox-selected-team-id");
  const editorWorkspaceId = workspace?.id;

  const editorInferredWorkspaceId = workspaces
    .map((w) => w.id)
    .find((id) => id === editorWorkspaceId);

  /**
   * 1. If the sandbox/repository has a team attached and the user is part of that workspace, use that
   * 2. Else use the stored workspace id from localStorage
   * 3. Finally, use the primary workspace as the fallback
   */
  const activeWorkspaceId =
    editorInferredWorkspaceId || storedWorkspaceId || primaryWorkspaceId;

  const activeWorkspace = workspaces.find((w) => w.id === activeWorkspaceId);

  useEffect(() => {
    // Persist the active workspace id if it is different then the previously persisted one
    if (activeWorkspace && activeWorkspaceId !== storedWorkspaceId) {
      storage.set("codesandbox-selected-team-id", activeWorkspaceId);
    }
  }, [activeWorkspace, activeWorkspaceId]);

  if (workspaces.length === 0) {
    // Consider that workspaces are not yet fetched, so no workspace is selected
    return undefined;
  }

  return activeWorkspace;
};
