import { Content } from "@radix-ui/react-dropdown-menu";

import { styled } from "../../theme";

export const DropdownContent = styled(Content, {
  background: "$neutral-bg-medium",
  boxShadow: "$popup",
  color: "$netural-fg-high",
  padding: "$1 0",
  minWidth: "180px",
  borderRadius: "$2",
});
