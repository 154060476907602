import type { Env } from "isomorphic/types";

// @ts-ignore
export const env = window.ENV as Env;

/**
 * Key for dev pitcher manager URL in session storage
 */
const DEV_PITCHER_MANAGER_URL_KEY = "pitcherManagerURL";

/**
 * Set dev pitcher manager URL to session storage
 * This will only set the value if dev pitcher manager is allowed in the environment
 * @param pitcherManagerURL - URL of the pitcher manager
 */
export function setDevPitcherManagerURL(pitcherManagerURL: string): void {
  if (isDevPitcherMangerAllowed()) {
    return sessionStorage.setItem(
      DEV_PITCHER_MANAGER_URL_KEY,
      pitcherManagerURL,
    );
  }
}

/**
 * Get dev pitcher manager URL from session storage
 * This will only return the value if dev pitcher manager is allowed in the environment
 * @returns string
 */
export function getDevPitcherManagerURL(): string | undefined {
  if (isDevPitcherMangerAllowed()) {
    return sessionStorage.getItem(DEV_PITCHER_MANAGER_URL_KEY) ?? undefined;
  }
}

/**
 * Check if dev pitcher manager is allowed
 * @returns boolean
 */
function isDevPitcherMangerAllowed(): boolean {
  return (
    env.PUBLIC_ENVIRONMENT === "staging" ||
    env.PUBLIC_ENVIRONMENT === "development"
  );
}

/**
 * Check if pitcher manager should be called via API serviceWorker
 * @param pitcherManagerURL - URL of the pitcher manager from environment variable or session storage
 * @returns boolean
 */
export function getUsePitcherManagerThroughApi(): boolean {
  if (isDevPitcherMangerAllowed()) {
    const pitcherManagerURL = getDevPitcherManagerURL();
    if (pitcherManagerURL) {
      // If pitcher manager is running locally, then API will not be able to access it
      const isPitcherManagerURLLocal =
        pitcherManagerURL.startsWith("http://") ||
        pitcherManagerURL.startsWith("https://pitcher-local.csb.dev");
      if (isPitcherManagerURLLocal) {
        return false;
      }
    }
  }

  return true;
}
