import type { DialogContentProps } from "@radix-ui/react-alert-dialog";
import * as RadixDialog from "@radix-ui/react-dialog";
import type { VariantProps } from "@stitches/react";
import React from "react";

import type { CSS } from "../..";
import { Button, useTheme } from "../..";
import * as styles from "../../css/dialog";
import { styled } from "../../theme";

const Overlay = styled(RadixDialog.Overlay, styles.overlay);
const Content = styled(RadixDialog.Content, {
  ...styles.content,
  overflow: "hidden",

  variants: {
    width: {
      sm: {
        maxWidth: "320px",
      },
      md: {
        maxWidth: "500px",
      },
    },
  },
});

export const ImageHolder = styled("div", {
  marginLeft: "-$6",
  marginTop: "-$6",
  marginRight: "-$6",
  marginBottom: "$4",
  "& > * ": {
    margin: 0,
    display: "block",
    width: "100%",
    height: "auto !important",
  },
});
export const Close = styled(RadixDialog.Close, {});
export const Title = styled(RadixDialog.Title, styles.title);
export const Description = styled(RadixDialog.Description, styles.description);

export const CloseIconButton: React.FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  return (
    <Close asChild>
      <Button icon="close" onClick={onClick} />
    </Close>
  );
};

type ContentVariantProps = VariantProps<typeof Content>;

export interface DialogProps extends RadixDialog.DialogProps {
  contentProps?: DialogContentProps & Omit<CSS, "width">;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  width?: ContentVariantProps["width"];
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  contentProps = {},
  width = "sm",
  ...props
}) => {
  const theme = useTheme();

  return (
    <RadixDialog.Root {...props}>
      <Overlay />
      <Content className={theme.className} width={width} {...contentProps}>
        {children}
      </Content>
    </RadixDialog.Root>
  );
};
