import { Root, Content, Trigger, Portal } from "@radix-ui/react-tooltip";
import type { ComponentProps } from "@stitches/react";
import { keyframes } from "@stitches/react";
import React, { forwardRef } from "react";

import { styled } from "../../theme";
import { onGrid } from "../../tokens/space";
import { Box } from "../Box";

const switchingBetween = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const TooltipContent = styled(Content, {
  boxSizing: "border-box",
  borderRadius: "$1",
  padding: "$1 $2",
  color: "$neutral-fg-high",
  backgroundColor: "$neutral-bg-low",
  border: "1px solid $neutral-bg-focus_contrast",
  // truncateText: true,
  fontSize: "$caption1",
  lineHeight: "16px",
  // maxWidth: "40ch", // random max width for long text
  wordBreak: "break-word", // break long words
  boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.15)",
  display: "flex",
  alignItems: "center",
  gap: "$2",

  a: {
    color: "currentcolor",
    textDecoration: "underline",
  },

  "@media (prefers-reduced-motion: no-preference)": {
    /** @see https://www.radix-ui.com/docs/primitives/components/tooltip#origin-aware-animations */
    transformOrigin: "var(--radix-tooltip-content-transform-origin)",
    animationName: switchingBetween,
    animationDuration: "125ms", // Synced with out $fast style animation
  },
});

export const Shortcut = styled("div", {
  borderRadius: "$1",
  height: "$5",
  minWidth: "$5",
  px: "$1",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "uppercase",
  backgroundColor: "$neutral-bg-focus",
  fontFamily: "$base",
  fontSize: "11px",
  lineHeight: "16px",
  color: "$neutral-fg-subtle",
});

interface StoryProps {
  open?: boolean;
  side?: "top" | "right" | "bottom" | "left";
}

export type TooltipProps = Omit<ComponentProps<typeof Trigger>, "content"> & {
  children?: React.ReactNode;
  content: React.ReactNode;
  shortcut?: string[];
  rootProps?: ComponentProps<typeof Root>;
  contentProps?: ComponentProps<typeof Content>;
  /** Should only be used for storybook / demo purposes */
  __storyProps?: StoryProps;
};

/*
 * TODO: add Arrow to match designs. The radix arrow uses an SVG polygon, which I couldn't style to match our designs.
 * We will likely need to roll-our own solution for this. Won't take long to do, but needs to work with the arrow being on
 * every side of the tooltip
 */
export const Tooltip = forwardRef<HTMLButtonElement, TooltipProps>(
  (
    {
      children,
      content,
      shortcut,
      rootProps,
      __storyProps = {},
      contentProps,
      ...rest
    },
    ref,
  ) => {
    if (!content) {
      return <>{children}</>;
    }

    const hasShortcut = shortcut && shortcut.length > 0;

    return (
      <Root open={__storyProps.open} {...rootProps}>
        <Trigger ref={ref} asChild {...rest}>
          {children}
        </Trigger>
        <Portal>
          <TooltipContent
            css={{
              paddingRight: hasShortcut ? "$1" : "$2",
            }}
            side={__storyProps.side}
            sideOffset={onGrid(1)}
            {...contentProps}
          >
            {content}
            {hasShortcut && (
              <Box
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "$1",
                }}
              >
                {shortcut.map((key) => (
                  <Shortcut key={key}>{key}</Shortcut>
                ))}
              </Box>
            )}
          </TooltipContent>
        </Portal>
      </Root>
    );
  },
);
