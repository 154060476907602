import { createEmitter } from "@codesandbox/environment-interface";
import type { IDisposable } from "@codesandbox/pitcher-client";
import { Barrier } from "@codesandbox/pitcher-client";
import type { registerCustomView } from "@codingame/monaco-vscode-workbench-service-override";
import type {
  MonacoEditor,
  MonacoEditorEvent,
  MonacoLibraryState,
} from "environment-interface/monacoEditor";
import type * as vscodeExtensionApi from "vscode";

import { EditorService } from "./services/editor";
import type { Command, VSCodeApi } from "./services/editor/vscode/monaco";
import {
  loadMonacoLibrary,
  updateMonacoConfig,
} from "./services/editor/vscode/monaco";
import {
  createRegisterEditor,
  type RegisterEditorParams,
} from "./services/editor/vscode/tools/custom-editor";

export const createMonacoEditor = (): MonacoEditor => {
  const monacoEditorEvents = createEmitter<MonacoEditorEvent>();

  let monacoLibraryState: MonacoLibraryState = {
    state: "NOT_LOADED",
  };

  let editorService: EditorService | undefined;
  let vscodeApi: VSCodeApi | undefined;
  const vscodeExtensionApiBarrier = new Barrier<typeof vscodeExtensionApi>();
  const vscodeRegisterEditorBarrier = new Barrier<{
    registerEditor: (params: RegisterEditorParams) => IDisposable;
  }>();
  const vscodeRegisterCustomViewBarrier = new Barrier<
    typeof registerCustomView
  >();

  const monacoBarrier = new Barrier<void>();

  return {
    events: monacoEditorEvents,
    get libraryState() {
      return monacoLibraryState;
    },
    loadLibrary(config) {
      updateMonacoConfig(config);

      createRegisterEditor().then((registerEditor) => {
        // Initialize registerEditor as fast as possible so that when VSCode loads all
        // deserializers for the editor are already registered
        vscodeRegisterEditorBarrier.open({ registerEditor });
      });

      import("@codingame/monaco-vscode-workbench-service-override").then(
        (api) => vscodeRegisterCustomViewBarrier.open(api.registerCustomView),
      );

      if (monacoLibraryState.state === "LOADED" && editorService) {
        monacoEditorEvents.emit({
          type: "MONACO_EDITOR:LIBRARY_LOADED",
        });

        return;
      }

      monacoLibraryState = {
        state: "LOADING",
      };

      loadMonacoLibrary(config.vscodeLoaderOpts)
        .then(({ monaco, vscodeApi: newVscodeApi }) => {
          editorService = new EditorService(monaco);
          monaco.editor.remeasureFonts();
          vscodeApi = newVscodeApi;

          vscodeExtensionApiBarrier.open(newVscodeApi.extensionApi);

          monacoLibraryState = {
            state: "LOADED",
            monaco,
          };

          monacoEditorEvents.emit({
            type: "MONACO_EDITOR:LIBRARY_LOADED",
          });

          monacoBarrier.open();
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);

          monacoEditorEvents.emit({
            type: "MONACO_EDITOR:LIBRARY_ERROR",
            error: err.message,
          });
        });
    },

    createDiffEditor(diffEditorOptions) {
      if (!editorService) {
        throw new Error(
          "Cannot create file editor, monaco has not been loaded yet",
        );
      }

      return editorService.createDiffEditor(diffEditorOptions);
    },
    createFileEditor(fileEditorOptions) {
      if (!editorService) {
        throw new Error(
          "Cannot create file editor, monaco has not been loaded yet",
        );
      }

      return editorService.createFileEditor(fileEditorOptions);
    },
    registerCommand: (command: Command): IDisposable => {
      if (vscodeExtensionApiBarrier.isOpen()) {
        return vscodeApi!.registerKeybinding(command);
      } else {
        throw new Error("Cannot register command, VSCode is not initialized");
      }
    },
    registerEditor: async (
      params: RegisterEditorParams,
    ): Promise<IDisposable> => {
      const editorBarrier = await vscodeRegisterEditorBarrier.wait();
      if (editorBarrier.status === "disposed") {
        throw new Error("Editor registerer was disposed");
      }

      return editorBarrier.value.registerEditor(params);
    },
    getRegisterCustomView: async () => {
      const customViewBarrier = await vscodeRegisterCustomViewBarrier.wait();
      if (customViewBarrier.status === "disposed") {
        throw new Error("Editor registerer was disposed");
      }

      return customViewBarrier.value;
    },
    getExtensionApi: async () => {
      const result = await vscodeExtensionApiBarrier.wait();

      if (result.status === "disposed") {
        // We never dispose the barrier, so this should never happen
        throw new Error("VSCode API has been disposed");
      }

      return result.value;
    },
    vscodeExtensionApiBarrier,
    dispose() {
      if (editorService) {
        editorService.dispose();
      }
    },
  };
};
