import * as RadixSelect from "@radix-ui/react-select";
import { forwardRef } from "react";

import { styled } from "../../theme";
import { Icon } from "../Icon";
import { Stack } from "../Stack";

export const SelectItem = forwardRef<
  HTMLDivElement,
  RadixSelect.SelectItemProps & {
    itemIndicatorPosition?: "left" | "right" | "none";
  }
>(({ children, itemIndicatorPosition = "right", ...props }, forwardedRef) => {
  return (
    <StyledItem {...props} ref={forwardedRef}>
      <Stack gap={1} horizontal>
        {itemIndicatorPosition === "left" && (
          <RadixSelect.ItemIndicator>
            <Icon size={3} type="check" />
          </RadixSelect.ItemIndicator>
        )}
        <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        {itemIndicatorPosition === "right" && (
          <RadixSelect.ItemIndicator>
            <Icon size={3} type="check" />
          </RadixSelect.ItemIndicator>
        )}
      </Stack>
    </StyledItem>
  );
});

const StyledItem = styled(RadixSelect.Item, {
  outline: "none",
  width: "100%",
  px: "$2",
  height: "$7",
  display: "flex",
  alignItems: "center",
  color: "$neutral-fg-medium",
  background: "$netural-bg-medium",
  cursor: "var(--csb-cursor)",

  "&[data-highlighted]": {
    backgroundColor: "$neutral-bg-focus",
    color: "$neutral-fg-high",
  },
});
