import { Emitter } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../common/AsyncValueStore";
export class PortClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.portsUpdatedEmitter = new Emitter();
        this.onPortsUpdated = this.portsUpdatedEmitter.event;
        this.portsErrorEmitter = new Emitter();
        this.onPortsError = this.portsErrorEmitter.event;
        this.ports = this.createPortsValue();
        this.readyPromise = this.ports.getInitPromise();
        messageHandler.onNotification("port/changed", ({ list }) => {
            // Will emit changed event
            this.ports.set(list);
        });
    }
    createPortsValue() {
        const ports = new AsyncValueStore([], () => this.messageHandler
            .request({
            method: "port/list",
            params: {},
        })
            .then(({ list }) => list), { fetchEagerly: true });
        ports.onChange(({ value }) => this.portsUpdatedEmitter.fire(value));
        ports.onError((error) => this.portsErrorEmitter.fire(error));
        return ports;
    }
    getPorts() {
        return this.ports.get();
    }
    resync() {
        return this.ports.refresh();
    }
}
