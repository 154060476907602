import { sleep, ot, Emitter, BidirectionalMap, } from "@codesandbox/pitcher-common";
import { Rc } from "../../../common/Rc";
import { File } from "./File";
export * from "./File";
export * from "./Document";
// The time to wait until we actually close a file. Using IntelliSense it often reopens the file very quickly
const WAIT_TO_CLOSE_FILE_MS = 1000;
export class FileClient {
    constructor(messageHandler, fs, workspacePath) {
        this.messageHandler = messageHandler;
        this.fs = fs;
        this.workspacePath = workspacePath;
        this.onFileOpenEmitter = new Emitter();
        this.onFileOpen = this.onFileOpenEmitter.event;
        this.onFileJoinEmitter = new Emitter();
        this.onFileJoin = this.onFileJoinEmitter.event;
        this.onFileLeaveEmitter = new Emitter();
        this.onFileLeave = this.onFileLeaveEmitter.event;
        this.onFileSaveEmitter = new Emitter();
        this.onFileSave = this.onFileSaveEmitter.event;
        this.onFileMoveEmitter = new Emitter();
        this.onFileMove = this.onFileMoveEmitter.event;
        this.onFileDeleteEmitter = new Emitter();
        this.onFileDelete = this.onFileDeleteEmitter.event;
        this.openedPaths = new BidirectionalMap();
        this.openFiles = new Map();
        this.pendingFiles = new Map();
        messageHandler.onNotification("file/join", (data) => {
            this.onFileJoinEmitter.fire(data);
        });
        messageHandler.onNotification("file/save", (data) => {
            this.onFileSaveEmitter.fire({
                ...data,
                path: fs.getPathFromId(data.id),
            });
        });
        messageHandler.onNotification("file/leave", (data) => {
            this.onFileLeaveEmitter.fire(data);
        });
        fs.onFSSync(() => {
            for (const fileRc of this.openFiles.values()) {
                // Making TS happy
                const file = fileRc.object;
                const fileId = file.id;
                // Skip files that are opened with "file/openByPath"
                if (this.openedPaths.getKey(fileId)) {
                    continue;
                }
                const foundPath = fs.getPathFromId(fileId);
                if (!foundPath) {
                    this.onFileDeleteEmitter.fire({
                        id: fileId,
                        path: file.path,
                    });
                }
                else if (file.path !== foundPath) {
                    this.onFileMoveEmitter.fire({
                        id: fileId,
                        newPath: foundPath,
                        oldPath: file.path,
                    });
                    file.updatePath(foundPath);
                }
            }
        });
    }
    hasDirtyFiles() {
        for (const [, openFile] of this.openFiles) {
            if (openFile.object.isDirty) {
                return true;
            }
        }
        return false;
    }
    registerFile(path, result) {
        const id = result.id;
        const foundFile = this.openFiles.get(id);
        if (foundFile) {
            return foundFile;
        }
        const f = new File(path, result, this.messageHandler);
        const file = new Rc(f, () => {
            // When dispoing of a ref we immediately remove the file as being open
            this.openFiles.delete(fileId);
            this.openedPaths.deleteByValue(fileId);
            // We put it back into pending files as "awaiting to be closed". This ensures if a new call to "open"
            // happens we'll set it back to "OPENING" again, aborting the closing
            this.pendingFiles.set(f.path, {
                state: "AWAITING_CLOSE",
                promise: sleep(WAIT_TO_CLOSE_FILE_MS).then(() => {
                    // If we are still awaiting the file to be closed, we actually close it
                    if (this.pendingFiles.get(f.path)?.state === "AWAITING_CLOSE")
                        // And we do so by setting a closing state, so we can await this before opening the file again
                        this.pendingFiles.set(f.path, {
                            state: "CLOSING",
                            promise: f["internalClose"]().finally(() => {
                                this.pendingFiles.delete(f.path);
                            }),
                        });
                }),
            });
        });
        const fileId = file.object.id;
        this.openFiles.set(fileId, file);
        this.onFileOpenEmitter.fire(file.object);
        return file;
    }
    getFileIdFromPath(filepath) {
        const fileId = this.fs.getIdFromPath(filepath);
        if (!fileId || !this.fs.isFile(fileId)) {
            return this.openedPaths.getValue(filepath);
        }
        return fileId;
    }
    getPathFromFileId(fileId) {
        return this.openedPaths.getKey(fileId) ?? this.fs.getPathFromId(fileId);
    }
    getOpenedFile(fileId) {
        const openFile = this.openFiles.get(fileId);
        if (openFile) {
            return openFile.object;
        }
    }
    getOpenFiles() {
        return Array.from(this.openFiles.values());
    }
    async openFile(fileId) {
        const openFile = this.openFiles.get(fileId);
        if (openFile) {
            return Promise.resolve(openFile.acquire());
        }
        // If we have a CUID we always have a path
        const filePath = this.getPathFromFileId(fileId);
        const existingPendingFile = this.pendingFiles.get(filePath);
        let pendingFilePromise;
        // If we are currently closing the file, let's wait until it is done to avoid any race condition on Pitcher
        if (existingPendingFile?.state === "CLOSING") {
            await existingPendingFile.promise;
        }
        // If we are already opening it, we can reuse the same promise
        if (existingPendingFile?.state === "OPENING") {
            pendingFilePromise = existingPendingFile.promise;
        }
        else {
            // If it is awaiting to be closed or is not pending at all, we open it
            pendingFilePromise = this.messageHandler
                .request({
                method: "file/open",
                params: {
                    id: fileId,
                },
            })
                .then((result) => this.registerFile(this.getPathFromFileId(result.id), result))
                .finally(() => {
                this.pendingFiles.delete(filePath);
            });
            this.pendingFiles.set(filePath, {
                state: "OPENING",
                promise: pendingFilePromise,
            });
        }
        return pendingFilePromise.then((rcFile) => rcFile.acquire());
    }
    async openFileByPath(filepath) {
        const fileId = this.getFileIdFromPath(filepath);
        // The path exists in MemoryFS, use normal "openFile"
        if (fileId) {
            return this.openFile(fileId);
        }
        const existingPendingFile = this.pendingFiles.get(filepath);
        let pendingFilePromise;
        // If we are currently closing the file, let's wait until it is done to avoid any race condition on Pitcher
        if (existingPendingFile?.state === "CLOSING") {
            await existingPendingFile.promise;
        }
        // If we are already opening it, we can reuse the same promise
        if (existingPendingFile?.state === "OPENING") {
            pendingFilePromise = existingPendingFile.promise;
        }
        else {
            // If it is awaiting to be closed or is not pending at all, we open it
            pendingFilePromise = this.messageHandler
                .request({
                method: "file/openByPath",
                params: {
                    path: filepath,
                },
            })
                .then((result) => {
                this.openedPaths.set(filepath, result.id);
                return this.registerFile(filepath, result);
            })
                .finally(() => {
                this.pendingFiles.delete(filepath);
            });
            this.pendingFiles.set(filepath, {
                state: "OPENING",
                promise: pendingFilePromise,
            });
        }
        return pendingFilePromise.then((f) => f.acquire());
    }
    createDocumentOperationFromDiff(originalText, modifiedText) {
        return ot.createDiffTextOperation(originalText, modifiedText);
    }
    async resync() {
        await Promise.all(Array.from(this.openFiles.values()).map((f) => f.object.resync()));
    }
    getIdFromWorkspaceUriString(uriString) {
        // Remove protocol
        const absolutePath = this.fs.asAbsoluteWorkspacePath(uriString.replace(/^.*:\/\//, ""));
        return this.getFileIdFromPath(this.fs.absoluteToRelativeWorkspacePath(absolutePath));
    }
    dispose() {
        this.onFileJoinEmitter.dispose();
        this.onFileLeaveEmitter.dispose();
        this.onFileSaveEmitter.dispose();
    }
}
