import { safeLocalStorage } from "./safeLocalStorage";

export interface Experiments {
  extensionMarketplace: boolean;
  serverExtensions: boolean;
}

export function getExperiments() {
  const extensionMarketplace = safeLocalStorage.get(
    "experiments.extensionMarketplace",
  );
  const serverExtensions = safeLocalStorage.get("experiments.serverExtensions");

  return {
    extensionMarketplace: Boolean(
      extensionMarketplace === "true" || extensionMarketplace === null,
    ),
    serverExtensions: Boolean(
      serverExtensions === "true" || serverExtensions === null,
    ),
  };
}

export function setExperiment(experiment: keyof Experiments, value: boolean) {
  safeLocalStorage.set(`experiments.${experiment}`, String(value));
}
