import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";
import { registerExtension } from "vscode/extensions";

export function initializeDraculaThemes() {
  // eslint-disable-next-line
  const config: any = {
    name: "theme-dracula",
    version: "2.24.3",
    displayName: "Dracula Official",
    homepage: "https://draculatheme.com/",
    description:
      "Official Dracula Theme. A dark theme for many editors, shells, and more.",
    publisher: "dracula-theme",
    engines: {
      vscode: "*",
    },
    contributes: {
      themes: [
        {
          label: "Dracula",
          uiTheme: "vs-dark",
          path: "./themes/dracula.json",
        },
      ],
    },
  };

  const { registerFileUrl } = registerExtension(config, 2);
  registerFileUrl(
    "./themes/dracula.json",

    withBasePath(env, "/vscode-extensions/themes/dracula.json"),
  );

  return config.contributes.themes;
}
