import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";
import { registerExtension } from "vscode/extensions";

export function initializeVesperThemes() {
  // eslint-disable-next-line
  const config: any = {
    name: "vesper",
    displayName: "Vesper",
    publisher: "raunofreiberg",
    description: "Peppermint and orange flavored dark theme for VSCode.",
    license: "MIT",
    version: "0.0.39",
    engines: {
      vscode: "*",
    },
    contributes: {
      themes: [
        {
          label: "Vesper",
          uiTheme: "vs-dark",
          path: "./themes/vesper.json",
        },
      ],
    },
  };

  const { registerFileUrl } = registerExtension(config, 2);
  registerFileUrl(
    "./themes/vesper.json",

    withBasePath(env, "/vscode-extensions/themes/vesper.json"),
  );

  return config.contributes.themes;
}
