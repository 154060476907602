import { satisfies, valid } from "semver";
import { TarStore } from "./tar-store";
import { fetchWithRetries } from "./utils";
const SANDPACK_SECRET_COOKIE_NAME = "csb_sandpack_secret";
const getSandpackSecret = () => document.cookie.replace(new RegExp(`(?:(?:^|.*;\\s*)${SANDPACK_SECRET_COOKIE_NAME}\\s*\\=\\s*([^;]*).*$)|^.*$`), "$1");
function getCookie(name) {
    const cookieArray = document.cookie.split(";"); // Split the cookie string on semicolons into an array of cookies
    for (let i = 0; i < cookieArray.length; i++) {
        const cookiePair = cookieArray[i].split("="); // Split each individual cookie into a name and value pair
        if (name === cookiePair[0].trim()) {
            // Trim whitespace and check if the name matches the desired cookie
            return decodeURIComponent(cookiePair[1]); // Decode the cookie value and return it
        }
    }
    return null; // Return null if the cookie was not found
}
const NPM_REGISTRY_ACCEPT_HEADER = "application/vnd.npm.install-v1+json; q=1.0, application/json; q=0.8, */*";
function join(a, b) {
    if (a.endsWith("/")) {
        return a + b;
    }
    return a + "/" + b;
}
export class NpmRegistryFetcher {
    constructor(registryLocation, config) {
        this.registryLocation = registryLocation;
        this.tarStore = new TarStore();
        this.packageMetadata = new Map();
        this.proxyEnabled = false;
        this.condition = (name, _version) => {
            if (this.scopeWhitelist) {
                return this.scopeWhitelist.some((scope) => {
                    const [scopeName] = name.split("/");
                    return scopeName === scope;
                });
            }
            return true;
        };
        this.proxyUrl = config.proxyUrl;
        this.scopeWhitelist = config.scopeWhitelist;
        this.authToken = config.authToken;
        this.provideTarballUrl = config.provideTarballUrl;
        this.proxyEnabled = config.proxyEnabled;
        this.authType = config.authType || "Bearer";
    }
    getProxiedUrl(url) {
        if (this.proxyUrl) {
            return this.proxyUrl + "?registryurl=" + url;
        }
        return url;
    }
    getTarballUrl(name, version, tarballUrl) {
        if (this.provideTarballUrl) {
            return this.provideTarballUrl(name, version, tarballUrl);
        }
        return this.getProxiedUrl(tarballUrl);
    }
    getPackageUrl(name) {
        const encodedName = name.replace("/", "%2f");
        return this.getProxiedUrl(join(this.registryLocation, encodedName));
    }
    getRequestInit() {
        const headers = new Headers();
        headers.append("Accept", NPM_REGISTRY_ACCEPT_HEADER);
        headers.append("Content-Type", "application/json");
        /**
         * Private packages conditionals:
         * 1. Explicit token: if `authToken` is provide, add it to the header
         * 2. Proxy disabled: then it's a custom registry, so do not anything
         * 3. Proxy is enabled and team-id is provide: it's a private package provided by CSB
         */
        if (this.authToken) {
            // Custom registry url
            headers.append("Authorization", `${this.authType} ${this.authToken}`);
        }
        else if (getSandpackSecret()) {
            // CSB proxy
            headers.append("Authorization", `Bearer ${getSandpackSecret()}`);
        }
        else if (process.env.NODE_ENV === "development") {
            // Development proxy
            headers.append("Authorization", `Bearer ${getCookie("devJwt")}`);
        }
        return {
            method: "get",
            headers,
            mode: "cors",
            credentials: this.proxyEnabled ? "include" : undefined,
        };
    }
    fetchRegistry(url) {
        return fetchWithRetries(url, 3, this.getRequestInit())
            .then((x) => x.json())
            .catch(async (e) => {
            let errorMessage = "Make sure the right auth token and URL are set";
            if (e.responseObject) {
                const res = await e.responseObject.json();
                if (res.error) {
                    errorMessage = res.error;
                }
                else if (res.errors?.detail) {
                    errorMessage = res.errors.detail[0];
                }
            }
            return Promise.reject(new Error(`Could not fetch from registry. ${errorMessage}.`));
        });
    }
    async getPackageMetadata(name) {
        if (!this.packageMetadata.has(name)) {
            this.packageMetadata.set(name, this.fetchRegistry(this.getPackageUrl(name)));
        }
        return this.packageMetadata.get(name);
    }
    async getAbsoluteVersion(name, version) {
        if (valid(version)) {
            return version;
        }
        const metadata = await this.getPackageMetadata(name);
        if (metadata["dist-tags"] && metadata["dist-tags"][version]) {
            return metadata["dist-tags"][version];
        }
        const versions = Object.keys(metadata.versions).reverse();
        const foundVersion = versions.find((absoluteVersion) => satisfies(absoluteVersion, version));
        if (!foundVersion) {
            throw new Error(`Can't find version that satisfies ${name}@${version}`);
        }
        return foundVersion;
    }
    async getVersionInfo(name, version) {
        const absoluteVersion = await this.getAbsoluteVersion(name, version);
        const metadata = await this.getPackageMetadata(name);
        const versionInfo = metadata.versions[absoluteVersion];
        if (!versionInfo) {
            throw new Error(`Version '${version}' is not available on the registry for '${name}'`);
        }
        return versionInfo;
    }
    async file(name, version, path) {
        const versionInfo = await this.getVersionInfo(name, version);
        const tarball = this.getTarballUrl(name, versionInfo.version, versionInfo.dist.tarball);
        return this.tarStore.file(name, tarball, path, this.getRequestInit());
    }
    async meta(name, version) {
        const versionInfo = await this.getVersionInfo(name, version);
        const tarball = this.getTarballUrl(name, versionInfo.version, versionInfo.dist.tarball);
        return this.tarStore.meta(name, tarball, this.getRequestInit());
    }
    async massFiles(name, version) {
        const versionInfo = await this.getVersionInfo(name, version);
        const tarball = this.getTarballUrl(name, versionInfo.version, versionInfo.dist.tarball);
        return this.tarStore.massFiles(name, tarball, this.getRequestInit());
    }
}
