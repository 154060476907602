import {
  GitHubProfileDocument,
  WorkspacesDocument,
} from "gql-types/documents/web-client";

import { useQuery } from "./utils/useQuery";

export const useGitHubProfileQuery = () => useQuery(GitHubProfileDocument);

export const useWorkspacesQuery = (shouldFetch = true) =>
  useQuery(shouldFetch ? WorkspacesDocument : null);
