import type { rest } from "@codesandbox/api";
import { CodeSandboxApi } from "@codesandbox/api";

import { env } from "../environment-interface/env";

/**
 * HACK: Next JS uses build variables from our generic build on static pages like 404, which are wrong in the browser when running on stream and production.
 * To properly identify the apiUrl and usage of CLI authentication we need to manually verify that we are not running in the browser on .io or .stream
 */
const isBrowser = typeof window !== "undefined";
const PRODUCTION_HOST = "codesandbox.io";
const STREAM_HOST = "codesandbox.stream";

const shouldForceCliAuthentication =
  isBrowser &&
  window.location.host !== PRODUCTION_HOST &&
  window.location.host !== STREAM_HOST;

export let apiUrl: string;

if (isBrowser && window.location.host === PRODUCTION_HOST) {
  apiUrl = "https://codesandbox.io";
} else if (isBrowser && window.location.host === STREAM_HOST) {
  apiUrl = "https://codesandbox.stream";
} else {
  apiUrl = isBrowser
    ? env.PUBLIC_API_DOMAIN
    : process.env.INTERNAL_API_DOMAIN || env.PUBLIC_API_DOMAIN;
}

// This factory is also used in testing
export const createCodeSandboxApi = (
  user?: rest.User,
  // We use CLI auth on inline preview
  useCliAuthentication?: boolean,
) =>
  new CodeSandboxApi({
    clientType: "web",
    // We might send the user authenticated from the server, so we'll avoid
    // doing a second authentication round
    user,
    apiUrl,
    useCliAuthentication: useCliAuthentication || shouldForceCliAuthentication,
  });

// We create a global reference to the API to allow the
// the environment interface to consume this. Later this will
// probably be instantiated in "_app.tsx"
export const csbApi = createCodeSandboxApi();
