export const IGNORE_ERRORS = [
  "Canceled", // Whenever a cancellation is done in VSCode we get "Canceled"
  "TypeScript Server Error",
  "ResizeObserver loop limit exceeded",
  "[object Object]",
  "You do not have permission:",
  "Error: Disconnected",
  "Instance change required",
  "timed out",
  "Pitcher connection lost",
  "connection: close",
  "<jsx-tags>",
  "getLayoutMap()", // Thrown by VSCode when trying to get keyboard layout map from iframe
  "textDocument/", // Almost always thrown if user does not have permission for LSP request, or if the client was disposed
  "character must be non-negative", // Thrown in VSCode when our LSP extension sends wrong params (we should check this)
  "editor.contrib.referencesController",
  "Not able to send message in state", // To be addressed in https://linear.app/codesandbox/issue/PC-1251/error-not-able-to-send-message-in-state-disconnected
  "Webgl2 is only supported on Safari 16 and above", // this affects a small percentage of safari users
  "Client got disposed and can't be restarted.", // Thrown by base language implementation on VSCode
  "ResizeObserver loop completed with undelivered notifications.", // We could not identify the origin, but it's irrelevant
  "Unable to figure out browser width and height", // Thrown by VSCode when trying to get window size
  "EntryNotFound",
];
