import { Emitter, Disposable } from "@codesandbox/pitcher-common";
export class BrowserAiClient {
    constructor() {
        this.chatsUpdatedEmitter = new Emitter();
        this.onChatsUpdated = this.chatsUpdatedEmitter.event;
        this.chatsErrorEmitter = new Emitter();
        this.onChatsError = this.chatsErrorEmitter.event;
        this.chatCreatedEmitter = new Emitter();
        this.onChatCreated = this.chatCreatedEmitter.event;
        this.chatMessageEmitter = new Emitter();
        this.onChatMessage = this.chatMessageEmitter.event;
    }
    subscribeToMessage() {
        return new Disposable();
    }
    fetchChats() {
        return;
    }
    resync() {
        return;
    }
    suggestCommit() {
        return Promise.reject(new Error("Not implemented"));
    }
    raw() {
        return Promise.reject(new Error("Not implemented"));
    }
    stream() {
        return Promise.reject(new Error("Not implemented"));
    }
    getChat() {
        return Promise.reject(new Error("Not implemented"));
    }
}
