import { Emitter } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../common/AsyncValueStore";
export class SetupClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.setupProgressUpdateEmitter = new Emitter();
        this.onSetupProgressUpdate = this.setupProgressUpdateEmitter.event;
        this.setupProgressErrorEmitter = new Emitter();
        this.onSetupProgressError = this.setupProgressErrorEmitter.event;
        this.setupProgress = this.createSetupProgressValue();
        this.readyPromise = this.setupProgress.getInitPromise();
        messageHandler.onNotification("setup/progress", (progress) => 
        // This will emit the progress event
        this.setupProgress.set(progress));
    }
    createSetupProgressValue() {
        const setupProgress = new AsyncValueStore({
            state: "IDLE",
            currentStepIndex: 0,
            steps: [],
        }, () => this.messageHandler.request({
            method: "setup/get",
            params: {},
        }), { fetchEagerly: true });
        setupProgress.onChange(({ value }) => this.setupProgressUpdateEmitter.fire(value));
        setupProgress.onError((error) => this.setupProgressErrorEmitter.fire(error));
        return setupProgress;
    }
    getProgress() {
        return this.setupProgress.get();
    }
    resync() {
        return this.setupProgress.refresh();
    }
    /**
     * Skip the currently running step. We expect you to send the current step as a verification for Pitcher
     * to know whether you'd like to skip the current step.
     */
    skipStep(stepIndexToSkip) {
        return this.messageHandler.request({
            method: "setup/skip",
            params: {
                stepIndexToSkip,
            },
        });
    }
    /**
     * Skip the entire remaining progress. Current step is finished as SKIPPED, rest of the steps are ignored
     */
    skipAll() {
        return this.messageHandler.request({
            method: "setup/skipAll",
            params: null,
        });
    }
    /**
     * Sets the current step, allows you to go back in time again
     */
    setStep(stepIndex) {
        return this.messageHandler.request({
            method: "setup/setStep",
            params: {
                stepIndex,
            },
        });
    }
    disable() {
        this.messageHandler.request({
            method: "setup/disable",
            params: null,
        });
    }
    enable() {
        this.messageHandler.request({
            method: "setup/enable",
            params: null,
        });
    }
    init() {
        return this.messageHandler.request({
            method: "setup/init",
            params: null,
        });
    }
}
