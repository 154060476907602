import { murmur, Emitter, Disposable } from "@codesandbox/pitcher-common";
export class BrowserFile extends Disposable {
    get isDirty() {
        return this.contentHash !== this.savedHash;
    }
    constructor(fileClient, path, fileId, fileData, content, seamlessFork) {
        super();
        this.fileClient = fileClient;
        this.seamlessFork = seamlessFork;
        this.onDidSaveEmitter = this.addDisposable(new Emitter());
        this.onDidSave = this.onDidSaveEmitter.event;
        this.onDidContentChangeEmitter = this.addDisposable(new Emitter());
        this.onDidContentChange = this.onDidContentChangeEmitter.event;
        this.onWillClientJoinEmitter = this.addDisposable(new Emitter());
        this.onWillClientJoin = this.onWillClientJoinEmitter.event;
        this.onDidClientJoinEmitter = this.addDisposable(new Emitter());
        this.onDidClientJoin = this.onDidClientJoinEmitter.event;
        this.onWillClientLeaveEmitter = this.addDisposable(new Emitter());
        this.onWillClientLeave = this.onWillClientLeaveEmitter.event;
        this.onDidClientLeaveEmitter = this.addDisposable(new Emitter());
        this.onDidClientLeave = this.onDidClientLeaveEmitter.event;
        this.onDidMoveEmitter = this.addDisposable(new Emitter());
        this.onDidMove = this.onDidMoveEmitter.event;
        this.clients = {};
        this.document = null;
        this.path = path;
        this.id = fileId;
        this.savedHash = murmur(fileData.savedContent);
        this.content = content;
        this.isBinary = fileData.isBinary;
        // Content is a unique url for isBinary, so we use this url as the base for the hash...
        this.contentHash = murmur(fileData.content);
    }
    updatePath(newPath) {
        this.path = newPath;
        this.onDidMoveEmitter.fire(newPath);
    }
    async resync() {
        return;
    }
    updateContent(newContent) {
        this.content = newContent;
        this.contentHash = murmur(newContent);
        this.onDidContentChangeEmitter.fire({
            contentHash: this.contentHash,
            newContent: this.content,
        });
    }
    getLatestFilePath() {
        const updatedFilepath = this.fileClient.fs.getPathFromId(this.id);
        if (!updatedFilepath) {
            throw new Error("File no longer exists");
        }
        if (this.path !== updatedFilepath) {
            this.updatePath(updatedFilepath);
        }
        return updatedFilepath;
    }
    async save() {
        const updatedFilepath = this.getLatestFilePath();
        const content = this.content;
        this.seamlessFork("file/save");
        if (typeof content === "string") {
            const textEncoder = new TextEncoder();
            await this.fileClient.fs.writeFile(updatedFilepath, textEncoder.encode(content));
            this.savedHash = murmur(content);
        }
        else {
            await this.fileClient.fs.writeFile(updatedFilepath, content);
        }
        this.onDidSaveEmitter.fire({ savedHash: this.savedHash });
    }
    async resetFromClient(_content) { }
    async resetFromServer() {
        return undefined;
    }
}
