import { Disposable } from "@codesandbox/pitcher-client";
import _debug from "debug";
import type { IDisposable } from "monaco-editor";
import { Emitter } from "monaco-editor";
import { Uri, RemoteAuthorityResolverError } from "vscode";
import type { VSBuffer } from "vscode/vscode/vs/base/common/buffer";
import type { SocketDiagnosticsEventType } from "vscode/vscode/vs/base/parts/ipc/common/ipc.net";
import type {
  IWebSocket,
  IWebSocketCloseEvent,
  IWebSocketFactory,
} from "vscode/vscode/vs/platform/remote/browser/browserSocketFactory";

import { getLatestMonacoConfig } from "./monaco";

const debug = _debug("csb:vscode:remote:ws");

class BrowserWebSocket extends Disposable implements IWebSocket {
  private readonly _onData = new Emitter<ArrayBuffer>();
  public readonly onData = this._onData.event;

  private readonly _onOpen = this.addDisposable(new Emitter<void>());
  public readonly onOpen = this._onOpen.event;

  private readonly _onClose = this.addDisposable(
    new Emitter<IWebSocketCloseEvent>(),
  );
  public readonly onClose = this._onClose.event;

  public readonly _onError = this.addDisposable(new Emitter<unknown>());
  public readonly onError = this._onError.event;

  private _socket: WebSocket;
  private readonly _fileReader: FileReader;
  private readonly _queue: Blob[];
  private _isReading: boolean;
  private _isClosed: boolean;

  private readonly _socketMessageListener: (ev: MessageEvent) => void;

  public traceSocketEvent(
    _type: SocketDiagnosticsEventType,
    _data?: VSBuffer | Uint8Array | ArrayBuffer | ArrayBufferView | unknown,
  ): void {
    /* noop, implemented in vscode but not here */
  }

  constructor(url: string, debugLabel: string) {
    super();

    debug("Creating WebSocket", url, debugLabel);

    this.onWillDispose(() => {
      debug("Disposing WebSocket", url, debugLabel);
    });

    this._socket = new WebSocket(url);
    this._fileReader = new FileReader();
    this._queue = [];
    this._isReading = false;
    this._isClosed = false;

    this._fileReader.onload = (event) => {
      this._isReading = false;
      const buff = (event.target as { result: ArrayBuffer }).result;

      this._onData.fire(buff);

      if (this._queue.length > 0) {
        enqueue(this._queue.shift()!);
      }
    };

    const enqueue = (blob: Blob) => {
      if (this._isReading) {
        this._queue.push(blob);
        return;
      }
      this._isReading = true;
      this._fileReader.readAsArrayBuffer(blob);
    };

    this._socketMessageListener = (ev: MessageEvent) => {
      const blob = ev.data as Blob;

      enqueue(blob);
    };

    this._socket.addEventListener("message", this._socketMessageListener);

    this.addDisposable(
      addDisposableListener(this._socket, "open", () => {
        this._onOpen.fire();
      }),
    );

    // WebSockets emit error events that do not contain any real information
    // Our only chance of getting to the root cause of an error is to
    // listen to the close event which gives out some real information:
    // - https://www.w3.org/TR/websockets/#closeevent
    // - https://tools.ietf.org/html/rfc6455#section-11.7
    //
    // But the error event is emitted before the close event, so we therefore
    // delay the error event processing in the hope of receiving a close event
    // with more information

    let pendingErrorEvent: unknown | null = null;

    const sendPendingErrorNow = () => {
      const err = pendingErrorEvent;
      pendingErrorEvent = null;
      this._onError.fire(err);
    };

    const errorRunner = this.addDisposable(
      new RunOnceScheduler(sendPendingErrorNow, 0),
    );

    const sendErrorSoon = (err: unknown) => {
      errorRunner.cancel();
      pendingErrorEvent = err;
      errorRunner.schedule();
    };

    const sendErrorNow = (err: unknown) => {
      errorRunner.cancel();
      pendingErrorEvent = err;
      sendPendingErrorNow();
    };

    this.addDisposable(
      addDisposableListener(this._socket, "close", (e: CloseEvent) => {
        this._isClosed = true;

        if (pendingErrorEvent) {
          if (!window.navigator.onLine) {
            // The browser is offline => this is a temporary error which might resolve itself
            sendErrorNow(
              RemoteAuthorityResolverError.TemporarilyNotAvailable(
                "Browser is offline",
              ),
            );
          } else {
            // An error event is pending
            // The browser appears to be online...
            if (!e.wasClean) {
              // Let's be optimistic and hope that perhaps the server could not be reached or something
              sendErrorNow(
                RemoteAuthorityResolverError.TemporarilyNotAvailable(
                  e.reason || `WebSocket close with status code ${e.code}`,
                ),
              );
            } else {
              // this was a clean close => send existing error
              errorRunner.cancel();
              sendPendingErrorNow();
            }
          }
        }

        this._onClose.fire({
          code: e.code,
          reason: e.reason,
          wasClean: e.wasClean,
          event: e,
        });
      }),
    );

    this.addDisposable(
      addDisposableListener(this._socket, "error", (err) => {
        sendErrorSoon(err);
      }),
    );
  }

  send(data: ArrayBuffer | ArrayBufferView): void {
    if (this._isClosed) {
      // Refuse to write data to closed WebSocket...
      return;
    }

    this._socket.send(data);
  }

  close(): void {
    debug("Closing WebSocket", this._socket.url);
    this._isClosed = true;
    this._socket.close();
    this._socket.removeEventListener("message", this._socketMessageListener);
    this.dispose();
  }
}

class DomListener implements IDisposable {
  private _handler: (e: unknown) => void;
  private _node: EventTarget;
  private readonly _type: string;
  private readonly _options: boolean | AddEventListenerOptions;

  constructor(
    node: EventTarget,
    type: string,
    handler: (e: unknown) => void,
    options?: boolean | AddEventListenerOptions,
  ) {
    this._node = node;
    this._type = type;
    this._handler = handler;
    this._options = options || false;
    this._node.addEventListener(this._type, this._handler, this._options);
  }

  public dispose(): void {
    if (!this._handler) {
      // Already disposed
      return;
    }

    this._node.removeEventListener(this._type, this._handler, this._options);

    // Prevent leakers from holding on to the dom or handler func
    this._node = null!;
    this._handler = null!;
  }
}

class RunOnceScheduler implements IDisposable {
  protected runner: ((...args: unknown[]) => void) | null;

  private timeoutToken: NodeJS.Timeout;
  private timeout: number;
  private timeoutHandler: () => void;

  constructor(runner: (...args: unknown[]) => void, delay: number) {
    this.timeoutToken = -1 as unknown as NodeJS.Timeout;
    this.runner = runner;
    this.timeout = delay;
    this.timeoutHandler = this.onTimeout.bind(this);
  }

  /**
   * Dispose RunOnceScheduler
   */
  dispose(): void {
    this.cancel();
    this.runner = null;
  }

  /**
   * Cancel current scheduled runner (if any).
   */
  cancel(): void {
    if (this.isScheduled()) {
      clearTimeout(this.timeoutToken);
      this.timeoutToken = -1 as unknown as NodeJS.Timeout;
    }
  }

  /**
   * Cancel previous runner (if any) & schedule a new runner.
   */
  schedule(delay = this.timeout): void {
    this.cancel();
    this.timeoutToken = setTimeout(this.timeoutHandler, delay);
  }

  get delay(): number {
    return this.timeout;
  }

  set delay(value: number) {
    this.timeout = value;
  }

  /**
   * Returns true if scheduled.
   */
  isScheduled(): boolean {
    return this.timeoutToken !== (-1 as unknown as NodeJS.Timeout);
  }

  flush(): void {
    if (this.isScheduled()) {
      this.cancel();
      this.doRun();
    }
  }

  private onTimeout() {
    this.timeoutToken = -1 as unknown as NodeJS.Timeout;
    if (this.runner) {
      this.doRun();
    }
  }

  protected doRun(): void {
    this.runner?.();
  }
}

function addDisposableListener<K extends keyof GlobalEventHandlersEventMap>(
  node: EventTarget,
  type: K,
  handler: (event: GlobalEventHandlersEventMap[K]) => void,
  useCapture?: boolean,
): IDisposable;
function addDisposableListener(
  node: EventTarget,
  type: string,
  handler: (event: any) => void, // eslint-disable-line
  useCapture?: boolean,
): IDisposable;
function addDisposableListener(
  node: EventTarget,
  type: string,
  handler: (event: any) => void, // eslint-disable-line
  options: AddEventListenerOptions,
): IDisposable;
function addDisposableListener(
  node: EventTarget,
  type: string,
  handler: (event: any) => void, // eslint-disable-line
  useCaptureOrOptions?: boolean | AddEventListenerOptions,
): IDisposable {
  return new DomListener(node, type, handler, useCaptureOrOptions);
}

// Hardcoded from VSCode codebase
const connectionTokenQueryName = "tkn";
const remoteResourcesPath = `/vscode-remote-resource`;
export class WebSocketFactory implements IWebSocketFactory {
  private previewToken: string | undefined;
  constructor(
    public currentHostname: string,
    private placeHolderHostname: string,
    private connectionToken: string,
  ) {}

  async initializeToken() {
    const getAndSetToken = () =>
      getLatestMonacoConfig()
        .api.get<{ token: string }>({
          path: "/v1/auth/preview_token",
        })
        .then(({ token }) => {
          this.previewToken = token;
        });

    setInterval(
      () => {
        getAndSetToken();
      },
      60 * 60 * 1000,
    );

    await getAndSetToken();
  }

  public replaceHostname(hostname: string): void {
    this.currentHostname = hostname;
  }

  create(url: string, debugLabel: string): IWebSocket {
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname === this.placeHolderHostname) {
      parsedUrl.hostname = this.currentHostname;
      parsedUrl.searchParams.set("preview_token", this.previewToken!);
      debug("Creating ReplaceableWebSocket", {
        old: url,
        new: parsedUrl.toString(),
        debugLabel,
      });

      const websocket = new BrowserWebSocket(parsedUrl.toString(), debugLabel);

      return websocket;
    }

    return new BrowserWebSocket(url, debugLabel);
  }

  public rewriteUri(uri: Uri): Uri {
    let query = `path=${encodeURIComponent(uri.path)}`;
    query += `&${connectionTokenQueryName}=${encodeURIComponent(
      this.connectionToken,
    )}`;
    if (this.previewToken) {
      query += `&preview_token=${this.previewToken}`;
    }

    return Uri.from({
      scheme: "https",
      authority:
        uri.authority === this.placeHolderHostname
          ? this.currentHostname
          : uri.authority,
      path: remoteResourcesPath,
      query,
    });
  }
}
