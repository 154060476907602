import type { clients } from "@codesandbox/pitcher-client";
import type { PortApi } from "environment-interface/pitcher/port";

export const createPortApi = (portClient: clients.IPortClient): PortApi => {
  return {
    getPorts() {
      return portClient.getPorts();
    },
  };
};
