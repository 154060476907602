import type * as Monaco from "monaco-editor";
import { onGrid } from "prism-react";

export interface IEditorOptionsParams {
  fonts: Record<string, string>;
  wordWrap: boolean;
}

export function getEditorOptions(
  params: IEditorOptionsParams,
): Monaco.editor.IEditorOptions {
  return {
    /**
     * Editor UI
     */
    // Setting automaticLayout to true will break peek definition logic
    automaticLayout: false,
    scrollbar: {
      useShadows: false,
      verticalScrollbarSize: onGrid(1.5),
      horizontalScrollbarSize: onGrid(1.5),
    },
    minimap: { enabled: false, renderCharacters: false },
    guides: {
      indentation: false,
    },
    renderLineHighlightOnlyWhenFocus: true,
    overviewRulerBorder: false,

    /** Allows widgets to render outside the boundary of the editor (which has overflow: hidden) */
    fixedOverflowWidgets: true,

    /**
     * Fonts
     */
    fontFamily: params.fonts.mono,
    fontLigatures: true,
    fontSize: 14,
    lineHeight: 0, // Use 0 to automatically compute the line height from the font size.
    lineNumbersMinChars: 5,

    /**
     * Code suggestions
     */
    quickSuggestions: true,
    quickSuggestionsDelay: 10,
    suggestOnTriggerCharacters: true,
    acceptSuggestionOnCommitCharacter: false,

    wordWrap: params.wordWrap ? "on" : "off",
    wrappingIndent: "same",
  };
}
