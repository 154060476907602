import type { Storage } from "environment-interface/storage";

import { safeLocalStorage } from "utils/safeLocalStorage";

export const createStorage = (): Storage => {
  // eslint-disable-next-line
  const subscribers: Record<string, Set<(value: any) => void>> = {};

  return {
    set(key, value) {
      safeLocalStorage.set(key, JSON.stringify(value));

      if (subscribers[key]) {
        subscribers[key].forEach((cb) => {
          cb(value);
        });
      }
    },
    get(key) {
      try {
        const value = safeLocalStorage.get(key);
        if (!value) {
          return null;
        }

        return JSON.parse(value);
      } catch {
        return null;
      }
    },
    delete(key) {
      safeLocalStorage.delete(key);

      if (subscribers[key]) {
        subscribers[key].forEach((cb) => {
          cb(undefined);
        });
      }
    },
    subscribe(key, cb) {
      if (!subscribers[key]) {
        subscribers[key] = new Set();
      }

      subscribers[key].add(cb);

      return () => {
        subscribers[key].delete(cb);
      };
    },
  };
};
