import type { PortalProps } from "@radix-ui/react-portal";
import * as radixPortal from "@radix-ui/react-portal";
import React from "react";

const { Root } = radixPortal;

/**
 * Portal renders differently on server and client, but
 * we do not really care about that. So we suppress suppressHydration
 * warnings to ensure we pick up ACTUAL hydration issues
 */
export const Portal: React.FC<PortalProps> = (props) => (
  <div suppressHydrationWarning>
    <Root {...props} />
  </div>
);
