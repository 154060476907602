import { Emitter } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../common/AsyncValueStore";
export class ShellClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.openShells = new Map();
        this.shellCreatedEmitter = new Emitter();
        this.onShellCreated = this.shellCreatedEmitter.event;
        this.shellRestartedEmitter = new Emitter();
        this.onShellRestarted = this.shellRestartedEmitter.event;
        this.shellTerminatedEmitter = new Emitter();
        this.onShellTerminated = this.shellTerminatedEmitter.event;
        this.shellNameChangeEmitter = new Emitter();
        this.onShellNameChange = this.shellNameChangeEmitter.event;
        this.shellExitedEmitter = new Emitter();
        this.onShellExited = this.shellExitedEmitter.event;
        this.shellsUpdatedEmitter = new Emitter();
        this.onShellsUpdated = this.shellsUpdatedEmitter.event;
        this.shellsErrorEmitter = new Emitter();
        this.onShellsError = this.shellsErrorEmitter.event;
        this.shellOutEmitter = new Emitter();
        this.onShellOut = this.shellOutEmitter.event;
        this.shells = this.createShellsValue();
        this.readyPromise = this.shells.getInitPromise();
        messageHandler.onNotification("shell/terminate", ({ shellId, author }) => {
            this.shellTerminatedEmitter.fire({ shellId, author });
            this.deleteShell(shellId);
        });
        messageHandler.onNotification("shell/exit", ({ shellId, shellType, exitCode }) => {
            this.shellExitedEmitter.fire({ shellId, exitCode });
            // Only terminals are removed on exit, command shells can still be open
            if (shellType === "TERMINAL") {
                this.deleteShell(shellId);
            }
        });
        messageHandler.onNotification("shell/create", (shell) => {
            this.shellCreatedEmitter.fire(shell);
            this.shells.update((shells) => shells.concat(shell));
        });
        messageHandler.onNotification("shell/restart", ({ shellId }) => this.shellRestartedEmitter.fire({ shellId }));
        messageHandler.onNotification("shell/out", (params) => this.shellOutEmitter.fire(params));
        messageHandler.onNotification("shell/rename", ({ shell }) => {
            this.shells.update((shells) => [
                ...shells.filter((item) => item.shellId !== shell.shellId),
                shell,
            ]);
            this.shellNameChangeEmitter.fire({ shellId: shell.shellId });
        });
    }
    createShellsValue() {
        const shells = new AsyncValueStore([], () => this.messageHandler
            .request({
            method: "shell/list",
            params: {},
        })
            .then(({ shells }) => shells), { fetchEagerly: true });
        shells.onChange(({ value }) => this.shellsUpdatedEmitter.fire(value));
        shells.onError((error) => this.shellsErrorEmitter.fire(error));
        return shells;
    }
    closeShell(shellId) {
        this.openShells.delete(shellId);
    }
    deleteShell(shellId) {
        this.shells.update((shells) => shells.filter((shell) => shell.shellId !== shellId));
        this.closeShell(shellId);
    }
    getShells() {
        return this.shells.get();
    }
    getShellName(id) {
        const name = this.shells.get().find((shell) => shell.shellId === id)?.name || null;
        return name;
    }
    resync() {
        this.shells.refresh();
        Array.from(this.openShells.entries()).forEach(([shellId, shellSize]) => this.open(shellId, shellSize).catch(() => {
            // The shell might not exist anymore so we say that it has been terminated
            this.shellTerminatedEmitter.fire({
                shellId,
                author: "connection loss",
            });
        }));
    }
    create(projectPath, size, command, type, isSystemShell) {
        return this.messageHandler
            .request({
            method: "shell/create",
            params: {
                projectPath,
                command,
                size,
                type,
                isSystemShell,
            },
        })
            .then((response) => {
            this.openShells.set(response.shellId, size);
            return response;
        });
    }
    open(shellId, size) {
        return this.messageHandler
            .request({
            method: "shell/open",
            params: {
                shellId,
                size,
            },
        })
            .then((response) => {
            this.openShells.set(response.shellId, size);
            return response;
        });
    }
    close(shellId) {
        return this.messageHandler
            .request({
            method: "shell/close",
            params: {
                shellId,
            },
        })
            .then((response) => {
            this.closeShell(shellId);
            return response;
        });
    }
    restart(shellId) {
        return this.messageHandler.request({
            method: "shell/restart",
            params: {
                shellId,
            },
        });
    }
    delete(shellId) {
        return this.messageHandler
            .request({
            method: "shell/terminate",
            params: {
                shellId,
            },
        })
            .then((response) => {
            this.deleteShell(shellId);
            return response;
        });
    }
    send(shellId, input, size) {
        return this.messageHandler.request({
            method: "shell/in",
            params: {
                shellId,
                input,
                size,
            },
        });
    }
    resize(shellId, size) {
        return this.messageHandler
            .request({
            method: "shell/resize",
            params: {
                shellId,
                size,
            },
        }, {
            // This is immutable and can be triggered very often
            queueForReconnect: false,
        })
            .then((response) => {
            this.openShells.set(shellId, size);
            return response;
        });
    }
    rename(shellId, name) {
        return this.messageHandler.request({
            method: "shell/rename",
            params: {
                shellId,
                name,
            },
        });
    }
}
