import { createEmitter } from "@codesandbox/environment-interface";
import type {
  Visibility,
  VisibilityEvent,
} from "environment-interface/visibility";

export const createVisibility = (): Visibility => {
  const visibilityEvents = createEmitter<VisibilityEvent>();

  window.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      visibilityEvents.emit({
        type: "VISIBILITY:HIDDEN",
      });
    } else {
      visibilityEvents.emit({
        type: "VISIBILITY:VISIBLE",
      });
    }
  });

  return {
    events: visibilityEvents,
    get isVisible() {
      return document.visibilityState === "visible";
    },
    get isInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  };
};
