import { Content } from "@radix-ui/react-context-menu";

import { styled } from "../../theme/stitches";

export const ContextMenuContent = styled(Content, {
  background: "$neutral-bg-medium",
  boxShadow: "$popup",
  padding: "$1 0",
  minWidth: "200px",
  borderRadius: "$2",
});
