import { Root, Indicator } from "@radix-ui/react-checkbox";
import React from "react";
import type { ComponentProps } from "react";

import { styled } from "../../theme/stitches";
import { toShadowRing } from "../../tokens/shadow";
import { Icon } from "../Icon";

const StyledCheckbox = styled(Root, {
  appearance: "none",
  backgroundColor: "$neutral-bg-low",
  border: "1px solid $neutral-bg-high",
  width: "$4",
  height: "$4",
  borderRadius: "$1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "box-shadow $fast, color $fast, background $fast",

  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
  },

  "&:hover": {
    boxShadow: toShadowRing("thick", "neutral-bg-focus"),
  },

  "&:active": {
    boxShadow: "none",
  },

  "&:focus-visible": {
    outlineOffset: 0,
  },

  "& > span": {
    display: "flex",
  },

  /* 
    These data-state styles are applied to **uncontrolled** components
  */
  variants: {
    variant: {
      primary: {
        '&[data-state="checked"], &[data-state="indeterminate"]': {
          background: "$accent-primary-base",
          borderColor: "$accent-primary-base",
          color: "$neutral-bg-low",
        },
      },
      secondary: {
        '&[data-state="checked"], &[data-state="indeterminate"]': {
          background: "$neutral-bg-subtle",
          borderColor: "$neutral-bg-high",
          color: "$neutral-fg-high",
        },
      },
    },
  },
});

export type CheckboxProps = ComponentProps<typeof StyledCheckbox>;

export const Checkbox: React.FC<CheckboxProps> = ({
  children,
  checked,
  onCheckedChange,
  variant = "primary",
  disabled,
  ...rootProps
}) => {
  return (
    <StyledCheckbox
      {...rootProps}
      checked={checked}
      disabled={disabled}
      onCheckedChange={onCheckedChange}
      variant={variant}
    >
      {children ? (
        children
      ) : (
        <Indicator>
          {checked === "indeterminate" ? <Dash /> : <Check />}
        </Indicator>
      )}
    </StyledCheckbox>
  );
};

export const Check: React.FC = () => (
  <Icon color="inherit" size={3} type="check" />
);
export const Dash: React.FC = () => (
  <Icon color="inherit" size={4} type="line" />
);
