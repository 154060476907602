import { Emitter } from "@codesandbox/pitcher-common";
export class BrowserShellClient {
    constructor() {
        this.readyPromise = undefined;
        this.shellCreatedEmitter = new Emitter();
        this.onShellCreated = this.shellCreatedEmitter.event;
        this.shellRestartedEmitter = new Emitter();
        this.onShellRestarted = this.shellRestartedEmitter.event;
        this.shellTerminatedEmitter = new Emitter();
        this.onShellTerminated = this.shellTerminatedEmitter.event;
        this.shellNameChangeEmitter = new Emitter();
        this.onShellNameChange = this.shellNameChangeEmitter.event;
        this.shellExitedEmitter = new Emitter();
        this.onShellExited = this.shellExitedEmitter.event;
        this.shellsUpdatedEmitter = new Emitter();
        this.onShellsUpdated = this.shellsUpdatedEmitter.event;
        this.shellsErrorEmitter = new Emitter();
        this.onShellsError = this.shellsErrorEmitter.event;
        this.shellOutEmitter = new Emitter();
        this.onShellOut = this.shellOutEmitter.event;
    }
    getShells() {
        return [];
    }
    getShellName() {
        return null;
    }
    resync() {
        return;
    }
    create() {
        return Promise.reject(new Error("Not implemented"));
    }
    open() {
        return Promise.reject(new Error("Not implemented"));
    }
    close() {
        return Promise.reject(new Error("Not implemented"));
    }
    restart() {
        return Promise.reject(new Error("Not implemented"));
    }
    delete() {
        return Promise.reject(new Error("Not implemented"));
    }
    send() {
        return Promise.reject(new Error("Not implemented"));
    }
    resize() {
        return Promise.reject(new Error("Not implemented"));
    }
    rename() {
        return Promise.reject(new Error("Not implemented"));
    }
}
