import { Channel } from "./Channel";
import { CommandChannel } from "./CommandChannel";
import { FollowChannel } from "./FollowChannel";
import { SharedChannel } from "./SharedChannel";
export { CommandChannel, FollowChannel, SharedChannel };
export class ChannelClient {
    constructor({ messageHandler, currentClient, clientClient, }) {
        // We cache all channels created as they just act as a wrapper around
        // message passing, they should not act as different instances
        this.channels = {
            raw: {},
            command: {},
            follow: {},
            shared: {},
        };
        this.currentClient = currentClient;
        this.messageHandler = messageHandler;
        this.clientClient = clientClient;
    }
    getChannel(name) {
        return (this.channels.raw[name] =
            this.channels.raw[name] || new Channel(name, this.messageHandler));
    }
    getFollowChannel(name) {
        return (this.channels.follow[name] =
            this.channels.follow[name] ||
                new FollowChannel(name, this.messageHandler, this.currentClient));
    }
    getCommandChannel(name) {
        return (this.channels.command[name] =
            this.channels.command[name] ||
                new CommandChannel({
                    name,
                    clientClient: this.clientClient,
                    currentClient: this.currentClient,
                    messageHandler: this.messageHandler,
                }));
    }
    getSharedChannel(name) {
        return (this.channels.shared[name] =
            this.channels.shared[name] ||
                new SharedChannel(name, this.messageHandler));
    }
    resync() {
        for (const channels of Object.values(this.channels)) {
            for (const channelId in channels) {
                channels[channelId].resync();
            }
        }
    }
}
