export class BrowserChannelClient {
    getChannel() {
        throw new Error("Not implemented");
    }
    getFollowChannel() {
        throw new Error("Not implemented");
    }
    getCommandChannel() {
        throw new Error("Not implemented");
    }
    getSharedChannel() {
        throw new Error("Not implemented");
    }
}
