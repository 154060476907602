import { styled } from "../../theme";
import { neutralFg, informative, accent } from "../../tokens/colors";
import { createVariants } from "../../utils/createVariants";

export const Text = styled("span", {
  color: "currentcolor",
  margin: 0,
  fontWeight: "$regular",

  "> a": {
    color: "$accent-primary-base",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  variants: {
    color: createVariants({ ...neutralFg, ...informative, ...accent }, "color"),
    size: {
      // ❓ map through theme variants or do manually?
      xs: {
        typography: "$xs",
      },
      base: {
        typography: "$base",
      },
      sm: {
        typography: "$sm",
      },
      md: {
        typography: "$md",
      },
      lg: {
        typography: "$lg",
      },
      xl: {
        typography: "$xl",
      },
      xxl: {
        typography: "$xxl",
      },
    },
    weight: {
      regular: {
        fontWeight: "$regular",
      },
      medium: {
        fontWeight: "$medium",
      },
      semibold: {
        fontWeight: "$semibold",
      },
    },
    emphasis: {
      high: {
        fontFamily: "$display",
      },
      base: {
        fontFamily: "$base",
      },
    },
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    truncate: {
      true: {
        truncateText: true,
      },
    },
  },
});
