/* eslint-disable @typescript-eslint/no-explicit-any */
import { Emitter, SliceList } from "@codesandbox/pitcher-common";
import { PitcherResponseStatus, isNotificationPayload, isErrorPayload, isResultPayload, decodeMessage, } from "@codesandbox/pitcher-protocol";
import { PendingPitcherMessage } from "../common/PendingPitcherMessage";
/**
 * This class is completely decoupled from the connection itself. Pitcher class is responsible for funneling the messages
 * through the current connection. The connection can change when seamless branching or reconnecting.
 */
export class PitcherMessageHandler {
    constructor(onSendRequest, seamlessFork) {
        this.onSendRequest = onSendRequest;
        this.seamlessFork = seamlessFork;
        this.nextMessageId = 0;
        this.awaitReconnectQueue = [];
        this.pendingMessages = new Map();
        this.isRequestingInstanceChange = false;
        this.onInstanceChangeRequiredEmitter = new Emitter();
        this.onInstanceChangeRequired = this.onInstanceChangeRequiredEmitter.event;
        this.notificationListeners = {};
        this.messageEmitter = new Emitter();
        this.onMessage = this.messageEmitter.event;
        this.errorEmitter = new Emitter();
        this.onError = this.errorEmitter.event;
    }
    toggleSeamlessFork(value) {
        this.seamlessFork = value;
    }
    onNotification(method, cb) {
        let listeners = this.notificationListeners[method];
        if (!listeners) {
            listeners = this.notificationListeners[method] = new SliceList();
        }
        const idx = listeners.add(cb);
        return () => {
            this.notificationListeners[method]?.remove(idx);
        };
    }
    receiveMessage(blob) {
        const payload = decodeMessage(blob);
        this.messageEmitter.fire(payload);
        const method = payload.method;
        if (isNotificationPayload(payload)) {
            const listeners = this.notificationListeners[method];
            if (listeners) {
                for (const cb of listeners.values()) {
                    cb(payload.params);
                }
            }
            return;
        }
        let response;
        if (isErrorPayload(payload)) {
            response = {
                status: PitcherResponseStatus.REJECTED,
                error: {
                    code: payload.error.code,
                    data: payload.error.data,
                    message: payload.error.message,
                },
                method,
            };
        }
        else if (isResultPayload(payload)) {
            response = {
                status: PitcherResponseStatus.RESOLVED,
                result: payload.result,
                method,
            };
        }
        else {
            throw new Error("Unable to identify message type");
        }
        const messageToResolve = this.pendingMessages.get(payload.id);
        if (messageToResolve) {
            messageToResolve.resolve(response);
        }
        // We do not care if we do not have a matching message, this is related to changing connection
    }
    /**
     * Replaces the current sending message handler with a new one, bound to a new connection. We do this instead of emitting an event
     * as we want to deal with any errors
     */
    setOnSendRequest(onSendRequest) {
        this.onSendRequest = onSendRequest;
    }
    request(pitcherRequest, options = {}) {
        const { timeoutMs, queueForReconnect = true, seamlessForkStrategy, } = options;
        const request = this.createRequest(pitcherRequest, timeoutMs);
        if (this.seamlessFork &&
            (seamlessForkStrategy === "queue" || seamlessForkStrategy === "dispose")) {
            if (seamlessForkStrategy === "queue") {
                this.awaitReconnectQueue.push(request);
            }
            else {
                request.dispose();
            }
            if (!this.isRequestingInstanceChange) {
                this.isRequestingInstanceChange = true;
                this.onInstanceChangeRequiredEmitter.fire(pitcherRequest.method);
            }
            return request.unwrap();
        }
        if (this.seamlessFork &&
            this.isRequestingInstanceChange &&
            seamlessForkStrategy === "queueDuringFork") {
            this.awaitReconnectQueue.push(request);
            return request.unwrap();
        }
        try {
            // This will throw if we are not in the right connection state
            this.onSendRequest(request);
            return request.unwrap();
        }
        catch (error) {
            // If the request is eligable for trying again on reconnect we'll queue and unwrap it for a pending
            // promise state
            if (queueForReconnect) {
                this.awaitReconnectQueue.push(request);
                return request.unwrap();
            }
            this.errorEmitter.fire({
                message: error.message,
                extras: {
                    source: "pitcher-message-handler",
                    type: "send-request",
                    request: pitcherRequest,
                },
            });
            // We always want to return a promise from the method so it does not matter if the error is related to disconnect
            // or Pitcher giving an error. It all ends up in the `catch` of the unwrapped promise
            return Promise.reject(error);
        }
    }
    createRequest(request, timeoutMs) {
        const id = this.nextMessageId++;
        const pitcherMessage = new PendingPitcherMessage(id, request, timeoutMs);
        this.pendingMessages.set(id, pitcherMessage);
        pitcherMessage.onDidDispose(() => this.pendingMessages.delete(id));
        return pitcherMessage;
    }
    getPendingMessages() {
        return this.pendingMessages;
    }
    disposePendingMessages(exceptions) {
        this.pendingMessages.forEach((pendingMessage) => {
            if (!exceptions || !exceptions.includes(pendingMessage.message)) {
                pendingMessage.dispose();
            }
        });
    }
    disableSeamlessFork() {
        this.seamlessFork = false;
        this.isRequestingInstanceChange = false;
    }
    async flushReconnectQueue() {
        try {
            await Promise.all(this.awaitReconnectQueue.map((message) => this.onSendRequest(message)));
            this.awaitReconnectQueue.length = 0;
        }
        catch {
            // We do not care if this fails as we'll try sending it again on next reconnect
        }
    }
    dispose() {
        this.onSendRequest = () => { };
        this.disposePendingMessages();
        this.pendingMessages.clear();
        this.notificationListeners = {};
        this.errorEmitter.dispose();
        this.messageEmitter.dispose();
    }
}
