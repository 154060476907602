import type { rest } from "@codesandbox/api";
import { gql } from "@codesandbox/api";

const permissions: rest.PermissionType[] = [
  "write_code",
  "write_project",
  "comment",
  "read",
  "none",
];

/**
 * Whether the given permission is permitted compared to the required permission.
 * We use a simple model for this, where if you have a top permission it will be considered
 * that you have all other permissions. This same list is saved on the server, and this needs
 * to keep in sync with that.
 * @param permission Permission to test
 * @param requiredPermission Required permission to pass the test
 */
export function hasSandboxPermission(
  permission: rest.PermissionType,
  requiredPermission: rest.PermissionType,
) {
  return (
    permissions.indexOf(permission) <= permissions.indexOf(requiredPermission)
  );
}

export const getPermissionLevelFromGitHub = (
  permission: gql.GithubPermission,
) =>
  permission === gql.GithubPermission.READ ||
  permission === gql.GithubPermission.NONE;

export const hasRepoWriteAccess = (permission: gql.GithubPermission) =>
  permission === gql.GithubPermission.WRITE ||
  permission === gql.GithubPermission.ADMIN;

export const getProjectAuthorization = (
  authorization: gql.ProjectAuthorization,
) =>
  authorization === gql.ProjectAuthorization.READ ||
  authorization === gql.ProjectAuthorization.NONE;

export const hasBranchWriteAccess = (authorization: gql.ProjectAuthorization) =>
  authorization === gql.ProjectAuthorization.WRITE ||
  authorization === gql.ProjectAuthorization.ADMIN;
