import React from "react";

import type { CSS, Tokens } from "../../theme";
import { styled } from "../../theme";
import type { IconSize, IconType } from "../Icon";
import { Icon } from "../Icon";
import { Stack } from "../Stack";
import { Text } from "../Text";

export interface BadgeProps {
  icon?: IconType;
  iconColor?: keyof Tokens["colors"];
  iconPosition?: "left" | "right";
  iconSize?: IconSize;
  variant?:
    | "neutral"
    | "accentSecondary"
    | "error"
    | "warning"
    | "accentTrial"
    | "accentPrimary";
  children: React.ReactNode;
  css?: CSS;
  textCss?: CSS;
}

const StyledBadge = styled(Stack, {
  alignItems: "center",
  borderRadius: "9999px",
  height: "$5",
  padding: "0 $2",
  fontSize: "$12",

  background: "$neutral-bg-medium",
  color: "$neutral-fg-subtle",

  variants: {
    variant: {
      neutral: {},
      accentTrial: {
        background: "$accent-trial-base",
        color: "$neutral-fg-high",
      },
      accentSecondary: {
        background: "$accent-secondary-base",
        color: "$neutral-bg-low",
      },
      accentPrimary: {
        background: "$accent-primary-base",
        color: "$neutral-bg-low",
      },
      error: {
        background: "$informative-error-base",
        color: "$neutral-bg-low",
      },
      warning: {
        background: "$informative-warning-base",
        color: "$neutral-bg-low",
      },
    },
  },
});

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      css,
      icon,
      iconPosition = "left",
      iconColor,
      children,
      iconSize = 3,
      variant = "neutral",
      textCss,
    },
    ref,
  ) => {
    return (
      <StyledBadge
        ref={ref}
        className="badge-container"
        css={css}
        gap={1}
        variant={variant}
        horizontal
      >
        {icon && iconPosition === "left" && (
          <Icon css={{ color: `$${iconColor}` }} size={iconSize} type={icon} />
        )}
        <Text
          css={{
            color: "inherit",
            lineHeight: "16px",
            userSelect: "none",
            ...textCss,
          }}
          truncate
        >
          {children}
        </Text>
        {icon && iconPosition === "right" && (
          <Icon css={{ color: `$${iconColor}` }} size={iconSize} type={icon} />
        )}
      </StyledBadge>
    );
  },
);

Badge.toString = () => ".badge";
