import type { CSSProperties } from "@stitches/react";
import type { ComponentProps } from "react";
import React from "react";

import type { space } from "../../tokens/space";
import { Box } from "../Box";

interface StackProps extends ComponentProps<typeof Box> {
  gap?: keyof typeof space;
  horizontal?: boolean;
  selectable?: boolean;
  style?: CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: string | React.ComponentType<any>;
}

/**
 * Support nested selectors
 * https://stitches.dev/docs/styling#target-a-react-component
 */
const COMPONENT_CLASSNAME = "prism-stack";

export const Stack: React.FC<StackProps> = ({
  className,
  css = {},
  children,
  gap = 2,
  horizontal = false,
  selectable = false,
  style,
  as = "div",
  ...rest
}) => {
  return (
    <Box
      // Stitches issue
      // @ts-ignore
      as={as}
      className={`${className ?? ""} ${COMPONENT_CLASSNAME}`}
      css={{
        // Styles which CAN be overrwritten by the `css` prop
        alignItems: horizontal ? "center" : "normal",
        flexDirection: horizontal ? "row" : "column",

        "> *:not(:last-child)": {
          [horizontal ? "marginInlineEnd" : "marginBlockEnd"]: `$${gap}`,
          [horizontal ? "marginRight" : "marginBottom"]: `$${gap}`,
        },

        ...css,

        // Styles which CANNOT be overrwritten by the `css` prop
        display: "flex",
      }}
      selectable={selectable}
      style={style}
      {...rest}
    >
      {children}
    </Box>
  );
};

Stack.toString = () => `.${COMPONENT_CLASSNAME}`;
