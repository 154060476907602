import type { Context, ScopeContext } from "@sentry/types";

export type SentryContext = Partial<ScopeContext>;

export type FeatureErrorContext = Context & {
  event: string;
  state: string;
};

/**
 * Returns an error context in a format that is convenient to send to sentry from a feature:

 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/context/#passing-context-directly
 */
export const toSentryContext = (
  featureName: string,
  featureContext: Context,
  tags: SentryContext["tags"] = {},
): SentryContext => ({
  contexts: {
    feature: featureContext,
  },
  tags: {
    feature: featureName,
    ...tags,
  },
});
