import { Emitter } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../common/AsyncValueStore";
export class CommandClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.commandsUpdatedEmitter = new Emitter();
        this.onCommandsUpdated = this.commandsUpdatedEmitter.event;
        this.commandsErrorEmitter = new Emitter();
        this.onCommandsError = this.commandsErrorEmitter.event;
        this.commands = this.createCommandsValue();
        messageHandler.onNotification("command/changed", ({ commands }) => {
            // Will emit changed event
            this.commands.set(commands);
        });
    }
    createCommandsValue() {
        const commands = new AsyncValueStore([], () => this.messageHandler
            .request({
            method: "command/list",
            params: {},
        })
            .then(({ commands }) => {
            return commands;
        }));
        commands.onChange(({ value }) => this.commandsUpdatedEmitter.fire(value));
        commands.onError((error) => this.commandsErrorEmitter.fire(error));
        return commands;
    }
    getCommands() {
        return this.commands.get();
    }
    resync() {
        return this.commands.refresh();
    }
    async executeCommand(commandId) {
        await this.messageHandler.request({
            method: "command/execute",
            params: { commandId },
        });
    }
}
