import { Trigger as RadixTabTrigger } from "@radix-ui/react-tabs";
import React from "react";
import type { ComponentProps } from "react";

import { styled } from "../../theme";
import { Icon } from "../Icon";
import type { IconType } from "../Icon";

const TAB_ICON_SIZE = 3;

const StyledTabTrigger = styled(RadixTabTrigger, {
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
  gap: "$1",
  color: "$neutral-fg-base",
  transition: "color $fast",

  "&:hover": {
    color: "$neutral-fg-medium",
  },

  "&:focus-visible": {
    outline: "none",
    color: "$neutral-fg-medium",
  },

  "&[data-state='active']": {
    color: "$neutral-fg-high",
  },
});

interface Props extends ComponentProps<typeof StyledTabTrigger> {
  iconLeft?: IconType;
  iconRight?: IconType;
}

export const TabTrigger: React.FC<Props> = ({
  children,
  iconLeft,
  iconRight,
  ...tabProps
}) => {
  return (
    <StyledTabTrigger {...tabProps}>
      {iconRight && <Icon size={TAB_ICON_SIZE} type={iconRight} />}
      <span className="TEXT">{children}</span>
      {iconLeft && <Icon size={TAB_ICON_SIZE} type={iconLeft} />}
    </StyledTabTrigger>
  );
};
