import { Disposable, Emitter } from "@codesandbox/pitcher-common";
import { States } from "class-states";
import { BrowserAiClient } from "./clients/AiClient";
import { BrowserChannelClient } from "./clients/ChannelClient";
import { BrowserClientsClient } from "./clients/ClientsClient";
import { BrowserCommandClient } from "./clients/CommandClient";
import { BrowserContainerClient } from "./clients/ContainerClient";
import { BrowserFSClient } from "./clients/FSClient";
import { FilesState } from "./clients/FilesState";
import { BrowserGitClient } from "./clients/GitClient";
import { BrowserNotificationClient } from "./clients/NotificationClient";
import { BrowserSetupClient } from "./clients/SetupClient";
import { BrowserShellClient } from "./clients/ShellClient";
import { BrowserSystemClient } from "./clients/SystemClient";
import { BrowserTaskClient } from "./clients/TaskClient";
import { getApiUtils } from "./clients/api-utils";
import { BrowserFileClient } from "./clients/file/FileClient";
import { BrowserLanguageClient } from "./clients/language/LanguageClient";
import { BrowserPortClient } from "./clients/port/PortClient";
import { Sandpack } from "./clients/port/Sandpack";
export { PICKER_BROWSER_PACKAGE_MANAGER } from "./clients/TaskClient";
export class PitcherBrowserClient extends Disposable {
    get sandboxUrl() {
        return window.location.host === "codesandbox.io"
            ? `https://${this.sandboxId}.csb.app`
            : `https://${this.sandboxId}.csb.dev`;
    }
    constructor(csbApi, sandbox, filesState, seamlessFork) {
        super();
        this.type = "browser";
        this.bootupType = "CLEAN";
        // This state never really changes as we are in the browser
        this.state = new States({
            state: "CONNECTED",
            connectedAt: Date.now(),
            lastActivity: Date.now(),
            lastFocus: Date.now(),
        });
        this.onStateChange = this.state.onTransition;
        this.onReconnectedEmitter = this.addDisposable(new Emitter());
        this.onReconnected = this.onReconnectedEmitter.event;
        this.onInstanceChangedEmitter = this.addDisposable(new Emitter());
        this.onInstanceChanged = this.onInstanceChangedEmitter.event;
        this.onInstanceChangeRequiredEmitter = this.addDisposable(new Emitter());
        this.onInstanceChangeRequired = this.onInstanceChangeRequiredEmitter.event;
        this.capabilities = {
            client: {
                status: true,
                list: true,
            },
            file: {
                status: true,
                openClose: true,
                openByPath: true,
                save: true,
                ot: true,
                selection: true,
            },
            fs: {
                raw: true,
                read: true,
                operations: true,
                search: true,
                pathSearch: true,
            },
            language: {
                list: true,
                pitcherLsp: true,
            },
        };
        this.pitcherVersion = "browser";
        this.pitcherManagerVersion = "browser";
        this.pitcherProtocolVersion = "browser";
        this.workspacePath = "/nodebox";
        this.userWorkspacePath = "/nodebox";
        this.cluster = "browser";
        this.reconnectToken = null;
        this.filesState = filesState;
        this.seamlessFork = seamlessFork;
        this.sandboxId = sandbox.id;
        this.instanceId = sandbox.id;
        const session = csbApi.session.state.get();
        const user = session.state === "AUTHENTICATED" ? session.user : null;
        const apiUtils = getApiUtils(csbApi, () => this.sandboxId);
        // Most of this stuff is irrelevant...
        this.sessionStartTime = Date.now();
        this.currentClient = this.createCurrentClient(user);
        this.permissions = this.createPermissions(user);
        const onSeamlessFork = (method) => {
            if (!this.seamlessFork) {
                return false;
            }
            this.onInstanceChangeRequiredEmitter.fire(method);
            return true;
        };
        const fsClient = (this.fsClient = new BrowserFSClient(this.filesState, sandbox, apiUtils, onSeamlessFork, () => this.clients.language ?? null));
        this.addDisposable(fsClient);
        const fileClient = (this.fileClient = new BrowserFileClient(fsClient, this.filesState, onSeamlessFork));
        this.addDisposable(fileClient);
        this.sandpack = this.addDisposable(new Sandpack(sandbox, fsClient, fileClient, this.filesState));
        const langClient = this.addDisposable(new BrowserLanguageClient(fsClient, fileClient, sandbox.npmRegistries));
        this.addDisposable(this.onInstanceChanged(async () => {
            const newSandbox = await apiUtils.getSandbox();
            this.sandpack.updateSandbox(newSandbox);
            langClient.updateRegistries(sandbox.npmRegistries);
        }));
        const portClient = (this.portClient = new BrowserPortClient(this.sandpack, this.sandboxUrl));
        this.addDisposable(portClient);
        const clientClient = new BrowserClientsClient(this.currentClient);
        this.addDisposable(clientClient);
        csbApi.session.onSessionChange(({ session }) => {
            const newUser = session.state === "AUTHENTICATED" ? session.user : null;
            this.currentClient = this.createCurrentClient(newUser);
            this.permissions = this.createPermissions(newUser);
            clientClient.updateCurrentClient(this.currentClient);
        });
        this.clients = {
            // Mock clients
            shell: new BrowserShellClient(),
            port: portClient,
            git: new BrowserGitClient(),
            setup: new BrowserSetupClient(),
            ai: new BrowserAiClient(),
            task: new BrowserTaskClient(this.filesState, this.fsClient, this.fileClient),
            system: new BrowserSystemClient(),
            command: new BrowserCommandClient(),
            notification: new BrowserNotificationClient(),
            channel: new BrowserChannelClient(),
            container: new BrowserContainerClient(),
            // Real clients
            fs: fsClient,
            file: fileClient,
            client: clientClient,
            language: langClient,
        };
    }
    createCurrentClient(user) {
        if (user) {
            return {
                appId: "codesandbox",
                avatarUrl: user.avatarUrl,
                clientId: user.username,
                color: "#FFE500",
                name: user.name,
                username: user.username,
            };
        }
        else {
            return {
                appId: "codesandbox",
                avatarUrl: null,
                clientId: "123",
                color: "red",
                name: "Anonymous",
                username: "anonymous",
            };
        }
    }
    createPermissions(user) {
        return user ? this.getWritePermissions() : this.getReadOnlyPermissions();
    }
    getReadOnlyPermissions() {
        return {
            file: {
                open: true,
                documentOperation: false,
                documentSelection: true,
                save: false,
                documentAck: true,
                close: true,
            },
            fs: {
                read: true,
                operation: true,
                search: true,
                pathSearch: true,
                upload: false,
                download: true,
            },
            language: {
                list: true,
                lspRead: true,
                lspWrite: true,
            },
        };
    }
    getWritePermissions() {
        return {
            file: {
                open: true,
                documentOperation: true,
                documentSelection: true,
                save: true,
                documentAck: true,
                close: true,
            },
            fs: {
                read: true,
                operation: true,
                search: true,
                pathSearch: true,
                upload: true,
                download: true,
            },
            language: {
                list: true,
                lspRead: true,
                lspWrite: true,
            },
        };
    }
    reconnect() {
        return Promise.resolve();
    }
    // This is always false for the browser?
    // This should've really used capabilities... not sure what went wrong here
    hasFeature(_feature) {
        return false;
    }
    changeInstance(instanceId) {
        this.sandboxId = instanceId;
        this.instanceId = instanceId;
        this.seamlessFork = false;
        this.fsClient.openSeamlessBranchBarrier();
        this.portClient.changeUrl(this.sandboxUrl);
        this.onInstanceChangedEmitter.fire({ instanceId: this.instanceId });
        return Promise.resolve();
    }
    revertSeamlessFork() {
        this.seamlessFork = true;
        this.fsClient.openSeamlessBranchBarrier();
        this.portClient.changeUrl(this.sandboxUrl);
        this.onInstanceChangedEmitter.fire({ instanceId: this.instanceId });
    }
    toggleSeamlessFork(value) {
        this.seamlessFork = value;
    }
    isUpToDate() {
        return true;
    }
    disconnect() { }
}
export async function initPitcherBrowserClient(csbApi, sandboxId, seamlessFork) {
    const sandbox = await csbApi.rest.sandboxes.get(sandboxId);
    const filesState = new FilesState();
    filesState.populate(sandbox.modules);
    await filesState.downloadBinaryFiles();
    return new PitcherBrowserClient(csbApi, sandbox, filesState, seamlessFork);
}
