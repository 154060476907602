/* eslint-disable no-console, @typescript-eslint/no-explicit-any */

import { safeLocalStorage } from "utils/safeLocalStorage";

const logger = {};

Object.entries(console).forEach(([key, log]) => {
  (logger as any)[key] = (...data: any[]) => {
    const should =
      (global.window
        ? safeLocalStorage.get("CSB_DEBUG") === "ENABLED"
        : null) || key === "error";

    if (should) log(...data);
  };
});

export default logger as Record<keyof typeof console, (...data: any[]) => void>;
