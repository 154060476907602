import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";
import { registerExtension } from "vscode/extensions";

export function initializePoimandresTheme() {
  // eslint-disable-next-line
  const config: any = {
    name: "pmndrs",
    displayName: "poimandres",
    description: "poimandres dark theme",
    version: "0.3.7",
    publisher: "poimandres",
    contributes: {
      themes: [
        {
          label: "poimandres",
          uiTheme: "vs-dark",
          path: "./themes/poimandres-color-theme.json",
        },
      ],
    },
  };

  const { registerFileUrl } = registerExtension(config, 2);
  registerFileUrl(
    "./themes/poimandres-color-theme.json",
    withBasePath(env, "/vscode-extensions/themes/poimandres-color-theme.json"),
  );

  return config.contributes.themes;
}
