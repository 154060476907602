import { opaqueHex, palette } from "../palette";

import type { Tokens } from "./base";

export const light: Partial<Tokens> = {
  colors: {
    // Accent
    "accent-primary-base": palette.lime[4],
    "accent-secondary-base": palette.purple[4],
    "accent-secondary-subtle": palette.purple[5],
    "accent-secondary-high": palette.purple[6],
    "accent-trial-base": palette.purple[4],
    "accent-trial-subtle": palette.purple[5],

    // Background
    "neutral-bg-low": palette.neutral[9],
    "neutral-bg-base": palette.neutral[10],
    "neutral-bg-subtle": palette.neutral[9],
    "neutral-bg-medium": palette.neutral[8],
    "neutral-bg-high": palette.neutral[7],
    "neutral-bg-contrast": palette.neutral[6],

    // Modifiers
    "neutral-bg-focus": opaqueHex(palette.neutral[0], 0.1),
    "neutral-bg-focus_contrast": opaqueHex(palette.neutral[0], 0.1),
    "neutral-bg-base_transparent": opaqueHex(palette.neutral[1], 0),

    // Foreground
    "neutral-fg-low": palette.neutral[4],
    "neutral-fg-base": palette.neutral[3],
    "neutral-fg-subtle": palette.neutral[2],
    "neutral-fg-medium": palette.neutral[1],
    "neutral-fg-high": palette.neutral[0],

    // Support
    "informative-warning-base": palette.yellow[4],
    "informative-alert-base": palette.orange[4],
    "informative-error-base": palette.red[4],
    "informative-error-subtle": palette.red[5],
    "informative-success-base": palette.green[4],
    "informative-info-base": palette.blue[4],

    "support-git-modified": palette.yellow[4],
    "support-git-untracked": palette.green[4],
    "support-git-deleted": palette.red[4],
    "support-git-renamed": palette.neutral[4],
    "support-git-conflict": palette.orange[4],

    // System
    "support-system-terminal": palette.neutral[9],
    "support-system-selection": opaqueHex(palette.purple[5], 0.5),
    "support-system-scrollbar": palette.neutral[6],
  },
};
