import { Emitter, newCuid } from "@codesandbox/pitcher-common";
import { AiChat } from "./AiChat";
import { AiMessageStream } from "./MessageStream";
export class AiClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.chats = new Map();
        this.messageStates = new Map();
        this.messageProgressEmitter = new Emitter();
        this.onMessageProgress = this.messageProgressEmitter.event;
        this.chatsUpdatedEmitter = new Emitter();
        this.onChatsUpdated = this.chatsUpdatedEmitter.event;
        this.chatsErrorEmitter = new Emitter();
        this.onChatsError = this.chatsErrorEmitter.event;
        this.chatCreatedEmitter = new Emitter();
        this.onChatCreated = this.chatCreatedEmitter.event;
        this.chatMessageEmitter = new Emitter();
        this.onChatMessage = this.chatMessageEmitter.event;
        messageHandler.onNotification("ai/messageProgress", ({ messageId, chunk, isFinished }) => {
            const state = this.messageStates.get(messageId) ?? {
                isFinished,
                content: "",
            };
            state.content += chunk;
            state.isFinished = isFinished;
            this.messageStates.set(messageId, state);
            this.messageProgressEmitter.fire(messageId);
        });
        messageHandler.onNotification("ai/chatCreated", (evt) => {
            const chat = new AiChat(evt.chatId, evt.title, true, messageHandler, this);
            chat.entries = evt.entries;
            this.chats.set(chat.chatId, chat);
            this.chatCreatedEmitter.fire(chat);
            this.chatsUpdatedEmitter.fire(this.chats);
        });
        messageHandler.onNotification("ai/chatMessage", (message) => {
            this.chatMessageEmitter.fire(message);
        });
    }
    subscribeToMessage(messageId, callback) {
        const disposable = this.onMessageProgress((evtId) => {
            if (evtId === messageId) {
                const state = this.messageStates.get(messageId);
                if (state) {
                    callback(state.content, state.isFinished);
                }
            }
        });
        const state = this.messageStates.get(messageId);
        if (state) {
            callback(state.content, state.isFinished);
        }
        return disposable;
    }
    fetchChats() {
        this.messageHandler
            .request({
            method: "ai/chats",
            params: {},
        })
            .then((v) => {
            for (const c of v.chats) {
                const existingChat = this.chats.get(c.chatId);
                if (!existingChat) {
                    const chatInstance = new AiChat(c.chatId, c.title, false, this.messageHandler, this);
                    this.chats.set(c.chatId, chatInstance);
                    chatInstance.refresh();
                }
                this.chatsUpdatedEmitter.fire(this.chats);
            }
        })
            .catch((err) => {
            this.chatsErrorEmitter.fire(err.message);
        });
    }
    resync() {
        this.fetchChats();
    }
    suggestCommit(params) {
        if (!params.files.length) {
            throw new Error("Need to provide at least 1 file");
        }
        return this.messageHandler.request({
            method: "ai/suggestCommit",
            params,
        });
    }
    raw(params) {
        return this.messageHandler.request({
            method: "ai/raw",
            params,
        });
    }
    async stream(params) {
        const result = await this.messageHandler.request({
            method: "ai/stream",
            params,
        });
        const stream = new AiMessageStream(result.messageId, this);
        return stream;
    }
    async getChat({ id = newCuid() }) {
        let chat = this.chats.get(id);
        if (chat) {
            return chat;
        }
        chat = new AiChat(id, "New chat", true, this.messageHandler, this);
        this.chats.set(chat.chatId, chat);
        this.chatsUpdatedEmitter.fire(this.chats);
        await chat.getHistory();
        return chat;
    }
}
