import { createClient, createQueryUtils, InlineFragmentSymbol } from '@codesandbox/create-gql-api';
var enums = [
    "COMMENT",
    "NONE",
    "OWNER",
    "READ",
    "WRITE_CODE",
    "WRITE_PROJECT",
    "BASIC",
    "BEARER",
    "ASC",
    "DESC",
    "COLLABORATOR",
    "CONTRIBUTOR",
    "FIRST_TIMER",
    "FIRST_TIME_CONTRIBUTOR",
    "MANNEQUIN",
    "MEMBER",
    "ADMIN",
    "WRITE",
    "MERGE",
    "REBASE",
    "SQUASH",
    "APPROVE",
    "REQUEST_CHANGES",
    "LEFT",
    "RIGHT",
    "FILE",
    "LINE",
    "GITHUB",
    "CREATED",
    "JOINED",
    "SET_DEFAULT_PERMISSION",
    "SET_GUEST_PERMISSION",
    "STARTED",
    "STOPPED",
    "APPLE",
    "GOOGLE",
    "DELETED",
    "EDITED",
    "CLOSED",
    "CONVERTED_TO_DRAFT",
    "OPENED",
    "READY_FOR_REVIEW",
    "REVIEW_REQUEST_REMOVED",
    "REVIEW_REQUESTED",
    "DISMISSED",
    "SUBMITTED",
    "APPROVED",
    "CHANGES_REQUESTED",
    "COMMENTED",
    "CUSTOM",
    "NPM",
    "MONTHLY",
    "YEARLY",
    "LEGACY",
    "PATRON",
    "PILOT",
    "STRIPE",
    "ACTIVE",
    "CANCELLED",
    "INCOMPLETE",
    "INCOMPLETE_EXPIRED",
    "PAUSED",
    "TRIALING",
    "UNKNOWN",
    "UNPAID",
    "PERSONAL_PRO",
    "TEAM_PRO",
    "PERSONAL",
    "TEAM",
    "ORGANIZATION_MEMBER",
    "FULL_NAME",
    "PUSHED",
    "UPDATED"
];
export var Authorization = {
    COMMENT: enums[0],
    NONE: enums[1],
    OWNER: enums[2],
    READ: enums[3],
    WRITE_CODE: enums[4],
    WRITE_PROJECT: enums[5]
};
export var AuthType = {
    BASIC: enums[6],
    BEARER: enums[7]
};
export var Direction = {
    ASC: enums[8],
    DESC: enums[9]
};
export var GitHubAuthorAssociation = {
    COLLABORATOR: enums[10],
    CONTRIBUTOR: enums[11],
    FIRST_TIMER: enums[12],
    FIRST_TIME_CONTRIBUTOR: enums[13],
    MANNEQUIN: enums[14],
    MEMBER: enums[15],
    NONE: enums[1],
    OWNER: enums[2]
};
export var GithubPermission = {
    ADMIN: enums[16],
    NONE: enums[1],
    READ: enums[3],
    WRITE: enums[17]
};
export var GitHubPullRequestMergeMethod = {
    MERGE: enums[18],
    REBASE: enums[19],
    SQUASH: enums[20]
};
export var GitHubPullRequestReviewAction = {
    APPROVE: enums[21],
    COMMENT: enums[0],
    REQUEST_CHANGES: enums[22]
};
export var GitHubPullRequestReviewCommentSide = {
    LEFT: enums[23],
    RIGHT: enums[24]
};
export var GitHubPullRequestReviewCommentSubjectType = {
    FILE: enums[25],
    LINE: enums[26]
};
export var GithubRepoAuthorization = {
    READ: enums[3],
    WRITE: enums[17]
};
export var GitProvider = {
    GITHUB: enums[27]
};
export var InstallationEventAction = {
    CREATED: enums[28]
};
export var LiveSessionEventAction = {
    JOINED: enums[29],
    SET_DEFAULT_PERMISSION: enums[30],
    SET_GUEST_PERMISSION: enums[31],
    STARTED: enums[32],
    STOPPED: enums[33]
};
export var LiveSessionPermission = {
    READ: enums[3],
    WRITE: enums[17]
};
export var ProjectAuthorization = {
    ADMIN: enums[16],
    NONE: enums[1],
    READ: enums[3],
    WRITE: enums[17]
};
export var ProviderName = {
    APPLE: enums[34],
    GITHUB: enums[27],
    GOOGLE: enums[35]
};
export var PullRequestCommentEventAction = {
    CREATED: enums[28],
    DELETED: enums[36],
    EDITED: enums[37]
};
export var PullRequestEventAction = {
    CLOSED: enums[38],
    CONVERTED_TO_DRAFT: enums[39],
    OPENED: enums[40],
    READY_FOR_REVIEW: enums[41],
    REVIEW_REQUEST_REMOVED: enums[42],
    REVIEW_REQUESTED: enums[43]
};
export var PullRequestReviewCommentEventAction = {
    CREATED: enums[28],
    DELETED: enums[36],
    EDITED: enums[37]
};
export var PullRequestReviewEventAction = {
    DISMISSED: enums[44],
    EDITED: enums[37],
    SUBMITTED: enums[45]
};
export var PullRequestReviewState = {
    APPROVED: enums[46],
    CHANGES_REQUESTED: enums[47],
    COMMENTED: enums[48],
    DISMISSED: enums[44]
};
export var RegistryType = {
    CUSTOM: enums[49],
    GITHUB: enums[27],
    NPM: enums[50]
};
export var SubscriptionInterval = {
    MONTHLY: enums[51],
    YEARLY: enums[52]
};
export var SubscriptionOrigin = {
    LEGACY: enums[53],
    PATRON: enums[54],
    PILOT: enums[55]
};
export var SubscriptionPaymentProvider = {
    STRIPE: enums[56]
};
export var SubscriptionStatus = {
    ACTIVE: enums[57],
    CANCELLED: enums[58],
    INCOMPLETE: enums[59],
    INCOMPLETE_EXPIRED: enums[60],
    PAUSED: enums[61],
    TRIALING: enums[62],
    UNKNOWN: enums[63],
    UNPAID: enums[64]
};
export var SubscriptionType = {
    PERSONAL_PRO: enums[65],
    TEAM_PRO: enums[66]
};
export var TeamMemberAuthorization = {
    ADMIN: enums[16],
    READ: enums[3],
    WRITE: enums[17]
};
export var TeamType = {
    PERSONAL: enums[67],
    TEAM: enums[68]
};
export var UserRepoAffiliation = {
    COLLABORATOR: enums[10],
    ORGANIZATION_MEMBER: enums[69],
    OWNER: enums[2]
};
export var UserRepoSort = {
    CREATED: enums[28],
    FULL_NAME: enums[70],
    PUSHED: enums[71],
    UPDATED: enums[72]
};
var _a = createQueryUtils(enums, {
    "collection": {
        "path": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        }
    },
    "collections": {
        "teamId": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        }
    },
    "githubRepos": {
        "affiliation": {
            "isNonNull": true,
            "type": "UserRepoAffiliation",
            "isList": true
        },
        "page": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "perPage": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "sort": {
            "isNonNull": false,
            "type": "UserRepoSort",
            "isList": false
        }
    },
    "notifications": {
        "limit": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "orderBy": {
            "isNonNull": false,
            "type": "OrderBy",
            "isList": false
        },
        "type": {
            "isNonNull": false,
            "type": "String",
            "isList": true
        }
    },
    "recentBranches": {
        "contribution": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "limit": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "recentProjects": {
        "limit": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        }
    },
    "recentlyAccessedSandboxes": {
        "limit": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "recentlyUsedTemplates": {
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "sandboxes": {
        "hasOriginalGit": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "limit": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "orderBy": {
            "isNonNull": false,
            "type": "OrderBy",
            "isList": false
        },
        "showDeleted": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "alwaysOn": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "authorId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "team": {
        "id": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "templates": {
        "showAll": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "baseGitSandboxes": {
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "originalGitSandboxes": {
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "branchByName": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "branch": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        },
        "team": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        }
    },
    "acceptTeamInvitation": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "addCollaborator": {
        "authorization": {
            "isNonNull": true,
            "type": "Authorization",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "username": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "addSandboxesToAlbum": {
        "albumId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "addToCollection": {
        "collectionPath": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "privacy": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "sandboxIds": {
            "isNonNull": false,
            "type": "ID",
            "isList": true
        },
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "addToCollectionOrTeam": {
        "collectionPath": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "privacy": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "sandboxIds": {
            "isNonNull": false,
            "type": "ID",
            "isList": true
        },
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "archiveNotification": {
        "notificationId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "bookmarkTemplate": {
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        },
        "templateId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "changeCollaboratorAuthorization": {
        "authorization": {
            "isNonNull": true,
            "type": "Authorization",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "username": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "changeSandboxInvitationAuthorization": {
        "authorization": {
            "isNonNull": true,
            "type": "Authorization",
            "isList": false
        },
        "invitationId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "changeTeamMemberAuthorizations": {
        "memberAuthorizations": {
            "isNonNull": true,
            "type": "MemberAuthorization",
            "isList": true
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "convertToUsageBilling": {
        "addons": {
            "isNonNull": true,
            "type": "String",
            "isList": true
        },
        "billingInterval": {
            "isNonNull": false,
            "type": "SubscriptionInterval",
            "isList": false
        },
        "plan": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "createAlbum": {
        "description": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "title": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "createBranch": {
        "branch": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "from": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        },
        "team": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "createCodeComment": {
        "anchorReference": {
            "isNonNull": true,
            "type": "CodeReference",
            "isList": false
        },
        "codeReferences": {
            "isNonNull": true,
            "type": "CodeReference",
            "isList": true
        },
        "content": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "id": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        },
        "imageReferences": {
            "isNonNull": true,
            "type": "ImageReference",
            "isList": true
        },
        "parentCommentId": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "userReferences": {
            "isNonNull": true,
            "type": "UserReference",
            "isList": true
        }
    },
    "createCollection": {
        "path": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "createComment": {
        "codeReferences": {
            "isNonNull": true,
            "type": "CodeReference",
            "isList": true
        },
        "content": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "id": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        },
        "imageReferences": {
            "isNonNull": true,
            "type": "ImageReference",
            "isList": true
        },
        "parentCommentId": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "userReferences": {
            "isNonNull": true,
            "type": "UserReference",
            "isList": true
        }
    },
    "createContributionBranch": {
        "from": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        }
    },
    "createGithubPullRequestReview": {
        "body": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "comments": {
            "isNonNull": true,
            "type": "GithubPullRequestReviewCommentInput",
            "isList": true
        },
        "commitId": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "event": {
            "isNonNull": true,
            "type": "GitHubPullRequestReviewAction",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "pullRequestNumber": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        }
    },
    "createOrUpdatePrivateNpmRegistry": {
        "authType": {
            "isNonNull": false,
            "type": "AuthType",
            "isList": false
        },
        "enabledScopes": {
            "isNonNull": true,
            "type": "String",
            "isList": true
        },
        "limitToScopes": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "proxyEnabled": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "registryAuthKey": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "registryType": {
            "isNonNull": true,
            "type": "RegistryType",
            "isList": false
        },
        "registryUrl": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "sandpackTrustedDomains": {
            "isNonNull": true,
            "type": "String",
            "isList": true
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "createPreviewComment": {
        "anchorReference": {
            "isNonNull": true,
            "type": "PreviewReference",
            "isList": false
        },
        "codeReferences": {
            "isNonNull": true,
            "type": "CodeReference",
            "isList": true
        },
        "content": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "id": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        },
        "imageReferences": {
            "isNonNull": true,
            "type": "ImageReference",
            "isList": true
        },
        "parentCommentId": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "userReferences": {
            "isNonNull": true,
            "type": "UserReference",
            "isList": true
        }
    },
    "createSandboxInvitation": {
        "authorization": {
            "isNonNull": true,
            "type": "Authorization",
            "isList": false
        },
        "email": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "createTeam": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "pilot": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        }
    },
    "deleteAlbum": {
        "id": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "deleteBranch": {
        "id": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "deleteCollection": {
        "path": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "deleteComment": {
        "commentId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "deleteCurrentUser": {
        "confirm": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        }
    },
    "deleteGithubPullRequestReviewComment": {
        "commentId": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "deletePrivateNpmRegistry": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "deleteProject": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        },
        "team": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "deleteProjectById": {
        "id": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "deleteSandboxes": {
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "deleteWorkspace": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "dismissGithubPullRequestReview": {
        "message": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "pullRequestNumber": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        },
        "pullRequestReviewId": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        }
    },
    "importBranch": {
        "branch": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        },
        "team": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "importProject": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        },
        "team": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "importReadOnlyBranch": {
        "branch": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        }
    },
    "importReadOnlyProject": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        }
    },
    "incrementSandboxVersion": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "inviteToTeam": {
        "authorization": {
            "isNonNull": false,
            "type": "TeamMemberAuthorization",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "username": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "inviteToTeamViaEmail": {
        "authorization": {
            "isNonNull": false,
            "type": "TeamMemberAuthorization",
            "isList": false
        },
        "email": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "joinEligibleWorkspace": {
        "workspaceId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "joinLiveSession": {
        "id": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "joinUsageBillingBeta": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "leaveTeam": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "makeSandboxesTemplates": {
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "markNotificationAsRead": {
        "notificationId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "mergeGithubPullRequest": {
        "mergeMethod": {
            "isNonNull": false,
            "type": "GitHubPullRequestMergeMethod",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "pullRequestNumber": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        }
    },
    "permanentlyDeleteSandboxes": {
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "previewConvertToUsageBilling": {
        "addons": {
            "isNonNull": true,
            "type": "String",
            "isList": true
        },
        "billingInterval": {
            "isNonNull": false,
            "type": "SubscriptionInterval",
            "isList": false
        },
        "plan": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "previewUpdateSubscriptionBillingInterval": {
        "billingInterval": {
            "isNonNull": true,
            "type": "SubscriptionInterval",
            "isList": false
        },
        "subscriptionId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "reactivateSubscription": {
        "subscriptionId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "redeemSandboxInvitation": {
        "invitationToken": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "redeemTeamInviteToken": {
        "inviteToken": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "rejectTeamInvitation": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "removeCollaborator": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "username": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "removeFromTeam": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "userId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "removeRequestedGithubPullRequestReviewers": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "pullRequestNumber": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        },
        "reviewers": {
            "isNonNull": true,
            "type": "String",
            "isList": true
        }
    },
    "removeSandboxesFromAlbum": {
        "albumId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "renameCollection": {
        "newPath": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "newTeamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        },
        "path": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        }
    },
    "renameSandbox": {
        "id": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "title": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "replyToGithubPullRequestReview": {
        "body": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "commentId": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "pullRequestNumber": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        }
    },
    "requestGithubPullRequestReviewers": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "pullRequestNumber": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        },
        "reviewers": {
            "isNonNull": true,
            "type": "String",
            "isList": true
        }
    },
    "requestTeamInvitation": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "resolveComment": {
        "commentId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "revokeSandboxInvitation": {
        "invitationId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "revokeTeamInvitation": {
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "userId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "setBranchProtection": {
        "branchId": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "protected": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        }
    },
    "setDefaultTeamMemberAuthorization": {
        "defaultAuthorization": {
            "isNonNull": true,
            "type": "TeamMemberAuthorization",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "setLiveSessionDefaultPermission": {
        "permission": {
            "isNonNull": true,
            "type": "LiveSessionPermission",
            "isList": false
        },
        "vmId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "setLiveSessionGuestPermission": {
        "permission": {
            "isNonNull": true,
            "type": "LiveSessionPermission",
            "isList": false
        },
        "userId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "vmId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "setPreventSandboxesExport": {
        "preventSandboxExport": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "setPreventSandboxesLeavingWorkspace": {
        "preventSandboxLeaving": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "setPrimaryWorkspace": {
        "primaryWorkspaceId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "setSandboxesFrozen": {
        "isFrozen": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "setSandboxesPrivacy": {
        "privacy": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "setTeamAiConsent": {
        "privateRepositories": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "privateSandboxes": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "publicRepositories": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "publicSandboxes": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "setTeamDescription": {
        "description": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "setTeamLimits": {
        "onDemandSpendingLimit": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "setTeamMetadata": {
        "metadata": {
            "isNonNull": true,
            "type": "TeamMetadataInput",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "setTeamMinimumPrivacy": {
        "minimumPrivacy": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "updateDrafts": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        }
    },
    "setTeamName": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "setWorkspaceSandboxSettings": {
        "preventSandboxExport": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "preventSandboxLeaving": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "softCancelSubscription": {
        "subscriptionId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "startLiveSession": {
        "defaultPermission": {
            "isNonNull": true,
            "type": "LiveSessionPermission",
            "isList": false
        },
        "vmId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "stopLiveSession": {
        "vmId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "unbookmarkTemplate": {
        "teamId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        },
        "templateId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "unmakeSandboxesTemplates": {
        "sandboxIds": {
            "isNonNull": true,
            "type": "ID",
            "isList": true
        }
    },
    "unresolveComment": {
        "commentId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "updateAlbum": {
        "description": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "id": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "title": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        }
    },
    "updateComment": {
        "codeReferences": {
            "isNonNull": true,
            "type": "CodeReference",
            "isList": true
        },
        "commentId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "content": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "imageReferences": {
            "isNonNull": true,
            "type": "ImageReference",
            "isList": true
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        },
        "userReferences": {
            "isNonNull": true,
            "type": "UserReference",
            "isList": true
        }
    },
    "updateCurrentUser": {
        "bio": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "name": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "socialLinks": {
            "isNonNull": true,
            "type": "String",
            "isList": true
        },
        "username": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "updateGithubPullRequestReview": {
        "body": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "pullRequestNumber": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        },
        "pullRequestReviewId": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        }
    },
    "updateGithubPullRequestReviewComment": {
        "body": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "commentId": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        },
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "updateNotificationPreferences": {
        "emailCommentMention": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "emailCommentReply": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "emailMarketing": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "emailNewComment": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "emailSandboxInvite": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "emailTeamInvite": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "emailTeamRequest": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "inAppPrReviewReceived": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "inAppPrReviewRequest": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        }
    },
    "updateNotificationReadStatus": {
        "notificationId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "read": {
            "isNonNull": true,
            "type": "Boolean",
            "isList": false
        }
    },
    "updateProjectSettings": {
        "aiConsent": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "projectId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "updateProjectVmTier": {
        "branchId": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "projectId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "vmTier": {
            "isNonNull": true,
            "type": "Int",
            "isList": false
        }
    },
    "updateSandboxSettings": {
        "aiConsent": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        },
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "updateSubscription": {
        "quantity": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "subscriptionId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "updateSubscriptionBillingInterval": {
        "billingInterval": {
            "isNonNull": true,
            "type": "SubscriptionInterval",
            "isList": false
        },
        "subscriptionId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "updateUsageSubscription": {
        "addons": {
            "isNonNull": true,
            "type": "String",
            "isList": true
        },
        "teamId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "album": {
        "albumId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "albums": {
        "username": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "branchById": {
        "id": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "getLiveSession": {
        "vmId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "git": {
        "branch": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "path": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "username": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "githubOrganizationRepos": {
        "organization": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "page": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "perPage": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "sort": {
            "isNonNull": false,
            "type": "UserRepoSort",
            "isList": false
        }
    },
    "githubRepo": {
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "project": {
        "gitProvider": {
            "isNonNull": false,
            "type": "GitProvider",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "team": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        }
    },
    "projects": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        },
        "syncData": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        }
    },
    "recentTeamsByRepository": {
        "name": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "provider": {
            "isNonNull": true,
            "type": "GitProvider",
            "isList": false
        }
    },
    "sandbox": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "sandboxEligibleWorkspace": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "teamByToken": {
        "inviteToken": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "branchEvents": {
        "branchName": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "collaboratorAdded": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "collaboratorChanged": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "collaboratorRemoved": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "commentAdded": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "commentChanged": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "commentRemoved": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "githubEvents": {
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "invitationChanged": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "invitationCreated": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "invitationRemoved": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "liveSessionEvents": {
        "vmId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "projectCommits": {
        "branchId": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "projectConnections": {
        "branchId": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "projectEvents": {
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "projectStatus": {
        "branchId": {
            "isNonNull": false,
            "type": "String",
            "isList": false
        },
        "owner": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        },
        "repo": {
            "isNonNull": true,
            "type": "String",
            "isList": false
        }
    },
    "sandboxChanged": {
        "sandboxId": {
            "isNonNull": true,
            "type": "ID",
            "isList": false
        }
    },
    "teamEvents": {
        "teamId": {
            "isNonNull": false,
            "type": "ID",
            "isList": false
        }
    },
    "comment": {
        "commentId": {
            "isNonNull": true,
            "type": "UUID4",
            "isList": false
        }
    },
    "drafts": {
        "authorId": {
            "isNonNull": false,
            "type": "UUID4",
            "isList": false
        },
        "limit": {
            "isNonNull": false,
            "type": "Int",
            "isList": false
        },
        "orderBy": {
            "isNonNull": false,
            "type": "OrderBy",
            "isList": false
        }
    },
    "subscription": {
        "includeCancelled": {
            "isNonNull": false,
            "type": "Boolean",
            "isList": false
        }
    }
}), createQuery = _a.createQuery, createMutation = _a.createMutation, createSubscription = _a.createSubscription;
var createFragment = function (fragment) { return fragment; };
export var fragments = new Proxy({}, {
    get: function () {
        return createFragment;
    }
});
var createInlineFragment = function (type, fragment) {
    var _a;
    return Object.assign(fragment, (_a = {}, _a[InlineFragmentSymbol] = type, _a));
};
export var inlineFragments = new Proxy({}, {
    get: function (target, prop) {
        return createInlineFragment.bind(target, prop);
    }
});
export { createQuery, createMutation, createSubscription, createClient };
