import { Emitter } from "@codesandbox/pitcher-common";
export class BrowserCommandClient {
    constructor() {
        this.commandsUpdatedEmitter = new Emitter();
        this.onCommandsUpdated = this.commandsUpdatedEmitter.event;
        this.commandsErrorEmitter = new Emitter();
        this.onCommandsError = this.commandsErrorEmitter.event;
    }
    getCommands() {
        return [];
    }
    resync() {
        return;
    }
    executeCommand() {
        return Promise.reject(new Error("Not implemented"));
    }
}
