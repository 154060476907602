import { Disposable } from "@codesandbox/pitcher-common";
import { States } from "class-states";
import { Channel } from "./Channel";
/**
 * Allows to send commands to clients of the same user, guarantees message is sent to the host
 */
export class CommandChannel extends Disposable {
    constructor({ name, currentClient, clientClient, messageHandler, }) {
        super();
        this.state = new States({
            state: "SOLO",
        });
        this.name = name;
        this.hostChannel = new Channel(`${name}-${currentClient.clientId}`, messageHandler);
        this.hostChannel.subscribe();
        this.messageHandler = messageHandler;
        this.currentClient = currentClient;
        this.addDisposable(clientClient.onClientsUpdated((clients) => this.updateChannels(clients)));
        this.updateChannels(clientClient.getClients());
        this.onWillDispose(() => {
            this.hostChannel.dispose();
            this.state.match({
                MULTIPLAYER: ({ channels }) => {
                    for (const clientId in channels) {
                        channels[clientId]?.dispose();
                    }
                },
                SOLO: () => { },
            });
        });
    }
    updateChannels(clients) {
        const clientIdsOfUser = clients
            .filter((client) => client.clientId !== this.currentClient.clientId &&
            client.username &&
            client.username === this.currentClient.username)
            .map((client) => client.clientId);
        const channels = this.state.match({
            SOLO: () => this.state.set({
                state: "MULTIPLAYER",
                channels: {},
            }).channels,
            MULTIPLAYER: ({ channels }) => channels,
        });
        // We just mutate the channels as this value is only used when sending
        // messages, consuming client does not need an event when it changes
        // Add new channels
        clientIdsOfUser.forEach((clientId) => {
            if (!channels[clientId]) {
                const channel = new Channel(`${this.name}-${clientId}`, this.messageHandler);
                channels[clientId] = channel;
                channel.subscribe();
            }
        });
        // Remove unused channels
        for (const clientId in channels) {
            const channel = channels[clientId];
            if (channel && !clientIdsOfUser.includes(clientId)) {
                channel.dispose();
                delete channels[clientId];
            }
        }
    }
    /**
     * Messages from your other clients
     */
    onMessage(cb) {
        return this.hostChannel.onMessage((message) => {
            if (message.isUser) {
                cb(message);
            }
        });
    }
    /**
     * Send to your other clients
     */
    send(message) {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        this.state.match({
            MULTIPLAYER: ({ channels }) => {
                const channelValues = Object.values(channels);
                channelValues.forEach((channel) => channel.sendAll(message));
            },
            SOLO: () => { },
        });
    }
    resync() {
        this.hostChannel.resync();
    }
}
