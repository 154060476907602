export const errnos: Record<number, { code: string; message: string }> = {
  1: { code: "EPERM", message: "Operation not permitted" },
  2: { code: "ENOENT", message: "No such file or directory" },
  3: { code: "ESRCH", message: "No such process" },
  4: { code: "EINTR", message: "Interrupted system call" },
  5: { code: "EIO", message: "Input/output error" },
  6: { code: "ENXIO", message: "No such device or address" },
  7: { code: "E2BIG", message: "Argument list too long" },
  8: { code: "ENOEXEC", message: "Exec format error" },
  9: { code: "EBADF", message: "Bad file descriptor" },
  10: { code: "ECHILD", message: "No child processes" },
  11: { code: "EAGAIN", message: "Resource temporarily unavailable" },
  12: { code: "ENOMEM", message: "Cannot allocate memory" },
  13: { code: "EACCES", message: "Permission denied" },
  14: { code: "EFAULT", message: "Bad address" },
  15: { code: "ENOTBLK", message: "Block device required" },
  16: { code: "EBUSY", message: "Device or resource busy" },
  17: { code: "EEXIST", message: "File exists" },
  18: { code: "EXDEV", message: "Invalid cross-device link" },
  19: { code: "ENODEV", message: "No such device" },
  20: { code: "ENOTDIR", message: "Not a directory" },
  21: { code: "EISDIR", message: "Is a directory" },
  22: { code: "EINVAL", message: "Invalid argument" },
  23: { code: "ENFILE", message: "Too many open files in system" },
  24: { code: "EMFILE", message: "Too many open files" },
  25: { code: "ENOTTY", message: "Inappropriate ioctl for device" },
  26: { code: "ETXTBSY", message: "Text file busy" },
  27: { code: "EFBIG", message: "File too large" },
  28: { code: "ENOSPC", message: "No space left on device" },
  29: { code: "ESPIPE", message: "Illegal seek" },
  30: { code: "EROFS", message: "Read-only file system" },
  31: { code: "EMLINK", message: "Too many links" },
  32: { code: "EPIPE", message: "Broken pipe" },
  33: { code: "EDOM", message: "Numerical argument out of domain" },
  34: { code: "ERANGE", message: "Numerical result out of range" },
  35: { code: "EDEADLOCK", message: "Resource deadlock avoided" },
  36: { code: "ENAMETOOLONG", message: "File name too long" },
  37: { code: "ENOLCK", message: "No locks available" },
  38: { code: "ENOSYS", message: "Function not implemented" },
  39: { code: "ENOTEMPTY", message: "Directory not empty" },
  40: { code: "ELOOP", message: "Too many levels of symbolic links" },
  42: { code: "ENOMSG", message: "No message of desired type" },
  43: { code: "EIDRM", message: "Identifier removed" },
  44: { code: "ECHRNG", message: "Channel number out of range" },
  45: { code: "EL2NSYNC", message: "Level 2 not synchronized" },
  46: { code: "EL3HLT", message: "Level 3 halted" },
  47: { code: "EL3RST", message: "Level 3 reset" },
  48: { code: "ELNRNG", message: "Link number out of range" },
  49: { code: "EUNATCH", message: "Protocol driver not attached" },
  50: { code: "ENOCSI", message: "No CSI structure available" },
  51: { code: "EL2HLT", message: "Level 2 halted" },
  52: { code: "EBADE", message: "Invalid exchange" },
  53: { code: "EBADR", message: "Invalid request descriptor" },
  54: { code: "EXFULL", message: "Exchange full" },
  55: { code: "ENOANO", message: "No anode" },
  56: { code: "EBADRQC", message: "Invalid request code" },
  57: { code: "EBADSLT", message: "Invalid slot" },
  59: { code: "EBFONT", message: "Bad font file format" },
  60: { code: "ENOSTR", message: "Device not a stream" },
  61: { code: "ENODATA", message: "No data available" },
  62: { code: "ETIME", message: "Timer expired" },
  63: { code: "ENOSR", message: "Out of streams resources" },
  64: { code: "ENONET", message: "Machine is not on the network" },
  65: { code: "ENOPKG", message: "Package not installed" },
  66: { code: "EREMOTE", message: "Object is remote" },
  67: { code: "ENOLINK", message: "Link has been severed" },
  68: { code: "EADV", message: "Advertise error" },
  69: { code: "ESRMNT", message: "Srmount error" },
  70: { code: "ECOMM", message: "Communication error on send" },
  71: { code: "EPROTO", message: "Protocol error" },
  72: { code: "EMULTIHOP", message: "Multihop attempted" },
  73: { code: "EDOTDOT", message: "RFS specific error" },
  74: { code: "EBADMSG", message: "Bad message" },
  75: { code: "EOVERFLOW", message: "Value too large for defined data type" },
  76: { code: "ENOTUNIQ", message: "Name not unique on network" },
  77: { code: "EBADFD", message: "File descriptor in bad state" },
  78: { code: "EREMCHG", message: "Remote address changed" },
  79: { code: "ELIBACC", message: "Can not access a needed shared library" },
  80: { code: "ELIBBAD", message: "Accessing a corrupted shared library" },
  81: { code: "ELIBSCN", message: ".lib section in a.out corrupted" },
  82: {
    code: "ELIBMAX",
    message: "Attempting to link in too many shared libraries",
  },
  83: { code: "ELIBEXEC", message: "Cannot exec a shared library directly" },
  84: {
    code: "EILSEQ",
    message: "Invalid or incomplete multibyte or wide character",
  },
  85: {
    code: "ERESTART",
    message: "Interrupted system call should be restarted",
  },
  86: { code: "ESTRPIPE", message: "Streams pipe error" },
  87: { code: "EUSERS", message: "Too many users" },
  88: { code: "ENOTSOCK", message: "Socket operation on non-socket" },
  89: { code: "EDESTADDRREQ", message: "Destination address required" },
  90: { code: "EMSGSIZE", message: "Message too long" },
  91: { code: "EPROTOTYPE", message: "Protocol wrong type for socket" },
  92: { code: "ENOPROTOOPT", message: "Protocol not available" },
  93: { code: "EPROTONOSUPPORT", message: "Protocol not supported" },
  94: { code: "ESOCKTNOSUPPORT", message: "Socket type not supported" },
  95: { code: "ENOTSUP", message: "Operation not supported" },
  96: { code: "EPFNOSUPPORT", message: "Protocol family not supported" },
  97: {
    code: "EAFNOSUPPORT",
    message: "Address family not supported by protocol",
  },
  98: { code: "EADDRINUSE", message: "Address already in use" },
  99: { code: "EADDRNOTAVAIL", message: "Cannot assign requested address" },
  100: { code: "ENETDOWN", message: "Network is down" },
  101: { code: "ENETUNREACH", message: "Network is unreachable" },
  102: { code: "ENETRESET", message: "Network dropped connection on reset" },
  103: { code: "ECONNABORTED", message: "Software caused connection abort" },
  104: { code: "ECONNRESET", message: "Connection reset by peer" },
  105: { code: "ENOBUFS", message: "No buffer space available" },
  106: {
    code: "EISCONN",
    message: "Transport endpoint is already connected",
  },
  107: { code: "ENOTCONN", message: "Transport endpoint is not connected" },
  108: {
    code: "ESHUTDOWN",
    message: "Cannot send after transport endpoint shutdown",
  },
  109: {
    code: "ETOOMANYREFS",
    message: "Too many references: cannot splice",
  },
  110: { code: "ETIMEDOUT", message: "Connection timed out" },
  111: { code: "ECONNREFUSED", message: "Connection refused" },
  112: { code: "EHOSTDOWN", message: "Host is down" },
  113: { code: "EHOSTUNREACH", message: "No route to host" },
  114: { code: "EALREADY", message: "Operation already in progress" },
  115: { code: "EINPROGRESS", message: "Operation now in progress" },
  116: { code: "ESTALE", message: "Stale file handle" },
  117: { code: "EUCLEAN", message: "Structure needs cleaning" },
  118: { code: "ENOTNAM", message: "Not a XENIX named type file" },
  119: { code: "ENAVAIL", message: "No XENIX semaphores available" },
  120: { code: "EISNAM", message: "Is a named type file" },
  121: { code: "EREMOTEIO", message: "Remote I/O error" },
  122: { code: "EDQUOT", message: "Disk quota exceeded" },
  123: { code: "ENOMEDIUM", message: "No medium found" },
  124: { code: "EMEDIUMTYPE", message: "Wrong medium type" },
  125: { code: "ECANCELED", message: "Operation canceled" },
  126: { code: "ENOKEY", message: "Required key not available" },
  127: { code: "EKEYEXPIRED", message: "Key has expired" },
  128: { code: "EKEYREVOKED", message: "Key has been revoked" },
  129: { code: "EKEYREJECTED", message: "Key was rejected by service" },
  130: { code: "EOWNERDEAD", message: "Owner died" },
  131: { code: "ENOTRECOVERABLE", message: "State not recoverable" },
  132: { code: "ERFKILL", message: "Operation not possible due to RF-kill" },
  133: { code: "EHWPOISON", message: "Memory page has hardware error" },
};
