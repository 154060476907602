import { useEffect, useState } from "react";

import * as serviceWorker from "../registerEditorServiceWorker";

export function useServiceWorker() {
  const [lastMessage, setLastMessage] = useState(
    serviceWorker.getLastServiceWorkerMessage(),
  );

  useEffect(() => serviceWorker.onUpdate(setLastMessage), []);

  return lastMessage;
}
