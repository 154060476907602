import { transition } from "@codesandbox/states";

import type { State, Action } from "./types";

export const reducer = (prevState: State, action: Action) =>
  transition(prevState, action, {
    READY: {
      "PITCHER:SET_BRANCH_NAME": (state) => ({ ...state }),
      "PITCHER:BRANCH_RENAME_FINISHED": (state) => ({ ...state }),
      "PITCHER:BRANCH_RENAME_ERROR": (state) => ({ ...state }),
      "PITCHER:BRANCH_RENAME_TAKEN_ERROR": (state) => ({ ...state }),
      RESTART_CONTAINER: (state) => ({
        ...state,
        state: "RESTARTING",
      }),
      "PITCHER:DISCONNECTED": (state, { wasClean, wasNotFound }) => {
        return {
          ...state,
          wasCleanDisconnect: wasClean,
          wasNotFound,
          state: "DISCONNECTED",
        };
      },
      "PITCHER:RECONNECTING": (state, { attempt }) => ({
        ...state,
        reconnectAttempts: attempt,
        state: "RECONNECTING",
      }),
      "PITCHER:CLIENTS:CLIENT_PERMISSIONS_UPDATED": (state) => ({
        ...state,
        state: "READY",
      }),
      "PITCHER:VERSIONS:FETCH_SUCCESS": (state) => ({ ...state }),
      "PITCHER:CONNECTION_CHANGED_SUCCESS": (state) => ({
        ...state,
        state: "READY",
      }),
      "PITCHER:CONNECTION_CHANGED_ERROR": (state) => ({
        ...state,
        state: "READY",
      }),
      "API:CREATE_SEAMLESS_INSTANCE_SUCCESS": (state) => ({
        ...state,
      }),
      "API:CREATE_SEAMLESS_INSTANCE_ERROR": (state) => ({
        ...state,
      }),
    },
    // We only end up in this state if we have tried to reconnect X number of
    // times
    DISCONNECTED: {
      RESTART_CONTAINER: (state) => ({
        ...state,
        state: "RESTARTING",
      }),
      "PITCHER:CONNECTED": (state, { versions }) => {
        const latestVersions = {};

        return {
          ...state,
          versions,

          latestVersions,
          state: "READY",
        };
      },
      "PITCHER:RECONNECTING": (state, { attempt }) => ({
        ...state,
        reconnectAttempts: attempt,
        state: "DISCONNECTED_RECONNECTING",
      }),
    },
    DISCONNECTED_RECONNECTING: {
      "PITCHER:CONNECTED": (state, { versions }) => {
        const latestVersions = {};

        return {
          ...state,
          versions,
          latestVersions,
          state: "READY",
        };
      },
      "PITCHER:DISCONNECTED": (state, { wasClean, wasNotFound }) => ({
        ...state,
        wasCleanDisconnect: wasClean,
        wasNotFound,
        state: "DISCONNECTED",
      }),
      "PITCHER:RECONNECTING": (state, { attempt }) => ({
        ...state,
        reconnectAttempts: attempt,
        state: "DISCONNECTED_RECONNECTING",
      }),
    },
    RECONNECTING: {
      "PITCHER:DISCONNECTED": (state, { wasClean, wasNotFound }) => {
        return {
          ...state,
          wasCleanDisconnect: wasClean,
          wasNotFound,
          state: "DISCONNECTED",
        };
      },
      "PITCHER:CONNECTED": (state, { versions }): State => {
        return {
          ...state,
          versions,
          state: "READY",
        };
      },
      "PITCHER:RECONNECTING": (state, { attempt }) => ({
        ...state,
        reconnectAttempts: attempt,
      }),
    },
    RESTARTING: {
      // It was probably already stopped
      "PITCHER:STOP_ERROR": (state) => ({
        ...state,
        state: "RESTARTING",
        reconnectAttempts: 0,
      }),
      "PITCHER:STOP_SUCCESS": (state) => ({
        ...state,
        state: "RESTARTING",
        reconnectAttempts: 0,
      }),
    },
    ERROR: {
      RESTART_CONTAINER: (state) => ({
        ...state,
        cluster: "",
        startedAt: Date.now(),
        versions: {},
        state: "RESTARTING",
      }),
    },
  });
