import { useId } from "@radix-ui/react-id";
import React from "react";

import { styled } from "../../theme";

const Content = styled("div", {
  px: "$4",
  py: "$3",

  backgroundColor: "$neutral-bg-contrast",

  color: "$neutral-bg-base",
  border: 0,

  truncateText: true,

  variants: {
    variant: {
      info: {
        background: "$neutral-bg-contrast",
      },
      error: {
        background: "$informative-error-base",
      },
      warning: {
        background: "$informative-warning-base",
      },
      success: {
        background: "$informative-success-base",
      },
    },
  },
});

const Wrapper = styled("div", {
  display: "inline-flex",
  boxShadow: "$popup",
  borderRadius: "$2",

  [`${Content}:first-child`]: {
    borderTopLeftRadius: "$2",
    borderBottomLeftRadius: "$2",
  },
  [`${Content}:last-child`]: {
    borderTopRightRadius: "$2",
    borderBottomRightRadius: "$2",
  },
});

const Button = styled(Content, {
  borderLeft: "1px solid $neutral-bg-focus",
  transition: "$fast",
  overflow: "visible",
  // button resets for Safari
  margin: 0,
  fontSize: "$base",
  // TODO: This is not a proper button
  cursor: "var(--csb-cursor)",

  "&:hover": {
    opacity: 0.9,
  },
});

export type ToastVariant = "info" | "warning" | "error" | "success";

export type ToastAction = {
  text: string;
  onAction?: () => void;
};

export interface ToastProps {
  children: React.ReactNode;
  variant?: ToastVariant;
  actions?: ToastAction[];
}

export const Toast: React.FC<ToastProps> = ({
  actions,
  variant = "info",
  children,
}) => {
  const toastId = useId();

  return (
    <Wrapper
      // Ideally, since this might have an action that users can perform
      // the role should be an alertdialog but this will require further
      // adjustments to implement proper focus management.
      // @see https://linear.app/codesandbox/issue/CSB-5516/a11y-make-toast-notifications-compliant
      role={variant === "info" ? "status" : "alert"}
    >
      <Content id={toastId} variant={variant}>
        {children}
      </Content>
      {actions &&
        actions.map((action) => {
          const { text, onAction } = action;
          return (
            <Button
              key={text}
              aria-describedby={toastId}
              onClick={onAction}
              style={{ marginRight: 16 }}
              variant={variant}
            >
              {text}
            </Button>
          );
        })}
    </Wrapper>
  );
};
