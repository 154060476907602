/**
 * Token names based on:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
 */
export const fontWeights = {
  regular: "400",
  medium: "500",
  semibold: "600",
};

export type FontWeightKey = keyof typeof fontWeights;
