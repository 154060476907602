import { captureException, setTag } from "@sentry/browser";
import type { CreateSentry } from "environment-interface/sentry";

import logger from "../../features/utils/logger";

export const createSentry: CreateSentry = (dns) => {
  const isSentryEnabled = dns !== undefined;

  return {
    reportError(error, context = {}) {
      if (isSentryEnabled) {
        return captureException(new Error(error), context);
      } else {
        logger.error(error, context);
      }
    },
    setTag(name, value = "UNKNOWN") {
      if (isSentryEnabled) {
        setTag(name, value);
      } else {
        logger.debug("Setting Sentry tag", { name, value });
      }
    },
  };
};
