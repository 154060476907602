export const fonts = {
  base: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
  display:
    "Everett, Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
  mono: "Fira Code, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace",
};

export const typography = {
  xs: {
    fontSize: "$fontSizes$xs",
    letterSpacing: "$letterSpacings$xs",
    lineHeight: "$lineHeights$xs",
  },
  base: {
    fontSize: "$fontSizes$base",
    letterSpacing: "$letterSpacings$base",
    lineHeight: "$lineHeights$base",
  },
  sm: {
    fontSize: "$fontSizes$sm",
    letterSpacing: "$letterSpacings$sm",
    lineHeight: "$lineHeights$sm",
  },
  md: {
    fontSize: "$fontSizes$md",
    letterSpacing: "$letterSpacings$md",
    lineHeight: "$lineHeights$md",
  },
  lg: {
    fontSize: "$fontSizes$lg",
    letterSpacing: "$letterSpacings$lg",
    lineHeight: "$lineHeights$lg",
  },
  xl: {
    fontSize: "$fontSizes$xl",
    letterSpacing: "$letterSpacings$xl",
    lineHeight: "$lineHeights$xl",
  },
  xxl: {
    fontSize: "$fontSizes$xxl",
    letterSpacing: "$letterSpacings$xxl",
    lineHeight: "$lineHeights$xxl",
  },
};

export const fontSizes = {
  /**
   * Option tokens
   *
   * Based on represented px values when root font is 10px.
   */

  12: "1.2rem",
  13: "1.3rem",
  14: "1.4rem",
  16: "1.6rem",
  24: "2.4rem",
  32: "3.2rem",
  48: "4.8rem",
  64: "6.4rem",

  // Code
  code: "$fontSizes$13",

  // Typography
  xs: "$fontSizes$12",
  base: "$fontSizes$13",
  sm: "$fontSizes$16",
  md: "$fontSizes$24",
  lg: "$fontSizes$32",
  xl: "$fontSizes$48",
  xxl: "$fontSizes$64",
};

export const lineHeights = {
  /**
   * Option tokens
   *
   * Line height scale consists of percentage values deciding how much
   * the height must grow based on the font size.
   */

  20: "1.25em",
  30: "1.35em",

  /**
   * Decision tokens
   */

  // Code
  code: "$lineHeights$30",

  // Typography
  xs: "$lineHeights$20",
  base: "$lineHeights$20",
  sm: "$lineHeights$30",
  md: "$lineHeights$30",
  lg: "$lineHeights$30",
  xl: "$lineHeights$30",
  xxl: "$lineHeights$30",
};

export const letterSpacings = {
  /**
   * Option tokens
   *
   * Letter spacing scale consists of percentage values deciding how much
   * the spacing must grow or shrink based on the font size.
   */

  // 0: "0em",
  // 5: "0.05em",

  /**
   * No they don't!
   */

  0: "-0.03em",
  1: "-0.02em",
  2: "-0.01em",
  3: "0em",
  4: "0.05em",
  5: "0.01em",

  /**
   * Decision tokens
   */

  // Code
  code: "$letterSpacings$3",

  // Typography
  xs: "$letterSpacings$4",
  base: "$letterSpacings$3",
  sm: "$letterSpacings$2",
  md: "$letterSpacings$2",
  lg: "$letterSpacings$1",
  xl: "$letterSpacings$1",
  xxl: "$letterSpacings$0",
};
