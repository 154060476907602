import type {
  AbsoluteWorkspacePath,
  IPitcherClient,
} from "@codesandbox/pitcher-client";
import { DisposableStore } from "@codesandbox/pitcher-common";
import { FS_SCHEME } from "environment-interface";
import type { IDisposable } from "monaco-editor";
import * as vscode from "vscode";

export function activate(pitcherClient: IPitcherClient): IDisposable {
  const disposableStore = new DisposableStore();

  disposableStore.add(
    vscode.workspace.onDidChangeTextDocument(async (e) => {
      const document = e.document;

      if (document.uri.scheme !== FS_SCHEME) {
        return;
      }

      const relativePath =
        pitcherClient.clients.fs.absoluteToRelativeWorkspacePath(
          document.uri.path as AbsoluteWorkspacePath,
        );

      try {
        const openFileRef =
          await pitcherClient.clients.file.openFileByPath(relativePath);

        if (openFileRef) {
          openFileRef.object.updateContent(document.getText());
        }

        openFileRef.dispose();
      } catch (e) {
        /* Could be a file outside of the in-memory fs, it's fine */
      }
    }),
  );

  return disposableStore;
}
