import { gql } from "@codesandbox/api";
import type { BranchEditor } from "features/types/editor";
import { createCapabilitiesObject } from "features/utils/dto";
import {
  projectFragmentWithDefaultBranch,
  type ProjectDataWithBranch,
  pullRequestFragment,
  projectFragmentWithBranch,
  branchFragment,
} from "isomorphic/queries";

import { csbApi } from "utils/csbApi";

export const convertProjectQueryToBranchEditorData = (
  owner: string,
  repo: string,
  project: ProjectDataWithBranch,
): BranchEditor => {
  // We have filtered out "null" branchByName
  const branch = project.branch!;
  const protectedBranch = branch.settings.protected;
  const authorization = branch.contributionOwner
    ? gql.ProjectAuthorization.WRITE
    : project.authorization;
  const pullRequest = project.branch?.pullRequests[0] ?? null;

  const newProject: BranchEditor = {
    type: "branch",
    aiConsent: branch.aiConsent,
    canDownload: true,
    projectId: project.id,
    workspace: project.team ?? null,
    subscriptionType: project.team?.subscription?.type ?? null,
    contribution: branch.contribution,
    defaultBranchName: project.repository.defaultBranch,
    githubAppInstalled: project.appInstalled,
    id: branch.id,
    branch: branch.name,
    owner,
    repo,
    authorization,
    repoPermission: project.repository.permission,
    isPrivate: project.repository.private,
    pullRequest,
    sourceBranchName:
      project.repository.source?.name ?? project.repository.defaultBranch,
    // Not sure why we set this to source or default?
    targetBranchName:
      project?.branch?.sourceBranch ?? project.repository.defaultBranch,
    // This is not available yet
    protectedBranch,
    parentRepo: project.repository.parent
      ? {
          owner: project.repository.parent.owner,
          repo: project.repository.parent.name,
          defaultBranch: project.repository.parent.defaultBranch,
        }
      : null,
    allowedCapabilities: {
      branch: false,
      sandbox: false,
      renameProject: false,
      restartContainer: false,
      secrets: false,
      vscode: false,
      createLiveSession: false,
    },
    resources: project.resources,
  };

  // We can probably find a better way to do this, but it needs to support both sandboxes and branches, tricky
  newProject.allowedCapabilities = createCapabilitiesObject(newProject);

  return newProject;
};

type ImportProjectArgs = {
  owner: string;
  repo: string;
  workspaceId: string;
};

const importProjectQuery = gql.createMutation(
  "ImportProject",
  ({ owner, repo, workspaceId }: ImportProjectArgs) => ({
    importProject: [
      {
        owner,
        name: repo,
        team: workspaceId,
        provider: gql.GitProvider.GITHUB,
      },
      projectFragmentWithDefaultBranch,
    ],
  }),
);

export const pullRequestSubscription = gql.createSubscription(
  "PullRequest",
  ({
    owner,
    repo,
    branch,
  }: {
    owner: string;
    repo: string;
    branch: string;
  }) => ({
    branchEvents: [
      { owner, repo, branchName: branch },
      gql.inlineFragments.BranchEvent({
        PullRequestReviewEvent: {
          pullRequest: pullRequestFragment,
        },
        PullRequestReviewCommentEvent: {
          pullRequest: pullRequestFragment,
        },
        PullRequestCommentEvent: {
          pullRequest: pullRequestFragment,
        },
        PullRequestEvent: {
          pullRequest: pullRequestFragment,
        },
      }),
    ],
  }),
);

export async function importProject(
  args: ImportProjectArgs,
  serverHeaders?: Record<string, string>,
) {
  const { owner, repo } = args;

  const projectData = await csbApi.gql.query(
    importProjectQuery,
    args,
    serverHeaders,
  );

  return convertProjectQueryToBranchEditorData(
    owner,
    repo,
    projectData.importProject,
  );
}

type ImportReadOnlyProjectArgs = {
  owner: string;
  repo: string;
};

const importReadOnlyProjectQuery = gql.createMutation(
  "ImportReadOnlyProject",
  ({ owner, repo }: ImportReadOnlyProjectArgs) => ({
    importReadOnlyProject: [
      {
        owner,
        name: repo,
        provider: gql.GitProvider.GITHUB,
      },
      projectFragmentWithDefaultBranch,
    ],
  }),
);

export async function importReadOnlyProject(
  args: ImportReadOnlyProjectArgs,
  serverHeaders?: Record<string, string>,
) {
  const { owner, repo } = args;

  const projectData = await csbApi.gql.query(
    importReadOnlyProjectQuery,
    args,
    serverHeaders,
  );

  return convertProjectQueryToBranchEditorData(
    owner,
    repo,
    projectData.importReadOnlyProject,
  );
}

type ProjectBranchArgs = {
  owner: string;
  repo: string;
  branch: string;
  workspaceId: string | null;
};

const projectBranchQuery = gql.createQuery(
  "ProjectBranch",
  ({ owner, repo, branch, workspaceId }: ProjectBranchArgs) => ({
    project: [
      {
        owner,
        repo,
        gitProvider: gql.GitProvider.GITHUB,
        ...(workspaceId ? { team: workspaceId } : {}),
      },
      projectFragmentWithBranch(branch),
    ],
  }),
);

export const getBranchEditorDataByBranch = async (
  args: ProjectBranchArgs,
  serverHeaders?: Record<string, string>,
) => {
  const { owner, repo } = args;

  const projectData = await csbApi.gql.query(
    projectBranchQuery,
    args,
    serverHeaders,
  );

  if (!projectData.project?.branch) {
    return null;
  }

  return projectData.project
    ? convertProjectQueryToBranchEditorData(owner, repo, projectData.project)
    : null;
};

export type RepositoryWorkspace = {
  id: string;
  name: string;
  avatarUrl: string | null;
};

const getWorkspacesByRepositoryQuery = gql.createQuery(
  "RecentTeamsByRepository",
  ({ owner, repo }: { owner: string; repo: string }) => ({
    recentTeamsByRepository: [
      { provider: gql.GitProvider.GITHUB, owner, name: repo },
      {
        id: true,
        name: true,
        avatarUrl: true,
      },
    ],
  }),
);

export async function getPossibleWorkspacesToRequestAccess(
  { owner, repo }: { owner: string; repo: string },
  serverHeaders?: Record<string, string>,
): Promise<RepositoryWorkspace[]> {
  const data = await csbApi.gql.query(
    getWorkspacesByRepositoryQuery,
    {
      owner,
      repo,
    },
    serverHeaders,
  );

  return data.recentTeamsByRepository;
}

type ImportReadOnlyBranchArgs = { owner: string; repo: string; branch: string };

const importReadOnlyBranchQuery = gql.createMutation(
  "ImportPublicBranch",
  ({ owner, repo, branch }: ImportReadOnlyBranchArgs) => ({
    importReadOnlyBranch: [
      { provider: gql.GitProvider.GITHUB, owner, name: repo, branch },
      {
        project: projectFragmentWithBranch(branch),
      },
    ],
  }),
);

export async function importReadOnlyBranch(
  args: ImportReadOnlyBranchArgs,
  serverHeaders?: Record<string, string>,
): Promise<BranchEditor> {
  const data = await csbApi.gql.query(
    importReadOnlyBranchQuery,
    args,
    serverHeaders,
  );

  return convertProjectQueryToBranchEditorData(
    args.owner,
    args.repo,
    data.importReadOnlyBranch.project,
  );
}

type ImportBranchArgs = {
  owner: string;
  repo: string;
  branch: string;
  workspaceId: string;
};

const importBranchQuery = gql.createMutation(
  "ImportBranch",
  ({ owner, repo, branch, workspaceId }: ImportBranchArgs) => ({
    importBranch: [
      {
        owner,
        name: repo,
        branch,
        provider: gql.GitProvider.GITHUB,
        team: workspaceId,
      },
      {
        project: projectFragmentWithBranch(branch),
      },
    ],
  }),
);

export async function importBranch(
  args: ImportBranchArgs,
  serverHeaders?: Record<string, string>,
) {
  const data = await csbApi.gql.query(importBranchQuery, args, serverHeaders);

  return convertProjectQueryToBranchEditorData(
    args.owner,
    args.repo,
    data.importBranch.project,
  );
}

type CreateBranchArgs = {
  owner: string;
  repo: string;
  branch?: string;
  sourceBranch: string;
  workspaceId: string;
};

const createBranchQuery = gql.createMutation(
  "CreateBranch",
  ({ owner, repo, sourceBranch, workspaceId, branch }: CreateBranchArgs) => ({
    createBranch: [
      {
        owner,
        name: repo,
        from: sourceBranch,
        provider: gql.GitProvider.GITHUB,
        team: workspaceId,
        branch,
      },
      branchFragment,
    ],
  }),
);

export async function createBranch(
  args: CreateBranchArgs,
  serverHeaders?: Record<string, string>,
) {
  const data = await csbApi.gql.query(createBranchQuery, args, serverHeaders);

  return data.createBranch;
}

type CreateContributionBranchArgs = {
  owner: string;
  repo: string;
  sourceBranch: string;
};

const createContributionBranchQuery = gql.createMutation(
  "CreateContributionBranch",
  ({ owner, repo, sourceBranch }: CreateContributionBranchArgs) => ({
    createContributionBranch: [
      {
        owner,
        name: repo,
        from: sourceBranch,
        provider: gql.GitProvider.GITHUB,
      },
      branchFragment,
    ],
  }),
);

export async function createContributionBranch(
  args: CreateContributionBranchArgs,
  serverHeaders?: Record<string, string>,
) {
  const data = await csbApi.gql.query(
    createContributionBranchQuery,
    args,
    serverHeaders,
  );

  return data.createContributionBranch;
}
type TeamGitHubMembersQueryVariables = { teamId: string };
const teamGitHubMembers = gql.createQuery(
  "TeamGitHubMembers",
  ({ teamId }: TeamGitHubMembersQueryVariables) => ({
    me: {
      team: [
        { id: teamId },
        gql.fragments.Team({
          members: { name: true, githubUsername: true, avatarUrl: true },
        }),
      ],
    },
  }),
);

const teamGitHubMembersCache = new Map<
  string,
  Array<{
    name: string | null;
    avatarUrl: string;
    githubUsername: string | null;
  }>
>();
export const queryTeamGitHubMembers = async ({
  teamId,
}: TeamGitHubMembersQueryVariables) => {
  if (teamGitHubMembersCache.has(teamId)) {
    return Promise.resolve(teamGitHubMembersCache.get(teamId)!);
  }

  return csbApi.gql.query(teamGitHubMembers, { teamId }).then((response) => {
    const data = response.me?.team?.members ?? [];
    teamGitHubMembersCache.set(teamId, data);
    return data;
  });
};

type CreateGitHubReviewArgs = {
  body?: string;
  comments: gql.GithubPullRequestReviewCommentInput[];
  commitId?: string;
  event: gql.GitHubPullRequestReviewAction;
  name: string;
  owner: string;
  pullRequestNumber: number;
};

const createGitHubReview = gql.createMutation(
  "CreateGitHubReview",
  (args: CreateGitHubReviewArgs) => ({
    createGithubPullRequestReview: [
      args,
      {
        id: true,
      },
    ],
  }),
);

export const mutationCreateGitHubReview = async (
  data: CreateGitHubReviewArgs,
) => {
  return csbApi.gql.query(createGitHubReview, data).then((response) => {
    return response;
  });
};

type ReplyToGitHubPullRequestReviewArgs = {
  body: string;
  commentId: number;
  name: string;
  owner: string;
  pullRequestNumber: number;
};

const replyGitHubPullRequestReview = gql.createMutation(
  "ReplyToGitHubPullRequestReview",
  (args: ReplyToGitHubPullRequestReviewArgs) => ({
    replyToGithubPullRequestReview: [
      args,
      {
        id: true,
      },
    ],
  }),
);

export const mutationReplyGitHubPullRequestReview = async (
  data: ReplyToGitHubPullRequestReviewArgs,
) => {
  return csbApi.gql
    .query(replyGitHubPullRequestReview, data)
    .then((response) => {
      return response;
    });
};
