import type { ResultOf } from "@graphql-typed-document-node/core";
import { useEnvironmentInterface } from "environment-interface";
import type { GraphQLRequestBody } from "environment-interface/csbApi";
import type { DocumentNode } from "graphql";
import { print } from "graphql";
import type { SWRResponse } from "swr";
import useSWR from "swr";

export const useQuery = <Data extends DocumentNode = DocumentNode>(
  query: Data | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: Record<string, any> = {},
): SWRResponse<ResultOf<Data>, Error> => {
  const { api } = useEnvironmentInterface();
  const payload = query ? { query: print(query), variables } : null;

  return useSWR(payload, (callbackQuery: GraphQLRequestBody) =>
    api.query(callbackQuery),
  );
};
