type Font = "base" | "display" | "mono";
type FontRecord = Record<Font, string>;

export const font: FontRecord = {
  base: "Inter",
  display: "Everett",
  mono: "Fira Code",
};

const baseFallback =
  "-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol";

export const fontStack: FontRecord = {
  base: `${font.base}, ${baseFallback}`,
  display: `${font.display}, ${baseFallback}`,
  mono: `${font.mono}, 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace`,
};
