// @ts-expect-error there are no types for this package
import untar from "js-untar";
import { fetchWithRetries } from "./utils";
/**
 * Responsible for fetching, caching and converting tars to a structure that sandpack
 * understands and can use
 */
export class TarStore {
    constructor() {
        this.fetchedTars = {};
    }
    generateKey(name, version) {
        return name + "||" + version;
    }
    normalizeTar(tarContents) {
        const normalized = {};
        tarContents.forEach((tar) => {
            if (tar.type === "0") {
                normalized[tar.name.replace(/^[^/]+/, "")] = new Uint8Array(tar.buffer);
            }
        });
        return normalized;
    }
    fetchTar(name, version, requestInit) {
        const tarKey = this.generateKey(name, version);
        this.fetchedTars[tarKey] = (async () => {
            const file = await fetchWithRetries(version, 6, requestInit)
                .then((x) => x.blob())
                .then((x) => x.stream().pipeThrough(new DecompressionStream("gzip")));
            const finalBlob = await streamToBuffer(file);
            const untarredFile = await untar(finalBlob.buffer);
            const normalizedTar = this.normalizeTar(untarredFile);
            return normalizedTar;
        })();
        return this.fetchedTars[tarKey];
    }
    async file(name, url, path, requestInit) {
        const tarKey = this.generateKey(name, url);
        const tar = await (this.fetchedTars[tarKey] ||
            this.fetchTar(name, url, requestInit));
        return tar[path];
    }
    async meta(name, url, requestInit) {
        const tarKey = this.generateKey(name, url);
        const tar = await (this.fetchedTars[tarKey] ||
            this.fetchTar(name, url, requestInit));
        const meta = {};
        Object.keys(tar).forEach((path) => {
            meta[path] = true;
        });
        return meta;
    }
    async massFiles(name, url, requestInit) {
        const tarKey = this.generateKey(name, url);
        const tar = await (this.fetchedTars[tarKey] ||
            this.fetchTar(name, url, requestInit));
        return tar;
    }
}
async function streamToBuffer(readableStream) {
    const reader = readableStream.getReader();
    const chunks = []; // This will store arrays of bytes
    let finished = false;
    while (!finished) {
        const { done, value } = await reader.read();
        if (done) {
            finished = true;
        }
        else {
            chunks.push(value);
        }
    }
    // Combine all chunks into a single Uint8Array
    const totalLength = chunks.reduce((total, arr) => total + arr.length, 0);
    const buffer = new Uint8Array(totalLength);
    let position = 0;
    for (const chunk of chunks) {
        buffer.set(chunk, position);
        position += chunk.length;
    }
    return buffer;
}
