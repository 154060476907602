import { Disposable } from "@codesandbox/pitcher-client";
import type { clients } from "@codesandbox/pitcher-client";
import { getLatestMonacoConfig } from "environment-interface/monacoEditor/browser/services/editor/vscode/monaco";
import { debounce } from "lodash";
import * as vscode from "vscode";

export interface FocusFileMessage {
  type: "FOCUS_FILE";
  filepath: string;
  visibleLines: [number, number];
  currentLine: number;
}

export type EditorChannel = clients.ICommandChannel<FocusFileMessage>;

export type WorkspaceChannel = clients.IFollowChannel<FocusFileMessage>;

export function initializeFollowingFunctionality(
  workspacePath: string,
  channelClient: clients.IChannelClient,
  client: clients.IClientClient,
  workspaceUri: vscode.Uri,
) {
  const followChannel =
    channelClient.getFollowChannel<FocusFileMessage>("WORKSPACE");
  const editorChannel = initializeEditorChannel(channelClient, workspaceUri);

  let currentEditor = vscode.window.activeTextEditor;
  let openedDocument = vscode.window.activeTextEditor?.document;

  const FOLLOWING_FRAME_ID = "following-frame";

  const createFollowigFrame = (followingClient: string) => {
    if (document.getElementById(FOLLOWING_FRAME_ID)) {
      return;
    }

    const clients = client.getClients();
    const clientColor = clients.find((c) => c.clientId === followingClient)
      ?.color;

    if (!clientColor) return;

    const element = document.createElement("div");

    element.id = FOLLOWING_FRAME_ID;
    element.style.position = "absolute";
    element.style.inset = "0";
    element.style.pointerEvents = "none";
    element.style.zIndex = "2147483647";
    element.style.border = `4px solid ${clientColor}`;

    document.body.appendChild(element);
  };

  const removeFollowingFrame = () => {
    document.getElementById(FOLLOWING_FRAME_ID)?.remove();
  };

  const sendCurrentViewState = (clientId?: string) => {
    if (
      followChannel.state.followers.is("NO_FOLLOWERS") &&
      editorChannel.channel.state.is("SOLO")
    ) {
      return;
    }

    const currentDocument = currentEditor?.document;

    if (!currentDocument || !currentEditor) {
      return;
    }

    const visibleRange = currentEditor.visibleRanges[0];
    if (!visibleRange) {
      return;
    }

    const uri = currentDocument.uri;
    const currentLine = currentEditor.selection.anchor.line;
    const message: FocusFileMessage = {
      type: "FOCUS_FILE",
      filepath: uri.fsPath.replace(workspacePath, ""),
      visibleLines: [visibleRange.start.line, visibleRange.end.line],
      currentLine,
    };

    if (clientId) {
      followChannel.sendFollower(clientId, message);
    } else {
      followChannel.sendAllFollowers(message);
    }
  };

  // Ensure when followers join that they get the current view state
  const onFollowerJoinListener =
    followChannel.onFollowerJoin(sendCurrentViewState);

  // Ensure when we change active text editor that followers are updated
  const didChangeActiveTextEditor = vscode.window.onDidChangeActiveTextEditor(
    (e) => {
      currentEditor = e;
      sendCurrentViewState();
    },
  );

  // Ensure followers gets the updated about scroll state
  const didChangeTextEditorVisibleRanges =
    vscode.window.onDidChangeTextEditorVisibleRanges((_e) => {
      sendCurrentViewState();
    });

  // When we follow someone, make sure we change the state of this editor
  const followMessageListener = followChannel.onFollowMessage(
    async (message) => {
      const state = followChannel.state.following.get();
      if (state.state === "FOLLOWING") {
        createFollowigFrame(state.clientId);
      }

      if (message.data.type === "FOCUS_FILE") {
        const document = await handleFocusFileMessage(
          message.data,
          workspaceUri,
        );

        if (document) {
          openedDocument = document;
        }
      }
    },
  );

  followChannel.onUnfollow(removeFollowingFrame);

  // When we click a different document we should stop following
  const debouncedHandleEditorChange = debounce(
    (editors: readonly vscode.TextEditor[]) => {
      if (followChannel.state.following.is("FOLLOWING")) {
        const hasEditorVisible = editors.some(
          (e) => e.document.uri.path === openedDocument?.uri.path,
        );
        if (!hasEditorVisible) {
          followChannel.unfollow();
        }
      }
    },
    // VSCode is a bit spammy with this event, so we debounce to make sure that we really have the latest
    // state when checking the editors.
    500,
  );

  const didChangeVisibleTextEditors =
    vscode.window.onDidChangeVisibleTextEditors(debouncedHandleEditorChange);

  return {
    disposable: Disposable.create(() => {
      editorChannel.disposeable.dispose();
      onFollowerJoinListener.dispose();
      didChangeActiveTextEditor.dispose();
      didChangeTextEditorVisibleRanges.dispose();
      followMessageListener.dispose();
      didChangeVisibleTextEditors.dispose();
    }),
    followChannel,
  };
}

function initializeEditorChannel(
  channelClient: clients.IChannelClient,
  workspaceUri: vscode.Uri,
) {
  const channel = channelClient.getCommandChannel<FocusFileMessage>("EDITOR");

  const messageListener = channel.onMessage(async (message) => {
    if (message.data.type === "FOCUS_FILE") {
      await handleFocusFileMessage(message.data, workspaceUri);
    }
  });

  return {
    channel,
    disposeable: Disposable.create(() => {
      messageListener.dispose();
    }),
  };
}

async function handleFocusFileMessage(
  data: FocusFileMessage,
  workspaceUri: vscode.Uri,
): Promise<vscode.TextDocument | undefined> {
  const { pitcher } = getLatestMonacoConfig();

  const absolutePath = pitcher.clients.fs.asAbsoluteWorkspacePath(
    data.filepath as `$RELATIVE/${string}`,
  );

  let document = vscode.workspace.textDocuments.find(
    (d) =>
      pitcher.clients.fs.asAbsoluteWorkspacePath(d.uri.path) === absolutePath,
  );

  if (!document) {
    document = await vscode.workspace.openTextDocument(
      vscode.Uri.from({
        ...workspaceUri,
        path: absolutePath,
      }),
    );
  }

  const visibleRange = new vscode.Range(
    new vscode.Position(data.visibleLines[0], 0),
    new vscode.Position(data.visibleLines[1], 0),
  );

  const editor = await vscode.window.showTextDocument(document, {
    preserveFocus: true,
  });
  editor.revealRange(visibleRange, vscode.TextEditorRevealType.InCenter);

  return document;
}
