export function moduleToFile(mod) {
    return {
        shortid: mod.shortid,
        title: mod.title,
        content: mod.code || "",
        savedContent: mod.savedCode || mod.code || "",
        isBinary: mod.isBinary || false,
        createdAt: new Date(mod.insertedAt).getTime(),
        updatedAt: new Date(mod.updatedAt).getTime(),
    };
}
export class FilesState {
    constructor() {
        // shortid to ICSBFile
        this.files = new Map();
        this.binaryFiles = new Map();
    }
    populate(modules) {
        modules.forEach((mod) => {
            this.files.set(mod.shortid, moduleToFile(mod));
        });
    }
    async downloadBinaryFiles() {
        const promises = [];
        for (const [fileId, file] of this.files.entries()) {
            if (!file.isBinary) {
                continue;
            }
            if (this.binaryFiles.has(fileId)) {
                continue;
            }
            const download = async (attempt = 0) => {
                try {
                    const res = await fetch(file.content);
                    if (!res.ok) {
                        // eslint-disable-next-line no-console
                        console.error("Failed to download file", res.status, res.statusText);
                        this.binaryFiles.set(fileId, new Uint8Array());
                        return;
                    }
                    const buf = await res.arrayBuffer();
                    this.binaryFiles.set(fileId, new Uint8Array(buf));
                }
                catch (err) {
                    if (attempt < 3) {
                        return download(attempt + 1);
                    }
                    this.binaryFiles.set(fileId, new Uint8Array());
                    // eslint-disable-next-line no-console
                    console.error("Failed to download file", err);
                }
            };
            promises.push(download());
        }
        await Promise.all(promises);
    }
    get(id) {
        return this.files.get(id);
    }
    getContent(id) {
        const file = this.get(id);
        if (!file) {
            return null;
        }
        if (file.isBinary) {
            const content = this.binaryFiles.get(id);
            if (!content) {
                // eslint-disable-next-line no-console
                console.warn("File content not downloaded, this should be impossible...");
                return null;
            }
            return content;
        }
        return file.content;
    }
    addFile(id, file) {
        this.files.set(id, file);
    }
    setBinaryContent(id, content) {
        this.binaryFiles.set(id, content);
    }
    deleteFile(id) {
        this.files.delete(id);
        this.binaryFiles.delete(id);
    }
}
