function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
export async function fetchWithRetries(url, retries = 2, requestInit) {
    const doFetch = () => window.fetch(url, requestInit).then((x) => {
        if (x.ok) {
            return x;
        }
        const error = new Error(`Could not fetch ${url}`);
        error.responseObject = x;
        throw error;
    });
    let lastTryTime = 0;
    for (let i = 0; i < retries; i++) {
        if (Date.now() - lastTryTime < 3000) {
            // Ensure that we at least wait 3s before retrying a request to prevent rate limits
            // eslint-disable-next-line
            await delay(3000 - (Date.now() - lastTryTime));
        }
        try {
            lastTryTime = Date.now();
            // eslint-disable-next-line
            return await doFetch();
        }
        catch (e) {
            // eslint-disable-next-line
            console.error(e);
            if (i === retries - 1) {
                throw e;
            }
        }
    }
    throw new Error("Could not fetch");
}
