import { styled } from "../../theme";

export const Box = styled("div", {
  variants: {
    selectable: {
      true: {
        userSelect: "text",
      },
    },
  },
});
