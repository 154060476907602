import type {
  IPitcherClient,
  RcRef,
  clients,
} from "@codesandbox/pitcher-client";
import { Disposable, protocol, ot } from "@codesandbox/pitcher-client";
import type { IDocument } from "@codesandbox/pitcher-client/dist/esm/interface/clients";
import type { IFile } from "@codesandbox/pitcher-client/dist/esm/interface/clients/IFile";
import type { ILogger } from "@codingame/monaco-vscode-log-service-override";
import { captureException } from "@sentry/browser";
import { getLatestMonacoConfig } from "environment-interface/monacoEditor/browser/services/editor/vscode/monaco";
import { Selection } from "monaco-editor";
import type { editor } from "monaco-editor";
import * as vscode from "vscode";
import { ITextFileService, StandaloneServices } from "vscode/services";

import * as operations from "../../../../../utils/operations";
import {
  createClientLocationsFromSelections,
  createEditorSelections,
  createSelectionDecorations,
} from "../../../../../utils/selection-decorations";

import type { FocusFileMessage } from "./following";

type SyncState = "SYNCED" | "NOTIFIED_OUT_OF_SYNC" | "OUT_OF_SYNC" | "SYNCING";

export class ModelHandler extends Disposable {
  private readonly pitcherDoc: IDocument | null;
  constructor(
    private vscodeModel: editor.ITextModel,
    private pitcherFile: RcRef<IFile>,
    private logger: ILogger,
    pitcher: IPitcherClient,
    private followChannel: clients.IFollowChannel<FocusFileMessage>,
  ) {
    super();
    this.pitcherDoc = pitcherFile.object.document;
    this.addDisposable(this.pitcherFile);

    this.registerContentHandlers();
    this.registerSelectionHandlers();

    this.setClients(pitcher.clients.client.getClients(), pitcher.currentClient);
    this.addDisposable(
      pitcher.clients.client.onClientsUpdated((clients) => {
        this.updateClients(clients);
      }),
    );
  }

  //#region Operations

  private applyingOperation = false;
  private syncState: SyncState = "SYNCED";

  private registerContentHandlers() {
    this.addDisposable(
      this.vscodeModel.onDidChangeContent((event) => {
        if (!this.pitcherDoc) {
          return;
        }
        if (this.applyingOperation) {
          return;
        }

        switch (this.syncState) {
          case "SYNCED": {
            const operation = operations.convertMonacoChangesToOperation(
              this.pitcherDoc.otClient.document,
              event.changes,
              event.eol.length,
            );

            // This can happen when the FS syncs a non dirty file which just brings
            // you to the current saved version of the document. If the read on a non dirty document
            // results in a difference (File changed directly on FS), it should send the operation
            // to properly sync on Pitcher
            const resultsInSameDocument =
              this.pitcherDoc.otClient.document === this.vscodeModel.getValue();

            if (resultsInSameDocument || operation.isNoop()) {
              return;
            }

            this.followChannel.unfollow();

            try {
              this.pitcherDoc.applyClient(operation);
            } catch (error) {
              this.logger.error(
                "Error sending operation on applyClient",
                error,
                operation,
              );
              captureException(error, {
                extra: {
                  message: "MONACO unable to apply operation to OT document",
                },
              });
              this.resetModel();
            }
            break;
          }
          case "NOTIFIED_OUT_OF_SYNC": {
            // We are showing the notification
            return;
          }
          case "OUT_OF_SYNC": {
            // We dismissed the notification and are still out of sync
            this.resetModel();
            return;
          }
          case "SYNCING": {
            // We are currently syncing. There is a tiny chance the user types something
            // at this point, but no matter client/server sync we will replace the contents
            // with the intended sync content
            return;
          }
        }
      }),
    );

    if (this.pitcherDoc) {
      this.addDisposable(
        this.pitcherDoc.onIncomingOperation((evt) => {
          const vscodeDoc = vscode.workspace.textDocuments.find(
            (doc) => doc.uri.path === this.vscodeModel.uri.path,
          );

          if (!this.pitcherDoc || !vscodeDoc) {
            return;
          }

          /*
            We only want to handle resync events when the document is dirty. When document is not dirty
            VSCode will rather update the document from reading the FS. This results in a NOOP operation
            and we are in sync, preventing any errors about file being newer on FS. When the file is dirty
            we are most likely already in sync, with no RESYNC operation, but you might be collaborating
            and in that case it might be a RESYNC operation putting you in sync with your collaborator. In
            this scenario there is no overriding FS read as the document is dirty
          */
          if (!vscodeDoc.isDirty && evt.reason === ot.OperationReason.RESYNC) {
            return;
          }

          switch (this.syncState) {
            case "SYNCED": {
              this.applyingOperation = true;
              try {
                operations.applyOperationToModel(
                  evt.operation,
                  this.vscodeModel,
                  Selection,
                );
              } catch (error) {
                this.logger.error("Error applying operation", error);
                captureException(error, {
                  extra: {
                    message: "MONACO unable to apply operation to MODEL",
                  },
                });
                this.resetModel();
              } finally {
                this.applyingOperation = false;
              }
              break;
            }
            case "OUT_OF_SYNC": {
              // An incoming operation might arrive, we'll show the notification again
              this.resetModel();
              return;
            }
            case "SYNCING":
            case "NOTIFIED_OUT_OF_SYNC": {
              // Reciving operations during syncing is not really possible as WebSocket messages come in order, no matter
              // the response holds the latest contents which will be put into the editor. when showing notification we just
              // ignore all incoming operations
              return;
            }
          }
        }),
      );

      this.addDisposable(
        this.pitcherFile.object.onDidSave((e) => {
          const fileManager = StandaloneServices.get(ITextFileService);
          const fileModel = fileManager.files.get(this.vscodeModel.uri);

          if (fileModel) {
            fileModel.save({
              skipSaveParticipants: true,
              ignoreModifiedSince:
                e.savedHash === this.pitcherFile.object.contentHash,
            });
          }
        }),
      );

      if (this.vscodeModel.getValue() !== this.pitcherDoc.otClient.document) {
        this.applyingOperation = true;
        // Set current value to pitcher document
        this.vscodeModel.setValue(this.pitcherDoc.otClient.document);
        this.applyingOperation = false;
      }
    }
  }

  private async resetModel() {
    this.syncState = "NOTIFIED_OUT_OF_SYNC";
    const resetType = await getLatestMonacoConfig().showResetFileNotification(
      this.pitcherFile.object.id,
    );

    this.syncState = "SYNCING";

    if (this.isDisposed || !resetType) {
      this.syncState = "OUT_OF_SYNC";
      return;
    }

    let newContent = this.vscodeModel.getValue();
    if (newContent !== undefined) {
      if (resetType === "client") {
        await this.pitcherFile.object.resetFromClient(newContent);
      } else {
        const serverContent = await this.pitcherFile.object.resetFromServer();
        newContent =
          typeof serverContent === "string" ? serverContent : newContent;
      }
    }

    if (this.isDisposed) {
      return;
    }

    this.applyingOperation = true;
    try {
      this.vscodeModel.setValue(newContent || "");
    } finally {
      this.applyingOperation = false;
    }
    this.syncState = "SYNCED";
  }

  //#endRegion
  //#region Selections

  private registerSelectionHandlers() {
    this.addDisposable(
      vscode.window.onDidChangeTextEditorSelection((event) => {
        if (
          event.textEditor.document.uri.toString() !==
          this.vscodeModel.uri.toString()
        ) {
          return;
        }

        if (this.vscodeModel.isDisposed()) {
          // We have to explicitly check for disposal, because the extension API
          // can be a bit behind, and still call listeners for a model that's disposed.
          return;
        }

        this.pitcherDoc?.sendSelection(
          this.getSelections(event.selections),
          event.kind === vscode.TextEditorSelectionChangeKind.Keyboard
            ? protocol.file.SelectionsUpdateReason.CONTENT_CHANGE
            : protocol.file.SelectionsUpdateReason.SELECTION,
        );
      }),
    );
    this.onWillDispose(() => {
      this.sendEmptySelection();
    });

    let isActiveEditor =
      vscode.window.activeTextEditor?.document.uri.toString() ===
      this.vscodeModel.uri.toString();
    this.addDisposable(
      vscode.window.onDidChangeActiveTextEditor((editor) => {
        if (!editor) {
          if (isActiveEditor) {
            isActiveEditor = false;
            this.sendEmptySelection();
          }
          return;
        }

        const wasActiveEditor = isActiveEditor;
        isActiveEditor =
          editor.document.uri.toString() === this.vscodeModel.uri.toString();
        if (wasActiveEditor && !isActiveEditor) {
          this.sendEmptySelection();
        }
      }),
    );

    if (
      vscode.window.activeTextEditor?.document.uri.toString() ===
        this.vscodeModel.uri.toString() &&
      this.pitcherDoc
    ) {
      this.pitcherDoc.sendSelection(
        this.getSelections(vscode.window.activeTextEditor.selections),
        protocol.file.SelectionsUpdateReason.SELECTION,
      );
    }

    if (this.pitcherDoc) {
      this.addDisposable(
        this.pitcherDoc.onSelection((event) => {
          this.handleDocumentSelectionsChange(
            getSelectionsFromDocument(event.clientSelections),
            event.reason,
          );
        }),
      );

      this.documentSelections = getSelectionsFromDocument(
        this.pitcherDoc.clientSelections,
      );
      this.updateSelections();
    }
  }

  private getSelections(selections: readonly vscode.Selection[]) {
    const lines = this.vscodeModel.getValue().split("\n");
    return selections.map((selection) => {
      /**
       * We should use "anchor" and "active", as they reflect
       * the actual caret position
       */
      return {
        anchor: lineAndColumnToIndex(
          lines,
          selection.anchor.line + 1,
          selection.anchor.character + 1,
        ),
        head: lineAndColumnToIndex(
          lines,
          selection.active.line + 1,
          selection.active.character + 1,
        ),
      };
    });
  }

  // Selections state
  private clients: Record<string, protocol.client.ClientJSON> = {};
  private clientId = "";
  private documentSelections: protocol.file.IDocumentSelections = {};
  private previousSelectionDecorations: string[] = [];
  clientLineLocations: Map<number, Set<string>> = new Map();
  handleDocumentSelectionsChange(
    documentSelections: protocol.file.IDocumentSelections,
    reason?: protocol.file.SelectionsUpdateReason,
  ): void {
    // We do not want to handle content change selections as Monaco updates that automatically
    // on incoming operations
    if (reason === protocol.file.SelectionsUpdateReason.CONTENT_CHANGE) {
      return;
    }

    this.documentSelections = documentSelections || {};
    this.updateSelections();
  }

  private updateSelections(): void {
    const model = this.vscodeModel;
    const lines = model.getLinesContent();
    const eolLength = model.getEOL().length;

    const editorSelections = createEditorSelections(
      lines,
      eolLength,
      this.clients,
      this.documentSelections,
      this.clientId,
    );

    const newDecorations = createSelectionDecorations(model, editorSelections);

    this.previousSelectionDecorations = model.deltaDecorations(
      this.previousSelectionDecorations,
      newDecorations,
    );

    // Update user locations
    this.clientLineLocations =
      createClientLocationsFromSelections(editorSelections);
  }

  private sendEmptySelection() {
    this.pitcherDoc?.sendSelection(
      [],
      protocol.file.SelectionsUpdateReason.SELECTION,
    );
  }

  /**
   * Called when the file editor is started.
   */
  setClients(
    clients: protocol.client.ClientJSON[],
    currentClient: protocol.client.ClientJSON,
  ): void {
    this.clientId = currentClient.clientId;
    this.updateClients(clients);
  }

  /**
   * Updates the clients. Unlike `setClients`, this needs to be called
   * whenever a client joins or leaves. It also handles things like updating selections.
   */
  updateClients(clients: protocol.client.ClientJSON[]): void {
    this.clients = clients.reduce<Record<string, protocol.client.ClientJSON>>(
      (aggr, client) => {
        aggr[client.clientId] = client;
        return aggr;
      },
      {},
    );
    this.updateSelections();
  }

  //#endregion
}

export function lineAndColumnToIndex(
  lines: string[],
  lineNumber: number,
  column: number,
) {
  let currentLine = 0;
  let index = 0;

  while (currentLine + 1 < lineNumber) {
    index += lines[currentLine]?.length || 0;
    index += 1; // Linebreak character
    currentLine += 1;
  }

  index += column - 1;

  return index;
}

const getSelectionsFromDocument = (
  clientSelections: clients.ClientSelections,
): protocol.file.IDocumentSelections => {
  const selections: protocol.file.IDocumentSelections = {};
  clientSelections.forEach((value, clientId) => {
    selections[clientId] = value;
  });
  return selections;
};
