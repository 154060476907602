import React from "react";

import {
  Dialog as Root,
  Close,
  CloseIconButton,
  Description,
  Title,
  ImageHolder,
} from "./Dialog";
import type { DialogProps } from "./Dialog";

const Dialog: IDialog = (props) => <Root {...props} />;

interface IDialog extends React.FC<DialogProps> {
  ImageHolder: typeof ImageHolder;
  Close: typeof Close;
  CloseIconButton: typeof CloseIconButton;
  Title: typeof Title;
  Description: typeof Description;
}

Dialog.ImageHolder = ImageHolder;
Dialog.Close = Close;
Dialog.CloseIconButton = CloseIconButton;
Dialog.Title = Title;
Dialog.Description = Description;

export { Dialog };
