import { useControls, button } from "leva";
// This completely crashes the typecheck for some reason
// import type { SchemaToValues } from "leva/dist/declarations/src/types";
import { omit } from "lodash";
import React, { useEffect } from "react";

import { safeLocalStorage } from "utils/safeLocalStorage";

/**
 *
 * An hard-coded list of feature flags with links to the linear project and any additional
 * configuration field we want to expose to the client.
 *
 * Use this sparingly and only for features that are not yet ready for the client AND
 * need to be blocked.
 *
 * EG. a screen that has a new version => use a flag
 * a new screen that's still not accessible => no need for it
 */

/**
 * The list of feature flags.
 *
 * The key will be used to query the activeness of the flag and its configuration.
 */
const featureFlags = {
  inlinePreview: {
    name: "Inline Preview",
    linear: "",
    schema: {},
  },
};

type FeatureFlags = typeof featureFlags;
type FeatureFlagData<T> = { active: boolean } & T;
type FeatureFlagNames = keyof FeatureFlags;

export const getStoredValues = (name: string) => {
  if (typeof window === "undefined") {
    return;
  }

  const stored = safeLocalStorage.get("CSB_FEATURE_FLAG_" + name);
  if (stored) {
    return JSON.parse(stored);
  }

  return;
};

/**
 * creates a schema from a flag object.
 * It adds:
 * - a button field for the linear project link
 * - an active boolean field for the feature flag status
 */
const schemaFromFlag = <T extends FeatureFlagNames>(flagName: T) => {
  const { linear, schema } = featureFlags[flagName];

  return {
    "Open Linear Issue": button(() => {
      window.open(linear, "_blank");
    }),
    active: { value: false, label: "Active?" },
    ...schema,
    ...getStoredValues(flagName),
  };
};

type ReturnedValues<T extends FeatureFlagNames> = FeatureFlagData<
  FeatureFlags[T]["schema"]
>;

/**
 * Queries the feature flag and returns the values.
 * It will always have an active field that's true when we want to show the feature in the client.
 *
 * @example
 *
 * ```jsx
 * const { active, foo } = useFeatureFlag("proj-diffs");
 *
 * if (active) {
 *  return <MyCoolNewView foo={foo} />
 * } else {
 *  return <MyOldBoringView />
 * }
 * ```
 *
 * @deprecated use useGlobalPersistedState and add to Experiments tab on settings
 */
export const useFeatureFlag = <T extends FeatureFlagNames>(
  name: T,
): ReturnedValues<T> => {
  const [values] = useControls(
    `Feature Flags.${name}`,
    () => schemaFromFlag(name),
    { collapsed: true },
  );

  const omitted = omit(values, [
    "Open Linear Issue",
  ]) as unknown as ReturnedValues<T>;

  useEffect(() => {
    safeLocalStorage.set(`CSB_FEATURE_FLAG_${name}`, JSON.stringify(omitted));
  }, [values]);

  /**
   * Until the values have been restored from local storage, directly return the parsed stored values.
   * Once the values have been set in leva in the effect, return the values from leva.
   *
   * In the case where there are no local values, it will still return the leva values
   */
  return omitted;
};

/**
 * Used internally to conditionally call the useFeatureFlag hooks
 * since hooks can't be nested in conditionals.
 *
 * Only used internally by @see FeatureFlags
 */
const RegisterFeatureFlagControls = <T extends keyof FeatureFlags>({
  name,
}: {
  name: T;
}): null => {
  useFeatureFlag(name);
  return null;
};

export const FeatureFlags: React.FC = () => {
  return (
    <>
      {Object.keys(featureFlags).map((name) => (
        <RegisterFeatureFlagControls
          key={name}
          name={name as keyof FeatureFlags}
        />
      ))}
    </>
  );
};
