import { useEffect, useReducer } from "react";

import { createHookContext } from "utils/createHookContext";

import { setClientPreference } from "../utils/debug";

import { reducer } from "./reducer";
import type { State } from "./types";

interface Props {
  initialState: State;
}

interface DebugWindowType extends Window {
  debugMode?(value: string): void;
}

const debugWindow: DebugWindowType =
  typeof window !== "undefined" ? window : ({} as DebugWindowType);

export const useDebug = createHookContext(({ initialState }: Props) => {
  const feature = useReducer(reducer, initialState);

  const [state, dispatch] = feature;

  useEffect(() => {
    debugWindow.debugMode = (value: string) => {
      switch (value) {
        case "ON":
          if (state.state === "ON") return;
          setClientPreference("CSB_DEBUG", "ENABLED");
          window.location.reload(); // required to prevent runtime error when directly changing state.
          break;
        case "OFF":
          dispatch({ type: "SET_MODE", enabled: false });
          break;
      }
    };
  }, [state.state]);

  return feature;
});

export const DebugProvider = useDebug.Provider;

export const DebugConsumer = useDebug.Consumer;
