import { match } from "@codesandbox/states";
import { DevtoolsProvider } from "@codesandbox/states/devtools";
import { DebugConsumer, DebugProvider } from "features/debug/Feature";
import {
  getClientPreference,
  isDebugMode,
  setClientPreference,
} from "features/utils/debug";
import { useControls, Leva, button } from "leva";
import { Box, Portal } from "prism-react";
import { useEffect, useState } from "react";

import { FeatureFlags } from "components/FeatureFlagsControls";

const showStateDevtoolsInitially =
  getClientPreference("CSB_STATE_DEVTOOLS") === "ENABLED";

const rcDebugInitially = getClientPreference("CSB_RC_DEBUG") === "ENABLED";

// dummy comment for release

const LevaWrapper: React.FC<{ hidden?: boolean }> = ({ hidden }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <FeatureFlags />
      <Portal>
        <Box
          css={{
            position: "fixed",
            right: 0,
            bottom: 0,
            width: "260px",
            pointerEvents: "none",

            "> *": {
              pointerEvents: "auto",
            },
          }}
        >
          {hidden ? (
            <Leva hidden />
          ) : (
            <Leva
              collapsed={{
                collapsed,
                onChange: () => {
                  setCollapsed(!collapsed);
                },
              }}
              fill
              oneLineLabels
            />
          )}
        </Box>
      </Portal>
    </>
  );
};

export const Debug: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { showStateDevtools } = useControls(
    "UI",
    {
      showStateDevtools: {
        label: "react-states devtool",
        value: showStateDevtoolsInitially,
      },
    },
    { collapsed: true },
  );

  const { rc_debug } = useControls("Internal.rcTraceLog", {
    rc_debug: {
      label: "Activate trace log",
      value: rcDebugInitially,
    },
    "log last trace": button(() => {
      // eslint-disable-next-line
      console.log(
        // eslint-disable-next-line
        // @ts-ignore
        window.RC_DEBUG.traceLog.slice(0),
      );
      // eslint-disable-next-line
      // @ts-ignore
      window.RC_DEBUG.traceLog.length = 0;
    }),
    "log counters": button(() => {
      // eslint-disable-next-line
      console.log(
        // eslint-disable-next-line
        // @ts-ignore
        Array.from(window.RC_DEBUG.counters.entries()),
      );
    }),
  });

  /**
   * Can be removed after updated to SpacesProvider to support hiding it
   * @see https://linear.app/codesandbox/issue/CSB-2116/support-hiding-react-states-devtoolsprovider
   */
  useEffect(() => {
    // Do nothing on first render.
    if (showStateDevtools === showStateDevtoolsInitially) return;

    setClientPreference(
      "CSB_STATE_DEVTOOLS",
      showStateDevtools ? "ENABLED" : "DISABLED",
    );
  }, [showStateDevtools]);

  useEffect(() => {
    // Do nothing on first render.
    if (rc_debug === rcDebugInitially) return;

    setClientPreference("CSB_RC_DEBUG", rc_debug ? "ENABLED" : "DISABLED");

    window.location.reload();
  }, [rc_debug]);

  return (
    <DebugProvider
      initialState={
        isDebugMode
          ? {
              state: "ON",
            }
          : {
              state: "OFF",
            }
      }
    >
      <DebugConsumer>
        {([state]) =>
          match(state, {
            ON: () => (
              <>
                <LevaWrapper />
                <DevtoolsProvider show={showStateDevtools}>
                  {children}
                </DevtoolsProvider>
              </>
            ),
            OFF: () => (
              <>
                <LevaWrapper hidden />
                <DevtoolsProvider show={false}>{children}</DevtoolsProvider>
              </>
            ),
          })
        }
      </DebugConsumer>
    </DebugProvider>
  );
};
