import type {
  clients,
  IPitcherClient,
  protocol,
} from "@codesandbox/pitcher-client";
import { DisposableStore } from "@codesandbox/pitcher-common";
import type { IEditorSelection } from "environment-interface/monacoEditor/browser/utils/selection-decorations";
import { createEditorSelections } from "environment-interface/monacoEditor/browser/utils/selection-decorations";
import { fromClientsDTO } from "features/utils/dto";
import type { FocusFileMessage } from "hooks/pitcher/useEditorChannel";
import type { editor, IDisposable } from "monaco-editor";
import { Position, Range } from "monaco-editor";
import { IEditorService, StandaloneServices } from "vscode/services";
import type {
  IDiffEditor,
  IEditor,
} from "vscode/vscode/vs/editor/common/editorCommon";

export function initCollaboratorsAvatar(pitcher: IPitcherClient): IDisposable {
  const disposableStore = new DisposableStore();

  const editorService = StandaloneServices.get(IEditorService);
  const disposableAvatarStore = new Set<{ dispose: () => void }>();

  let clients = fromClientsDTO(
    pitcher.clients.client.getClients(),
    pitcher.currentClient,
  );

  // On init
  mountSelectionActiveEditor();

  // On file change
  disposableStore.add(
    editorService.onDidVisibleEditorsChange(mountSelectionActiveEditor),
  );

  // Update clients
  disposableStore.add(
    pitcher.clients.client.onClientConnected(() => {
      clients = fromClientsDTO(
        pitcher.clients.client.getClients(),
        pitcher.currentClient,
      );
    }),
  );

  // Update clients
  disposableStore.add(
    pitcher.clients.client.onClientDisconnected(() => {
      clients = fromClientsDTO(
        pitcher.clients.client.getClients(),
        pitcher.currentClient,
      );
    }),
  );

  async function mountSelectionActiveEditor() {
    // Dispose old avatars
    disposableAvatarStore.forEach((item) => item.dispose());
    disposableAvatarStore.clear();

    const currentEditor = editorService.activeTextEditorControl;

    if (!currentEditor || !isCodeEditor(currentEditor)) {
      return;
    }

    const model = currentEditor.getModel();
    const path = model?.uri.path;

    if (!path) {
      return;
    }

    const pitcherFileRef = await pitcher.clients.file.openFileByPath(
      pitcher.clients.fs.absoluteToRelativeWorkspacePath(
        path as `$ABSOLUTE/${string}`,
      ),
    );

    if (!pitcherFileRef.object.document) {
      return;
    }

    const selections = getSelections(
      pitcherFileRef.object.document.clientSelections,
      clients,
      model,
      pitcher.currentClient,
    );

    if (!selections) {
      return;
    }

    const followChannel =
      pitcher.clients.channel.getFollowChannel<FocusFileMessage>("WORKSPACE");

    createHTMLDOMWidget({
      clients,
      selections,
      currentEditor,
      followChannel,
    });

    const disposeWatcher = pitcherFileRef.object.document.onSelection(
      (event) => {
        const selections = getSelections(
          event.clientSelections,
          clients,
          model,
          pitcher.currentClient,
        );

        if (!selections) {
          return;
        }

        createHTMLDOMWidget({
          clients,
          selections,
          currentEditor,
          followChannel,
        });
      },
    );

    disposableStore.add(disposeWatcher);
  }

  function createHTMLDOMWidget({
    clients,
    currentEditor,
    selections,
    followChannel,
  }: {
    clients: Record<string, protocol.client.ClientJSON>;
    selections: IEditorSelection[];
    currentEditor: editor.ICodeEditor;
    followChannel: clients.IFollowChannel<FocusFileMessage>;
  }) {
    disposableAvatarStore.forEach((item) => item.dispose());
    disposableAvatarStore.clear();

    Object.values(clients).forEach((client) => {
      function onClick() {
        const followedClient = followChannel.state.following.match({
          FOLLOWING: ({ clientId }) => clients[clientId],
          NOT_FOLLOWING: () => undefined,
        });

        if (followedClient?.clientId === client.clientId) {
          followChannel.unfollow();
        } else {
          followChannel.follow(client.clientId);
        }
      }

      if (pitcher.currentClient.clientId === client.clientId) {
        return;
      }

      const lineNumber = selections.find(
        (selection) => selection.clientId === client.clientId,
      );

      if (!lineNumber) {
        return;
      }

      const domNode = document.createElement("button");
      const wrapper = document.createElement("div");

      if (client.avatarUrl) {
        const img = document.createElement("img");
        img.src = client.avatarUrl;
        img.style.width = "100%";
        wrapper.style.border = `2px solid ${client.color}`;

        wrapper.appendChild(img);
      } else {
        wrapper.textContent = client.username[0].toUpperCase();
        wrapper.style.fontSize = "12px";
        wrapper.style.lineHeight = "20px";
        wrapper.style.fontWeight = "600";
        wrapper.style.backgroundColor = client.color;
      }

      wrapper.style.width = "20px";
      wrapper.style.height = "20px";
      wrapper.style.boxSizing = "border-box";

      wrapper.style.borderRadius = "99999px";
      wrapper.style.overflow = "hidden";

      domNode.onclick = onClick;
      domNode.style.paddingLeft = "5px";
      domNode.appendChild(wrapper);

      const widget = {
        getDomNode() {
          return domNode;
        },
        getId() {
          return "csb-glyph-margin-widget-avatar" + client.clientId;
        },
        getPosition() {
          return {
            lane: 1,
            zIndex: 100000,
            range: Range.fromPositions(
              new Position(lineNumber.range.startLineNumber, 1),
            ),
          };
        },
      };

      currentEditor.addGlyphMarginWidget(widget);

      disposableAvatarStore.add({
        dispose() {
          currentEditor.removeGlyphMarginWidget(widget);
        },
      });
    });
  }

  return {
    dispose() {
      disposableAvatarStore.forEach((item) => item.dispose());
      disposableAvatarStore.clear();

      disposableStore.dispose();
    },
  };
}

/**
 * Utils
 */
function isCodeEditor(
  editor: IEditor | IDiffEditor,
): editor is editor.ICodeEditor {
  return editor.getEditorType() === "vs.editor.ICodeEditor";
}

const getSelections = (
  clientSelections: clients.ClientSelections | undefined,
  clients: Record<string, protocol.client.ClientJSON>,
  model: editor.ITextModel,
  currentClient: protocol.client.ClientJSON,
) => {
  if (!clientSelections || model.isDisposed()) return;

  const lines = model.getLinesContent();
  const eolLength = model.getEOL().length;

  return createEditorSelections(
    lines,
    eolLength,
    clients,
    getSelectionsFromDocument(clientSelections),
    currentClient.clientId,
  );
};

const getSelectionsFromDocument = (
  clientSelections: clients.ClientSelections,
): protocol.file.IDocumentSelections => {
  const selections: protocol.file.IDocumentSelections = {};
  clientSelections.forEach((value, clientId) => {
    selections[clientId] = value;
  });
  return selections;
};
