import { basename } from "path";
import { Emitter, Disposable, ot, newCuid } from "@codesandbox/pitcher-common";
import { Rc } from "../../../common/Rc";
import { isText } from "../../is-binary";
import { BrowserFile } from "./BrowserFile";
export class BrowserFileClient extends Disposable {
    constructor(fs, filesState, seamlessFork) {
        super();
        this.fs = fs;
        this.filesState = filesState;
        this.seamlessFork = seamlessFork;
        this.onFileOpenEmitter = this.addDisposable(new Emitter());
        this.onFileOpen = this.onFileOpenEmitter.event;
        this.onFileJoinEmitter = this.addDisposable(new Emitter());
        this.onFileJoin = this.onFileJoinEmitter.event;
        this.onFileLeaveEmitter = this.addDisposable(new Emitter());
        this.onFileLeave = this.onFileLeaveEmitter.event;
        this.onFileSaveEmitter = this.addDisposable(new Emitter());
        this.onFileSave = this.onFileSaveEmitter.event;
        this.onFileMoveEmitter = this.addDisposable(new Emitter());
        this.onFileMove = this.onFileMoveEmitter.event;
        this.onFileDeleteEmitter = this.addDisposable(new Emitter());
        this.onFileDelete = this.onFileDeleteEmitter.event;
        this.openFiles = new Map();
        this.pathToFileId = new Map();
        fs.onFSSync(() => {
            for (const fileRc of this.openFiles.values()) {
                // Making TS happy
                const file = fileRc.object;
                const fileId = file.id;
                // Skip files that are opened with "file/openByPath"
                // if (this.openedPaths.getKey(fileId)) {
                //   continue;
                // }
                const foundPath = fs.getPathFromId(fileId);
                if (!foundPath) {
                    this.onFileDeleteEmitter.fire({
                        id: fileId,
                        path: file.path,
                    });
                }
                else if (file.path !== foundPath) {
                    this.onFileMoveEmitter.fire({
                        id: fileId,
                        newPath: foundPath,
                        oldPath: file.path,
                    });
                    file.updatePath(foundPath);
                }
            }
        });
    }
    hasDirtyFiles() {
        for (const [, openFile] of this.openFiles) {
            if (openFile.object.isDirty) {
                return true;
            }
        }
        return false;
    }
    setOpenFile(fileId, rcFile) {
        this.openFiles.set(fileId, rcFile);
        this.onFileOpenEmitter.fire(rcFile.object);
    }
    removeOpenFile(fileId) {
        this.openFiles.delete(fileId);
    }
    getFileIdFromPath(filepath) {
        return this.fs.getIdFromPath(filepath);
    }
    getPathFromFileId(fileId) {
        return this.fs.getPathFromId(fileId);
    }
    getIdFromWorkspaceUriString(uriString) {
        // Remove protocol
        const absolutePath = this.fs.asAbsoluteWorkspacePath(uriString.replace(/^.*:\/\//, ""));
        return this.getFileIdFromPath(this.fs.absoluteToRelativeWorkspacePath(absolutePath));
    }
    getOpenedFile(fileId) {
        const openFile = this.openFiles.get(fileId);
        if (openFile) {
            return openFile.object;
        }
    }
    getOpenFiles() {
        return Array.from(this.openFiles.values());
    }
    async openFile(fileId, isRetry = false) {
        const existingFileRef = this.openFiles.get(fileId);
        if (existingFileRef) {
            return existingFileRef.acquire();
        }
        const foundFile = this.filesState.get(fileId);
        // TODO: Is this what the error should look like?
        if (!foundFile) {
            if (!isRetry) {
                return this.fs.operationQueue.add(() => {
                    return this.openFile(fileId, true);
                });
            }
            throw new Error("File not found");
        }
        let content = foundFile.content;
        if (foundFile.isBinary) {
            const filepath = this.getPathFromFileId(fileId);
            if (!filepath) {
                throw new Error("File not found");
            }
            content = this.fs.readFileSync(filepath);
        }
        const file = this.addDisposable(new BrowserFile(this, this.getPathFromFileId(fileId), fileId, foundFile, content, this.seamlessFork));
        this.addDisposable(file.onDidSave((evt) => {
            const fileState = this.filesState.files.get(file.id);
            if (fileState && !fileState.isBinary) {
                fileState.content = file.content;
                fileState.savedContent = file.content;
            }
            this.onFileSaveEmitter.fire({
                id: file.id,
                path: file.path,
                savedHash: evt.savedHash,
            });
        }));
        this.addDisposable(file.onDidContentChange(() => {
            const fileState = this.filesState.files.get(file.id);
            if (fileState && !fileState.isBinary) {
                fileState.content = file.content;
            }
        }));
        const rcFile = new Rc(file, () => {
            this.removeOpenFile(fileId);
            file.dispose();
        });
        this.setOpenFile(fileId, rcFile);
        return rcFile.acquire();
    }
    async openReadOnlyFile(filepath, content) {
        const fileId = this.pathToFileId.get(filepath) ?? newCuid();
        if (fileId) {
            const existingFile = this.openFiles.get(fileId);
            if (existingFile) {
                return existingFile.acquire();
            }
        }
        this.pathToFileId.set(filepath, fileId);
        const filename = basename(filepath);
        const isBinary = !isText(filename, content);
        let normalizedContent = content;
        if (!isBinary) {
            const decoder = new TextDecoder();
            const decoded = decoder.decode(content);
            normalizedContent = decoded;
        }
        const file = new BrowserFile(this, this.fs.asRelativeWorkspacePath(filepath), fileId, {
            isBinary,
            content: filepath,
            savedContent: filepath,
        }, normalizedContent, this.seamlessFork);
        const rcFile = new Rc(file, () => {
            this.removeOpenFile(fileId);
            file.dispose();
        });
        this.setOpenFile(fileId, rcFile);
        return rcFile.acquire();
    }
    async openFileByPath(filepath) {
        const fileId = this.fs.getIdFromPath(filepath);
        if (!fileId) {
            const fileContent = await this.fs.readFile(filepath);
            if (fileContent.type === "ok") {
                return this.openReadOnlyFile(filepath, fileContent.result.content);
            }
            else {
                throw new Error("File not found");
            }
        }
        return this.openFile(fileId);
    }
    createDocumentOperationFromDiff(originalText, modifiedText) {
        return ot.createDiffTextOperation(originalText, modifiedText);
    }
    resync() {
        return Promise.resolve();
    }
}
