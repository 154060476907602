import { useEnvironmentInterface } from "environment-interface";
import { env } from "environment-interface/env";
import { useEditor } from "features/editor/Feature";
import { usePitcher } from "features/utils/usePitcher";
import {
  hasBranchWriteAccess,
  hasSandboxPermission,
} from "isomorphic/permission";
import { clearCache } from "registerEditorServiceWorker";

import { useServiceWorker } from "hooks/useServiceWorker";

export type EditorUpdateState =
  | {
      status: "EDITOR_CHECKING_VERSION";
    }
  | {
      status: "EDITOR_INSTALLING";
    }
  | {
      status: "EDITOR_UPDATING";
    }
  | {
      status: "EDITOR_UPDATED";
      restart(): void;
    }
  | {
      status: "EDITOR_UPDATE_ERROR";
      error: string;
      restart(): void;
    }
  | {
      status: "MICRO_VM_UPDATED";
      restart(): void;
    }
  | {
      status: "EDITOR_AND_MICRO_VM_UPDATED";
      restart(): void;
    };

export function useEditorUpdate(): EditorUpdateState | null {
  const environment = useEnvironmentInterface();
  const message = useServiceWorker();

  if (env.PUBLIC_ENVIRONMENT === "development") {
    return null;
  }

  let pitcher: ReturnType<typeof usePitcher> | null = null;
  let editor: ReturnType<typeof useEditor> | null = null;

  // This hook can be used in any context, also without necessarily Pitcher or Editor data. We still want
  // to ensure we have the latest version of the editor itself
  try {
    // eslint-disable-next-line
    pitcher = usePitcher();
    // eslint-disable-next-line
    editor = useEditor();
  } catch {
    // This is okay, we can still evaluate new editor version
  }

  // We notify micro VM update on default branches and devboxes you have write access to
  const shouldNotifyMicroVMUpdate = Boolean(
    // This hooks is used with GlobalError which might not have the editor data
    editor &&
      ((editor.type === "branch" &&
        hasBranchWriteAccess(editor.authorization) &&
        editor.branch === editor.defaultBranchName) ||
        (editor.type === "sandbox" &&
          editor.isCloud &&
          hasSandboxPermission(editor.authorization, "write_code"))),
  );

  if (!message) {
    return {
      status: "EDITOR_CHECKING_VERSION",
    };
  }

  // If we have an update to both the editor and the microVM, we'll restart the microVM
  // and refresh the browser to get latest editor
  if (
    pitcher &&
    editor &&
    message.type === "CACHE_UPDATED" &&
    !pitcher.isUpToDate() &&
    shouldNotifyMicroVMUpdate
  ) {
    return {
      status: "EDITOR_AND_MICRO_VM_UPDATED",
      restart: () => {
        environment.pitcherInstanceManager.stopPitcherEnvironment(editor!.id);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
    };
  }

  const hasVerifiedEditorVersion =
    message.type === "CACHE_CREATED" || message.type === "CACHE_IS_LATEST";

  // If we have verified current version and there is no new update to editor, but
  // there is an update to microVM, we restart the microVM
  if (
    pitcher &&
    editor &&
    hasVerifiedEditorVersion &&
    !pitcher.isUpToDate() &&
    shouldNotifyMicroVMUpdate
  ) {
    return {
      status: "MICRO_VM_UPDATED",
      restart: () => {
        environment.pitcherInstanceManager.stopPitcherEnvironment(editor!.id);
      },
    };
  }

  if (message.type === "CACHE_NOT_AVAILABLE") {
    return {
      status: "EDITOR_INSTALLING",
    };
  }

  if (message.type === "CACHE_EXPIRED") {
    return {
      status: "EDITOR_UPDATING",
    };
  }

  if (message.type === "CACHE_UPDATED") {
    return {
      status: "EDITOR_UPDATED",
      restart: () => {
        window.location.reload();
      },
    };
  }

  if (message.type === "CACHE_UPDATE_FAILED") {
    return {
      status: "EDITOR_UPDATE_ERROR",
      error: message.error,
      restart: () => {
        clearCache().then(() => {
          window.location.reload();
        });
      },
    };
  }

  return null;
}
