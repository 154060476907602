import { Disposable, Emitter } from "@codesandbox/pitcher-common";
/**
 * Exposes an imperative abstraction over the Channels Protocol of Pitcher. It automatically
 * keeps a state of subscribers and manages notification listeners
 * related to the channel being subscribed or not
 */
export class Channel extends Disposable {
    constructor(name, messageHandler) {
        super();
        this.name = name;
        this.messageHandler = messageHandler;
        this.onErrorEmitter = this.addDisposable(new Emitter());
        this.onJoinEmitter = this.addDisposable(new Emitter());
        this.onLeaveEmitter = this.addDisposable(new Emitter());
        this.onMessageEmitter = this.addDisposable(new Emitter());
        this.state = {
            hasSubscribed: false,
        };
        this.subscribers = new Set();
        this.onError = this.onErrorEmitter.event;
        this.onSubscribe = this.onJoinEmitter.event;
        this.onUnsubscribe = this.onLeaveEmitter.event;
        this.onMessage = this.onMessageEmitter.event;
        this.onWillDispose(() => {
            this.unsubscribe();
            this.subscribers = new Set();
            if (this.state.hasSubscribed) {
                this.state.disposeNotificationListeners();
            }
        });
    }
    listenToNotifications() {
        const onMessageDisposer = this.messageHandler.onNotification("channel/message", (message) => {
            if (message.name === this.name) {
                this.onMessageEmitter.fire({
                    clientId: message.clientId,
                    data: message.data,
                    isUser: message.isUser,
                });
            }
        });
        const onSubscribedDisposer = this.messageHandler.onNotification("channel/subscribed", (message) => {
            if (message.name === this.name) {
                this.subscribers = new Set(message.subscribers);
                this.onJoinEmitter.fire(message.clientId);
            }
        });
        const onUnsubscribedDisposer = this.messageHandler.onNotification("channel/unsubscribed", (message) => {
            if (message.name === this.name) {
                this.subscribers = new Set(message.subscribers);
                this.onLeaveEmitter.fire(message.clientId);
            }
        });
        return () => {
            onMessageDisposer();
            onSubscribedDisposer();
            onUnsubscribedDisposer();
        };
    }
    async subscribe() {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        if (this.state.hasSubscribed === true) {
            return;
        }
        // We immediately listen to make sure we we get messages as soon as
        // Pitcher sees the user as joined
        const disposeNotificationListeners = this.listenToNotifications();
        try {
            const result = await this.messageHandler.request({
                method: "channel/subscribe",
                params: {
                    name: this.name,
                },
            });
            this.subscribers = new Set(result.subscribers);
            this.state = {
                hasSubscribed: true,
                disposeNotificationListeners,
            };
        }
        catch (err) {
            const error = err;
            // Either you ignored the promise and listened to event,
            // or you waited for the promise
            disposeNotificationListeners();
            this.onErrorEmitter.fire({
                code: error.code,
                message: error.message,
            });
            throw new Error(error.message);
        }
    }
    async unsubscribe() {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        if (this.state.hasSubscribed === false) {
            return;
        }
        // We immediately dispose to avoid getting messages during leaving
        this.state.disposeNotificationListeners();
        this.state = {
            hasSubscribed: false,
        };
        try {
            await this.messageHandler.request({
                method: "channel/unsubscribe",
                params: {
                    name: this.name,
                },
            });
            this.subscribers = new Set();
            this.state = {
                hasSubscribed: false,
            };
        }
        catch (err) {
            // If something bad happens we need to listen to messages again
            const dispose = this.listenToNotifications();
            const error = err;
            this.state = {
                hasSubscribed: true,
                disposeNotificationListeners: dispose,
            };
            // Either you ignored the promise and listened to event,
            // or you waited for the promise
            this.onErrorEmitter.fire({
                code: error.code,
                message: error.message,
            });
            throw new Error(error.message);
        }
    }
    sendAll(data) {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        return this.messageHandler.request({
            method: "channel/message",
            params: {
                data,
                name: this.name,
            },
        });
    }
    send(subscribers, data) {
        if (this.isDisposed) {
            throw new Error("Channel is disposed");
        }
        return this.messageHandler.request({
            method: "channel/message",
            params: {
                data,
                name: this.name,
                clients: Array.from(subscribers),
            },
        });
    }
    resync() {
        if (this.state.hasSubscribed) {
            this.state.disposeNotificationListeners();
            this.state = {
                hasSubscribed: false,
            };
            this.subscribe();
        }
    }
}
