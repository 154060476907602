import { initializeCodeSandboxTheme } from "./codesandbox";
import { initializeDraculaThemes } from "./dracula";
import { initializeGitHubThemes } from "./github";
import { initializeNightOwlTheme } from "./night-owl";
import { initializeOneDarkProThemes } from "./onedarkpro";
import { initializePoimandresTheme } from "./poimandres";
import { initializeVesperThemes } from "./vesper";

export function initializeThemes() {
  initializeCodeSandboxTheme();
  initializeGitHubThemes();
  initializePoimandresTheme();
  initializeDraculaThemes();
  initializeVesperThemes();
  initializeNightOwlTheme();
  initializeOneDarkProThemes();
}
