import React, { useCallback } from "react";

import { useNotifications } from "./Feature";
import type {
  AlertNotification,
  StatusNotification,
  Notification,
} from "./types";

/**
 * Prebuilt notification hooks, mostly for moving configuration from the call-point to the declaration
 * & to give better type hints & defaults. Ideally all notification dispatches should pass from these.
 */

type UseDispatchStatusNotification = () => (notification: {
  message: StatusNotification["message"];
  status?: StatusNotification["status"];
  // context?: StatusNotification["context"];
  actions?: StatusNotification["actions"];
  uniqueName?: StatusNotification["uniqueName"];
  callback?: StatusNotification["callback"];
  timeout?: StatusNotification["timeout"];
  icon?: StatusNotification["icon"];
}) => void;

/**
 * Use to dispatch a status notification,
 * this will be rendered as an auto-dismissing toast at the bottom center of the screen.
 * Only one of these types of notifications will be rendered at any time.
 *
 * @example
 * ```tsx
 * const dispatch = useAddStatusNotification()
 * // ...
 * dispatch({ message: 'Hello' })
 * ```
 */
export const useAddStatusNotification: UseDispatchStatusNotification = () => {
  const [_, dispatch] = useNotifications();

  return useCallback(
    (notification) =>
      dispatch({
        type: "ADD_NOTIFICATION",

        notification: {
          status: "info",
          ...notification,
          showDismiss: true,
          type: "STATUS",
          // sound: "ping",
        },
      }),
    [dispatch],
  );
};

type UseAddAlertNotification = () => (notification: {
  message: AlertNotification["message"];
  actions?: AlertNotification["actions"];
  callback?: AlertNotification["callback"];
  showDismiss?: AlertNotification["showDismiss"];
  uniqueName?: AlertNotification["uniqueName"];
  timeout?: StatusNotification["timeout"];
  // context?: StatusNotification["context"];
}) => void;

/**
 * Use to dispatch an alert notification,
 * this will be rendered as an user-dismissed banner at the bottom left of the screen.
 * These alerts will create a stack.
 *
 * @example
 * ```tsx
 * const dispatch = useAddAlertNotification()
 * // ...
 * dispatch({ message: 'Hello' })
 * ```
 */
export const useAddAlertNotification: UseAddAlertNotification = () => {
  const [_, dispatch] = useNotifications();

  return useCallback(
    (notification) =>
      dispatch({
        type: "ADD_NOTIFICATION",

        notification: {
          actions: notification.actions,
          ...notification,
          showDismiss:
            typeof notification.showDismiss === "boolean"
              ? notification.showDismiss
              : true,
          type: "ALERT",
          // sound: "gong",
        },
      }),
    [dispatch],
  );
};

type UseDismissNotification = () => (id: Notification["id"]) => void;

/**
 * Use to dismiss a notification of id.
 *
 * @example
 * ```tsx
 * const dismiss = useDismissNotification()
 * // ...
 * onDismiss={() => dismiss(notification.id)}
 * ```
 */
export const useDismissNotification: UseDismissNotification = () => {
  const [_, dispatch] = useNotifications();

  return useCallback(
    (id) => {
      dispatch({
        type: "DISMISS_NOTIFICATION",

        id,
      });
    },
    [dispatch],
  );
};

/**
 * Some prebuilt hooks to get a filtered array of a specific type of notifications from the notifications map
 */

/**
 * Returns all notifications with type STATUS
 */
export const useStatusNotifications: () => StatusNotification[] = () => {
  const [{ items: notifications }] = useNotifications();

  return React.useMemo(
    () =>
      Object.values(notifications).filter(
        (n) => n.type === "STATUS",
      ) as StatusNotification[],
    [notifications],
  );
};

/**
 * Returns all notifications with type ALERT
 */
export const useAlertNotifications: () => AlertNotification[] = () => {
  const [{ items: notifications }] = useNotifications();

  return React.useMemo(
    () =>
      Object.values(notifications).filter(
        (n) => n.type === "ALERT",
      ) as AlertNotification[],
    [notifications],
  );
};
