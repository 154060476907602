import React from "react";

import { styled } from "../../theme";

import { getInitial } from "./utils";

export const InitialWrapper = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

interface Props {
  name: string;
}

export const Initial: React.FC<Props> = ({ name = "Anonymous" }) => {
  return <InitialWrapper>{name ? getInitial(name) : "A"}</InitialWrapper>;
};
