import { NpmRegistryFetcher } from "./registry-fetcher/npm-registry";
/**
 * Fetches all matching packages for the given registry info, and also fetches
 * all subdependencies of those packages and makes sure that those are also
 * matched if they match the registry.
 */
async function getMatchingPackages(packageJson, privateRegistryInfo, fetcher, matchingPackagesProgress = []) {
    const allDependencies = {
        ...(packageJson.dependencies || {}),
        // Don't add dev dependencies to the list of dependencies, because some companies
        // put packages in there that are not available in any registry
        // ...(packageJson.devDependencies || {}),
    };
    const matchingPackages = Object.keys(allDependencies)
        .filter((dependency) => {
        if (privateRegistryInfo.limitToScopes) {
            const scope = dependency.split("/")[0];
            return privateRegistryInfo.enabledScopes.includes(scope);
        }
        return true;
    })
        .filter((name) => {
        return !matchingPackagesProgress.some((pkg) => pkg.name === name);
    });
    matchingPackages.forEach((name) => {
        matchingPackagesProgress.push({ name, version: allDependencies[name] });
    });
    const matchingSubDependencies = await Promise.all(matchingPackages.map(async (name) => {
        const version = allDependencies[name];
        const packageJson = await fetcher.file(name, version, "/package.json");
        const parsed = JSON.parse(new TextDecoder().decode(packageJson));
        return getMatchingPackages(parsed, privateRegistryInfo, fetcher, matchingPackagesProgress);
    }));
    matchingPackagesProgress.push(...matchingSubDependencies.flat());
    return matchingPackagesProgress;
}
const fetchers = {};
function getNpmFetcher(privateRegistryInfo) {
    if (fetchers[privateRegistryInfo.registryUrl]) {
        return fetchers[privateRegistryInfo.registryUrl];
    }
    let cleanUrl = privateRegistryInfo.registryUrl.replace(/\/$/, "");
    if (process.env.NODE_ENV === "development" && cleanUrl.startsWith("/")) {
        // We're in a v2 editor that doesn't proxy /v1/api, we need to explicitly set the URL correctly
        cleanUrl = `https://codesandbox.stream${cleanUrl}`;
    }
    const options = {
        proxyEnabled: privateRegistryInfo.proxyEnabled ?? true,
    };
    if (privateRegistryInfo.limitToScopes) {
        options.scopeWhitelist = privateRegistryInfo.enabledScopes;
    }
    if (options.proxyEnabled) {
        // With our custom proxy on the server we want to handle downloading
        // the tarball. So we proxy it.
        options.provideTarballUrl = (name, version) => `${cleanUrl}/${name.replace("/", "%2f")}/${version}`;
    }
    if (privateRegistryInfo.registryAuthKey) {
        options.authToken = privateRegistryInfo.registryAuthKey;
    }
    const fetcher = new NpmRegistryFetcher(cleanUrl, options);
    fetchers[privateRegistryInfo.registryUrl] = fetcher;
    return fetcher;
}
export async function fetchPrivatePackagesFromPackageJSON(packageJson, privateRegistryInfos) {
    const fetchResult = {
        files: {},
        packages: [],
    };
    for (const privateRegistryInfo of privateRegistryInfos) {
        const fetcher = getNpmFetcher(privateRegistryInfo);
        const matchingPackages = await getMatchingPackages(packageJson, privateRegistryInfo, fetcher);
        if (matchingPackages.length === 0) {
            continue;
        }
        const results = await Promise.all(matchingPackages.map(async ({ name, version }) => ({
            name,
            version,
            files: await fetcher.massFiles(name, version),
        })));
        results.forEach((result) => {
            Object.keys(result.files).forEach((path) => {
                fetchResult.files[`/node_modules/${result.name}${path}`] =
                    result.files[path];
            });
            fetchResult.packages.push(result.name);
        });
    }
    return fetchResult;
}
