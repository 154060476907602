import { env } from "environment-interface/env";

/**
 * Next.js useRouter() hook will return a query object
 * containing all query parameters. These can either be simple
 * strings or an array of strings, in case there are multiple values
 * for the same key (e.g. ?foo=bar&foo=baz). Most of the times though,
 * consumers will only need to get a simple string from the query params.
 *
 * This function abstracts the logic needed to make this pattern easy to use.
 *
 * e.g. on url `/post?id=1`
 * const { query } = useRouter()
 * getQueryParam(query, 'id') // will return '1'
 *
 * e.g. on url `/post?title=foo&title=bar`
 * const { query } = useRouter()
 * getQueryParam(query, 'title') // will return 'foo'
 */
export const getQueryParam = (
  query: Record<string, string>,
  key: string,
): string | undefined => {
  const queryKey = query?.[key];
  if (Array.isArray(queryKey)) {
    return queryKey?.[0];
  }

  return queryKey;
};

export const getBranchQueryParams = (query: Record<string, string>) => {
  const workspaceId = getQueryParam(query, "workspaceId");
  const owner = getQueryParam(query, "owner");
  const repo = getQueryParam(query, "repo");
  const preventWorkspaceRedirect =
    getQueryParam(query, "preventWorkspaceRedirect") === "true";
  const createBranch = getQueryParam(query, "create") === "true";

  const branch =
    query["branchParts"] && Array.isArray(query.branchParts)
      ? query.branchParts.join("/")
      : query["branchParts"];

  return {
    workspaceId,
    owner,
    repo,
    preventWorkspaceRedirect,
    branch: branch || "",
    createBranch,
  };
};

export const getSandboxQueryParams = (query: Record<string, string>) => {
  const workspaceId = getQueryParam(query, "workspaceId");
  const id = getQueryParam(query, "id");

  return {
    workspaceId,
    id,
  };
};

export const getBranchUrlParams = (pathname: string) => {
  const [owner, repo, ...branchParts] = pathname
    .substring("/github".length + 1)
    .split("/");

  return {
    owner,
    repo,
    branch: branchParts?.join("/"),
  };
};

export const getSandboxUrlParams = (pathname: string) => {
  return {
    id: pathname.substring("/sandbox".length + 1),
  };
};

export const getDevboxUrlParams = (pathname: string) => {
  return {
    id: pathname.substring("/devbox".length + 1),
  };
};

export const asUrlParams = (
  values: Record<string, string | undefined | null>,
): string => {
  const urlValues: Record<string, string> = {};

  for (const key in values) {
    const value = values[key];

    if (value) {
      urlValues[key] = value;
    }
  }

  if (Object.keys(urlValues).length === 0) return "";

  return `?${new URLSearchParams(urlValues).toString()}`;
};

export const paths = {
  userProfile: (userId: string) => `${env.PUBLIC_V1_URL}/u/${userId}`,
  dashboard: {
    drafts: (teamId?: string) =>
      `${env.PUBLIC_V1_URL}/dashboard/drafts${asUrlParams({
        workspace: teamId,
      })}`,
    collection: (path: string, teamId?: string) =>
      `${env.PUBLIC_V1_URL}/dashboard/sandboxes${path}${asUrlParams({
        workspace: teamId,
      })}`,
    recent: (
      teamId?: string,
      queryParams: Record<string, string> = {},
    ): string =>
      `${env.PUBLIC_V1_URL}/dashboard/recent${asUrlParams({
        workspace: teamId,
        ...queryParams,
      })}`,
    repositories: (teamId?: string) =>
      `${env.PUBLIC_V1_URL}/dashboard/repositories${asUrlParams({
        workspace: teamId,
      })}`,
    branches: (org: string, repo: string, teamId: string | null): string =>
      `${
        env.PUBLIC_V1_URL
      }/dashboard/repositories/github/${org}/${repo}${asUrlParams({
        workspace: teamId ? teamId : undefined,
      })}`,
    sandboxes: (teamId?: string) =>
      `${env.PUBLIC_V1_URL}/dashboard/sandboxes${asUrlParams({
        workspace: teamId,
      })}`,
    workspaceSettings: (teamId: string) =>
      `${env.PUBLIC_V1_URL}/t/overview${asUrlParams({
        workspace: teamId,
      })}`,
    workspaceVMSettings: (teamId?: string) =>
      `${env.PUBLIC_V1_URL}/t/vm_settings${asUrlParams({
        workspace: teamId,
      })}`,
    permission: (teamId: string) =>
      `${env.PUBLIC_V1_URL}/t/permissions${asUrlParams({
        workspace: teamId,
      })}`,
    experiments: (teamId?: string) =>
      `${env.PUBLIC_V1_URL}/dashboard/recent${asUrlParams({
        workspace: teamId,
        preferences: "experiments",
      })}`,
    upgrade: (teamId?: string) =>
      `${env.PUBLIC_V1_URL}/upgrade${asUrlParams({
        workspace: teamId,
      })}`,
  },
  editorV1: {
    sandbox: (id: string) => `${env.PUBLIC_V1_URL}/s/${id}`,
  },
  upgrade: (params: { source: string; workspaceId: string | null }): string => {
    return `${env.PUBLIC_V1_URL}/upgrade${asUrlParams({
      workspace: params.workspaceId,
      utm_source: params.source,
    })}`;
  },
  manageBilling: (params: {
    source: string;
    workspaceId: string | null;
  }): string => {
    return `${env.PUBLIC_V1_URL}/t/billing${asUrlParams({
      workspace: params.workspaceId,
      utm_source: params.source,
    })}`;
  },
  editor: {
    sandbox: (path: string) => `/sandbox/${path}`,
    branch: (
      org: string,
      repo: string,
      branch: string,
      workspaceId: string | null,
      isImport = false,
    ): string => {
      const searchParams = new URLSearchParams();

      if (workspaceId) {
        searchParams.set("workspaceId", workspaceId);
      }
      if (isImport) {
        searchParams.set("import", "true");
      }

      return `/github/${org}/${repo}/${branch}?${searchParams.toString()}`;
    },

    /**
     *
     * @param org - The github organization to which the repository belongs
     * @param repo - The name of the github repository
     * @param qsObject - Additional query string parameters to add to the URL
     * @returns
     */
    draftBranch: (
      org: string,
      repo: string,
      qsObject: Record<string, string> = {},
    ): string => {
      const searchParams = new URLSearchParams({
        create: "true",
        ...qsObject,
      });

      return `/github/${org}/${repo}?${searchParams.toString()}`;
    },
  },
};

export const externalPaths = {
  integrations: {
    githubAuth: `${env.PUBLIC_API_DOMAIN}/auth/github/oauth-settings`,
    githubApp: `${env.PUBLIC_API_DOMAIN}/auth/github/app-install`,
  },
  calendly: {
    bookAChat: "https://www.youtube.com/watch?v=aSDSpRxkTnY",
  },
};
