import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import React from "react";

import { styled } from "../../theme";

const CollapsibleBase = styled(CollapsiblePrimitive.Root, {});

export interface CollapsibleProps
  extends CollapsiblePrimitive.CollapsibleProps {
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export const Collapsible: React.FC<CollapsibleProps> = ({
  children,
  onOpenChange,
  open,
}) => {
  return (
    <CollapsibleBase onOpenChange={onOpenChange} open={open}>
      {children}
    </CollapsibleBase>
  );
};
