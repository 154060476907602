// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import { env } from "environment-interface/env";

import { IGNORE_ERRORS } from "./sentry.ignore";

export function initializeSentry() {
  const environment = (() => {
    if (window.location.host.endsWith(".stream")) return "stream";

    if (window.location.host.endsWith(".io")) return "production";

    return "development";
  })();

  if (environment === "development") return;

  Sentry.init({
    sampleRate: 0.1,
    // Disabled to prevent too many requests in the editor
    // See https://linear.app/codesandbox/issue/CSB-2421/proposal-make-the-sentry-requests-invisible-for-the-browser-or-do-less
    autoSessionTracking: false,
    release: env.PUBLIC_VERSION,
    dsn: env.PUBLIC_SENTRY_DSN,
    ignoreErrors: IGNORE_ERRORS,
    environment,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations: function (integrations) {
      return (
        integrations
          // integrations will be all default integrations
          .filter(function (integration) {
            // We do not want the TryCatch integration cause it will report errors to Sentry on
            // any async error: https://docs.sentry.io/platforms/javascript/configuration/integrations/default/?original_referrer=https%3A%2F%2Fwww.npmjs.com%2F#trycatch
            return integration.name !== "TryCatch";
          })
          .concat(
            new CaptureConsole({
              levels: ["error"],
            }),
          )
      );
    },
  });
}
