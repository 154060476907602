/* eslint-disable @typescript-eslint/no-explicit-any */
import { Disposable } from "@codesandbox/pitcher-common";
import { PitcherResponseStatus, createRequestPayload, } from "@codesandbox/pitcher-protocol";
const PITCHER_MESSAGE_TIMEOUT_MS = 90000;
export class PitcherMessageError extends Error {
    constructor(message, code) {
        super(message);
        this.code = code;
    }
    static match(error) {
        return error instanceof PitcherMessageError;
    }
    static matchCode(error, code) {
        return error instanceof PitcherMessageError && error.code === code;
    }
}
export class PendingPitcherMessage extends Disposable {
    constructor(id, request, timeoutMs = PITCHER_MESSAGE_TIMEOUT_MS) {
        super();
        this._hasResolved = false;
        this.id = id;
        const data = {
            ...request,
            id,
        };
        this.method = request.method;
        this.message = createRequestPayload(data);
        this.timeoutRef = setTimeout(() => this.dispose(`Pitcher message ${this.method} timed out`), timeoutMs);
        this.promise = new Promise((_resolve, _reject) => {
            this._resolve = _resolve;
            this._reject = _reject;
        }).then((response) => {
            if (response.status === PitcherResponseStatus.RESOLVED) {
                return response.result;
            }
            const err = new PitcherMessageError(response.error.message, response.error.code);
            // @ts-expect-error - data is optional
            err.data = response.error.data;
            throw err;
        });
    }
    resolve(response) {
        if (!this.isDisposed && this._resolve) {
            this._resolve(response);
            this._hasResolved = true;
        }
        this.dispose();
    }
    reject(error) {
        if (!this.isDisposed && this._reject) {
            this._reject(error);
            this._hasResolved = true;
        }
        this.dispose();
    }
    unwrap() {
        return this.promise;
    }
    dispose(message) {
        if (!this._hasResolved && this._reject) {
            this._reject(new Error(message ?? `Pitcher message ${this.method} has been disposed`));
        }
        super.dispose();
        if (this.timeoutRef) {
            clearTimeout(this.timeoutRef);
            this.timeoutRef = undefined;
        }
    }
}
