import type { IDisposable, IPitcherClient } from "@codesandbox/pitcher-client";
import { Disposable } from "@codesandbox/pitcher-client";
import type { fs } from "@codesandbox/pitcher-protocol";
import { FS_SCHEME, relativePathToResource } from "environment-interface";
import type { Progress, TextSearchResult } from "vscode";
import { Position, Range } from "vscode";
import { registerExtension } from "vscode/extensions";
import { DisposableStore } from "vscode/monaco";

import { runWithLatestMonacoConfig } from "../../tools/with-monaco-config";

/**
 * Checks if the characters of the provided query string are included in the
 * target string. The characters do not have to be contiguous within the string.
 */
function fuzzyContains(target: string, query: string): boolean {
  if (!target || !query) {
    return false; // return early if target or query are undefined
  }

  if (target.length < query.length) {
    return false; // impossible for query to be contained in target
  }

  const queryLen = query.length;
  const targetLower = target.toLowerCase();

  let index = 0;
  let lastIndexOf = -1;
  while (index < queryLen) {
    const indexOf = targetLower.indexOf(query[index], lastIndexOf + 1);
    if (indexOf < 0) {
      return false;
    }

    lastIndexOf = indexOf;

    index++;
  }

  return true;
}

export async function activate(): Promise<IDisposable> {
  const { dispose, getApi } = registerExtension(
    {
      name: "pitcher-file-search",
      version: "1.0.0",
      engines: {
        vscode: "*",
      },
      publisher: "codesandbox",
      enabledApiProposals: ["fileSearchProvider", "textSearchProvider"],
    },
    1,
    { system: true },
  );

  const api = await getApi();

  const disposableStore = new DisposableStore();
  disposableStore.add({ dispose });
  disposableStore.add(
    runWithLatestMonacoConfig(({ pitcher }) => {
      const disposableStore = new DisposableStore();
      const { fs } = pitcher.clients;
      disposableStore.add(
        api.workspace.registerFileSearchProvider(FS_SCHEME, {
          async provideFileSearchResults(query, options, _token) {
            const files = Array.from(fs.memoryFS.tree.nodes.values()).filter(
              (node) =>
                node.type === 0 &&
                fuzzyContains(node.path, query.pattern.toLocaleLowerCase()),
            );
            files.length = Math.min(
              files.length,
              options.maxResults || files.length,
            );

            return files.map((node) => {
              return relativePathToResource(node.path);
            });
          },
        }),
      );

      disposableStore.add(
        api.workspace.registerTextSearchProvider(FS_SCHEME, {
          async provideTextSearchResults(query, options, progress, token) {
            const cancelDisposer = new Disposable();
            const disposableToken = token.onCancellationRequested(() => {
              cancelDisposer.dispose();
              disposableToken.dispose();
            });

            // The regexp tool used in Pitcher does not have a wordMatch option,
            // so we use a regexp to achieve the same thing
            const text = query.isWordMatch
              ? `\b${query.pattern}\b}`
              : query.pattern;
            const isRegex = query.isRegExp || query.isWordMatch;

            const fsArgs: fs.FSSearchParams = {
              text,
              caseSensitivity: query.isCaseSensitive ? "enabled" : "disabled",
              isRegex,
              glob: [
                ...options.includes,
                ...options.excludes.map((glob) => `!${glob}`),
              ].join("\n"),
            };

            let limitHit = false;

            if (pitcher.capabilities.fs?.streamingSearch) {
              const { hitLimit } = await pitcher.clients.fs.streamingSearch(
                fsArgs,
                mapResultsToVSCode(pitcher, progress),
                cancelDisposer,
              );
              limitHit = hitLimit;
            } else {
              const matches = await pitcher.clients.fs.search(fsArgs);
              mapResultsToVSCode(pitcher, progress)(matches);
            }

            return { limitHit };
          },
        }),
      );

      return disposableStore;
    }),
  );

  return disposableStore;
}

function mapResultsToVSCode(
  pitcher: IPitcherClient,
  progress: Progress<TextSearchResult>,
): (matches: Array<fs.StreamingSearchResult | fs.SearchResult>) => void {
  return (matches) => {
    matches.forEach((match) => {
      let uri;
      if ("filepath" in match) {
        uri = relativePathToResource(match.filepath);
      } else {
        const relativePath = pitcher.clients.fs.getPathFromId(match.fileId);
        if (!relativePath) {
          return;
        }
        uri = relativePathToResource(relativePath);
      }

      progress.report({
        uri,
        lineNumber: match.lineNumber,
        ranges: match.submatches.map(
          (submatch) =>
            new Range(
              new Position(match.lineNumber - 1, submatch.start),
              new Position(match.lineNumber - 1, submatch.end),
            ),
        ),
        text: match.lines ? match.lines.text : "",
        preview: match.lines
          ? {
              text: match.lines.text,
              matches: match.submatches.map(
                (submatch) =>
                  new Range(
                    new Position(0, submatch.start),
                    new Position(0, submatch.end),
                  ),
              ),
            }
          : undefined,
      });
    });
  };
}
