import { styled } from "prism-react/theme";

import { Box } from "../Box";

// The following styles are defined to match
// the stripe message on the v1 component.
export const StyledButton = styled("button", {
  position: "relative",
  width: "fit-content",
  height: "26px",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  border: "none",
  px: "$2",
  borderRadius: "$2",

  fontSize: "$caption1",
  fontFamily: "$base",
  fontWeight: "$medium",
  letterSpacing: "$base",
  lineHeight: 1,
  textDecoration: "none",

  variants: {
    variant: {
      trial: {
        backgroundColor: "$neutral-bg-base",
        color: "$neutral-fg-high",

        "&:hover:not([disabled])": {
          backgroundColor: "$neutral-bg-high",
        },
      },
      warning: {
        backgroundColor: "#252525",
        color: "#EBEBEB",

        "&:hover:not([disabled])": {
          backgroundColor: "#343434", // four down in the black scale (black[100])
        },
      },
      error: {
        backgroundColor: "$neutral-bg-base",
        color: "$neutral-fg-high",

        "&:hover:not([disabled])": {
          backgroundColor: "$neutral-bg-high",
        },
      },
      info: {
        backgroundColor: "$neutral-bg-base",
        color: "$neutral-fg-high",

        "&:hover:not([disabled])": {
          backgroundColor: "$neutral-bg-high",
        },
      },
    },
    loading: {
      true: {
        visibility: "hidden",
      },
      false: {
        visibility: "initial",
      },
    },
  },
});

export const StyledMessage = styled(Box, {
  position: "relative",
  px: "$4",

  variants: {
    variant: {
      trial: {
        backgroundColor: "#644ED7",
        color: "#FFFFFF",
      },
      warning: {
        backgroundColor: "#F7CC66",
        color: "#0E0E0E",
      },
      error: {
        backgroundColor: "$informative-error-base",
        color: "$neutral-bg-base",
      },
      info: {
        backgroundColor: "$informative-info-base",
        color: "$neutral-bg-base",
      },
    },
    hasAction: {
      true: {
        py: "$2",
      },
      false: {
        py: "$3",
      },
    },
    corners: {
      rounded: {
        borderRadius: "$2",
      },
      "rounded-bottom": {
        borderRadius: "0 0 $2 $2",
      },
      straight: {},
    },
  },
});
