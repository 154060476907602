import { Root } from "@radix-ui/react-dropdown-menu";
import React from "react";

import { useTheme } from "../../theme";
import { onGrid } from "../../tokens/space";
import { Icon } from "../Icon";
import { Stack } from "../Stack";

import { DropdownContent } from "./DropdownContent";
import { DropdownSubmenuTrigger } from "./DropdownSubmenuTrigger";

export interface DropdownSubmenuProps
  extends React.ComponentProps<typeof DropdownSubmenuTrigger> {
  trigger: React.ReactNode;
}

export const DropdownSubmenu: React.FC<DropdownSubmenuProps> = ({
  children,
  trigger,
  ...triggerProps
}) => {
  const theme = useTheme();

  return (
    <Root>
      <DropdownSubmenuTrigger {...triggerProps}>
        <Stack
          css={{ justifyContent: "space-between", width: "100%" }}
          horizontal
        >
          <span>{trigger}</span>
          <Stack
            css={{
              height: "$4",
              width: "$4",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon size={2} type="caret" />
          </Stack>
        </Stack>
      </DropdownSubmenuTrigger>
      <DropdownContent className={theme.className} sideOffset={0 - onGrid(1)}>
        {children}
      </DropdownContent>
    </Root>
  );
};
