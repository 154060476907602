import type { CodeSandboxApi } from "@codesandbox/api";
import { createContext } from "@codesandbox/context";
import { useEnvironmentInterface } from "environment-interface";
import { toSentryContext } from "features/utils/sentry";
import { useEffect } from "react";

import { csbApi } from "utils/csbApi";

export const useCsbApi = createContext(({ api }: { api?: CodeSandboxApi }) => {
  // We still allow to pass a specific API instance for testing, but fall
  // back to the global instance
  const activeApi = api || csbApi;

  const { amplitude, sentry } = useEnvironmentInterface();

  useEffect(
    () =>
      activeApi.session.onSessionChange(({ session }) => {
        switch (session.state) {
          case "AUTHENTICATED": {
            amplitude.setUserId(session.user.id);
            break;
          }
          case "UNAUTHENTICATED": {
            amplitude.resetSession();
            if (session.error) {
              sentry.reportError(
                session.error,
                toSentryContext("session", {
                  event: "SESSION_CHANGE",
                  state: "UNAUTHENTICATED",
                }),
              );
            }
            break;
          }
        }
      }),
    [],
  );

  return activeApi;
});
