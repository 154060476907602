import React from "react";

import type { DropdownProps } from "./Dropdown";
import { Dropdown as DropdownDefault } from "./Dropdown";
import { DropdownCheckbox } from "./DropdownCheckbox";
import { DropdownItem } from "./DropdownItem";
import { DropdownLabel } from "./DropdownLabel";
import { DropdownRadio, DropdownRadioGroup } from "./DropdownRadio";
import { DropdownSeparator } from "./DropdownSeparator";
import { DropdownSubmenu } from "./DropdownSubmenu";
interface IDropdown extends React.FC<DropdownProps> {
  Checkbox: typeof DropdownCheckbox;
  Item: typeof DropdownItem;
  Label: typeof DropdownLabel;
  Radio: typeof DropdownRadio;
  RadioGroup: typeof DropdownRadioGroup;
  Separator: typeof DropdownSeparator;
  SubMenu: typeof DropdownSubmenu;
}

const Dropdown: IDropdown = (props) => <DropdownDefault {...props} />;
Dropdown.Checkbox = DropdownCheckbox;
Dropdown.Item = DropdownItem;
Dropdown.Label = DropdownLabel;
Dropdown.Radio = DropdownRadio;
Dropdown.RadioGroup = DropdownRadioGroup;
Dropdown.Separator = DropdownSeparator;
Dropdown.SubMenu = DropdownSubmenu;

export { Dropdown };
