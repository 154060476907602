import { safeLocalStorage } from "utils/safeLocalStorage";

import type { ThemeColorSchema } from "./constants";
import {
  BUILT_IN_DARK_COLOR_SCHEMA,
  BUILT_IN_LIGHT_COLOR_SCHEMA,
  THEME_LOCAL_STORAGE_KEY,
} from "./constants";

export const toStitchesToken = (token: string | number): string => `$${token}`;

export const saveColorSchemaToStorage = (data: ThemeColorSchema) => {
  // Is it built-in color schema
  if (
    data.name === BUILT_IN_DARK_COLOR_SCHEMA.name ||
    data.name === BUILT_IN_LIGHT_COLOR_SCHEMA.name
  ) {
    delete data["colors"];
  }

  safeLocalStorage.set(THEME_LOCAL_STORAGE_KEY, JSON.stringify(data));
};

export const standarlizeColorSchemaName = (name: string): string =>
  name.replace("CodeSandbox ", "").toLowerCase();

export const getColorSchemaFromStorage = () => {
  try {
    const dataFromStorage = safeLocalStorage.get(THEME_LOCAL_STORAGE_KEY);
    if (!dataFromStorage) {
      return BUILT_IN_DARK_COLOR_SCHEMA;
    }

    const initialData: ThemeColorSchema = JSON.parse(dataFromStorage);

    return initialData;
  } catch {
    return BUILT_IN_DARK_COLOR_SCHEMA;
  }
};
