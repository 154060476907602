export function getApiUtils(csbApi, getSandboxId) {
    return {
        getSandbox: async () => {
            return csbApi
                .get({
                path: `/v1/sandboxes/${getSandboxId()}`,
            })
                .then((response) => response.data);
        },
        createModule: async (data) => {
            return csbApi
                .post({
                path: `/v1/sandboxes/${getSandboxId()}/modules`,
                data: {
                    module: {
                        title: data.title,
                        directory_shortid: data.directoryShortId,
                        code: data.code,
                        is_binary: data.isBinary === undefined ? false : data.isBinary,
                    },
                },
            })
                .then((response) => response.data);
        },
        deleteModule: async (moduleShortid) => {
            await csbApi.delete({
                path: `/v1/sandboxes/${getSandboxId()}/modules/${moduleShortid}`,
            });
        },
        saveModuleCode: async (moduleShortid, newCode) => {
            return csbApi.put({
                path: `/v1/sandboxes/${getSandboxId()}/modules/${moduleShortid}`,
                data: {
                    module: {
                        code: newCode,
                    },
                },
            });
        },
        saveModules: async (updates) => {
            return csbApi.put({
                path: `/v1/sandboxes/${getSandboxId()}/modules/mupdate`,
                data: {
                    modules: updates,
                },
            });
        },
        changeModuleDirectory(moduleShortid, directoryShortid) {
            return csbApi.put({
                path: `/v1/sandboxes/${getSandboxId()}/modules/${moduleShortid}`,
                data: {
                    module: {
                        directory_shortid: directoryShortid,
                    },
                },
            });
        },
        saveModuleTitle(moduleShortid, title) {
            return csbApi.put({
                path: `/v1/sandboxes/${getSandboxId()}/modules/${moduleShortid}`,
                data: {
                    module: { title },
                },
            });
        },
        createDirectory: async (title, parentId) => {
            return csbApi
                .post({
                path: `/v1/sandboxes/${getSandboxId()}/directories`,
                data: {
                    directory: {
                        title,
                        directory_shortid: parentId,
                    },
                },
            })
                .then((response) => response.data);
        },
        changeDirectoryParentDirectory(sourceDirectoryShortid, targetDirectoryShortId) {
            return csbApi.put({
                path: `/v1/sandboxes/${getSandboxId()}/directories/${sourceDirectoryShortid}`,
                data: {
                    directory: {
                        directory_shortid: targetDirectoryShortId,
                    },
                },
            });
        },
        deleteDirectory(directoryShortid) {
            return csbApi.delete({
                path: `/v1/sandboxes/${getSandboxId()}/directories/${directoryShortid}`,
            });
        },
        saveDirectoryTitle(directoryShortid, title) {
            return csbApi.put({
                path: `/v1/sandboxes/${getSandboxId()}/directories/${directoryShortid}`,
                data: {
                    directory: { title },
                },
            });
        },
        /** Upload binary file, does not work with text files, content should be a base64 encoded data-url */
        createUpload(name, content) {
            return csbApi.post({
                path: "/v1/users/current_user/uploads",
                data: {
                    content,
                    name,
                },
            });
        },
    };
}
