import type { CurrentUserDTO, UserDTO } from "environment-interface/api";
import type { CsbApi } from "environment-interface/csbApi";

export function fetchCurrentUser(
  csbApi: CsbApi,
  headers: Record<string, string>,
) {
  return csbApi.rest
    .get<{ data: UserDTO; githubProfile?: { scopes: string[] } }>({
      path: "/v1/users/current",
      headers,
    })
    .then(({ data: user, githubProfile }): CurrentUserDTO => {
      let githubIntegration = null;

      if (user.integrations.github) {
        // Backwards compatibility.
        if (githubProfile) {
          githubIntegration = {
            email: user.integrations.github.email,
            scopes: githubProfile.scopes,
          };
        }

        // Check for GitHub profile inside user data.
        if (user.githubProfile.data) {
          githubIntegration = {
            email: user.integrations.github.email,
            scopes: user.githubProfile.data.scopes,
          };
        }
      }

      return {
        avatarUrl: user.avatarUrl,
        email: user.email,
        id: user.id,
        name: user.name,
        provider: user.provider,
        username: user.username,
        githubIntegration: githubIntegration,
        githubProfile: user.githubProfile,
      };
    });
}
