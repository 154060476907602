/* eslint-disable @typescript-eslint/no-explicit-any */
import type { clients, protocol } from "@codesandbox/pitcher-client";
import type { PitcherEvents } from "environment-interface/pitcher";
import type { LanguageApi } from "environment-interface/pitcher/language";

export const createLanguageApi = (
  languageClient: clients.ILanguageClient,
  pitcherEvents: PitcherEvents,
): LanguageApi => {
  languageClient.onLspNotification((params) => {
    pitcherEvents.emit({
      type: "PITCHER:LANGUAGES:LSP_NOTIFICATION",
      languageId: params.languageId,
      message: params.message,
    });
  });

  languageClient.onLspServerRequest((params) => {
    pitcherEvents.emit({
      type: "PITCHER:LANGUAGES:LSP_SERVER_REQUEST",
      languageId: params.languageId,
      message: params.message,
    });
  });

  languageClient.onLanguagesUpdated((languages) => {
    pitcherEvents.emit({
      type: "PITCHER:LANGUAGES:UPDATE",
      languages,
    });
  });

  languageClient.onLanguagesError((error) => {
    pitcherEvents.emit({
      type: "PITCHER:LANGUAGES:ERROR",
      error,
    });
  });

  return {
    getLanguages() {
      return languageClient.getLanguages();
    },
    sendLSPRequest(
      languageId: string,
      serverId: string,
      method: string,
      params: any,
    ) {
      return languageClient.sendLSPRequest({
        languageId,
        serverId,
        message: {
          method,
          params,
        },
      });
    },
    sendLSPServerResponse(
      languageId,
      serverId: string,
      requestId,
      result,
      error,
    ) {
      return languageClient.sendLSPServerResponse({
        languageId,
        serverId,
        message: {
          id: requestId,
          result: result as Record<string, unknown>,
          error: error as protocol.ProtocolError,
        },
      });
    },
    getLanguageId(filepath: string) {
      return languageClient.getLanguageId(filepath);
    },
  };
};
