import type {
  DropdownMenuProps as RootProps,
  DropdownMenuContentProps,
} from "@radix-ui/react-dropdown-menu";
import { Trigger, Root } from "@radix-ui/react-dropdown-menu";
import React from "react";

import { useTheme } from "../../theme";
import { onGrid } from "../../tokens/space";
import { Box } from "../Box";
import { ScrollArea } from "../ScrollArea";

import { DropdownContent } from "./DropdownContent";

/* TODO:
  - isChecked: right side icon
  - with icon on left side
  - with checkbox
  - handle 'as' prop to render links: https://github.com/modulz/stitches/issues/448
*/

export interface DropdownProps {
  children: React.ReactNode;
  trigger: React.ReactNode;
  rootProps?: RootProps;
  contentProps?: DropdownMenuContentProps;
  experimentalOnCloseAutoFocus?: DropdownMenuContentProps["onCloseAutoFocus"];
  maxHeight?: number | false;
}

const DROPDOWN_ITEM_HEIGHT = 31.5;
const DROPDOWN_MAX_ITEM_BEFORE_SCROLL = 10;

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  experimentalOnCloseAutoFocus,
  rootProps,
  contentProps,
  trigger,
  maxHeight = DROPDOWN_MAX_ITEM_BEFORE_SCROLL,
}) => {
  const theme = useTheme();

  const childrenCount = React.Children.toArray(children).length;

  return (
    <Root
      /**
       * We will most probably want to change this at some point, but it's hard coded here for now to save time.
       *
       * This fixes an issue with iFrames inside the Sidekick "flashing" when interacting with any Dropdown. Having spoken
       * to the radix team about it, they shared some notes on why this flash occurs:
       *
       * > modal={true} uses the react-remove-scroll library and i wonder if that is the culprit here
       *
       * > if you already have your own code to disable scroll then it might be okay to have modal={false} … this will mean that:
       *
       * > We aren’t preventing scroll
       * > Pointer events aren’t disabled outside of the dropdown menu
       * > Page content outside of the dropdown isn’t aria-hidden
       * > Focus isn’t trapped so any scripts that try to focus something outside of the menu would be able to
       *
       * @see https://www.loom.com/share/5a338146328248f7a8846bf286a5f6b7
       */
      modal={false}
      {...rootProps}
    >
      <Trigger asChild>{trigger}</Trigger>

      {/* DropdownContent renders a portal. We need to explicitly set the theme inside it. */}
      <DropdownContent
        className={theme.className}
        sideOffset={onGrid(1)}
        {...contentProps}
        onCloseAutoFocus={experimentalOnCloseAutoFocus}
      >
        {maxHeight && childrenCount > maxHeight ? (
          <Box
            style={{
              height: maxHeight * DROPDOWN_ITEM_HEIGHT,
            }}
          >
            <ScrollArea>{children}</ScrollArea>
          </Box>
        ) : (
          children
        )}
      </DropdownContent>
    </Root>
  );
};
