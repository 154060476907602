import { transition } from "@codesandbox/states";
import { createCapabilitiesObject } from "features/utils/dto";

import type { State, Action } from "./types";
import {
  handleBranchRename,
  updateBranchEditorData,
  updateSandboxEditorData,
} from "./utils";

export const reducer = (prevState: State, action: Action) =>
  transition(prevState, action, {
    REMOVING_BRANCH: {
      PULL_REQUEST_UPDATED: (state, { data }) => ({
        ...state,
        editor: {
          ...state.editor,
          pullRequest: data,
        },
      }),
      "API:REMOVE_REMOTE_BRANCH_SUCCESS": ({ editor }) => ({
        state: "READY",
        preventAuthenticationRefetch: false,
        editor,
        isSeamlesslyBranching: false,
      }),
      "API:REMOVE_REMOTE_BRANCH_ERROR": (_, { error }) => ({
        state: "ERROR",
        error,
        couldNotAccessWorkspace: false,
      }),
    },
    FETCHING: {
      "API:FETCH_EDITOR_DATA_ERROR": (
        _,
        { error, couldNotAccessWorkspace, possibleWorkspacesToRequestAccess },
      ) => ({
        state: "ERROR",
        error,
        couldNotAccessWorkspace,
        possibleWorkspacesToRequestAccess,
      }),
      "API:FETCH_EDITOR_DATA_SUCCESS": (_, { editorData }) => ({
        state: "READY",
        preventAuthenticationRefetch: false,
        editor: editorData,
        isSeamlesslyBranching: false,
      }),
    },
    READY: {
      START_SEAMLESS_BRANCH: (state) => ({
        ...state,
        isSeamlesslyBranching: true,
      }),
      REVERT_SEAMLESS_BRANCH: (state) => ({
        ...state,
        isSeamlesslyBranching: false,
      }),
      UPDATE_SANDBOX: (state, { project }) =>
        state.editor.type === "sandbox"
          ? {
              ...state,
              editor: {
                ...state.editor,
                ...project,
              },
            }
          : state,
      PULL_REQUEST_UPDATED: (state, { data }) =>
        state.editor.type === "branch"
          ? {
              ...state,
              editor: {
                ...state.editor,
                pullRequest: data,
              },
            }
          : state,
      FETCH_BRANCH: (
        _,
        { owner, repo, branch, workspaceId, forcePublicWorkspace },
      ) => ({
        state: "FETCHING",
        type: "branch",
        owner,
        repo,
        branch,
        workspaceId,
        forcePublicWorkspace,
      }),
      FETCH_SANDBOX: (_, { id, workspaceId }) => ({
        state: "FETCHING",
        type: "sandbox",
        id,
        workspaceId,
      }),
      SWITCH_BRANCH: (state, { name, create }) =>
        state.editor.type === "branch"
          ? {
              state: "FETCHING",
              type: "branch",
              owner: state.editor.owner,
              repo: state.editor.repo,
              workspaceId: state.editor.workspace?.id ?? null,
              branch: name,
              create: create || false,
              forcePublicWorkspace: false,
            }
          : state,
      SWITCH_SANDBOX: (state, { alias, workspaceId }) =>
        state.editor.type === "sandbox"
          ? {
              state: "FETCHING",
              type: "sandbox",
              id: alias,
              workspaceId,
            }
          : state,
      SWITCH_DEVBOX: (state, { alias, workspaceId }) =>
        state.editor.type === "sandbox"
          ? {
              state: "FETCHING",
              type: "sandbox",
              id: alias,
              workspaceId,
            }
          : state,
      SWITCH_SEAMLESS_INSTANCE: (state, { data }) => {
        if (state.editor.type === "sandbox" && data.type === "sandbox") {
          return {
            state: "READY",
            preventAuthenticationRefetch: false,
            editor: updateSandboxEditorData(state.editor, data.data),
            isSeamlesslyBranching: false,
          };
        }

        if (state.editor.type === "branch" && data.type === "branch") {
          return {
            state: "READY",
            preventAuthenticationRefetch: false,
            editor: updateBranchEditorData(state.editor, data.data),
            isSeamlesslyBranching: false,
          };
        }

        return state;
      },
      SET_PREVENT_AUTHENTICATION_REFETCH: (state, { prevent }) => ({
        state: "READY",
        editor: state.editor,
        preventAuthenticationRefetch: prevent,
        isSeamlesslyBranching: false,
      }),
      IMPORT_PROJECT: (state, { newProject }) =>
        state.editor.type === "branch"
          ? {
              // It might seem weird we need to refetch once we have the data in "newProject", but we need to force a remount of the Project Editor
              // to reconnect Pitcher to a new VM. To not break seamless branching (Which would happen on using branch id to remount). We
              // could maybe have used workspaceId to cause the remount, but it is so implicit that this happens and would certainly cause bugs
              state: "FETCHING",
              type: "branch",
              owner: newProject.owner,
              branch: newProject.branch,
              repo: newProject.repo,
              workspaceId: newProject.workspace?.id ?? null,
              forcePublicWorkspace: false,
            }
          : state,
      RENAME_BRANCH: (state, { newName }) =>
        state.editor.type === "branch"
          ? {
              state: "RENAMING_BRANCH",
              editor: state.editor,
              newName,
            }
          : state,
      REMOVE_BRANCH: (state, { branch }) =>
        state.editor.type === "branch"
          ? {
              state: "REMOVING_BRANCH",
              branch,
              editor: state.editor,
            }
          : state,
      // This is handling the scenario of other users renaming while you are connecting to the same branch
      SET_BRANCH_NAME: (state, { name }) =>
        state.editor.type === "branch"
          ? {
              state: "READY",
              preventAuthenticationRefetch: false,
              editor: handleBranchRename(state.editor, name),
              isSeamlesslyBranching: false,
            }
          : state,
      SET_GITHUB_APP: (state) =>
        state.editor.type === "branch"
          ? {
              state: "READY",
              preventAuthenticationRefetch: false,
              editor: {
                ...state.editor,
                githubAppInstalled: true,
              },
              isSeamlesslyBranching: false,
            }
          : state,
      UPDATE_CAPABILITIES: (state, { capabilities }) => ({
        state: "READY",
        preventAuthenticationRefetch: false,
        editor: {
          ...state.editor,
          allowedCapabilities: {
            ...state.editor.allowedCapabilities,
            ...capabilities,
          },
        },
        isSeamlesslyBranching: false,
      }),
      "PITCHER:CLIENTS:CLIENT_PERMISSIONS_UPDATED": (
        state,
        { isProtected },
      ) => {
        const editor = {
          ...state.editor,
          protectedBranch: isProtected,
        };

        return {
          state: "READY",
          preventAuthenticationRefetch: false,
          editor: {
            ...editor,
            allowedCapabilities: createCapabilitiesObject(editor),
          },
          isSeamlesslyBranching: false,
        };
      },
      SET_AI_CONSENT: (state, { aiConsent }) => ({
        ...state,
        editor: {
          ...state.editor,
          aiConsent,
        },
      }),
    },
    RENAMING_BRANCH: {
      PULL_REQUEST_UPDATED: (state, { data }) => ({
        ...state,
        editor: {
          ...state.editor,
          pullRequest: data,
        },
      }),
      SET_BRANCH_NAME: ({ newName, editor }, { name }) => ({
        state: "RENAMING_BRANCH",
        editor: handleBranchRename(editor, name),
        newName,
      }),
      BRANCH_RENAME_FINISHED: ({ editor }) => ({
        state: "READY",
        preventAuthenticationRefetch: false,
        preventPublicRedirect: false,
        editor,
        isSeamlesslyBranching: false,
      }),
      BRANCH_RENAME_ERROR: ({ editor }) => ({
        state: "READY",
        preventAuthenticationRefetch: false,
        preventPublicRedirect: false,
        editor,
        isSeamlesslyBranching: false,
      }),
      BRANCH_RENAME_TAKEN_ERROR: ({ editor }) => ({
        state: "READY",
        preventAuthenticationRefetch: false,
        preventPublicRedirect: false,
        editor,
        isSeamlesslyBranching: false,
      }),
    },
    ERROR: {
      FETCH_BRANCH: (
        _,
        { owner, repo, branch, workspaceId, forcePublicWorkspace },
      ) => ({
        state: "FETCHING",
        type: "branch",
        owner,
        repo,
        branch,
        workspaceId,
        forcePublicWorkspace,
      }),
      FETCH_SANDBOX: (_, { id, workspaceId }) => ({
        state: "FETCHING",
        type: "sandbox",
        id,
        workspaceId,
      }),
    },
  });
