import { Emitter } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../common/AsyncValueStore";
export class GitClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.statusUpdatedEmitter = new Emitter();
        this.onStatusUpdated = this.statusUpdatedEmitter.event;
        this.checkoutPreventedEmitter = new Emitter();
        this.onCheckoutPrevented = this.checkoutPreventedEmitter.event;
        this.remotesUpdatedEmitter = new Emitter();
        this.onRemotesUpdated = this.remotesUpdatedEmitter.event;
        this.remotesErrorEmitter = new Emitter();
        this.onRemotesError = this.remotesErrorEmitter.event;
        this.pullStartedEmitter = new Emitter();
        this.onPullStarted = this.pullStartedEmitter.event;
        this.pullFinishedEmitter = new Emitter();
        this.onPullFinished = this.pullFinishedEmitter.event;
        this.commitStartedEmitter = new Emitter();
        this.onCommitStarted = this.commitStartedEmitter.event;
        this.commitFinishedEmitter = new Emitter();
        this.onCommitFinished = this.commitFinishedEmitter.event;
        this.branchRenamedEmitter = new Emitter();
        this.onBranchRenamed = this.branchRenamedEmitter.event;
        messageHandler.onNotification("git/status", (gitStatus) => {
            this.cachedGitStatus = gitStatus;
            this.statusUpdatedEmitter.fire(gitStatus);
        });
        this.remotes = this.createRemotesValue();
        // @ts-ignore
        messageHandler.onNotification("git/checkoutPrevented", ({ branch }) => {
            // @ts-ignore
            this.checkoutPreventedEmitter.fire(branch);
        });
        messageHandler.onNotification("git/pullStarted", () => {
            this.pullStartedEmitter.fire();
        });
        messageHandler.onNotification("git/remotes", (remotes) => {
            this.remotes.set(remotes);
        });
        messageHandler.onNotification("git/pullFinished", () => {
            this.pullFinishedEmitter.fire();
        });
        messageHandler.onNotification("git/commitStarted", (notification) => {
            this.commitStartedEmitter.fire(notification);
        });
        messageHandler.onNotification("git/commitFinished", (notification) => {
            this.commitFinishedEmitter.fire(notification);
        });
        messageHandler.onNotification("git/renameBranch", (msg) => {
            this.branchRenamedEmitter.fire(msg);
        });
    }
    createRemotesValue() {
        const remotes = new AsyncValueStore(
        // TODO: Empty value, the initial state will be fetched later
        { origin: "", upstream: "" }, () => this.messageHandler.request({
            method: "git/remotes",
            params: {},
        }));
        remotes.onChange(({ value }) => this.remotesUpdatedEmitter.fire(value));
        remotes.onError((error) => this.remotesErrorEmitter.fire(error));
        // Fetch initial state
        remotes.get();
        return remotes;
    }
    pushToRemote(url, branch, squashAllCommits = false) {
        return this.messageHandler.request({
            method: "git/pushToRemote",
            params: {
                url,
                branch,
                squashAllCommits,
            },
        });
    }
    resetLocalWithRemote() {
        return this.messageHandler.request({
            method: "git/resetLocalWithRemote",
            params: {},
        });
    }
    getStatus() {
        if (this.cachedGitStatus) {
            return Promise.resolve(this.cachedGitStatus);
        }
        return this.messageHandler
            .request({
            method: "git/status",
            params: {},
        })
            .then((gitStatus) => {
            this.cachedGitStatus = gitStatus;
            return gitStatus;
        });
    }
    getRemotes() {
        return this.remotes.get();
    }
    getTargetDiffStatus(baseBranch) {
        return this.messageHandler.request({
            method: "git/diffStatus",
            params: {
                base: `origin/${baseBranch}`,
                head: "HEAD",
            },
        });
    }
    resync() {
        // When resyncing we need to go and get the latest status as it could be stale and
        // no notifications will be sent until you make a change
        delete this.cachedGitStatus;
        this.remotes.refresh();
        return this.getStatus().then((updatedStatus) => {
            this.statusUpdatedEmitter.fire(updatedStatus);
        });
    }
    compare(branch) {
        return this.messageHandler.request({
            method: "git/targetDiff",
            params: {
                branch,
            },
        });
    }
    pull(branch, force) {
        return this.messageHandler.request({
            method: "git/pull",
            params: {
                branch,
                force,
            },
        });
    }
    commit(message, paths, push = true) {
        return this.messageHandler.request({
            method: "git/commit",
            params: {
                message,
                paths,
                push,
            },
        });
    }
    push() {
        return this.messageHandler.request({ method: "git/push", params: null });
    }
    discard(paths) {
        return this.messageHandler
            .request({
            method: "git/discard",
            params: {
                paths,
            },
        })
            .then(({ paths }) => paths);
    }
    renameBranch(oldBranch, newBranch) {
        return this.messageHandler.request({
            method: "git/renameBranch",
            params: {
                oldBranch,
                newBranch,
            },
        }, {
            seamlessForkStrategy: "queue",
        });
    }
    remoteContent({ reference, filepath, }) {
        return this.messageHandler.request({
            method: "git/remoteContent",
            params: {
                reference,
                path: filepath,
            },
        });
    }
    checkoutInitialBranch() {
        return this.messageHandler.request({
            method: "git/checkoutInitialBranch",
            params: {},
        });
    }
    transposeLines(lines) {
        // eslint-disable-next-line
        // @ts-ignore
        return this.messageHandler.request({
            // eslint-disable-next-line
            // @ts-ignore
            method: "git/transposeLines",
            params: lines,
        });
    }
}
