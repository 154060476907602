import { useSession } from "./api/useSession";

type Scopes = {
  publicRepos: boolean;
  privateRepos: boolean;
};

export const useGitHubScopes = (): Scopes | undefined => {
  const [session] = useSession();

  if (session.state !== "AUTHENTICATED") {
    return;
  }

  const { githubProfile } = session.user;

  if (!githubProfile.data) {
    return;
  }

  const scopes = githubProfile.data.scopes;

  return {
    publicRepos: scopes.includes("repo") || scopes.includes("public_repo"),
    privateRepos: scopes.includes("repo"),
  };
};
