import * as analytics from "@amplitude/analytics-browser";
import type { Amplitude } from "environment-interface/amplitude";
import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";

import logger from "../../features/utils/logger";

import { getLastTimeEventSent, markLastTimeEventSent } from "./utils";

// After 30min no event we mark a session
const NEW_SESSION_TIME = 1000 * 60 * 30;

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const createAmplitude = (apiKey?: string): Amplitude => {
  let isInitialized = false;

  if (!apiKey || isInIframe()) {
    const log = (event: keyof Amplitude) => (data?: unknown) =>
      logger.debug(`amplitude.${event}`, data);

    return {
      resetSession: log("resetSession"),
      setUserId: log("setUserId"),
      track: () => Promise.resolve(),
      init: log("init"),
      setOptOut: log("setOptOut"),
      flushTrackQueue: () => Promise.resolve(),
    };
  }

  return {
    init: () => {
      if (isInitialized) {
        return;
      }

      analytics.init(apiKey, undefined, {
        serverUrl: "https://stats.codesandbox.io/2/httpapi",
        appVersion: env.PUBLIC_VERSION,
        flushIntervalMillis: 5000,
        defaultTracking: true,
      });

      isInitialized = true;
    },
    setOptOut: (optOut: boolean) => {
      analytics.setOptOut(optOut);
    },
    track: async ({ metric, ...data }) => {
      if (!isInitialized) {
        return Promise.resolve();
      }

      const currentTime = Date.now();
      if (currentTime - getLastTimeEventSent() > NEW_SESSION_TIME) {
        // We send a separate New Session event if people have been inactive for a while
        analytics.track("New Session");
      }
      markLastTimeEventSent();

      const pathname =
        typeof document !== "undefined" ? document.location.pathname : "";

      let project_type = "unknown";
      if (pathname.startsWith(withBasePath(env, "/sandbox"))) {
        project_type = "sandbox";
      } else if (pathname.startsWith(withBasePath(env, "/devbox"))) {
        project_type = "devbox";
      } else if (pathname.startsWith(withBasePath(env, "/github"))) {
        project_type = "branch";
      }

      return analytics.track(metric, {
        path: document.location.pathname + document.location.search,
        ...data,
        codesandbox: "V2",
        editorMode: "vscode-web",
        project_type,
      }).promise;
    },
    resetSession: () => {
      if (!isInitialized) {
        return;
      }

      analytics.reset();
    },
    setUserId: (userId: string) => {
      if (!isInitialized) {
        return;
      }

      analytics.setUserId(userId);
    },
    flushTrackQueue: () => {
      if (!isInitialized) {
        return Promise.resolve();
      }

      return analytics.flush().promise;
    },
  };
};
