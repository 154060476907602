import { Emitter } from "@codesandbox/pitcher-common";
export class BrowserContainerClient {
    constructor() {
        this.openSetupDevToolEmitter = new Emitter();
        this.onOpenSetupDevTool = this.openSetupDevToolEmitter.event;
    }
    runSetupContainer() {
        return Promise.reject(new Error("Not implemented"));
    }
}
