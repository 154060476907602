import type { IPitcherClient } from "@codesandbox/pitcher-client";
import { Disposable, Emitter } from "@codesandbox/pitcher-client";
import { FS_SCHEME } from "environment-interface";
import type { GitStatus, GitStatusShortFormat } from "features/types/git";
import * as vscode from "vscode";

export class FileDecorationProvider
  extends Disposable
  implements vscode.FileDecorationProvider
{
  private onDidChangeFileDecorationsEmitter = this.addDisposable(
    new Emitter<vscode.Uri[]>(),
  );
  private decorations = new Map<string, vscode.FileDecoration>();
  public onDidChangeFileDecorations =
    this.onDidChangeFileDecorationsEmitter.event;

  constructor(private readonly pitcherClient: IPitcherClient) {
    super();

    this.listenForChanges();
  }

  private listenForChanges() {
    this.pitcherClient.clients.git.getStatus().then((initialGitStatus) => {
      this.decorateGitStatus(initialGitStatus);
    });
    this.addDisposable(
      this.pitcherClient.clients.git.onStatusUpdated(
        this.decorateGitStatus.bind(this),
      ),
    );
  }

  private decorateGitStatus(status: GitStatus) {
    const oldDecorationURIs = Array.from(this.decorations.keys()).map((item) =>
      vscode.Uri.parse(item, true),
    );

    // Init with the old decorations to make sure to
    // clean them up on the next decoration loop
    const newURIs: vscode.Uri[] = oldDecorationURIs;

    // Only now reset all the decorations
    this.decorations = new Map();

    Object.values(status.changedFiles).forEach((item) => {
      const uri = vscode.Uri.from({
        scheme: FS_SCHEME,
        authority: this.pitcherClient.instanceId,
        path: `${this.pitcherClient.workspacePath}${
          item.path.startsWith("/") ? item.path : `/${item.path}`
        }`,
      });
      newURIs.push(uri);

      const vscodeStatus = fromGitStatusToVSStatus(
        item.workingTree,
        item.isConflicted,
      );
      this.decorations.set(uri.toString(), {
        badge: getStatusLetter(vscodeStatus),
        color: getStatusColor(vscodeStatus),
        tooltip: getStatusText(vscodeStatus),
        propagate: true,
      });
    });

    // Fire event
    this.onDidChangeFileDecorationsEmitter.fire(newURIs);
  }

  provideFileDecoration(
    uri: vscode.Uri,
  ): vscode.ProviderResult<vscode.FileDecoration> {
    return this.decorations.get(uri.toString());
  }
}

const enum Status {
  ADDED,
  RENAMED,
  COPIED,
  MODIFIED,
  DELETED,
  UNTRACKED,
  CONFLICT,
}

function fromGitStatusToVSStatus(
  gitStatus: GitStatusShortFormat,
  isConflicted: boolean,
): Status {
  if (isConflicted) {
    return Status.CONFLICT;
  }

  let result: Status = Status.UNTRACKED;

  switch (gitStatus) {
    case "M":
      result = Status.MODIFIED;
      break;

    case "A":
      result = Status.ADDED;
      break;

    case "D":
      result = Status.DELETED;
      break;

    case "R":
      result = Status.RENAMED;
      break;

    case "C":
      result = Status.COPIED;
      break;
  }

  return result;
}

function getStatusLetter(type: Status): string {
  switch (type) {
    case Status.MODIFIED:
      return "M";
    case Status.ADDED:
      return "A";
    case Status.DELETED:
      return "D";
    case Status.RENAMED:
      return "R";
    case Status.UNTRACKED:
      return "U";
    case Status.COPIED:
      return "C";
    case Status.CONFLICT:
      return "!"; // Using ! instead of ⚠, because the latter looks really bad on windows
    default:
      throw new Error("Unknown git status: " + type);
  }
}

function getStatusText(type: Status) {
  switch (type) {
    case Status.MODIFIED:
      return "Modified";
    case Status.ADDED:
      return "Added";
    case Status.DELETED:
      return "Deleted";
    case Status.RENAMED:
      return "Renamed";
    case Status.COPIED:
      return "Copied";
    case Status.UNTRACKED:
      return "Untracked";
    case Status.CONFLICT:
      return "Conflict";
    default:
      return "";
  }
}

function getStatusColor(type: Status): vscode.ThemeColor {
  switch (type) {
    case Status.MODIFIED:
      return new vscode.ThemeColor("gitDecoration.modifiedResourceForeground");
    case Status.DELETED:
      return new vscode.ThemeColor("gitDecoration.deletedResourceForeground");
    case Status.ADDED:
      return new vscode.ThemeColor("gitDecoration.addedResourceForeground");
    case Status.COPIED:
    case Status.RENAMED:
      return new vscode.ThemeColor("gitDecoration.renamedResourceForeground");
    case Status.UNTRACKED:
      return new vscode.ThemeColor("gitDecoration.untrackedResourceForeground");
    case Status.CONFLICT:
      return new vscode.ThemeColor(
        "gitDecoration.conflictingResourceForeground",
      );
    default:
      throw new Error("Unknown git status: " + type);
  }
}
