import type { IDisposable } from "@codesandbox/pitcher-client";

import type { MonacoConfig } from "../../../../../";
import { onMonacoConfigChange, getLatestMonacoConfig } from "../monaco";

import { runWithLatestValue } from "./run-with-latest-value";

/**
 * Runs the given callback with the latest Monaco config, and will automatically
 * dispose what is returned by the callback when a new config comes in.
 */
export const runWithLatestMonacoConfig = (
  callback: (config: MonacoConfig) => IDisposable,
) =>
  runWithLatestValue(onMonacoConfigChange, callback, getLatestMonacoConfig());
