import { createEnvironmentInterface } from "@codesandbox/environment-interface";
import { IWorkspaceContextService, StandaloneServices } from "vscode/services";

import type { Amplitude } from "./amplitude";
import type { Api } from "./api";
import type { Clipboard } from "./clipboard";
import type { CsbApi } from "./csbApi";
import type { MonacoEditor } from "./monacoEditor";
import type { Navigation } from "./navigation";
import type { PitcherInstanceManager } from "./pitcher";
import type { Sentry } from "./sentry";
import type { Storage } from "./storage";
import type { Terminal } from "./terminal";
import type { Visibility } from "./visibility";

// TODO: remove these exports, but it's easier right now since the browser automatically
// imports from here.
export { FS_SCHEME } from "./monacoEditor/browser/services/editor/vscode/constants";

export function relativePathToResource(relativePath: string) {
  const workspaceContextService = StandaloneServices.get(
    IWorkspaceContextService,
  );
  return workspaceContextService
    .getWorkspace()
    .folders[0].toResource(relativePath);
}

export type Environment = {
  amplitude: Amplitude;
  api: Api;
  csbApi?: CsbApi;
  clipboard: Clipboard;
  sentry: Sentry;
  storage: Storage;
  terminal: Terminal;
  pitcherInstanceManager: PitcherInstanceManager;
  monacoEditor: MonacoEditor;
  navigation: Navigation;
  visibility: Visibility;
};

const { EnvironmentInterfaceProvider, useEnvironmentInterface } =
  createEnvironmentInterface<Environment>();

export { EnvironmentInterfaceProvider, useEnvironmentInterface };
