/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ot } from "@codesandbox/pitcher-client";
import type * as Monaco from "monaco-editor";

import { enforce } from "../../../../features/utils/enforce";

export function getLinesFromContent(input: string): string[] {
  return input.split(/\r\n|\r|\n/);
}

/**
 * Convert a character index within a document to a line/column based position
 * @param lines an array of the document lines, may not contain any newline characters
 * @param index the index of the character that we want to get the position of
 */
export function characterIndexToPosition(
  lines: string[],
  index: number,
  eolLength: number,
): Monaco.IPosition {
  // Handle negative indices
  if (index <= 0 || lines.length < 1) {
    return {
      lineNumber: 1,
      column: 1,
    };
  }

  let lineIndex = 0;
  let remainingCharacters = index;
  for (lineIndex; lineIndex < lines.length; lineIndex++) {
    const line = lines[lineIndex];
    const lineLength = line?.length || 0;

    // Subtract line length and eol length, we also add one back as line.length is 1 larger than the last index of the line
    const newRemainingCharacters =
      remainingCharacters - lineLength - eolLength + 1;
    // If newRemainingCharacters is smaller than or equal to 0, we know this is the line where the character is so we break
    if (newRemainingCharacters <= 0) break;
    remainingCharacters = newRemainingCharacters - 1;
  }

  // Handle index out of bound
  if (lineIndex === lines.length) {
    const lastLine = lines[lineIndex - 1];
    const lastLineLength = lastLine?.length || 0;
    remainingCharacters = lastLineLength - 1;
    lineIndex = lines.length - 1;
  }

  // We add one to lineNumber and column as monaco's indexes start at 1
  return {
    lineNumber: lineIndex + 1,
    column: Math.max(1, remainingCharacters + 1),
  };
}

/**
 * Convert a monaco position to a character index, based on a lines array
 */
export function positionToCharacterIndex(
  lines: string[],
  position: Monaco.IPosition,
  eolLength: number,
): number {
  if (
    position.lineNumber < 0 ||
    position.column < 0 ||
    position.lineNumber > lines.length
  ) {
    throw new Error("Invalid position");
  }

  let index = 0;
  for (
    let currentLine = 0;
    currentLine < position.lineNumber - 1;
    currentLine++
  ) {
    index += lines[currentLine]?.length || 0;
    index += eolLength; // Line break character
  }
  index += position.column - 1;
  return index;
}

export function convertMonacoChangesToOperation(
  originalCode: string,
  changes: Monaco.editor.IModelContentChange[],
  eolLength: number,
): ot.TextOperation {
  let operation: ot.TextOperation = new ot.TextOperation().retain(
    originalCode.length,
  );

  // You might think it's possible to sort the operations from left to right and apply everything in
  // one big operation without transforms and composes but it's hard as monaco allow multiple changes
  // to happen at the same location (see commit b68cbe346801f013ce77f43c5c774074823480ec for an attempt at this)

  // Be careful when changing this file as the test suite does not cover all edge cases!

  // We reverse changes as monaco applied changes from end to start, so we should too to ensure we have the same end-result
  // Never remove the spread ([...changes]), stuff will break, thanks JS
  // eslint-disable-next-line no-restricted-syntax
  for (const change of [...changes].reverse()) {
    const cursorStartOffset = positionToCharacterIndex(
      getLinesFromContent(originalCode),
      {
        lineNumber: change.range.startLineNumber,
        column: change.range.startColumn,
      },
      eolLength,
    );

    const changeOperation = new ot.TextOperation();
    if (cursorStartOffset !== 0) {
      changeOperation.retain(cursorStartOffset);
    }

    if (change.rangeLength > 0) {
      // TODO: Does this work correctly with \r\n?
      changeOperation.delete(change.rangeLength);
    }

    if (change.text) {
      changeOperation.insert(change.text);
    }

    const remaining = originalCode.length - changeOperation.baseLength;
    if (remaining > 0) {
      changeOperation.retain(remaining);
    }

    const transformedOperation = ot.TextOperation.transform(
      operation,
      changeOperation,
    )[1];
    operation = operation.compose(transformedOperation);
  }

  return operation;
}

export function applyOperationToModel(
  operation: ot.TextOperation,
  model: Monaco.editor.IModel,
  Selection: SelectionConstr,
): void {
  // Skip no-ops, this is probably a selection...
  if (operation.isNoop()) {
    return;
  }

  const results: Array<{
    range: Monaco.IRange;
    text: string;
    forceMoveMarkers?: boolean;
  }> = [];
  let index = 0;
  const modelCode: string = model.getValue();
  const lines: string[] = model.getLinesContent();

  if (operation.baseLength !== modelCode.length) {
    throw new Error(
      "The base length of the operation doesn't match the length of the code",
    );
  }

  let eolLength = model.getEOL().length;
  for (let i = 0; i < operation.ops.length; i++) {
    const op = enforce.notUndefined(operation.ops[i]);

    if (ot.TextOperation.isRetain(op)) {
      index += op as number;
    } else if (ot.TextOperation.isInsert(op)) {
      const textOp = op as string;
      const { lineNumber, column } = characterIndexToPosition(
        lines,
        index,
        eolLength,
      );
      const range: Monaco.IRange = {
        startLineNumber: lineNumber,
        startColumn: column,
        endLineNumber: lineNumber,
        endColumn: column,
      };

      // if there's a new line we set the eolLength to be correct based on the newline in the text operation
      if (/\n/.test(textOp)) {
        eolLength = /\r\n/.test(textOp) ? 2 : 1;
      }

      results.push({
        range,
        text: textOp,
        forceMoveMarkers: true,
      });
    } else if (ot.TextOperation.isDelete(op)) {
      const delOp = op as number;
      const from = characterIndexToPosition(lines, index, eolLength);
      const to = characterIndexToPosition(lines, index - delOp, eolLength);
      const range: Monaco.IRange = {
        startLineNumber: from.lineNumber,
        startColumn: from.column,
        endLineNumber: to.lineNumber,
        endColumn: to.column,
      };
      results.push({
        range,
        text: "",
      });
      index -= delOp;
    }
  }

  // If the eol sequence length changed, we update the models eol here
  if (eolLength !== model.getEOL().length) {
    const newEolMode = eolLength === 2 ? 0 : 1;
    model.pushEOL(newEolMode);
  }

  const selections = results.map((op) => {
    return new Selection(
      op.range.startLineNumber,
      op.range.startColumn,
      op.range.endLineNumber,
      op.range.endColumn,
    );
  });

  // Below this comment is a minimal version of model.pushEditOperation ensuring we bypass some editor magic
  const operations = results.map((op) => {
    return {
      range: model.validateRange(op.range),
      text: op.text,
    };
  });

  // @ts-ignore
  model._commandManager.pushEditOperation(selections, operations, () => []);
}

export type SelectionConstr = new (
  selectionStartLineNumber: number,
  selectionStartColumn: number,
  positionLineNumber: number,
  positionColumn: number,
) => Monaco.Selection;
