import * as React from "react";

export class ErrorBoundary extends React.Component<{
  children: React.ReactNode;
  fallback: (props: { error: Error }) => React.ReactNode;
}> {
  state: { error?: Error } = {};
  componentDidCatch(error: Error) {
    this.setState({ error });
  }
  render() {
    if (this.state.error) {
      return this.props.fallback({ error: this.state.error });
    }
    return this.props.children;
  }
}
