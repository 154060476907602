import { Emitter } from "@codesandbox/pitcher-common";
export class ContainerClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.openDevToolEmitter = new Emitter();
        this.onOpenSetupDevTool = this.openDevToolEmitter.event;
        messageHandler.onNotification("container/openSetupDevtool", ({ dependencies }) => {
            this.openDevToolEmitter.fire({ dependencies });
        });
    }
    async runSetupContainer(params) {
        return await this.messageHandler.request({
            method: "container/setup",
            params,
        }, { seamlessForkStrategy: "queue" });
    }
}
