import type { protocol } from "@codesandbox/pitcher-client";
import type * as Monaco from "monaco-editor";
import type { IPosition, ISelection } from "monaco-editor";

import {
  characterIndexToPosition,
  positionToCharacterIndex,
} from "./operations";
import type { OTRange } from "./selection-decorations";

export interface Selection {
  selection: number[];
  cursorPosition: number;
}

export function jsonRangeToMonacoRange(
  lines: string[],
  eolLength: number,
  r: OTRange,
): Monaco.IRange {
  const startPosition = characterIndexToPosition(lines, r.anchor, eolLength);
  const endPosition = characterIndexToPosition(lines, r.head, eolLength);
  return {
    startLineNumber: startPosition.lineNumber,
    startColumn: startPosition.column,
    endLineNumber: endPosition.lineNumber,
    endColumn: endPosition.column,
  };
}

/**
 * Find closest position to the provided one, this is useful when working with selections
 * as monaco does not guarantee that selections are valid within the current document state
 * which sometimes will lead to selections being located outside of the document
 */
export function getClosestValidLocation(
  lines: string[],
  position: IPosition,
): IPosition {
  const line = lines[position.lineNumber - 1];
  if (line != null) {
    // If column exists we simply return the position, otherwise we opt for last column in the line
    if (line.length + 1 >= position.column) {
      return position;
    } else {
      return {
        lineNumber: position.lineNumber,
        // a Monaco Position column is 1 indexed and end-aligned, so we return the length + 1
        column: line.length + 1,
      };
    }
  } else {
    const lastLineIndex = lines.length - 1;
    const lastLine = lines[lastLineIndex];

    return {
      // a Monaco Position line is 1 indexed, so we return the length
      lineNumber: lines.length,
      // a Monaco Position column is 1 indexed and end-aligned, so we return the length + 1
      column: lastLine ? lastLine.length + 1 : 0,
    };
  }
}

/**
 * Converts a monaco selection into a character offset based selection
 */
export function getSelection(
  lines: string[],
  selection: ISelection,
  eolLength: number,
): Selection {
  const startPosition = getClosestValidLocation(lines, {
    lineNumber: selection.selectionStartLineNumber,
    column: selection.selectionStartColumn,
  });
  const startSelection = positionToCharacterIndex(
    lines,
    startPosition,
    eolLength,
  );

  const endPosition = getClosestValidLocation(lines, {
    lineNumber: selection.positionLineNumber,
    column: selection.positionColumn,
  });
  const endSelection = positionToCharacterIndex(lines, endPosition, eolLength);

  return {
    selection:
      startSelection === endSelection ? [] : [startSelection, endSelection],
    cursorPosition: endSelection,
  };
}

/**
 * Convert monaco selections into ot selections, so we can display it on the collaborators editor
 */
export function createSelection(
  lines: string[],
  selections: ISelection[],
  eolLength: number,
): protocol.file.ISelection {
  return selections.map((selection) => {
    const data = getSelection(lines, selection, eolLength);

    return {
      anchor: data.selection[0] ?? data.cursorPosition,
      head: data.selection[1] ?? data.cursorPosition,
    };
  });
}
