import type { protocol } from "@codesandbox/pitcher-client";
import type { IDocumentSelections } from "environment-interface/pitcher/file";
import { enforce } from "features/utils/enforce";
import type * as Monaco from "monaco-editor";
import { addStyles } from "prism-react";
import * as vscode from "vscode";

import { jsonRangeToMonacoRange } from "./selections";

export interface OTRange {
  head: number;
  anchor: number;
}

export interface IEditorSelection {
  clientId: string;
  username: string;
  color: string;
  range: Monaco.IRange;
  isSecondary: boolean;
}

// Maps username => color index
export type ClientColorMap = Map<string, string>;

export interface IUserColorState {
  nextColor: number;
  colorMap: ClientColorMap;
}

interface ISelectionClasses {
  cursor: string;
  selection: string;
  secondarySelection: string;
}

type SelectionClassesMap = Map<string, ISelectionClasses>;

const _selectionClasses: SelectionClassesMap = new Map();
const roundedClasses = {
  topLeft: "",
  topRight: "",
  bottomLeft: "",
  bottomRight: "",
};
export function ensureSelectionClasses(): SelectionClassesMap {
  if (!roundedClasses.topLeft) {
    roundedClasses.topLeft = addStyles({
      borderTopLeftRadius: 3,
    }).toString();

    roundedClasses.topRight = addStyles({
      borderTopRightRadius: 3,
    }).toString();

    roundedClasses.bottomLeft = addStyles({
      borderBottomLeftRadius: 3,
    }).toString();

    roundedClasses.bottomRight = addStyles({
      borderBottomRightRadius: 3,
    }).toString();
  }

  return _selectionClasses;
}

export function getSelectionColorClasses(color: string): ISelectionClasses {
  let result = _selectionClasses.get(color);
  if (!result) {
    result = {
      cursor: addStyles({
        backgroundColor: color,
        width: "2px !important",
        height: "100%",
      }).toString(),
      selection: addStyles({
        backgroundColor: `${color}1E`,
        minWidth: 8,
      }).toString(),
      secondarySelection: addStyles({
        backgroundColor: `${color}14`,
        minWidth: 8,
      }).toString(),
    };
    _selectionClasses.set(color, result);
  }
  return result;
}

export function createEditorSelections(
  lines: string[],
  eolLength: number,
  clients: Record<string, protocol.client.ClientJSON>,
  documentSelections: IDocumentSelections,
  currentClientId: string,
): IEditorSelection[] {
  const filteredClientIds = Object.keys(clients).filter(
    (clientId) => clientId !== currentClientId,
  );

  const selections: IEditorSelection[] = [];
  for (const clientId of filteredClientIds) {
    const client = enforce.notUndefined(clients[clientId]);

    const documentSelection = documentSelections[clientId];
    if (documentSelection) {
      for (let i = 0; i < documentSelection.length; i++) {
        const clientSelectionRange = enforce.notUndefined(documentSelection[i]);

        selections.push({
          clientId,
          // TODO: Use user.name?
          username: client.username,
          range: jsonRangeToMonacoRange(lines, eolLength, clientSelectionRange),
          isSecondary: i > 0,
          color: client.color,
        });
      }
    }
  }
  return selections;
}

function normalizeRange(range: Monaco.IRange): Monaco.IRange {
  if (range.startLineNumber > range.endLineNumber) {
    return {
      startLineNumber: range.endLineNumber,
      startColumn: range.endColumn,
      endLineNumber: range.startLineNumber,
      endColumn: range.startColumn,
    };
  } else {
    return range;
  }
}

function splitRangeIntoLines(
  model: Monaco.editor.ITextModel,
  range: Monaco.IRange,
): Array<{ line: number; startColumn: number; endColumn: number }> {
  range = normalizeRange(range);
  const startLine = range.startLineNumber;
  const endLine = range.endLineNumber;
  const result = [];
  for (let line = startLine; line <= endLine; line++) {
    let startColumn = 1;
    if (line === startLine) {
      startColumn = range.startColumn;
    }

    let endColumn = range.endColumn;
    if (line !== endLine) {
      endColumn = model.getLineMaxColumn(line);
    }

    result.push({
      line,
      startColumn,
      endColumn,
    });
  }
  return result;
}

export function createRoundedSelectionDecorations(
  model: Monaco.editor.ITextModel,
  color: string,
  inputDecoration: Monaco.editor.IModelDeltaDecoration,
): Monaco.editor.IModelDeltaDecoration[] {
  if (
    inputDecoration.range.startLineNumber ===
    inputDecoration.range.endLineNumber
  ) {
    return [inputDecoration];
  }

  const decorations: Monaco.editor.IModelDeltaDecoration[] = [];
  const lines = splitRangeIntoLines(model, inputDecoration.range);
  for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
    const prevLine = lineIndex > 0 ? lines[lineIndex - 1] : null;
    const line = enforce.notUndefined(lines[lineIndex]);
    const nextLine = lineIndex < lines.length - 1 ? lines[lineIndex + 1] : null;
    const classNames = [inputDecoration.options.className];
    if (!prevLine || line.endColumn > prevLine.endColumn) {
      classNames.push(roundedClasses.topRight);
    }
    if (!prevLine || line.startColumn < prevLine.startColumn) {
      classNames.push(roundedClasses.topLeft);
    }
    if (!nextLine || line.startColumn < nextLine.startColumn) {
      classNames.push(roundedClasses.bottomLeft);
    }
    if (!nextLine || line.endColumn > nextLine.endColumn) {
      classNames.push(roundedClasses.bottomRight);
    }
    decorations.push({
      range: {
        startLineNumber: line.line,
        startColumn: line.startColumn,
        endLineNumber: line.line,
        endColumn: line.endColumn,
      },
      options: {
        ...inputDecoration.options,
        className: classNames.join(" "),
        overviewRuler: {
          color,
          position: vscode.OverviewRulerLane.Right,
        },
      },
    });
  }
  return decorations;
}

export function createSelectionDecorations(
  model: Monaco.editor.ITextModel,
  selections: IEditorSelection[],
): Monaco.editor.IModelDeltaDecoration[] {
  const decorations: Monaco.editor.IModelDeltaDecoration[] = [];
  ensureSelectionClasses();
  for (const selection of selections) {
    const classes = getSelectionColorClasses(selection.color);
    if (classes) {
      if (
        selection.range.startLineNumber !== selection.range.endLineNumber ||
        selection.range.startColumn !== selection.range.endColumn
      ) {
        decorations.push(
          ...createRoundedSelectionDecorations(model, selection.color, {
            range: selection.range,
            options: {
              className: selection.isSecondary
                ? classes.secondarySelection
                : classes.selection,
              stickiness: 1,
              hoverMessage: {
                value: selection.username,
              },
            },
          }),
        );
      }

      decorations.push({
        range: {
          startLineNumber: selection.range.endLineNumber,
          startColumn: selection.range.endColumn,
          endLineNumber: selection.range.endLineNumber,
          endColumn: selection.range.endColumn,
        },
        options: {
          className: classes.cursor,
          stickiness: 3,
          hoverMessage: {
            value: selection.username,
          },
        },
      });
    }
  }
  return decorations;
}

export function createClientLocationsFromSelections(
  selections: IEditorSelection[],
): Map<number, Set<string>> {
  const clientLineLocations: Map<number, Set<string>> = new Map();
  for (const selection of selections) {
    const lineNumber = selection.range.endLineNumber;
    const clientIdSet = clientLineLocations.get(lineNumber) || new Set();
    clientIdSet.add(selection.clientId);
    clientLineLocations.set(lineNumber, clientIdSet);
  }
  return clientLineLocations;
}
