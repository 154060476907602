import type { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";
import { CheckboxItem, ItemIndicator } from "@radix-ui/react-dropdown-menu";
import React from "react";

import { item } from "../../css/menu";
import { styled } from "../../theme";
import { Checkbox } from "../Checkbox";
import { Stack } from "../Stack";
import { Text } from "../Text";

const Item = styled(CheckboxItem, item);

interface DropdownCheckboxProps extends DropdownMenuCheckboxItemProps {
  closeOnChange?: boolean;
}

export const DropdownCheckbox: React.FC<DropdownCheckboxProps> = ({
  checked,
  children,
  closeOnChange = false,
  onCheckedChange,
  onSelect,
}) => {
  return (
    <Item
      checked={checked}
      onCheckedChange={onCheckedChange}
      onSelect={(event: Event) => {
        if (closeOnChange === false) event.preventDefault();
        if (onSelect) onSelect(event);
      }}
      asChild
    >
      <Stack gap={2} horizontal>
        <Checkbox checked={checked}>
          <ItemIndicator asChild>
            <Checkbox.Check />
          </ItemIndicator>
        </Checkbox>
        <Text>{children}</Text>
      </Stack>
    </Item>
  );
};
