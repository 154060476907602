import { Fallback, Image, Root } from "@radix-ui/react-avatar";
import type { VariantProps } from "@stitches/react";
import React, { useState } from "react";

import { styled } from "../../theme";

import { Initial } from "./Initial";

// Same as the callback of onLoadingStatusChange. Type is not exported from `@radix-ui/react-avatar`
type ImageLoadingStatus = "idle" | "loading" | "loaded" | "error";

const AvatarRoot = styled(Root, {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  verticalAlign: "middle",
  overflow: "hidden",
  fontWeight: "$semibold",
  textTransform: "uppercase",
  color: "$text-dim",
  flexGrow: 0,
  borderRadius: "$1",
  lineHeight: 1,

  variants: {
    shape: {
      block: {
        borderRadius: 2,
      },
      circle: { borderRadius: "50%" },
    },
    hasHighlight: {
      true: {
        border: "2px solid $$highlightColor",
      },
      false: {},
    },
    hasImage: {
      true: {},
      false: {},
    },
    size: {
      xs: {
        width: "16px",
        height: "16px",
        fontSize: "10px",
      },
      s: {
        width: "18px",
        height: "18px",
        fontSize: "12px",
      },
      m: {
        height: "26px",
        width: "26px",
        fontSize: "14px",
      },
      l: {
        height: "$12",
        width: "$12",
        fontSize: "24px",
      },
    },
    state: {
      idle: {
        backgroundColor: "$neutral-bg-medium",
      },
      loading: {
        backgroundColor: "$$highlightColor",
      },
      loaded: {
        backgroundColor: "$neutral-bg-medium",
      },
      error: {
        backgroundColor: "$neutral-bg-medium",
      },
    },
  },

  compoundVariants: [
    {
      hasImage: false,
      hasHighlight: true,
      css: {
        backgroundColor: "$$highlightColor",
        color: "$neutral-bg-low",
      },
    },
  ],

  defaultVariants: {
    size: "m",
  },
});

const AvatarImage = styled(Image, {
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

type Variants = Omit<
  VariantProps<typeof AvatarRoot>,
  "state" | "hasHighlight" | "hasImage"
>;

interface Props extends Omit<Variants, "state"> {
  className?: string;
  highlightColor?: string;
  src?: string | null;
  name: string;
  title?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export const Avatar: React.FC<Props> = ({
  className,
  highlightColor,
  shape,
  size = "m",
  src,
  name,
  title,
  onClick,
}) => {
  const [imageStatus, setImageStatus] = useState<ImageLoadingStatus>(
    src ? "loading" : "idle",
  );

  return (
    <AvatarRoot
      className={className}
      css={{
        $$highlightColor: highlightColor,
      }}
      hasHighlight={highlightColor !== undefined}
      hasImage={src !== undefined}
      onClick={onClick}
      shape={shape}
      size={size}
      state={imageStatus}
      title={title}
    >
      {src ? (
        <>
          <AvatarImage onLoadingStatusChange={setImageStatus} src={src} />
          <Fallback delayMs={1000}>
            <Initial name={name} />
          </Fallback>
        </>
      ) : (
        <Initial name={name} />
      )}
    </AvatarRoot>
  );
};
