import { palette } from "prism-react/tokens/palette";
import type { ReactNode } from "react";
import React from "react";

import { styled } from "../../theme";
import type { ButtonProps } from "../Button";
import { Button } from "../Button";

interface AlertActionType {
  text: ReactNode;
  onAction: () => void;
}

export interface AlertProps {
  actions?: AlertActionType[];
  message: ReactNode;
  onDismiss: () => void;
  showDismiss: boolean;
}

const Wrapper = styled("div", {
  px: "$5",
  py: "$5",

  width: 340,
  minHeight: 105,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  boxShadow: "$popup",
  borderRadius: "$2",
  backgroundColor: palette.neutral[10],
});

const Content = styled("div", {
  display: "block",
  color: palette.neutral[0],
  lineHeight: "19px",
  opacity: 0.7,
});

const Actions = styled("div", {
  display: "inline-flex",
  // offset the container of the button's paddings. Smells a bit.
  marginLeft: "calc(-1 * $2)",
  marginBottom: "calc(-1 * $2)",
  justifyContent: "space-between",
});

const ActionButton: React.FC<ButtonProps> = (props) => {
  return <Button {...props} variant="ghost-inverted" />;
};

export const Alert: React.FC<AlertProps> = ({
  message,
  actions,
  onDismiss,
  showDismiss,
}) => {
  return (
    <Wrapper>
      <Content>{message}</Content>

      <Actions>
        {actions?.map((action, i) => (
          <ActionButton
            key={`${action.text}-${i}`}
            onClick={() => {
              action.onAction();
              onDismiss();
            }}
          >
            {action.text}
          </ActionButton>
        ))}

        {showDismiss && (
          <ActionButton onClick={onDismiss}>Dismiss</ActionButton>
        )}
      </Actions>
    </Wrapper>
  );
};
