import type {
  AbsoluteWorkspacePath,
  IDisposable,
  IPitcherClient,
  RelativeWorkspacePath,
  clients,
} from "@codesandbox/pitcher-client";
import { Disposable } from "@codesandbox/pitcher-client";
import { FS_SCHEME } from "environment-interface";
import type { editor } from "monaco-editor";
import { Uri } from "vscode";
import { registerExtension } from "vscode/extensions";
import { DisposableStore } from "vscode/monaco";
import {
  ILoggerService,
  IModelService,
  LogLevel,
  StandaloneServices,
} from "vscode/services";

import { runWithLatestMonacoConfig } from "../../tools/with-monaco-config";

import { initCollaboratorsAvatar } from "./avatars";
import type { FocusFileMessage } from "./following";
import { initializeFollowingFunctionality } from "./following";
import { ModelHandler } from "./model";

export function activate(): IDisposable {
  const disposableStore = new DisposableStore();
  const { dispose } = registerExtension({
    name: "codesandbox-live",
    publisher: "codesandbox",
    version: "1.0.0",
    engines: {
      vscode: "*",
    },
  });

  disposableStore.add({ dispose });
  disposableStore.add(
    runWithLatestMonacoConfig(
      (config) => new LiveProvider(config.pitcher, FS_SCHEME),
    ),
  );

  return disposableStore;
}

/**
 * Responsible for all live functionality inside VSCode.
 */
export class LiveProvider extends Disposable {
  private readonly logger = this.addDisposable(
    StandaloneServices.get(ILoggerService).createLogger("CodeSandbox Live"),
  );
  private readonly modelService = StandaloneServices.get(IModelService);
  private followChannel: clients.IFollowChannel<FocusFileMessage>;
  constructor(
    private pitcher: IPitcherClient,
    private fsScheme: string,
  ) {
    super();

    const { disposable, followChannel } = initializeFollowingFunctionality(
      pitcher.workspacePath,
      pitcher.clients.channel,
      pitcher.clients.client,
      Uri.from({
        path: pitcher.workspacePath,
        scheme: fsScheme,
      }),
    );

    this.addDisposable(initCollaboratorsAvatar(pitcher));

    this.addDisposable(disposable);
    this.followChannel = followChannel;
    this.registerModelHandlers();
    this.logger.setLevel(LogLevel.Debug);
  }

  private modelHandlers = new Map<RelativeWorkspacePath, IDisposable>();
  private registerModelHandlers() {
    this.addDisposable(
      this.modelService.onModelAdded((model) => {
        this.addModel(model);
      }),
    );

    this.addDisposable(
      this.modelService.onModelRemoved((model) => {
        this.removeModel(model);
      }),
    );

    this.modelService.getModels().forEach((model) => {
      this.addModel(model);
    });
  }

  private async addModel(model: editor.ITextModel) {
    const uri = model.uri;
    if (uri.scheme !== this.fsScheme) {
      return;
    }

    const path = uri.path as AbsoluteWorkspacePath;
    const relativePath =
      this.pitcher.clients.fs.absoluteToRelativeWorkspacePath(path);

    if (!relativePath) {
      return;
    }

    const pitcherFileRef =
      await this.pitcher.clients.file.openFileByPath(relativePath);

    if (this.isDisposed || model.isDisposed()) {
      pitcherFileRef.dispose();
      return;
    }

    if (this.modelHandlers.has(relativePath)) {
      pitcherFileRef.dispose();
      this.logger.warn("Model handler already registered for", relativePath);
      return;
    }

    this.logger.debug("Registering model handler for", relativePath);
    const modelHandler = this.addDisposable(
      new ModelHandler(
        model,
        pitcherFileRef,
        this.logger,
        this.pitcher,
        this.followChannel,
      ),
    );
    this.modelHandlers.set(relativePath, modelHandler);
  }

  private removeModel(model: editor.ITextModel) {
    const uri = model.uri;
    if (uri.scheme !== this.fsScheme) {
      return;
    }

    const path = uri.path as AbsoluteWorkspacePath;
    const relativePath =
      this.pitcher.clients.fs.absoluteToRelativeWorkspacePath(path);

    if (!relativePath) {
      return;
    }

    const modelHandler = this.modelHandlers.get(relativePath);
    if (!modelHandler) {
      return;
    }

    this.logger.debug("Removing model handler for", relativePath);
    modelHandler.dispose();
    this.modelHandlers.delete(relativePath);
  }
}
