import { murmur, Emitter, Disposable, sleep, } from "@codesandbox/pitcher-common";
import { Document } from "./Document";
export class File extends Disposable {
    get isDirty() {
        return this.contentHash !== this.savedHash;
    }
    constructor(path, fileData, messageHandler) {
        super();
        this.onDidSaveEmitter = new Emitter();
        this.onDidSave = this.onDidSaveEmitter.event;
        this.onDidContentChangeEmitter = new Emitter();
        this.onDidContentChange = this.onDidContentChangeEmitter.event;
        this.onWillClientJoinEmitter = new Emitter();
        this.onWillClientJoin = this.onWillClientJoinEmitter.event;
        this.onDidClientJoinEmitter = new Emitter();
        this.onDidClientJoin = this.onDidClientJoinEmitter.event;
        this.onWillClientLeaveEmitter = new Emitter();
        this.onWillClientLeave = this.onWillClientLeaveEmitter.event;
        this.onDidClientLeaveEmitter = new Emitter();
        this.onDidClientLeave = this.onDidClientLeaveEmitter.event;
        this.onDidMoveEmitter = new Emitter();
        this.onDidMove = this.onDidMoveEmitter.event;
        this.clients = {};
        this.document = null;
        this.path = path;
        this.id = fileData.id;
        this.savedHash = fileData.savedHash;
        this.messageHandler = messageHandler;
        this.content = fileData.content;
        this.isBinary = fileData.isBinary;
        this.clients = fileData.clients;
        this.contentHash =
            typeof fileData.content === "string"
                ? murmur(fileData.content)
                : fileData.savedHash;
        if (fileData.document) {
            this.document = new Document(this, fileData.document, messageHandler);
        }
        messageHandler.onNotification("file/save", ({ id, savedHash }) => {
            if (id !== this.id) {
                return;
            }
            this.savedHash = savedHash;
            this.onDidSaveEmitter.fire({
                savedHash,
            });
        });
        messageHandler.onNotification("file/join", ({ id, username, clientId }) => {
            if (id !== this.id) {
                return;
            }
            this.onWillClientJoinEmitter.fire({
                clientId,
                username,
            });
            this.clients[clientId] = {
                username,
            };
            this.onDidClientJoinEmitter.fire({
                clientId,
                username,
                clients: this.clients,
            });
        });
        messageHandler.onNotification("file/leave", ({ id, clientId, username }) => {
            if (id !== this.id) {
                return;
            }
            this.onWillClientLeaveEmitter.fire({
                clientId,
                username,
            });
            delete this.clients[clientId];
            this.onDidClientLeaveEmitter.fire({
                clientId,
                username,
                clients: this.clients,
            });
        });
        this.onWillDispose(() => {
            this.onDidSaveEmitter.dispose();
            this.onDidContentChangeEmitter.dispose();
            this.onWillClientJoinEmitter.dispose();
            this.onDidClientJoinEmitter.dispose();
            this.onWillClientLeaveEmitter.dispose();
            this.onDidClientLeaveEmitter.dispose();
            this.document?.dispose();
        });
    }
    updatePath(newPath) {
        this.path = newPath;
        this.onDidMoveEmitter.fire(newPath);
    }
    async resync() {
        if (!this.document) {
            return;
        }
        // TODO: Close file if it no longer exists?
        const readResult = await this.messageHandler.request({
            method: "file/open",
            params: {
                id: this.id,
                isResync: true,
            },
        }, 
        // Resync is a result of reconnecting, so we do not queue here
        {
            queueForReconnect: false,
        });
        this.savedHash = readResult.savedHash;
        if (readResult.document && typeof readResult.content === "string") {
            this.document.resync(readResult.content, readResult.document.revision);
        }
    }
    /**
     * Will get the current content of the document in Pitcher and reset the otClient to that state.
     * It returns the current contents which should be updated in the code editor
     */
    async resetFromServer() {
        if (!this.document) {
            return;
        }
        const readResult = await this.messageHandler.request({
            method: "file/open",
            params: {
                id: this.id,
                isResync: true,
            },
        }, 
        // In this context we do not want any queuing as a failed resync would mean we would call this again
        {
            queueForReconnect: false,
        });
        if (readResult.document && typeof readResult.content === "string") {
            this.document.reset(readResult.content, readResult.document.revision);
            return readResult.content;
        }
    }
    /**
     * Will get the current content of the document in Pitcher, reset the otClient to that state and
     * apply the current editor contents as a diff operation on top
     */
    async resetFromClient(content) {
        if (!this.document) {
            return;
        }
        const readResult = await this.messageHandler.request({
            method: "file/open",
            params: {
                id: this.id,
                isResync: true,
            },
        }, 
        // In this context we do not want any queuing as a failed resync would mean we would call this again
        {
            queueForReconnect: false,
        });
        if (readResult.document && typeof readResult.content === "string") {
            this.document.reset(readResult.content, readResult.document.revision);
            this.document.otClient.applyClientDocument(content);
        }
    }
    updateContent(newContent) {
        this.content = newContent;
        this.contentHash = murmur(newContent);
        this.onDidContentChangeEmitter.fire({
            contentHash: this.contentHash,
            newContent: this.content,
        });
    }
    async save(write = true) {
        const hasPendingOperations = () => this.document &&
            (this.document.otClient.hasPendingOperation ||
                this.document?.otClient.hasQueuedOperation);
        // We wait for all pending operations to be sent
        while (hasPendingOperations()) {
            await sleep(50);
        }
        return this.messageHandler
            .request({
            method: "file/save",
            params: {
                id: this.id,
                write,
            },
        }, {
            seamlessForkStrategy: "queue",
        })
            .then(() => {
            return;
        });
    }
    /** Do not call this directly, should be called by the wrapping Rc */
    internalClose() {
        this.dispose();
        return this.messageHandler
            .request({
            method: "file/close",
            params: {
                id: this.id,
            },
        })
            .then(() => {
            return;
        });
    }
}
