import type { IPitcherClient } from "@codesandbox/pitcher-client";
import { useEnvironmentInterface } from "environment-interface";
import type { LegacyPitcherClient } from "environment-interface/pitcher";
import React, { createContext } from "react";

export const LegacyPitcherContext = createContext<LegacyPitcherClient>(
  null as unknown as LegacyPitcherClient,
);

LegacyPitcherContext.displayName = "LegacyPitcher";

export const PitcherConsumer = LegacyPitcherContext.Consumer;

interface Props {
  children: React.ReactNode;
  client: IPitcherClient;
  id: string;
}

export const LegacyPitcherProvider: React.FC<Omit<Props, "id">> = ({
  children,
}) => {
  const { pitcherInstanceManager } = useEnvironmentInterface();

  return (
    <LegacyPitcherContext.Provider
      value={pitcherInstanceManager.getLegacyPitcherClient()}
    >
      {children}
    </LegacyPitcherContext.Provider>
  );
};

export const PitcherContext = createContext(null as unknown as IPitcherClient);

PitcherContext.displayName = "Pitcher";

export const PitcherProvider: React.FC<Props> = ({ client, children }) => {
  return (
    <PitcherContext.Provider value={client}>{children}</PitcherContext.Provider>
  );
};
