import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from "react";

import { safeLocalStorage } from "utils/safeLocalStorage";

import { Box } from "../components";
import { base } from "../tokens/themes/base";

import type { ThemeColorSchema, ThemeContextT, Tokens } from "./constants";
import { BUILT_IN_THEMES, DEFAULT_COLOR_SCHEMA } from "./constants";
import { globalStyles } from "./globalStyles";
import { createTheme } from "./stitches";
import {
  getColorSchemaFromStorage,
  saveColorSchemaToStorage,
  standarlizeColorSchemaName as standardizeColorSchemaName,
} from "./utils";

export const ThemeContext = createContext<ThemeContextT>({
  className: "",
  theme: DEFAULT_COLOR_SCHEMA,
  tokens: base,
  setTheme: () => {},
});

const themeClassNames: Record<string | string, string> = {
  dark: createTheme({}),
  light: createTheme(BUILT_IN_THEMES.light),
};

const allThemes: Record<string | string, Tokens> = { ...BUILT_IN_THEMES };

interface Props {
  children: React.ReactNode;
  theme?: ThemeColorSchema;
  /**
   * Variants are supported primarily for documentation purposes.
   *
   * global = theme applied to HTML element.
   * scoped = theme applied to all child nodes.
   */
  variant?: "global" | "scoped";

  // A function for getting the basePath for public files
  withBasePath: (url: string) => string;
}

export const ThemeProvider: React.FC<Props> = ({
  children,
  theme = DEFAULT_COLOR_SCHEMA,
  variant = "global",
  withBasePath,
}) => {
  const [currentTheme, setCurrentStitchesTheme] =
    useState<ThemeColorSchema>(theme);
  const lastThemeRef = useRef(themeClassNames[theme.type].toString());
  const currentSchemaName = standardizeColorSchemaName(currentTheme.name);

  const applyThemeToDocument = (name: string, theme: string) => {
    const themeClassName =
      themeClassNames[standardizeColorSchemaName(name)].toString();

    // Remove all possible themes types
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.remove("light");

    document.documentElement.classList.add(themeClassName);
    document.documentElement.classList.add(theme);

    if (themeClassName !== lastThemeRef.current) {
      document.documentElement.classList.remove(lastThemeRef.current);
      lastThemeRef.current = themeClassName;
    }
  };

  globalStyles(withBasePath)();

  useEffect(function getInitialTheme() {
    try {
      const initialData = getColorSchemaFromStorage();

      createNewStitchesTheme(initialData); // do not move me
      applyThemeToDocument(initialData.name, initialData.type);
      setCurrentStitchesTheme(initialData);
      setGlobalVariables();
    } catch (err) {
      saveColorSchemaToStorage(DEFAULT_COLOR_SCHEMA);
    }
  }, []);

  const setTheme = (theme: ThemeColorSchema) => {
    const standardSchemaName = standardizeColorSchemaName(theme.name);
    if (!allThemes[standardSchemaName]) {
      createNewStitchesTheme(theme);
    }

    applyThemeToDocument(theme.name, theme.type);
    saveColorSchemaToStorage(theme);
    setCurrentStitchesTheme(theme);
  };

  return (
    <ThemeContext.Provider
      value={{
        className: themeClassNames[currentSchemaName],
        theme: currentTheme,
        tokens: allThemes[currentSchemaName],
        setTheme,
      }}
    >
      {variant === "global" ? (
        <>{children}</>
      ) : (
        <Box className={themeClassNames[currentTheme.name]}>{children}</Box>
      )}
    </ThemeContext.Provider>
  );
};

const setGlobalVariables = () => {
  // Default is true if the value is not set
  const usePointers = Boolean(
    safeLocalStorage.get("CSB/CURSOR_POINTER") ?? "true",
  );

  document.documentElement.style.setProperty(
    "--csb-cursor",
    usePointers ? "pointer" : "default",
  );
};

const createNewStitchesTheme = ({ name, type, colors }: ThemeColorSchema) => {
  const standardSchemaName = standardizeColorSchemaName(name);

  allThemes[standardSchemaName] = {
    ...BUILT_IN_THEMES[type],
    colors: { ...allThemes[type].colors, ...colors },
  };

  themeClassNames[standardSchemaName] = createTheme(
    allThemes[standardSchemaName],
  );
};

export const useTheme = (): ThemeContextT => useContext(ThemeContext);
export const useThemeTokens = (): ThemeContextT["tokens"] => useTheme().tokens;
