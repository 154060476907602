import { Emitter } from "@codesandbox/pitcher-common";
import { AsyncValueStore } from "../../common/AsyncValueStore";
export class ClientClient {
    constructor(messageHandler) {
        this.messageHandler = messageHandler;
        this.clientConnectedEmitter = new Emitter();
        this.onClientConnected = this.clientConnectedEmitter.event;
        this.clientDisconnectedEmitter = new Emitter();
        this.onClientDisconnected = this.clientDisconnectedEmitter.event;
        this.clientUpdatedEmitter = new Emitter();
        this.onClientUpdated = this.clientUpdatedEmitter.event;
        this.clientPermissionsEmitter = new Emitter();
        this.onClientPermissionsUpdated = this.clientPermissionsEmitter.event;
        this.clientsUpdatedEmitter = new Emitter();
        this.onClientsUpdated = this.clientsUpdatedEmitter.event;
        this.clientsErrorEmitter = new Emitter();
        this.onClientsError = this.clientsErrorEmitter.event;
        this.clients = this.createClientsValue();
        messageHandler.onNotification("client/connected", (connectedClient) => {
            this.clients.update((existingValue) => {
                const existingClient = existingValue.find((client) => client.clientId === connectedClient.clientId);
                if (existingClient) {
                    return existingValue.map((client) => client.clientId === connectedClient.clientId
                        ? // We might already have the client in the list, in that case we use the new connected client, but
                            // we rather use the avatar url from the stale client as this is lazily resolved and we do not want flicker
                            { ...connectedClient, avatarUrl: client.avatarUrl }
                        : client);
                }
                return existingValue.concat(connectedClient);
            });
            this.clientConnectedEmitter.fire(connectedClient);
        });
        messageHandler.onNotification("client/disconnected", ({ clientId, reason }) => {
            // We do not update the list of clients if it is the current user being disconnected, it will rather
            // update related to being connected again
            if (clientId === this.currentClientId) {
                return;
            }
            this.clients.update((existingValue) => existingValue.filter((client) => client.clientId !== clientId));
            this.clientDisconnectedEmitter.fire({ clientId, reason });
        });
        messageHandler.onNotification("client/updated", (updatedClient) => {
            this.clients.update((existingValue) => existingValue.map((client) => client.clientId === updatedClient.clientId ? updatedClient : client));
            this.clientUpdatedEmitter.fire(updatedClient);
        });
        messageHandler.onNotification("client/permissions", (updatedPermissions) => {
            this.clientPermissionsEmitter.fire(updatedPermissions);
        });
    }
    createClientsValue() {
        const clients = new AsyncValueStore([], () => this.messageHandler.request({
            method: "client/list",
            params: {},
        }));
        clients.onChange(({ value }) => this.clientsUpdatedEmitter.fire(value));
        clients.onError((error) => this.clientsErrorEmitter.fire(error));
        return clients;
    }
    async join(params) {
        const joinResult = await this.messageHandler.request({
            method: "client/join",
            params,
        }, {
            // 1 hour timeout
            timeoutMs: 3600000,
            queueForReconnect: false,
        });
        const joinedClient = joinResult.client;
        this.currentClientId = joinResult.client.clientId;
        // When we join the list is typically empty, but on reconnects we might have stale clients here. So we clean
        // it out, but keep the resolved avatarUrl of the current user given the clientId is the same
        this.clients.update((existingValue) => {
            const existingClient = existingValue.find((client) => client.clientId === joinedClient.clientId);
            // Avoid having flicker on avatar related to resolving the current user
            if (existingClient) {
                return [{ ...joinedClient, avatarUrl: existingClient.avatarUrl }];
            }
            return [joinedClient];
        });
        return joinResult;
    }
    getClients() {
        return this.clients.get();
    }
    resync() {
        return this.clients.refresh();
    }
}
