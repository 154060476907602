import * as path from "path";

import type { IPitcherClient } from "@codesandbox/pitcher-client";
import type { Event } from "@codesandbox/pitcher-common";
import {
  Barrier,
  DisposableStore,
  Emitter,
  listenOnce,
  sleep,
} from "@codesandbox/pitcher-common";
import _debug from "debug";
import type { CancellationToken } from "vscode";
import { CancellationError, CancellationTokenSource } from "vscode";

import { onceEvent } from "utils/event";

const debug = _debug("csb:vscode:remote:prepare");
const WEB_ENDPOINT_URL_TEMPLATE = `${document.location.protocol}//${document.location.hostname}`;
const VSCODE_LIBRARY_VERSION = `1.89.1.24130`;
const VSCODE_COMMIT_SHA = `dc96b837cf6bb4af9cd736aa3af08cf8279f7685`;
const VSCODE_LIBRARY_LOCATIONS = {
  global: path.join("/vscode", VSCODE_LIBRARY_VERSION),
  user: path.join("/user-vscode", VSCODE_LIBRARY_VERSION),
};
export const VSCODE_SERVER_PORT_RANGE = [50000, 50100];
const VSCODE_SERVER_SHELL_NAME = "VSCode Server";

if (process.env.NODE_ENV !== "production") {
  _debug.enable("csb:*");
}

export type VSCodeRemoteLoadProgress =
  | {
      type: "preparing";
      message: string;
      onDidOutput?: Event<string>;
    }
  | {
      type: "idle";
    }
  | {
      type: "done";
    }
  | {
      type: "error";
      error: Error;
    }
  | {
      type: "cancelled";
    };

export type OnProgressFn = (progress: VSCodeRemoteLoadProgress) => void;
export type RemoteOpts = {
  onProgress: OnProgressFn;
  isProtected?: boolean;
  cancellationToken: CancellationToken;
};

export type VSCodeServerRunningResult =
  | {
      status: "running";
      port: number;
      token: string;
    }
  | {
      status: "error";
      error: Error;
    }
  | {
      status: "cancelled";
    };

export async function initializeRemoteServer(
  opts: RemoteOpts,
  pitcher: IPitcherClient,
): Promise<VSCodeServerRunningResult> {
  try {
    if (!pitcher.capabilities.shell?.io) {
      throw new Error("Shell capabilities are not enabled");
    }

    debug("Initializing remote server...");
    // Check if the devcontainer of the user is already started
    await ensureDevcontainer(pitcher, opts);

    throwIfCancellationRequested(opts.cancellationToken);

    // First check if there is already a VSCode server installed
    debug("Checking if vscode server is installed...");
    const installedVscodeServerLocation = await getOrInstallVSCodeServer(
      pitcher,
      opts.cancellationToken,
      opts.onProgress,
    );
    debug("VSCode server installed at %s", installedVscodeServerLocation);

    throwIfCancellationRequested(opts.cancellationToken);

    const result = await runVSCodeServer(
      pitcher,
      installedVscodeServerLocation,
      opts,
    );

    throwIfCancellationRequested(opts.cancellationToken);

    const waitForPortResult = await Promise.race([
      waitForPort(pitcher, result.port),
      sleep(5000).then(() => "timeout" as const),
      onceEvent(opts.cancellationToken.onCancellationRequested).then(
        () => "cancelled" as const,
      ),
    ]);
    if (waitForPortResult === "timeout") {
      throw new Error("Waiting for port timed out");
    }

    if (waitForPortResult === "cancelled") {
      throw new CancellationError();
    }

    opts.onProgress({ type: "done" });
    debug("VSCode remote initialization done");

    return {
      status: "running",
      port: result.port,
      token: result.token,
    };
  } catch (e) {
    if (e instanceof CancellationError) {
      return {
        status: "cancelled",
      };
    } else if (e instanceof Error) {
      return {
        status: "error",
        error: e,
      };
    } else {
      return {
        status: "error",
        error: new Error("An unknown error occurred"),
      };
    }
  }
}

async function runVSCodeServer(
  pitcher: IPitcherClient,
  installedServerLocation: string,
  opts: RemoteOpts,
) {
  const runtimeDirectory = `/var/run/pitcher/.vscode/${VSCODE_LIBRARY_VERSION}`;

  if (!(await exists(pitcher, runtimeDirectory))) {
    debug("Creating runtime directory...");
    await pitcher.clients.fs.mkdir(runtimeDirectory, true);
  }
  const portLocation = path.join(runtimeDirectory, ".port");
  const connectionTokenLocation = path.join(runtimeDirectory, ".token");

  opts.onProgress({
    type: "preparing",
    message: "Check if VSCode server is already running...",
  });

  // Check if the port file exists and the port is opened already
  const isRunningResult = await checkVSCodeServerRunning(
    pitcher,
    portLocation,
    connectionTokenLocation,
  );

  if (isRunningResult) {
    debug(
      "VSCode server is already running, reusing port %d",
      isRunningResult.port,
    );
    return isRunningResult;
  }

  const outputEmitter = new Emitter<string>();
  outputEmitter.event((output) => {
    debug("[output]: %s", output);
  });

  opts.onProgress({
    type: "preparing",
    message: "Starting VSCode server...",
    onDidOutput: outputEmitter.event,
  });

  debug("VSCode server not running, starting...");

  // Create random string of length 32 to use as connection token
  const connectionToken = generateUUID();
  await writeFileUnwrapped(pitcher, connectionTokenLocation, connectionToken);

  debug("Writing new product.json");
  const productJsonLocation = path.join(
    installedServerLocation,
    "product.json",
  );
  const currentProductJsonRes =
    await pitcher.clients.fs.readFile(productJsonLocation);
  if (currentProductJsonRes.type !== "ok") {
    throw new Error("Could not find product.json in VSCode server");
  }
  const currentProductJson = JSON.parse(
    new TextDecoder().decode(currentProductJsonRes.result.content),
  );

  const expectedValues = {
    commit: VSCODE_COMMIT_SHA,
    webEndpointUrlTemplate: WEB_ENDPOINT_URL_TEMPLATE,
    nameShort: "CodeSandbox",
    nameLong: "CodeSandbox",
    applicationName: "codesandbox",
  };
  let changedProductJson = false;
  for (const [key, value] of Object.entries(expectedValues)) {
    if (currentProductJson[key] !== value) {
      changedProductJson = true;
      currentProductJson[key] = value;
    }
  }
  if (changedProductJson) {
    debug("Writing product.json", currentProductJson);
    await writeFileUnwrapped(
      pitcher,
      productJsonLocation,
      JSON.stringify(currentProductJson, null, 2),
    );
  }

  debug("Running VSCode server...");
  const cancellationTokenSource = new CancellationTokenSource();
  const serverLocation = path.join(
    installedServerLocation,
    "bin",
    "codium-server",
  );

  runCommandAsUser(
    pitcher,
    `${serverLocation} --port ${VSCODE_SERVER_PORT_RANGE.join(
      "-",
    )} --connection-token-file ${connectionTokenLocation}`,
    outputEmitter,
    cancellationTokenSource.token,
    VSCODE_SERVER_SHELL_NAME,
  );

  opts.cancellationToken.onCancellationRequested(() => {
    // Cancel the original cancellation if the outside cancellation cancels as well
    cancellationTokenSource.cancel();
  });

  const portBarrier = new Barrier<number>();
  const portListener = outputEmitter.event((output) => {
    if (output.includes("127.0.0.1:")) {
      const port = parseInt(output.split(":")[1].split(" ")[0]);
      portBarrier.open(port);
      portListener.dispose();
    }
  });

  const portResult = await Promise.race([
    onceEvent(opts.cancellationToken.onCancellationRequested).then(
      () => "cancelled" as const,
    ),
    portBarrier.wait(),
  ]);

  if (portResult === "cancelled") {
    throw new CancellationError();
  }

  if (portResult.status === "disposed") {
    cancellationTokenSource.cancel();
    throw new Error("Port listener was disposed");
  }

  debug("VSCode server started on port %d", portResult.value);
  await writeFileUnwrapped(pitcher, portLocation, portResult.value.toString());
  debug("Port written to disk");

  outputEmitter.dispose();

  return {
    port: portResult.value,
    token: connectionToken,
  };
}

async function checkVSCodeServerRunning(
  pitcher: IPitcherClient,
  portLocation: string,
  tokenLocation: string,
): Promise<{ port: number; token: string } | false> {
  if (!(await exists(pitcher, portLocation))) {
    return false;
  }

  const fileContentsResult = await pitcher.clients.fs.readFile(portLocation);
  if (fileContentsResult.type !== "ok") {
    return false;
  }

  const port = parseInt(
    new TextDecoder().decode(fileContentsResult.result.content),
  );
  if (!port || isNaN(port)) {
    return false;
  }

  debug(
    "VSCode server could be already running on port %d, checking connectivity...",
    port,
  );

  // Check if the port is opened
  const isPortOpen = pitcher.clients.port
    .getPorts()
    .some((p) => p.port === port);
  if (!isPortOpen) {
    return false;
  }

  await pitcher.clients.shell.readyPromise;

  // Finally, check if we have a shell called VSCode Server, to make sure that
  // _we_ have a shell open for that port and not someone else.
  const shellResult = pitcher.clients.shell
    .getShells()
    .find(
      (s) =>
        s.status === "RUNNING" &&
        s.shellType === "TERMINAL" &&
        s.isSystemShell &&
        s.name === VSCODE_SERVER_SHELL_NAME &&
        s.ownerUsername === pitcher.currentClient.username,
    );
  if (!shellResult) {
    return false;
  }

  debug("VSCode server is already running on port %d", port);
  const tokenReadResult = await pitcher.clients.fs.readFile(tokenLocation);
  if (tokenReadResult.type !== "ok") {
    return false;
  }

  const token = new TextDecoder().decode(tokenReadResult.result.content).trim();

  return { port, token };
}

export async function getOrInstallVSCodeServer(
  pitcher: IPitcherClient,
  cancellationToken: CancellationToken,
  onProgress?: OnProgressFn,
) {
  let installedVscodeServerLocation = undefined;

  for (const location of Object.values(VSCODE_LIBRARY_LOCATIONS)) {
    const vscodeServerExists = await exists(pitcher, location);
    if (vscodeServerExists) {
      const productJsonRead = await pitcher.clients.fs.readFile(
        path.join(location, "product.json"),
      );

      if (productJsonRead.type !== "ok") {
        continue;
      }

      try {
        const productJson = JSON.parse(
          new TextDecoder().decode(productJsonRead.result.content),
        );
        debug("Checking %s", location, {
          wanted: WEB_ENDPOINT_URL_TEMPLATE,
          current: productJson.webEndpointUrlTemplate,
        });
        if (productJson.webEndpointUrlTemplate === WEB_ENDPOINT_URL_TEMPLATE) {
          debug("VSCode server already installed at %s", location);
          installedVscodeServerLocation = location;
          break;
        }
      } catch (e) {
        /* ignore */
      }
    }
  }

  if (!installedVscodeServerLocation) {
    installedVscodeServerLocation = await installVSCodeServer(
      pitcher,
      cancellationToken,
      onProgress,
    );
  } else {
    debug("VSCode server already installed");
  }

  return installedVscodeServerLocation;
}

async function installVSCodeServer(
  pitcher: IPitcherClient,
  cancellationToken: CancellationToken,
  onProgress?: OnProgressFn,
) {
  const outputEmitter = new Emitter<string>();
  onProgress?.({
    type: "preparing",
    message: "Installing VSCode server...",
    onDidOutput: outputEmitter.event,
  });

  outputEmitter.event((output) => {
    debug("[output]: %s", output);
  });

  debug("VSCode server not installed, installing using the manual method...");
  const serverTarLocation = `https://github.com/VSCodium/vscodium/releases/download/${VSCODE_LIBRARY_VERSION}/vscodium-reh-linux-x64-${VSCODE_LIBRARY_VERSION}.tar.gz`;

  debug("Creating temporary directory...");
  const tmpdir = (
    await runCommandAsUser(
      pitcher,
      "mktemp -d",
      outputEmitter,
      cancellationToken,
    )
  ).output;

  debug(`Downloading VSCode server from ${serverTarLocation} to ${tmpdir}`);
  await runCommandAsUser(
    pitcher,
    `curl -L --max-redirs 5 ${serverTarLocation} | tar -xaz -C ${tmpdir}`,
    outputEmitter,
    cancellationToken,
  );
  debug(`Downloaded VSCode server to ${tmpdir}`);

  onProgress?.({
    type: "preparing",
    message: "Moving VSCode server...",
    onDidOutput: outputEmitter.event,
  });

  await runCommandAsUser(
    pitcher,
    `mkdir -p ${path.dirname(VSCODE_LIBRARY_LOCATIONS.user)} && mv ${tmpdir} ${
      VSCODE_LIBRARY_LOCATIONS.user
    }`,
    outputEmitter,
    cancellationToken,
  );

  await pitcher.clients.fs.remove(
    path.join(
      VSCODE_LIBRARY_LOCATIONS.user,
      "extensions",
      "github-authentication",
    ),
    true,
  );

  return VSCODE_LIBRARY_LOCATIONS.user;
}

async function ensureDevcontainer(pitcher: IPitcherClient, opts: RemoteOpts) {
  opts.onProgress({
    type: "preparing",
    message: "Ensuring that Dev Container is ready...",
  });

  await pitcher.clients.setup.readyPromise;
  const progress = pitcher.clients.setup.getProgress();
  if (progress.state !== "FINISHED" && progress.currentStepIndex === 0) {
    if (progress.state !== "IN_PROGRESS") {
      await pitcher.clients.setup.init();
    }

    const outputEmitter = new Emitter<string>();
    // It's currently installing the devcontainer, we have to wait for this.
    opts.onProgress({
      type: "preparing",
      message: "Installing Dev Container...",
      onDidOutput: outputEmitter.event,
    });

    const barrier = new Barrier<{ state: "ok" | "error" }>();
    const setupDisposable = pitcher.clients.setup.onSetupProgressUpdate(
      (setupProgress) => {
        if (setupProgress.currentStepIndex > 0) {
          barrier.open({ state: "ok" });
        }

        if (setupProgress.state !== "IN_PROGRESS") {
          barrier.open({ state: "error" });
        }
      },
    );
    if (progress.steps.length === 0) {
      // Wait for the first step to be added
      await onceEvent(
        pitcher.clients.setup.onSetupProgressUpdate,
        (data) => data.steps.length > 0,
      );
    }

    let shellId = pitcher.clients.setup.getProgress().steps[0].shellId;
    if (shellId === null) {
      // Wait for shell to be attached
      await onceEvent(pitcher.clients.setup.onSetupProgressUpdate, (data) => {
        if (data.steps[0].shellId != null) {
          shellId = data.steps[0].shellId;
          return true;
        }

        return false;
      });
    }
    shellId = shellId!;

    const output = await pitcher.clients.shell.open(shellId, {
      cols: 80,
      rows: 24,
    });

    outputEmitter.fire(output.buffer.join("\n"));

    const shellOutDisposable = pitcher.clients.shell.onShellOut(
      ({ out, shellId: evtShellId }) => {
        if (evtShellId !== shellId) {
          return;
        }

        outputEmitter.fire(out);
      },
    );

    const result = await Promise.race([
      onceEvent(opts.cancellationToken.onCancellationRequested).then(
        () => "cancelled" as const,
      ),
      barrier.wait(),
    ]);
    setupDisposable.dispose();
    shellOutDisposable.dispose();
    if (result === "cancelled") {
      throw new CancellationError();
    }

    if (result.status === "resolved" && result.value.state === "error") {
      throw new Error("Dev container installation failed");
    }
  }
}

async function exists(pitcher: IPitcherClient, path: string) {
  const stat = await pitcher.clients.fs.stat(path);

  if (stat.type === "error") {
    if (stat.errno === 2) {
      return false;
    }

    throw new Error(stat.error);
  }

  return true;
}

export async function runCommandAsUser(
  pitcher: IPitcherClient,
  command: string,
  onOutput?: Emitter<string>,
  cancellationToken?: CancellationToken,
  shellName?: string,
): Promise<{ output: string; exitCode?: number }> {
  if (cancellationToken) {
    throwIfCancellationRequested(cancellationToken);
  }

  const shell = await pitcher.clients.shell.create(
    pitcher.workspacePath,
    {
      cols: 128,
      rows: 40,
    },
    command,
    "TERMINAL",
    true,
  );

  if (shellName) {
    pitcher.clients.shell.rename(shell.shellId, shellName);
  }

  if (onOutput) {
    onOutput.fire(`> ${command}\r\n`);
  }

  if (shell.status === "FINISHED") {
    return {
      output: shell.buffer.join("\n").trim(),
      exitCode: shell.exitCode,
    };
  }

  let combinedOut = shell.buffer.join("\n");
  if (combinedOut && onOutput) {
    onOutput.fire(combinedOut);
  }
  const disposableStore = new DisposableStore();
  const barrier = new Barrier<{ exitCode?: number }>();

  disposableStore.add(
    pitcher.clients.shell.onShellOut(({ shellId, out }) => {
      if (shellId !== shell.shellId) {
        return;
      }

      if (onOutput) {
        onOutput.fire(out);
      }

      combinedOut += out;
    }),
  );

  disposableStore.add(
    pitcher.clients.shell.onShellExited(({ shellId, exitCode }) => {
      if (shellId !== shell.shellId) {
        return;
      }

      barrier.open({ exitCode });
    }),
  );

  disposableStore.add(
    pitcher.clients.shell.onShellTerminated(({ shellId }) => {
      if (shellId !== shell.shellId) {
        return;
      }

      barrier.open({ exitCode: undefined });
    }),
  );

  if (cancellationToken) {
    disposableStore.add(
      cancellationToken.onCancellationRequested(() => {
        pitcher.clients.shell.delete(shell.shellId);
      }),
    );
  }

  const cancellationListener = cancellationToken
    ? listenOnce(cancellationToken.onCancellationRequested).then(
        () => "cancelled" as const,
      )
    : new Promise<"cancelled">(() => {});

  const result = await Promise.race([barrier.wait(), cancellationListener]);
  disposableStore.dispose();

  if (result === "cancelled") {
    throw new CancellationError();
  }

  if (result.status === "disposed") {
    throw new Error("Shell was disposed");
  }

  disposableStore.dispose();

  return {
    output: combinedOut.trim(),
    exitCode: result.value.exitCode,
  };
}

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

async function writeFileUnwrapped(
  pitcher: IPitcherClient,
  path: string,
  data: string,
) {
  const res = await pitcher.clients.fs.writeFile(
    path,
    new TextEncoder().encode(data),
    true,
    true,
  );

  if (res.type === "error") {
    throw new Error(`Failed to write to ${path}: ${res.error}`);
  }
}

function waitForPort(pitcher: IPitcherClient, port: number): Promise<void> {
  const ports = pitcher.clients.port.getPorts();

  if (ports.find((p) => p.port === port)) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    const disposable = pitcher.clients.port.onPortsUpdated((ports) => {
      if (ports.find((p) => p.port === port)) {
        disposable.dispose();
        resolve();
      }
    });
  });
}

function throwIfCancellationRequested(token: CancellationToken) {
  if (token.isCancellationRequested) {
    throw new CancellationError();
  }
}
