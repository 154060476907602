import { CheckboxItem, ItemIndicator } from "@radix-ui/react-context-menu";
import React from "react";
import type { ComponentProps } from "react";

import { item } from "../../css/menu";
import { styled } from "../../theme";
import { Checkbox } from "../Checkbox";
import { Stack } from "../Stack";

const StyledCheckboxItem = styled(CheckboxItem, item);

interface Props
  extends Omit<ComponentProps<typeof CheckboxItem>, "as" | "onChange"> {
  closeOnChange?: boolean;
  onChange(checked: boolean): void;
}

export const ContextMenuCheckbox: React.FC<Props> = ({
  checked,
  children,
  closeOnChange = false,
  onChange,
  onSelect,
  ...checkboxItemProps
}) => {
  return (
    <StyledCheckboxItem
      checked={checked}
      onCheckedChange={onChange}
      onSelect={(event: Event) => {
        if (closeOnChange === false) {
          event.preventDefault();
        }

        if (onSelect) {
          onSelect(event);
        }
      }}
      {...checkboxItemProps}
    >
      <Stack
        css={{ justifyContent: "space-between", width: "100%" }}
        gap={2}
        horizontal
      >
        <span>{children}</span>
        <Checkbox checked={checked} onCheckedChange={onChange}>
          <ItemIndicator>
            <Checkbox.Check />
          </ItemIndicator>
        </Checkbox>
      </Stack>
    </StyledCheckboxItem>
  );
};
