import React from "react";

import type { CheckboxProps } from "./Checkbox";
import { Checkbox as CheckboxBase, Check } from "./Checkbox";

interface ICheckbox extends React.FC<CheckboxProps> {
  Check: typeof Check;
}

const Checkbox: ICheckbox = (props) => <CheckboxBase {...props} />;

Checkbox.Check = Check;

export { Checkbox };
