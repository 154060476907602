import type { clients } from "@codesandbox/pitcher-client";
import type { PitcherEvents } from "environment-interface/pitcher";
import type { TaskApi } from "environment-interface/pitcher/task";

export const createTaskApi = (
  taskClient: clients.ITaskClient,
  pitcherEvents: PitcherEvents,
): TaskApi => {
  taskClient.onTaskListUpdate((config) => {
    pitcherEvents.emit({
      type: "PITCHER:TASK:LIST_UPDATED",
      config,
    });
  });

  taskClient.onConfigParseError((error) => {
    pitcherEvents.emit({
      type: "PITCHER:TASK:CONFIG_PARSE_ERROR",
      error,
    });
  });

  taskClient.onTaskUpdate((task) => {
    pitcherEvents.emit({
      type: "PITCHER:TASK:UPDATED",
      task,
    });
  });

  taskClient.onUnassignedPortOpened((port) => {
    pitcherEvents.emit({
      type: "PITCHER:TASK:UNASSIGNED_PORT_OPENED",
      port,
    });
  });

  taskClient.onUnassignedPortClosed((port) => {
    pitcherEvents.emit({
      type: "PITCHER:TASK:UNASSIGNED_PORT_CLOSED",
      port,
    });
  });

  return {
    getTasks() {
      return taskClient.getTasks();
    },

    runTask(taskId) {
      taskClient
        .runTask(taskId)
        .then((task) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:UPDATED",
            task,
          });
        })
        .catch((err) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:RUN_ERROR",
            error: err.message,
          });
        });
    },

    runCommand(command, options) {
      taskClient
        .runCommand(command, options)
        .then((task) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:RUN_COMMAND_SUCCESS",
            task,
          });
        })
        .catch((err) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:RUN_COMMAND_ERROR",
            error: err.message,
          });
        });
    },

    stopTask(taskId) {
      taskClient
        .stopTask(taskId)
        .then((task) => {
          if (task) {
            pitcherEvents.emit({
              type: "PITCHER:TASK:UPDATED",
              task,
            });
          }
        })
        .catch((err) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:STOP_ERROR",
            error: err.message,
          });
        });
    },

    createTask(task, startTask) {
      taskClient
        .createTask(task, startTask)
        .then((task) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:CREATE_SUCCESS",
            task,
          });
        })
        .catch((err) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:CREATE_ERROR",
            error: err.message,
          });
        });
    },

    updateTask(taskId, taskFields) {
      taskClient
        .updateTask(taskId, taskFields)
        .then((task) => {
          if (task) {
            pitcherEvents.emit({
              type: "PITCHER:TASK:UPDATED",
              task,
            });
          }
        })
        .catch((err) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:UPDATE_ERROR",
            error: err.message,
          });
        });
    },

    saveToConfig(taskId) {
      taskClient
        .saveToConfig(taskId)
        .then((task) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:UPDATED",
            task,
          });
        })
        .catch((err) => {
          pitcherEvents.emit({
            type: "PITCHER:TASK:SAVE_TO_CONFIG_ERROR",
            error: err.message,
          });
        });
    },

    generateConfigFile() {},
  };
};
