import { Emitter, Disposable } from "@codesandbox/pitcher-common";
export class BrowserNotificationClient extends Disposable {
    constructor() {
        super(...arguments);
        this.notifications = {};
        this.onNotificationEmitter = new Emitter();
        this.onNotification = this.onNotificationEmitter.event;
        this.onDismissNotificationEmitter = new Emitter();
        this.onDismissNotification = this.onDismissNotificationEmitter.event;
    }
}
