import React from "react";

import { Box } from "../Box";

import { LoadingDots } from "./LoadingDots";

export const LoadingDotsOverlay: React.FC = () => (
  <Box
    aria-label="Loading"
    css={{
      position: "absolute",

      top: "0",
      right: "0",
      bottom: "0",
      left: "0",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      fontSize: "$caption1",
    }}
    role="img"
  >
    <LoadingDots />
  </Box>
);
