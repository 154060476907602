import { useDevtools } from "@codesandbox/states";
import type { useReducer } from "react";

import { useDebug } from "../debug/Feature";

/**
 * This hook enables spaces in development mode. The rules state that hooks
 * should not be called inside conditional statements, however, the spaces
 * are used thorought many features of the application. Thus, this intends to
 * override such rules in a single place rather than in every feature it's
 * used.
 */
export function useReducerDevtools(
  featureName: string,
  featureReducer: ReturnType<typeof useReducer>,
): void {
  const debugContext = useDebug();

  /**
   * TypeScript will tell you that this check is not needed, but it is.
   * It's needed because our feature contexts do not accept a default value
   * meaning that when this hook is used outside of a context provider
   * (in tests, for example) we get a runtime error when trying to destructure
   * undefined.
   *
   */
  if (Array.isArray(debugContext)) {
    const [debug] = debugContext;
    if (debug.state === "ON") {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDevtools(featureName, featureReducer);
    }
  }
}
