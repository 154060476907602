import pm from "picomatch";

import { parseString } from "./parse-ini";

export type IndentStyle = "space" | "tab";

export interface IEditorConfig {
  // indent_style in .editorconfig
  indentStyle?: IndentStyle;
  // tab_width or indent_size in .editorconfig
  indentSize?: number;
}

export class EditorConfigEntry {
  globRe: RegExp;
  indentStyle?: IndentStyle;
  indentSize?: number;

  constructor(glob: string, values: Record<string, string>) {
    if (!glob.includes("/")) {
      glob = "**/" + glob;
    }
    this.globRe = pm.makeRe(glob, {
      matchBase: true,
      dot: true,
      noext: true,
    });
    this.indentSize = parseInt(
      values["indent_size"] ?? values["tab_width"],
      10,
    );
    this.indentStyle =
      (values["indent_style"] as IndentStyle | null) ?? undefined;

    if (isNaN(this.indentSize)) {
      this.indentSize = undefined;
    }

    if (this.indentStyle && !["space", "tab"].includes(this.indentStyle)) {
      this.indentStyle = undefined;
    }
  }

  match(filename: string): boolean {
    return this.globRe.test(filename);
  }
}

export class EditorConfig {
  private entries: EditorConfigEntry[] = [];

  static parse(content: string): EditorConfig {
    const result = new EditorConfig();
    const entries = parseString(content);
    for (const entry of entries) {
      if (typeof entry[0] === "string" && typeof entry[1] === "object") {
        result.entries.push(new EditorConfigEntry(entry[0], entry[1]));
      }
    }
    return result;
  }

  addEntry(entry: EditorConfigEntry): void {
    this.entries.push(entry);
  }

  getConfig(filename: string): IEditorConfig {
    // CSB default values
    const result: IEditorConfig = {
      indentStyle: undefined,
      indentSize: undefined,
    };

    for (const entry of this.entries) {
      if (entry.match(filename)) {
        result.indentStyle = entry.indentStyle ?? result.indentStyle;
        result.indentSize = entry.indentSize ?? result.indentSize;
      }
    }

    // Set indent to 2 for tab as it's purely visual
    if (!result.indentSize && result.indentStyle === "tab") {
      result.indentSize = 2;
    }

    return result;
  }
}
