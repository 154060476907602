export const palette = {
  neutral: {
    0: "#000000",
    1: "#0D0D0D",
    2: "#1A1A1A",
    3: "#333333",
    4: "#525252",
    5: "#808080",
    6: "#ADADAD",
    7: "#CCCCCC",
    8: "#E6E6E6",
    9: "#F2F2F2",
    10: "#FFFFFF",
  },
  red: {
    1: "#290505",
    2: "#450C0C",
    3: "#732626",
    4: "#AA4141",
    5: "#DD5F5F",
    6: "#F68484",
    7: "#F5A8A8",
    8: "#FAD6D6",
    9: "#FCE8E8",
  },
  orange: {
    1: "#210F03",
    2: "#391A05",
    3: "#6D3209",
    4: "#B35614",
    5: "#E67728",
    6: "#F2995A",
    7: "#F6B98E",
    8: "#FAD9C2",
    9: "#FCEBDE",
  },
  yellow: {
    1: "#221D02",
    2: "#352E08",
    3: "#665605",
    4: "#A58B09",
    5: "#EBC300",
    6: "#F5D63D",
    7: "#F7E06E",
    8: "#F9E994",
    9: "#FCF3C5",
  },
  lime: {
    1: "#1C2301",
    2: "#2C3701",
    3: "#526605",
    4: "#809C11",
    5: "#BDE519",
    6: "#D4F358",
    7: "#DFF976",
    8: "#E7FD91",
    9: "#F0FFB2",
  },
  green: {
    1: "#082004",
    2: "#0E3B07",
    3: "#19690C",
    4: "#2B931B",
    5: "#43BB30",
    6: "#6DD55D",
    7: "#A3EC98",
    8: "#BBF6B1",
    9: "#D4FDCE",
  },
  blue: {
    1: "#03143F",
    2: "#062574",
    3: "#0A3EC2",
    4: "#3462D5",
    5: "#648EF7",
    6: "#83A5FC",
    7: "#A8BFFA",
    8: "#CFDCFC",
    9: "#E2EAFD",
  },
  purple: {
    1: "#150E39",
    2: "#261A66",
    3: "#3A298E",
    4: "#5D4ABF",
    5: "#8270DB",
    6: "#9D8BF9",
    7: "#BDB1F6",
    8: "#D4CCFF",
    9: "#E5E0FF",
  },
};

/**
 * 8 digit hexacecimal opacity values.
 * Just add the ones we need.
 * @see https://davidwalsh.name/hex-opacity
 */
const hexOpacityMap = {
  0: "00",
  0.04: "0A",
  0.08: "14",
  0.1: "1A",
  0.2: "33",
  0.3: "4D",
  0.5: "80",
};

export const opaqueHex = (
  hex: string,
  opacity: keyof typeof hexOpacityMap,
): string => {
  return `${hex}${hexOpacityMap[opacity]}`;
};
