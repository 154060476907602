import { Disposable, DisposableStore } from "@codesandbox/pitcher-common";
import { Emitter, type IDisposable } from "monaco-editor";
import * as vscodeApi from "vscode";
import { registerExtension } from "vscode/extensions";

import { csbApi } from "utils/csbApi";

import { runWithLatestMonacoConfig } from "../tools/with-monaco-config";

export async function initializeExtension() {
  const { getApi } = registerExtension(
    {
      name: "github-authentication",
      displayName: "GitHub Authentication (CodeSandbox)",
      publisher: "vscode",
      version: "1.0.0",
      contributes: {
        authentication: [
          {
            id: "github",
            label: "GitHub",
          },
        ],
      },
      engines: {
        vscode: "*",
      },
      activationEvents: ["onAuthenticationRequest:github"],
    },
    1,
    { system: true },
  );

  const api = await getApi();

  runWithLatestMonacoConfig(() => activate(api));
}

function activate(vscode: typeof vscodeApi): IDisposable {
  const disposableStore = new DisposableStore();

  disposableStore.add(
    vscode.authentication.registerAuthenticationProvider(
      "github",
      "GitHub",
      disposableStore.add(new GitHubAuthenticationProvider(vscode)),
      { supportsMultipleAccounts: false },
    ),
  );

  return disposableStore;
}

class GitHubAuthenticationProvider
  extends Disposable
  implements vscodeApi.AuthenticationProvider
{
  private readonly onDidChangeSessionEmitter = this.addDisposable(
    new Emitter<vscodeApi.AuthenticationProviderAuthenticationSessionsChangeEvent>(),
  );
  public readonly onDidChangeSessions = this.onDidChangeSessionEmitter.event;

  private logger: vscodeApi.OutputChannel;

  constructor(readonly vscode: typeof vscodeApi) {
    super();

    this.logger = this.addDisposable(
      vscodeApi.window.createOutputChannel("GitHub Authentication"),
    );
  }

  private async getUserGitHubToken(): Promise<string | null> {
    const { data } = await csbApi.get<{
      data?: { integrations: { github?: { token: string; email: string } } };
    }>({
      path: "/v1/users/current/integrations",
    });

    return data?.integrations.github?.token ?? null;
  }

  private async getUserGitHubInfo(): Promise<{
    scopes: string[];
    email: string;
    id: string;
    login: string;
  } | null> {
    const { data } = await csbApi.get<{
      data?: {
        integrations: {
          github?: { email: string };
        };
        githubProfile: {
          error: null;
          data?: {
            id: number;
            name: string;
            avatarUrl: string;
            login: string;
            scopes: string[];
          };
        };
      };
    }>({
      path: "/v1/users/current",
    });

    return data?.githubProfile.data &&
      data.integrations &&
      data.integrations.github
      ? {
          scopes: data.githubProfile.data.scopes,
          email: data.integrations.github.email,
          id: data.githubProfile.data.id.toString(),
          login: data.githubProfile.data.login,
        }
      : null;
  }

  private async getCurrentSession(): Promise<vscodeApi.AuthenticationSession | null> {
    const [token, githubInfo] = await Promise.all([
      this.getUserGitHubToken(),
      this.getUserGitHubInfo(),
    ]);

    if (!token || !githubInfo) {
      return null;
    }

    return {
      accessToken: token,
      account: {
        id: githubInfo.id,
        label: githubInfo.login,
      },
      id: githubInfo.id,
      scopes: githubInfo.scopes,
    };
  }

  private cachedSessions: Promise<vscodeApi.AuthenticationSession[]> | null =
    null;
  async getSessions(
    scopes?: string[],
  ): Promise<vscodeApi.AuthenticationSession[]> {
    if (!this.cachedSessions) {
      this.cachedSessions = this.getCurrentSession().then((session) => {
        if (!session) {
          return [];
        }

        this.logger.appendLine(
          "Returning current session with scopes: " + session.scopes,
        );

        return [session];
      });
    }

    return this.cachedSessions.then((sessions) =>
      sessions.filter((session) => {
        if (!scopes) {
          return true;
        }

        return scopes.every((scope) => session.scopes.includes(scope));
      }),
    );
  }

  async createSession(
    scopes: readonly string[],
  ): Promise<vscodeApi.AuthenticationSession> {
    this.logger.appendLine('Creating session with scopes "' + scopes + '"');
    const previousSessions = await this.getSessions();
    await csbApi.session.signIn({
      type: "github",
      rawScopes: [...scopes],
    });
    const session = await this.getCurrentSession();

    if (!session) {
      throw new Error("Sign in to GitHub failed");
    }

    this.cachedSessions = null;
    if (!previousSessions || previousSessions.length === 0) {
      this.onDidChangeSessionEmitter.fire({
        added: [session],
        removed: [],
        changed: [],
      });
    } else {
      this.onDidChangeSessionEmitter.fire({
        added: [session],
        removed: [previousSessions[0]],
        changed: [],
      });
    }

    return session;
  }

  async removeSession(): Promise<void> {
    const existingSession = await this.getSessions();
    if (existingSession?.length) {
      await csbApi.delete({
        path: "/v1/users/current_user/integrations/github",
      });
      this.cachedSessions = null;
      this.onDidChangeSessionEmitter.fire({
        removed: [existingSession[0]],
        added: [],
        changed: [],
      });
    }
  }
}
