import { Disposable, Emitter } from "@codesandbox/pitcher-common";
import { LanguageServer } from "./language-server";
import { fetchPrivatePackagesFromPackageJSON } from "./package-fetching";
const PKG_BLOCKLIST = new Set([
    "parcel",
    "parcel-bundler",
    "react-scripts",
    "typescript", // we will add it later as a devDependency
]);
export const LANGUAGE_SERVER_MAPPINGS = new Map([
    ["typescriptreact", "typescript"],
    ["typescript", "typescript"],
    ["javascriptreact", "typescript"],
    ["css", "css"],
    ["html", "html"],
    ["json", "json"],
    /*
    
    ["jsonc", "json"],
    
    ["scss", "css"],
    ["sass", "css"],
    ["less", "css"],
    ["astro", "astro"],
    ["vue", "vue"],
    ["rust", "rust"],
    ["python", "python"],
    ["php", "php"],
    ["ruby", "ruby"],
    */
]);
export class BrowserLSP extends Disposable {
    constructor(fsClient, fileClient, npmRegistries) {
        super();
        this.fsClient = fsClient;
        this.fileClient = fileClient;
        this.npmRegistries = npmRegistries;
        // eslint-disable-next-line
        this.onLspNotificationEmitter = this.addDisposable(new Emitter());
        this.onNotification = this.onLspNotificationEmitter.event;
        this.onLspRequestEmitter = this.addDisposable(new Emitter());
        this.onRequest = this.onLspRequestEmitter.event;
        this.langServers = new Map();
    }
    updateRegistries(npmRegistries) {
        this.npmRegistries = npmRegistries;
    }
    ensureEmulatorPromise() {
        if (!this.emulatorPromise) {
            this.emulatorPromise = this.startEmulator();
        }
        return this.emulatorPromise;
    }
    _readFileSync(path) {
        const pathWithoutWorkspace = this.fsClient.resolveRelativeWorkspacePath(path);
        if (pathWithoutWorkspace === "/tsconfig.json") {
            try {
                // Try to read tsconfig.json and return that, but if it doesn't exist, return the default one
                this.fsClient.readFileSync(path);
            }
            catch {
                const encoder = new TextEncoder();
                return encoder.encode(JSON.stringify({
                    include: ["./src/**/*"],
                    compilerOptions: {
                        strict: true,
                        esModuleInterop: true,
                        lib: ["dom", "es2015"],
                        jsx: "react-jsx",
                    },
                }));
            }
        }
        return this.fsClient.readFileSync(path);
    }
    /**
     * Takes the package.json, downloads its private packages configured for the user
     * and then returns the new package.json (without those packages) + the node modules
     * dependencies.
     */
    async rewritePackageJson(pkgJson) {
        const pkg = JSON.parse(new TextDecoder().decode(pkgJson));
        // Parse dependencies
        if (!pkg.dependencies) {
            pkg.dependencies = {};
        }
        if (!pkg.devDependencies) {
            pkg.devDependencies = {};
        }
        const result = await fetchPrivatePackagesFromPackageJSON(pkg, this.npmRegistries);
        result.packages.forEach((dependency) => {
            // Rewrite to `file:/node_modules/${dependency}` so TS LSP still auto-imports
            if (pkg.dependencies[dependency]) {
                pkg.dependencies[dependency] = `file:./node_modules/${dependency}`;
            }
            if (pkg.devDependencies[dependency]) {
                pkg.devDependencies[dependency] = `file:./node_modules/${dependency}`;
            }
        });
        for (const key of Object.keys(pkg.dependencies)) {
            if (PKG_BLOCKLIST.has(key)) {
                delete pkg.dependencies[key];
            }
        }
        // We do not load any dev dependencies except types. Dev dependencies are normally only relevant for DevBoxes
        // so we filter them out to ensure better performance.
        pkg.devDependencies = {
            typescript: "4.9.5",
            "typescript-language-server": "latest",
            "vscode-langservers-extracted": "4.8.0",
            "@types/web": "latest",
            ...Object.entries((pkg.devDependencies ?? {})).reduce((aggr, [key, value]) => {
                if (key.startsWith("@types")) {
                    aggr[key] = value;
                }
                return aggr;
            }, {}),
        };
        return {
            ...result.files,
            "/package.json": new TextEncoder().encode(JSON.stringify(pkg, null, 2)),
        };
    }
    async startEmulator() {
        const iframe = document.createElement("iframe");
        iframe.style.zIndex = "99999999";
        iframe.style.position = "fixed";
        iframe.style.left = "100px";
        iframe.style.top = "100px";
        iframe.style.width = "600px";
        iframe.style.height = "500px";
        iframe.style.display = "none";
        document.body.appendChild(iframe);
        const Nodebox = (await import("@codesandbox/nodebox")).Nodebox;
        const emulator = new Nodebox({
            iframe,
        });
        await emulator.connect();
        this.fsClient.watch("/", { recursive: true }, (evt) => {
            evt.paths.forEach((path) => {
                const pathWithoutWorkspace = this.fsClient.resolveRelativeWorkspacePath(path);
                if (evt.type === "remove") {
                    emulator.fs.rm(pathWithoutWorkspace, {
                        recursive: true,
                        force: true,
                    });
                }
                else {
                    if (pathWithoutWorkspace === "/package.json") {
                        this.rewritePackageJson(this._readFileSync("/package.json")).then((files) => {
                            Object.entries(files).forEach(([path, content]) => {
                                emulator.fs.writeFile(path, content, {
                                    recursive: true,
                                });
                            });
                        });
                    }
                    else {
                        try {
                            const content = this._readFileSync(path);
                            emulator.fs.writeFile(pathWithoutWorkspace, content, {
                                recursive: true,
                            });
                        }
                        catch (err) {
                            /* eslint-disable no-console */
                            console.error(err);
                        }
                    }
                }
            });
        });
        let files = {
            // The language server will create a watcher on this folder and crash when it tries to remove the
            // watcher. We create a dummy file in this directory to ensure it does not crash.
            "bower_components/test": new Uint8Array(),
        };
        for (const node of this.fsClient.memoryFS.tree.nodes.values()) {
            if (node.isFile()) {
                const nodePath = node.path;
                files[nodePath] = this._readFileSync(nodePath);
            }
        }
        const pkgPath = "/package.json";
        if (!files[pkgPath]) {
            files[pkgPath] = new TextEncoder().encode("{}");
        }
        try {
            const rewrittenFiles = await this.rewritePackageJson(files[pkgPath]);
            files = {
                ...files,
                ...rewrittenFiles,
            };
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error("Failed to fetch private packages", e);
        }
        await emulator.fs.init(files);
        const tsLspServer = this.addDisposable(new LanguageServer(emulator, this.fsClient, this.fileClient, {
            lib: "./node_modules/typescript-language-server/lib/cli.mjs",
            languageId: "typescriptreact",
            serverId: "typescript",
        }));
        this.addDisposable(tsLspServer.onNotification((notification) => {
            this.onLspNotificationEmitter.fire(notification);
        }));
        this.addDisposable(tsLspServer.onRequest((req) => {
            this.onLspRequestEmitter.fire({
                languageId: "typescriptreact",
                serverId: "typescript",
                message: {
                    id: req.id,
                    method: req.method,
                    params: req.params,
                },
            });
        }));
        const cssLspServer = this.addDisposable(new LanguageServer(emulator, this.fsClient, this.fileClient, {
            lib: "./node_modules/vscode-langservers-extracted/lib/css-language-server/node/cssServerMain.js",
            languageId: "css",
            serverId: "css",
        }));
        this.addDisposable(cssLspServer.onNotification((notification) => {
            this.onLspNotificationEmitter.fire(notification);
        }));
        this.addDisposable(cssLspServer.onRequest((req) => {
            this.onLspRequestEmitter.fire({
                languageId: "css",
                serverId: "css",
                message: {
                    id: req.id,
                    method: req.method,
                    params: req.params,
                },
            });
        }));
        const htmlLspServer = this.addDisposable(new LanguageServer(emulator, this.fsClient, this.fileClient, {
            lib: "./node_modules/vscode-langservers-extracted/lib/html-language-server/node/htmlServerMain.js",
            languageId: "html",
            serverId: "html",
        }));
        this.addDisposable(htmlLspServer.onNotification((notification) => {
            this.onLspNotificationEmitter.fire(notification);
        }));
        this.addDisposable(htmlLspServer.onRequest((req) => {
            this.onLspRequestEmitter.fire({
                languageId: "html",
                serverId: "html",
                message: {
                    id: req.id,
                    method: req.method,
                    params: req.params,
                },
            });
        }));
        const jsonLspServer = this.addDisposable(new LanguageServer(emulator, this.fsClient, this.fileClient, {
            lib: "./node_modules/vscode-langservers-extracted/lib/json-language-server/node/jsonServerMain.js",
            languageId: "json",
            serverId: "json",
        }));
        this.addDisposable(jsonLspServer.onNotification((notification) => {
            this.onLspNotificationEmitter.fire(notification);
        }));
        this.addDisposable(jsonLspServer.onRequest((req) => {
            this.onLspRequestEmitter.fire({
                languageId: "json",
                serverId: "json",
                message: {
                    id: req.id,
                    method: req.method,
                    params: req.params,
                },
            });
        }));
        this.langServers.set("typescript", tsLspServer);
        this.langServers.set("css", cssLspServer);
        this.langServers.set("html", htmlLspServer);
        this.langServers.set("json", jsonLspServer);
        this.onWillDispose(() => {
            document.body.removeChild(iframe);
        });
        return emulator;
    }
    /**
     * @id {string}: Can be a language or server identifier
     */
    getServerById(id) {
        const serverId = LANGUAGE_SERVER_MAPPINGS.get(id) ?? id;
        const foundServer = this.langServers.get(serverId);
        return foundServer ?? null;
    }
    async sendLSPNotification(params) {
        await this.ensureEmulatorPromise();
        const serverId = params.serverId ?? LANGUAGE_SERVER_MAPPINGS.get(params.languageId);
        const server = this.getServerById(serverId);
        if (!server)
            return;
        return server.sendNotification({
            method: params.message.method,
            params: params.message.params,
        });
    }
    async sendLSPRequest(params) {
        await this.ensureEmulatorPromise();
        const serverId = params.serverId ?? LANGUAGE_SERVER_MAPPINGS.get(params.languageId);
        const server = this.getServerById(serverId);
        if (!server)
            return;
        return server
            .sendRequest(params.message)
            .then((v) => {
            return {
                type: "ok",
                result: v,
            };
        })
            .catch((err) => {
            return {
                type: "error",
                error: err,
            };
        });
    }
    async sendLSPServerResponse(params) {
        await this.ensureEmulatorPromise();
        const serverId = params.serverId ?? LANGUAGE_SERVER_MAPPINGS.get(params.languageId);
        const server = this.getServerById(serverId);
        if (!server)
            return;
        return server.sendLSPServerResponse(params.message);
    }
    async readFile(filepath) {
        const emulator = await this.ensureEmulatorPromise();
        return emulator.fs.readFile(filepath);
    }
    async statFile(filepath) {
        const emulator = await this.ensureEmulatorPromise();
        return emulator.fs.stat(filepath);
    }
}
