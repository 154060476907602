import { ApiResponseError } from "@codesandbox/api";
import { createEmitter } from "@codesandbox/environment-interface";
import type { Api, UserDTO } from "environment-interface/api";
import type { CsbApi, GraphQLRequestBody } from "environment-interface/csbApi";
import type { BranchDTO } from "features/types/branch";
import type { BranchEditor } from "features/types/editor";
import type { ForkedSandbox } from "features/types/sandbox";
import { fromBranchDTO } from "features/utils/dto";
import { fetchSandbox, inferProjectForBranch } from "isomorphic/queries";
import {
  importReadOnlyBranch,
  getBranchEditorDataByBranch,
  importBranch,
  createBranch,
  createContributionBranch,
  importReadOnlyProject,
  convertProjectQueryToBranchEditorData,
  getPossibleWorkspacesToRequestAccess,
} from "queries/project";
import { fetchCurrentUser } from "queries/user";

import { csbApi } from "utils/csbApi";
import { getSandboxGitIdentifier } from "utils/sandbox";

interface CreateApiOptions {
  csbApi: CsbApi;
}

export const createApi = ({ csbApi: legacyCsbApi }: CreateApiOptions): Api => {
  const userCache: Map<string, Promise<UserDTO>> = new Map();

  return {
    events: createEmitter(),

    async query<Data extends unknown>(
      GraphQLQuery: GraphQLRequestBody | string,
    ) {
      return legacyCsbApi.graphQL
        .query<Data>({
          body: GraphQLQuery,
          accessToken: legacyCsbApi.devJwt.get(),
        })
        .then((result) => {
          if (result.type === "ERROR") {
            if (result.errors[0]) {
              throw result.errors[0];
            } else {
              throw new Error("Query failed");
            }
          }

          return result.data;
        })
        .catch((e) => {
          throw new Error(e.message);
        });
    },

    verifyCLICode(code) {
      legacyCsbApi.rest
        .get<{ data: { token: string } }>({
          path: `/v1/auth/verify/${code}`,
        })
        .then(({ data: { token } }) => {
          this.events.emit({
            type: "API:CLI_CODE_VALID",
            token,
          });
        })
        .catch(() => {
          this.events.emit({
            type: "API:CLI_CODE_INVALID",
          });
        });
    },

    fetchCurrentUser() {
      fetchCurrentUser(legacyCsbApi, {})
        .then((user) => {
          this.events.emit({
            type: "API:FETCH_CURRENT_USER_SUCCESS",
            user,
          });
        })
        .catch((error: Error) => {
          this.events.emit({
            type: "API:FETCH_CURRENT_USER_ERROR",
            error: error.message,
          });
        });
    },

    fetchUser(username: string) {
      const userPromise =
        userCache.get(username) ||
        legacyCsbApi.rest
          .get<{ data: UserDTO }>({
            path: `/v1/users/${username}`,
          })
          .then((v) => {
            return v.data;
          });

      userCache.set(username, userPromise);

      userPromise
        .then((user) => {
          this.events.emit({
            type: "API:FETCH_USER_SUCCESS",
            user,
          });
        })
        .catch((error: Error) => {
          this.events.emit({
            type: "API:FETCH_USER_ERROR",
            username,
            error: error.message,
          });
        })
        .finally(() => {
          userCache.delete(username);
        });
    },
    createBranch({ owner, repo, sourceBranch, newBranch, workspaceId }) {
      legacyCsbApi.rest
        .post<BranchDTO>({
          path: `/beta/sandboxes/github/${owner}/${repo}`,
          data: {
            source_branch: sourceBranch,
            new_branch: newBranch,
            workspace_id: workspaceId,
          },
        })
        .then((branchDTO) => {
          this.events.emit({
            type: "API:CREATE_BRANCH_SUCCESS",
            branch: fromBranchDTO(branchDTO),
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:CREATE_BRANCH_ERROR",
            error: error.message,
            workspaceId,
          });
        });
    },
    removeBranchFromCodesandbox({ owner, repo, branch, workspaceId }) {
      legacyCsbApi.rest
        .delete({
          path: `/beta/sandboxes/github/${owner}/${repo}/${branch}${
            workspaceId ? "?workspace_id=" + workspaceId : ""
          }`,
        })
        .then(() => {
          this.events.emit({ type: "API:REMOVE_REMOTE_BRANCH_SUCCESS" });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:REMOVE_REMOTE_BRANCH_ERROR",
            error: error.message,
          });
        });
    },
    createSeamlessContributionBranch(owner, repo, sourceBranch) {
      createContributionBranch({ owner, repo, sourceBranch }, {})
        .then((branch) => {
          if (!branch.contribution) {
            this.events.emit({
              type: "API:CREATE_SEAMLESS_INSTANCE_ERROR",
              error:
                "You signed into a workspace and need to refresh your browser to continue",
            });
            return;
          }
          // Because of our legacy layer we map from the project to the old branchDTO, though later we will
          // just consume this as the new project
          this.events.emit({
            type: "API:CREATE_SEAMLESS_INSTANCE_SUCCESS",
            data: {
              type: "branch",
              data: branch,
            },
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:CREATE_SEAMLESS_INSTANCE_ERROR",
            error: error.message,
          });
        });
    },
    createSeamlessSandboxFork(id, isCloud, workspaceId) {
      const isSyncedSandbox = id.startsWith("github/");
      const path = isSyncedSandbox
        ? `/v1/sandboxes/fork/${id}`
        : `/v1/sandboxes/${id}/fork`;

      legacyCsbApi.rest
        .post<{
          data: ForkedSandbox;
        }>({
          path,
          data: {
            team_id: workspaceId,
            // Some sandboxes have v2 false but do open in the v2 editor. Whenever we fork sandboxes
            // from this editor v2 should be set to true, to make sure the fork is actually v2
            ...(isSyncedSandbox ? {} : { v2: isCloud }),
          },
        })
        .then(({ data: forkedSandbox }) => {
          this.events.emit({
            type: "API:CREATE_SEAMLESS_INSTANCE_SUCCESS",
            data: {
              type: "sandbox",
              data: forkedSandbox,
            },
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:CREATE_SEAMLESS_INSTANCE_ERROR",
            error: error.message,
          });
        });
    },
    createSeamlessBranch({
      owner,
      repo,
      sourceBranch,
      workspaceId,
      targetBranch,
    }) {
      createBranch(
        { owner, repo, sourceBranch, workspaceId, branch: targetBranch },
        {},
      )
        .then((branch) => {
          // Because of our legacy layer we map from the project to the old branchDTO, though later we will
          // just consume this as the new project
          this.events.emit({
            type: "API:CREATE_SEAMLESS_INSTANCE_SUCCESS",
            data: {
              type: "branch",
              data: branch,
            },
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:CREATE_SEAMLESS_INSTANCE_ERROR",
            error: error.message,
          });
        });
    },
    fetchRemoteBranches(owner, repo) {
      legacyCsbApi.rest
        .get<{ branches: Array<{ name: string }>; default: { name: string } }>({
          path: `/beta/sandboxes/github/${owner}/${repo}/all_branches`,
        })
        .then((response) => {
          this.events.emit({
            type: "API:FETCH_REMOTE_BRANCHES_SUCCESS",
            branches: response.branches,
            defaultBranchName: response.default.name,
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:FETCH_REMOTE_BRANCHES_ERROR",
            error: error.message,
          });
        });
    },

    async fetchSandbox(id) {
      try {
        const editorData = await fetchSandbox(csbApi, id, {});
        // This handles the scenario where the alias in the url is old, we just replace the alias
        // here as it is still a valid reference. This solves mistmatch between sandbox data and url
        if (
          editorData.id !== id &&
          editorData.alias !== id &&
          getSandboxGitIdentifier(editorData) !== id
        ) {
          editorData.alias = id;
        }

        this.events.emit({
          type: "API:FETCH_EDITOR_DATA_SUCCESS",
          editorData,
        });
      } catch (error) {
        this.events.emit({
          type: "API:FETCH_EDITOR_DATA_ERROR",
          error: String(error),
        });
      }
    },

    // This is used to open new branches from the Project Editor, as the server loads the initial project. As we can
    // open remote branches from the Project editor we need to ensure that they will be created if you try to open them. Unlike
    // the server we do not infer what workspace to use, but use the current workspace
    async fetchBranch({
      owner,
      repo,
      branch,
      workspaceId,
      create,
      forcePublic,
    }: {
      owner: string;
      repo: string;
      branch?: string;
      workspaceId: string | null;
      create: boolean;
      forcePublic: boolean;
    }) {
      let errorCode: string | undefined = undefined;

      // This function ensures that opening a read only branch also has an assigned project to it
      const ensureReadOnlyBranch = async (branch: string) => {
        // When we do not have the read only project, we need to create it
        const readOnlyProject = await importReadOnlyProject({ owner, repo });

        if (!readOnlyProject) {
          return null;
        }

        // Then we can optimise by checking if the user tried to open the default branch, where we can
        // just return the already created project
        if (readOnlyProject.branch === branch) {
          return readOnlyProject;
        }

        // Or we have to go and create the specific branch
        return importReadOnlyBranch({ owner, repo, branch });
      };

      let editorData: BranchEditor | null = null;

      try {
        // When we have the create flag, we try to create one, we always try to do that first. This requires you to be
        // signed in and with a project inferred with a workspace. If we are not able to infer a project with a
        // workspace, we'll consider it a lack of workspace access
        if (create) {
          const inferredProject = await inferProjectForBranch(csbApi, {
            owner,
            repo,
            // It can be an empty string
            branch: branch || null,
            workspaceId,
          });

          if (inferredProject && inferredProject.team) {
            try {
              const branchData = await createBranch({
                owner,
                repo,
                sourceBranch: inferredProject.repository.defaultBranch,
                workspaceId: inferredProject.team.id,
                // It can be an empty string
                branch: branch || undefined,
              });

              branch = branchData.name;
            } catch (error) {
              // Branch could be created already, so we don't want to throw an error
            }
          } else {
            this.events.emit({
              type: "API:FETCH_EDITOR_DATA_ERROR",
              error: "Could not access workspace",
              couldNotAccessWorkspace: true,
            });
            return;
          }
        }

        if (!branch) {
          // When no branch is present we first try to infer an existing project. You might hit this url with
          // or without a workspaceId as well, so the inference takes that into account
          const inferredProject = await inferProjectForBranch(csbApi, {
            owner,
            repo,
            branch: null,
            workspaceId,
          });

          if (inferredProject) {
            // No branch was passed, so we use the default branch that was inferred
            // There is always a branch on a project when querying from gql. Otherwise, this should thrown un unexpected error
            branch = inferredProject.branch!.name;
          } else {
            // If there is no inferred project we try to create a read only project, even though there is a workspaceId. The reason
            // is that we never want implicit imports of projects to your dashboard. This scenario would really never happen as users would
            // have to manually change the url
            const importedProject = await importReadOnlyProject({
              owner,
              repo,
            });

            if (!importedProject) {
              this.events.emit({
                type: "API:FETCH_EDITOR_DATA_ERROR",
                error: "Not found",
              });
              return;
            }

            // We use the read only import to set the branch name. This is not the most performant in terms of data fetching,
            // but it co locates the complexity of possible next scenarios
            branch = importedProject.branch;
          }
        }

        // If a user has a branch in both a workspace and it is avilable publicly, they will be able to change that and force
        // inferring the public version even when they refresh the Project Editor. In this scenario we know the branch exists
        // and can just resolve. This should only happen when there is no workspaceId, cause the user might navigate further to
        // a workspace they actually want to use
        if (!workspaceId && forcePublic) {
          const publicBranch = await importReadOnlyBranch({
            owner,
            repo,
            branch,
          });

          this.events.emit({
            type: "API:FETCH_EDITOR_DATA_SUCCESS",
            editorData: publicBranch,
          });
          return;
        }

        // With a workspace id we want to be explicit about not being able to access it
        if (workspaceId) {
          editorData = await getBranchEditorDataByBranch({
            owner,
            repo,
            branch,
            workspaceId,
          });

          // If we can not find the branch, it might be on GitHub
          if (!editorData) {
            try {
              editorData = await importBranch({
                owner,
                repo,
                branch,
                workspaceId,
              });
            } catch {
              // Do not have access
            }
          }

          if (editorData) {
            this.events.emit({
              type: "API:FETCH_EDITOR_DATA_SUCCESS",
              editorData,
            });

            return;
          }

          // In he fallback error state when the workspace is in the URL
          // we can show the user the possibility to request access to the workspace
          // given they have write access to the repo on GH
          const possibleWorkspacesToRequestAccess =
            await getPossibleWorkspacesToRequestAccess({ owner, repo });

          this.events.emit({
            type: "API:FETCH_EDITOR_DATA_ERROR",
            error: "Could not access workspace",
            couldNotAccessWorkspace: true,
            // Pass only the workspace matching from the URL, as the query returns all possible workspaces
            possibleWorkspacesToRequestAccess:
              possibleWorkspacesToRequestAccess.filter(
                (w) => w.id === workspaceId,
              ),
          });

          return;
        }

        // At this point we need to infer what workspace to use.

        // We figure out what workspaces you have access to
        const inferredProject = await inferProjectForBranch(csbApi, {
          owner,
          repo,
          branch,
          workspaceId: null,
        });

        // When we are not able to infer the branch, but the repo is on a workspace and you have indicated that you want to create the branch
        if (
          inferredProject &&
          inferredProject.team &&
          !inferredProject.branch &&
          create
        ) {
          const branchData = await createBranch({
            owner,
            repo,
            sourceBranch: inferredProject.repository.defaultBranch,
            workspaceId: inferredProject.team.id,
            branch,
          });

          editorData = convertProjectQueryToBranchEditorData(owner, repo, {
            ...inferredProject,
            branch: branchData,
          });
        }

        // When request does not ask for specific workspace, but you have the repo with this branch in a workspace.
        // The client will always update the workspaceId in the query related to the workspace loaded (This saves as redirects and unnecssary query requests)
        else if (
          inferredProject &&
          inferredProject.team &&
          inferredProject.branch
        ) {
          editorData = convertProjectQueryToBranchEditorData(
            owner,
            repo,
            inferredProject,
          );
        }

        // When request does not ask for a specific workspace, but the repo is found in a different workspace without the branch,
        // we want to try to import it from GitHub. This is a typical flow for new companies trying CodeSandbox. They still work locally
        // but review using CodeSandbox. We do not want to create the branch without it already existing on GitHub. If this fails it means
        // the user is trying to access non existing content
        else if (
          inferredProject &&
          inferredProject.team &&
          !inferredProject.branch
        ) {
          try {
            editorData = await importBranch({
              owner,
              repo,
              branch,
              workspaceId: inferredProject.team.id,
            });
          } catch (err) {
            // Do not have access

            if (err instanceof ApiResponseError) {
              errorCode = err.parsedErrorMessage;
            }
          }
        }

        // When request does not ask for specific workspace and the repo has never been imported to a PUBLIC workspace, or the specific
        // branch does not exist in an existing PUBLIC workspace, we try to create it
        else if (!inferredProject || !inferredProject.team) {
          try {
            // You need write access to the repository for this to work
            const possibleWorkspacesToRequestAccess =
              await getPossibleWorkspacesToRequestAccess({ owner, repo });

            // It does not matter the state of the read only version of the repo/branch, we always want to move you to 404 and ask
            // to join a team, if possible
            if (
              possibleWorkspacesToRequestAccess &&
              possibleWorkspacesToRequestAccess.length > 0
            ) {
              this.events.emit({
                type: "API:FETCH_EDITOR_DATA_ERROR",
                error: "Could not access workspace",
                couldNotAccessWorkspace: true,
                possibleWorkspacesToRequestAccess,
              });
            }
          } catch {
            // Possible error, user does not have write access to the repo, so the query fails
          }

          try {
            // In the scenario the repo has not been imported in any workspace OR you don't have write access to the repository
            editorData = await ensureReadOnlyBranch(branch);
          } catch {
            // Do not exist or does not have access
          }
        }

        if (editorData) {
          this.events.emit({
            type: "API:FETCH_EDITOR_DATA_SUCCESS",
            editorData,
          });
        } else {
          this.events.emit({
            type: "API:FETCH_EDITOR_DATA_ERROR",
            error: errorCode || "Could not access workspace",
            couldNotAccessWorkspace: errorCode ? undefined : true,
          });
        }
      } catch (error) {
        this.events.emit({
          type: "API:FETCH_EDITOR_DATA_ERROR",
          error: String(error),
        });
      }
    },
    forkSandbox(id, isCloud, workspaceId, collectionId, privacy) {
      const path = id.startsWith("github/")
        ? `/v1/sandboxes/fork/${id}`
        : `/v1/sandboxes/${id}/fork`;

      legacyCsbApi.rest
        .post<{
          data: {
            id: string;
            updatedAt: string;
            alias: string;
            team?: {
              id: string;
            };
            privacy?: 0 | 1 | 2;
          };
        }>({
          path,
          data: {
            team_id: workspaceId,
            v2: isCloud,
            collection_id: collectionId,
            privacy,
          },
        })
        .then(({ data: forkedSandbox }) => {
          this.events.emit({
            type: "API:FORK_SANDBOX_SUCCESS",
            fork: {
              id: forkedSandbox.id,
              alias: forkedSandbox.alias || forkedSandbox.id,
              workspaceId: forkedSandbox.team?.id ?? null,
              updatedAt: forkedSandbox.updatedAt,
            },
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:FORK_SANDBOX_ERROR",
            message: error.message,
            workspaceId,
          });
        });

      return;
    },
    forkBranch(owner, repo, workspaceId) {
      legacyCsbApi.rest
        .post<BranchDTO>({
          path: `/beta/fork/github/${owner}/${repo}`,
          data: {
            create_branch: true,
            team_id: workspaceId,
          },
        })
        .then((branchDTO) => {
          this.events.emit({
            type: "API:FORK_BRANCH_SUCCESS",
            branch: {
              ...fromBranchDTO(branchDTO),
              owner: branchDTO.owner,
              repo: branchDTO.repo,
            },
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:FORK_BRANCH_ERROR",
            message: error.message,
            workspaceId,
          });
        });
    },
    getSecrets(opts, workspaceId) {
      if (opts.type === "sandbox") {
        legacyCsbApi.rest
          .get<{ data: Record<string, string> }>({
            path: `/v1/sandboxes/${opts.sandboxId}/env`,
          })
          .then(({ data }) => {
            this.events.emit({
              type: "API:FETCH_SECRETS_SUCCESS",
              secrets: data,
            });
          })
          .catch((error) => {
            this.events.emit({
              type: "API:FETCH_SECRETS_ERROR",
              error: error.message,
            });
          });
      } else {
        legacyCsbApi.rest
          .get<{
            owner: string;
            repo: string;
            secrets: Record<string, string>;
          }>({
            path: `/beta/repos/secrets/${opts.owner}/${opts.repo}${
              workspaceId ? "?team_id=" + workspaceId : ""
            }`,
          })
          .then(({ secrets }) => {
            this.events.emit({
              type: "API:FETCH_SECRETS_SUCCESS",
              secrets,
            });
          })
          .catch((error) => {
            this.events.emit({
              type: "API:FETCH_SECRETS_ERROR",
              error: error.message,
            });
          });
      }
    },
    async putSandboxSecrets(id, secrets) {
      /*
          /env end-point for sandboxes, currently takes only one key-pair
          at a time. And in v2 we pass all the values everytime. To handle remove and add
          kind of cases. So, making multiple calls for now. And refresh the list once done

          We can remove once the backend in v1 is updated to accept multiple values
        */
      const keys = Object.entries(secrets);

      let successKeys: Record<string, string> = {};
      try {
        for (const [name, value] of keys) {
          const data = await legacyCsbApi.rest.post<{
            data: Record<string, string>;
          }>({
            path: `/v1/sandboxes/${id}/env`,
            data: { environment_variable: { name, value } },
          });

          successKeys = { ...successKeys, ...data.data };
        }

        this.events.emit({
          type: "API:PUT_SECRETS_SUCCESS",
          secrets: successKeys,
        });
      } catch (e) {
        if (e instanceof Error) {
          this.events.emit({
            type: "API:PUT_SECRETS_ERROR",
            error: e.message,
          });
        }
      }
    },
    putProjectSecrets(owner, repo, workspaceId, secrets) {
      legacyCsbApi.rest
        .put<{
          owner: string;
          repo: string;
          secrets: Record<string, string>;
        }>({
          path: `/beta/repos/secrets/${owner}/${repo}`,
          data: { secrets, team_id: workspaceId },
        })
        .then(({ secrets }) => {
          this.events.emit({
            type: "API:PUT_SECRETS_SUCCESS",
            secrets,
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:PUT_SECRETS_ERROR",
            error: error.message,
          });
        });
    },

    deleteSandboxSecret(id, key: string) {
      legacyCsbApi.rest
        .delete<{
          data: Record<string, string>;
        }>({
          path: `/v1/sandboxes/${id}/env/${key}`,
        })
        .then((data) => {
          this.events.emit({
            type: "API:DELETE_SECRET_SUCCESS",
            secrets: data.data,
          });
        })
        .catch((error) => {
          this.events.emit({
            type: "API:DELETE_SECRET_ERROR",
            error: error.message,
          });
        });
    },

    getJwtToken() {
      const token = legacyCsbApi.devJwt.get();

      if (token) {
        return Promise.resolve(token);
      }

      /**
       * Stolen from v1 codebase: this endpoint is used to get fresh tokens
       * in order to be used on useSubscriptionsClient, which is a socket connection
       */
      return legacyCsbApi.rest
        .get<{ jwt: string }>({ path: "/v1/auth/jwt" })
        .then((data) => {
          if (data.jwt) {
            return data.jwt;
          }
        })
        .catch(() => {
          // Silent error
          return undefined;
        });
    },

    convertToCloudSandbox(sandboxId: string) {
      return legacyCsbApi.rest.put({
        path: `/v1/sandboxes/${sandboxId}`,
        data: { sandbox: { v2: true } },
      });
    },
  };
};
