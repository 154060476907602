import type * as Monaco from "monaco-editor";

interface ConflictBlock {
  left_start: number;
  base_start: number;
  right_start: number;
  right_end: number;
  left_code: string[];
  base_code: string[];
  right_code: string[];
}

export function getConflictBlocks(content: string): ConflictBlock[] {
  // scan the merged code to get the map between index and conflict blocks start line number
  const conflictBlocks: ConflictBlock[] = [];
  const lines = content.split(/\r?\n/);

  // get three way start line
  let block: ConflictBlock = {
    left_start: -1,
    base_start: -1,
    right_start: -1,
    right_end: -1,
    left_code: [],
    base_code: [],
    right_code: [],
  };

  let insideBlock = -1; // 1=left 0=base 2=right

  for (const i in lines) {
    const line = lines[i];

    if (!line) {
      continue;
    }

    if (insideBlock < 0) {
      if (line.startsWith("<<<<<<")) {
        block = {
          left_start: -1,
          base_start: -1,
          right_start: -1,
          right_end: -1,
          left_code: [],
          base_code: [],
          right_code: [],
        };
        block.left_start = parseInt(i) + 1;
        insideBlock = 1;
        continue;
      }
    } else {
      if (line.startsWith("|||||||")) {
        block.base_start = parseInt(i) + 1;
        insideBlock = 0;
        continue;
      }
      if (line.startsWith("======")) {
        block.right_start = parseInt(i) + 1;
        insideBlock = 2;
        continue;
      }
      if (line.startsWith(">>>>>>>")) {
        block.right_end = parseInt(i) + 1;
        conflictBlocks.push(block);
        block = {
          left_start: -1,
          base_start: -1,
          right_start: -1,
          right_end: -1,
          left_code: [],
          base_code: [],
          right_code: [],
        };
        insideBlock = -1;
        continue;
      }
      switch (insideBlock) {
        case 1:
          block.left_code.push(line);
          break;
        case 0:
          block.base_code.push(line);
          break;
        case 2:
          block.right_code.push(line);
          break;
      }
    }
  }

  return conflictBlocks;
}

export function getConflictDecorations(
  monaco: typeof Monaco,
  conflictBlocks: ConflictBlock[],
): Monaco.editor.IModelDeltaDecoration[] {
  // highlight conflicting lines
  const decorations: Monaco.editor.IModelDeltaDecoration[] = [];

  conflictBlocks.forEach((conflictBlock) => {
    if (conflictBlock.base_start > 0) {
      // diff3
      decorations.push({
        range: new monaco.Range(
          conflictBlock.left_start,
          0,
          conflictBlock.left_start,
          0,
        ),
        options: {
          isWholeLine: true,
          inlineClassName: "conflict-content-dim",
          className: "conflict-left-header conflict-top-right-corner",
          marginClassName: "conflict-left-header conflict-top-left-corner",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.left_start + 1,
          0,
          conflictBlock.base_start - 1,
          0,
        ),
        options: {
          isWholeLine: true,
          className: "conflict-left-line",
          marginClassName: "conflict-left-line",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.base_start,
          0,
          conflictBlock.base_start,
          0,
        ),
        options: {
          isWholeLine: true,
          inlineClassName: "conflict-content-dim",
          className: "conflict-left-header",
          marginClassName: "conflict-left-header",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.base_start + 1,
          0,
          conflictBlock.right_start - 1,
          0,
        ),
        options: {
          isWholeLine: true,
          className: "conflict-left-line",
          marginClassName: "conflict-left-line",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.right_start,
          0,
          conflictBlock.right_start,
          0,
        ),
        options: {
          isWholeLine: true,
          inlineClassName: "conflict-content-dim",
          className: "conflict-right-header",
          marginClassName: "conflict-right-header",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.right_start + 1,
          0,
          conflictBlock.right_end - 1,
          0,
        ),
        options: {
          isWholeLine: true,
          className: "conflict-right-line",
          marginClassName: "conflict-right-line",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.right_end,
          0,
          conflictBlock.right_end,
          0,
        ),
        options: {
          isWholeLine: true,
          inlineClassName: "conflict-content-dim",
          className: "conflict-right-line conflict-bottom-right-corner",
          marginClassName: "conflict-right-line conflict-bottom-left-corner",
        },
      });
    } else {
      // diff2
      decorations.push({
        range: new monaco.Range(
          conflictBlock.left_start,
          0,
          conflictBlock.left_start,
          0,
        ),
        options: {
          isWholeLine: true,
          inlineClassName: "conflict-content-dim",
          className: "conflict-left-header conflict-top-right-corner",
          marginClassName: "conflict-left-header conflict-top-left-corner",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.left_start + 1,
          0,
          conflictBlock.right_start - 1,
          0,
        ),
        options: {
          isWholeLine: true,
          className: "conflict-left-line",
          marginClassName: "conflict-left-line",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.right_start,
          0,
          conflictBlock.right_start,
          0,
        ),
        options: {
          isWholeLine: true,
          inlineClassName: "conflict-content-dim",
          className: "conflict-right-header",
          marginClassName: "conflict-right-header",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.right_start + 1,
          0,
          conflictBlock.right_end - 1,
          0,
        ),
        options: {
          isWholeLine: true,
          className: "conflict-right-line",
          marginClassName: "conflict-right-line",
        },
      });
      decorations.push({
        range: new monaco.Range(
          conflictBlock.right_end,
          0,
          conflictBlock.right_end,
          0,
        ),
        options: {
          isWholeLine: true,
          inlineClassName: "conflict-content-dim",
          className: "conflict-right-line conflict-bottom-right-corner",
          marginClassName: "conflict-right-line conflict-bottom-left-corner",
        },
      });
    }
  });

  return decorations;
}
