import { Disposable, Emitter } from "@codesandbox/pitcher-common";
import { NodeType } from "@codesandbox/pitcher-common/dist/BedrockFS";
import { loadSandpackClient } from "@codesandbox/sandpack-client";
export class Sandpack extends Disposable {
    constructor(sandbox, fsClient, fileClient, fileState) {
        super();
        this.sandbox = sandbox;
        this.fsClient = fsClient;
        this.fileState = fileState;
        this.evaluationStrategy = "edit-delayed";
        this.onMessageEmitter = this.addDisposable(new Emitter());
        this.onMount = this.onMessageEmitter.event;
        this.moduleViewOptions = {};
        this.addDisposable(fileClient.onFileOpen((file) => {
            const disposable = file.onDidContentChange(() => {
                this.update("edit");
            });
            file.onWillDispose(() => {
                disposable.dispose();
            });
        }));
        this.addDisposable(fsClient.onFSSync(() => {
            this.update("save");
        }));
        this.addDisposable(fileClient.onFileSave(() => {
            this.update("save");
            if (!this.client?.iframe) {
                return;
            }
            if (this.sandbox.template === "static") {
                /**
                 * Although Sandpack has a different bundler to run static templates,
                 * and would auto-reload on every change, we're not using that. We're
                 * using the original static template from csb-client, which contains
                 * the preview protocol and it's hosted on csb domain, for sharing purposes.
                 *
                 * This means, we need to manually reload the iframe to make sure we're
                 * using the latest state of the template.
                 */
                // eslint-disable-next-line
                this.client.iframe.src = this.client.iframe.src;
            }
        }));
        this.initializeLegacyModuleViewListener();
    }
    getNpmRegistries() {
        return (this.sandbox.npmRegistries ?? []).map((v) => {
            return {
                ...v,
                proxyEnabled: !!v.proxyEnabled,
                registryAuthToken: v.registryAuthKey,
            };
        });
    }
    setEvaluationStrategy(strategy) {
        this.evaluationStrategy = strategy;
    }
    updateSandbox(sandbox) {
        this.sandbox = {
            ...this.sandbox,
            ...sandbox,
        };
        this.client?.updateOptions(this.getOptions());
    }
    createSetup() {
        if (!this.sandbox) {
            throw new Error("No sandbox");
        }
        const files = {};
        for (const [_nodeId, node] of this.fsClient.memoryFS.tree.nodes) {
            if (node.type === NodeType.File) {
                const file = this.fileState.get(node.id);
                if (file) {
                    files[node.path] = { code: file.content };
                }
            }
        }
        const pkgJson = files["package.json"]?.code;
        try {
            const parsed = JSON.parse(pkgJson);
            this.prevPkgJson = parsed;
        }
        catch (err) {
            this.prevPkgJson = {
                dependencies: this.sandbox.npmDependencies,
            };
        }
        const deps = this.prevPkgJson.dependencies;
        return {
            files,
            entry: this.sandbox.entry,
            dependencies: deps,
            // TODO: Verify if we don't need to change this somehow?
            // Thought there was some auto-detect stuff in v1, but not sure this is still relevant
            template: this.sandbox.template,
        };
    }
    initializeLegacyModuleViewListener() {
        const { getOptions, onDidChange } = this.addDisposable(getModuleViewLegacyParameters());
        this.moduleViewOptions = getOptions();
        this.addDisposable(onDidChange(() => {
            this.moduleViewOptions = getOptions();
            this.client?.updateOptions(this.getOptions());
        }));
    }
    getOptions() {
        return {
            sandboxId: this.sandbox.id,
            bundlerURL: this.bundlerUrl,
            showOpenInCodeSandbox: false,
            showLoadingScreen: true,
            customNpmRegistries: this.getNpmRegistries(),
            clearConsoleOnFirstCompile: true,
            ...this.moduleViewOptions,
        };
    }
    async mount(iframe, url) {
        this.bundlerUrl = url;
        this.client = await loadSandpackClient(iframe, this.createSetup(), this.getOptions());
        this.onMessageEmitter.fire();
        this.onWillDispose(() => {
            this.client?.destroy();
        });
        return this.client;
    }
    update(reason) {
        if (!this.client) {
            return;
        }
        if (this.isDisposed) {
            return;
        }
        if (reason === "edit") {
            if (this.evaluationStrategy === "edit-instant") {
                this.update("save");
            }
            if (this.evaluationStrategy === "edit-delayed") {
                // If we're editing, we debounce the update
                clearTimeout(this.lastUpdateTimer);
                this.lastUpdateTimer = setTimeout(() => {
                    this.update("save");
                    this.lastUpdateTimer = undefined;
                }, 500);
            }
            return;
        }
        // This is interesting
        // this.client.listen(() => {})
        // this.client.dispatch()
        // https://codesandbox.io/p/github/codesandbox/sandpack/main?workspaceId=642af90c-4717-4730-bad3-e4c1e37ca5e2&file=%2Fsandpack-react%2Fsrc%2Fhooks%2FuseSandpackNavigation.ts&selection=%5B%7B%22endColumn%22%3A14%2C%22endLineNumber%22%3A6%2C%22startColumn%22%3A14%2C%22startLineNumber%22%3A6%7D%5D
        this.client.updateSandbox(this.createSetup());
    }
}
/**
 * This is to support the legacy "module view" of CodeSandbox that only a few people still use
 */
function getModuleViewLegacyParameters() {
    const searchParams = new URL(document.location.href).searchParams;
    const changeEmitter = new Emitter();
    const getParsedFile = () => {
        const file = new URL(document.location.href).searchParams.get("file");
        if (file) {
            const decodedFile = decodeURIComponent(file);
            const [decodedFilePart] = decodedFile.split(":");
            return decodedFilePart.replace("/", "");
        }
    };
    let lastParsedFile = getParsedFile();
    const interval = setInterval(() => {
        const searchParams = new URL(document.location.href).searchParams;
        const parsedFile = getParsedFile();
        if (searchParams.has("moduleview") && parsedFile !== lastParsedFile) {
            lastParsedFile = parsedFile;
            changeEmitter.fire();
        }
    }, 500);
    return {
        getOptions() {
            const isModuleView = searchParams.has("moduleview");
            return {
                entry: isModuleView ? getParsedFile() : undefined,
                isModuleView,
            };
        },
        dispose: () => {
            changeEmitter.dispose();
            clearInterval(interval);
        },
        onDidChange: changeEmitter.event,
    };
}
