import type { Event, IDisposable } from "@codesandbox/pitcher-common";

/**
 * Will run the given callback whenever the listener fires, and will automatically
 * dispose the result of the previous callback as soon as the listener is called again.
 *
 * This allows you to listen to e.g. config changes, and fully reinitialize when those
 * changes come in, with automatic cleanup of the previous instance.
 *
 * @param listener the event we subscribe to
 * @param callback the callback to run, returns the disposable to clean up side-effects
 * @param initialValue if this value is given, we'll immediately run the callback with this initial value
 * @param shouldUpdate only update if `shouldUpdate` is true
 */
export function runWithLatestValue<T>(
  listener: Event<T>,
  callback: (value: T) => IDisposable,
  initialValue?: T,
  shouldUpdate?: (a: T, b: T) => boolean,
) {
  let lastDisposable: IDisposable | undefined = undefined;
  let lastValue: T | undefined = undefined;
  const handler = (value: T) => {
    if (lastValue && shouldUpdate && !shouldUpdate(lastValue, value)) {
      return;
    }
    lastValue = value;

    if (lastDisposable) {
      lastDisposable.dispose();
    }
    lastDisposable = callback(value);
  };
  const listenerDisposable = listener(handler);
  if (initialValue) {
    handler(initialValue);
  }

  return {
    dispose() {
      listenerDisposable.dispose();
      if (lastDisposable) {
        lastDisposable.dispose();
      }
    },
  };
}
