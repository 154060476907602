import * as AlertDialog from "@radix-ui/react-alert-dialog";
import React from "react";

import * as styles from "../../css/dialog";
import { styled } from "../../theme";
import { Button } from "../Button";
import { Stack } from "../Stack";

export interface ConfirmDialogProps extends AlertDialog.AlertDialogProps {
  cancelText: string;
  confirmText: string;
  description: string;
  onCancel(): void;
  onConfirm(): void;
  onOpenChange(open: boolean): void;
  open: boolean;
  title: string;
}

const Content = styled(AlertDialog.Content, styles.content);
const Description = styled(AlertDialog.Description, styles.description);
const Overlay = styled(AlertDialog.Overlay, styles.overlay);
const Title = styled(AlertDialog.Title, styles.title);

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  cancelText,
  confirmText,
  description,
  onCancel,
  onConfirm,
  title,
  ...rootProps
}) => {
  return (
    <AlertDialog.Root {...rootProps}>
      <Overlay />
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Stack css={{ justifyContent: "flex-end" }} horizontal>
          <AlertDialog.Cancel asChild>
            <Button onClick={onCancel} variant="secondary">
              {cancelText}
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action asChild>
            <Button onClick={onConfirm} variant="primary">
              {confirmText}
            </Button>
          </AlertDialog.Action>
        </Stack>
      </Content>
    </AlertDialog.Root>
  );
};
