import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";
import { registerExtension } from "vscode/extensions";

export function initializeGitHubThemes() {
  // eslint-disable-next-line
  const config: any = {
    name: "github-vscode-theme",
    displayName: "GitHub Theme",
    description: "GitHub theme for VS Code",
    version: "6.3.4",
    publisher: "GitHub",
    engines: {
      vscode: "*",
    },
    contributes: {
      themes: [
        {
          label: "GitHub Light Default",
          uiTheme: "vs",
          path: "./themes/light-default.json",
        },
        {
          label: "GitHub Dark Default",
          uiTheme: "vs-dark",
          path: "./themes/dark-default.json",
        },
        {
          label: "GitHub Light High Contrast",
          uiTheme: "hc-light",
          path: "./themes/light-hc.json",
        },
        {
          label: "GitHub Dark High Contrast",
          uiTheme: "hc-dark",
          path: "./themes/dark-hc.json",
        },
      ],
    },
  };

  const { registerFileUrl } = registerExtension(config, 2);
  registerFileUrl(
    "./themes/dark-default.json",
    withBasePath(env, "/vscode-extensions/themes/github-dark-default.json"),
  );
  registerFileUrl(
    "./themes/light-default.json",
    withBasePath(env, "/vscode-extensions/themes/github-light-default.json"),
  );
  registerFileUrl(
    "./themes/dark-hc.json",
    withBasePath(env, "/vscode-extensions/themes/github-dark-hc.json"),
  );
  registerFileUrl(
    "./themes/light-hc.json",
    withBasePath(env, "/vscode-extensions/themes/github-light-hc.json"),
  );

  return config.contributes.themes;
}
