import React, { forwardRef } from "react";

import type { CSS } from "../../theme";
import { styled } from "../../theme";
import { toShadowRing } from "../../tokens/shadow";
import { onGridPx } from "../../tokens/space";

const ALERT_OUTLINE = "1px solid $informative-alert-base";
const FOCUS_OUTLINE = "1px solid $accent-secondary-base";
const ERROR_OUTLINE = "1px solid $informative-error-base";

const styles: Record<
  "alert" | "base" | "error",
  Record<"hover" | "focus", CSS>
> = {
  alert: {
    hover: {
      outline: ALERT_OUTLINE,
      color: "$neutral-fg-high",
    },
    focus: {
      outline: ALERT_OUTLINE,
      color: "$neutral-fg-high",
    },
  },
  base: {
    hover: {
      boxShadow: toShadowRing("thick", "neutral-bg-focus"),
    },
    focus: {
      outline: FOCUS_OUTLINE,
    },
  },
  error: {
    hover: {
      outline: ERROR_OUTLINE,
      color: "$neutral-fg-high",
    },
    focus: {
      outline: ERROR_OUTLINE,
      color: "$neutral-fg-high",
    },
  },
};

/**
 * TODO: Resuse the Input styles
 */
const TextAreaWrapper = styled("div", {
  background: "$neutral-bg-low",
  border: 0,
  borderRadius: "$1",
  color: "$neutral-fg-subtle",
  fontFamily: "$base",
  fontSize: "$base",
  height: "$25",
  lineHeight: onGridPx(4),
  outlineOffset: "-1px",
  overflowY: "auto",
  padding: "$2",
  resize: "none",
  transition: "box-shadow $fast, color $fast",

  variants: {
    state: {
      default: {},
      disabled: {
        cursor: "not-allowed",
        color: "$neutral-fg-low",
      },
      readonly: {
        cursor: "default",
      },
    },
    variant: {
      alert: {
        "&:focus": styles.alert.focus,
        "&:focus-within": styles.alert.focus,
      },
      base: {
        "&:focus": styles.base.focus,
        "&:focus-within": styles.base.focus,
      },
      error: {
        outline: ERROR_OUTLINE,
        "&:focus": styles.error.focus,
        "&:focus-within": styles.error.focus,
      },
    },
    width: {
      auto: {
        width: "auto",
      },
      full: {
        width: "100%",
      },
    },
  },

  compoundVariants: [
    {
      state: "default",
      variant: "alert",
      css: {
        boxShadow: ALERT_OUTLINE,
        "&:hover:not(:focus)": styles.alert.hover,
        "&:hover:not(:focus-within)": styles.alert.hover,
      },
    },
    {
      state: "default",
      variant: "base",
      css: {
        "&:hover:not(:focus)": styles.base.hover,
        "&:hover:not(:focus-within)": styles.base.hover,
      },
    },
    {
      state: "default",
      variant: "error",
      css: {
        boxShadow: ERROR_OUTLINE,
        "&:hover:not(:focus)": styles.error.hover,
        "&:hover:not(:focus-within)": styles.error.hover,
      },
    },
  ],
});

type WrapperProps = React.ComponentProps<typeof TextAreaWrapper>;

const getInputState = (
  disabled?: boolean,
  readOnly?: boolean,
): WrapperProps["state"] => {
  if (disabled) {
    return "disabled";
  }

  if (readOnly) {
    return "readonly";
  }

  return "default";
};

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  css?: CSS;
  variant?: WrapperProps["variant"];
  width?: WrapperProps["width"];
  as?: string;
}
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      css,
      disabled,
      readOnly,
      variant = "base",
      width,
      as: asComponent = "textarea",
      ...textAreaProps
    },
    ref,
  ) => {
    const state = getInputState(disabled, readOnly);

    return (
      <TextAreaWrapper
        // Stitches issue
        // @ts-ignore
        ref={ref}
        css={css}
        state={state}
        variant={variant}
        width={width}
        {...textAreaProps}
        as={asComponent as "textarea"}
        disabled={disabled}
        readOnly={readOnly}
        spellCheck="false"
      />
    );
  },
);
