import { env } from "environment-interface/env";
import { withBasePath } from "isomorphic/utils";
import { registerExtension } from "vscode/extensions";

export function initializeNightOwlTheme() {
  // eslint-disable-next-line
  const config: any = {
    name: "theme-night-owl",
    displayName: "Night Owl Theme",
    contributes: {
      themes: [
        {
          label: "Night Owl",
          uiTheme: "vs-dark",
          path: "./themes/night-owl-dark.json",
        },
      ],
    },
  };
  const { registerFileUrl } = registerExtension(config, 2);

  registerFileUrl(
    "./themes/night-owl-dark.json",
    withBasePath(env, "/vscode-extensions/themes/night-owl.json"),
  );

  return config.contributes.themes;
}
