import { Disposable } from "@codesandbox/pitcher-client";
import type { IPitcherClient } from "@codesandbox/pitcher-client";
import { DisposableStore } from "@codesandbox/pitcher-common";
import * as vscode from "vscode";

import { parseMonacoGitUri } from "../../../../../utils/uri-utils";
import { getLatestMonacoConfig, onMonacoConfigChange } from "../../monaco";

interface IRemoteStatus {
  remote: {
    branch?: string | null;
    head?: string | null;
  };
  target: {
    branch?: string | null;
    head?: string | null;
  };
}

function getRemoteHead(ref: string, status: IRemoteStatus): string | null {
  const branchName = ref.replace("origin/", "");
  if (status.remote.branch === branchName) {
    return status.remote.head ?? null;
  } else if (status.target.branch === branchName) {
    return status.target.head ?? null;
  } else {
    return null;
  }
}

class GitFile extends Disposable {
  content = "";
  isSynced = false;

  private onChangeEmitter = new vscode.EventEmitter<string>();
  onChange = this.onChangeEmitter.event;

  private onErrorEmitter = new vscode.EventEmitter<Error>();
  onError = this.onErrorEmitter.event;

  constructor(
    filepath: string,
    gitReference: string,
    git: IPitcherClient["clients"]["git"],
  ) {
    super();

    const refreshModelContent = () => {
      git
        .remoteContent({
          filepath,
          reference: gitReference,
        })
        .then(({ content: newContent }) => {
          if (newContent !== this.content) {
            this.content = newContent;
            this.onChangeEmitter.fire(newContent);
          }
        })
        .catch((err) => {
          this.onErrorEmitter.fire(err);
        })
        .finally(() => {
          this.isSynced = true;
        });
    };

    git.getStatus().then((currentGitStatus) => {
      let lastHead = currentGitStatus.head;

      if (gitReference === "HEAD") {
        const disposable = git.onStatusUpdated(async (evt) => {
          const newHead = evt.head;

          if (newHead !== lastHead) {
            refreshModelContent();
            lastHead = newHead;
          }
        });
        this.onWillDispose(() => disposable.dispose());
      }

      if (gitReference.startsWith("origin/")) {
        let lastHead = getRemoteHead(gitReference, currentGitStatus);
        const disposable = git.onStatusUpdated((evt) => {
          const newHead = getRemoteHead(gitReference, evt);
          if (newHead !== lastHead) {
            refreshModelContent();
            lastHead = newHead;
          }
        });
        this.onWillDispose(() => disposable.dispose());
      }

      refreshModelContent();
    });
  }
}

class GitFileSystemProvider implements vscode.FileSystemProvider {
  private git: IPitcherClient["clients"]["git"];

  private onDidChangeFileEmitter = new vscode.EventEmitter<
    vscode.FileChangeEvent[]
  >();
  onDidChangeFile = this.onDidChangeFileEmitter.event;

  private registry = new Map<string, GitFile>();

  constructor() {
    onMonacoConfigChange((newConfig) => {
      this.git = newConfig.pitcher.clients.git;
    });
    this.git = getLatestMonacoConfig().pitcher.clients.git;
  }

  private getGitFile(uri: vscode.Uri): Promise<GitFile> {
    try {
      const parsedUri = parseMonacoGitUri(uri);
      const cacheKey = `${parsedUri.filepath}@${parsedUri.gitReference}`;
      const foundFile = this.registry.get(cacheKey);
      const file =
        foundFile ??
        new GitFile(parsedUri.filepath, parsedUri.gitReference, this.git);

      this.registry.set(cacheKey, file);

      // For new files we also assign a change listener for file watching
      if (!foundFile) {
        file.onChange(() => {
          this.onDidChangeFileEmitter.fire([
            {
              uri,
              type: vscode.FileChangeType.Changed,
            },
          ]);
        });
        // TODO: Add file.onError handling for file removal?
      }

      if (file.isSynced) {
        return Promise.resolve(file);
      } else {
        return new Promise((resolve, reject) => {
          const disposableStore = new DisposableStore();
          disposableStore.add(
            file.onChange(() => {
              resolve(file);
              disposableStore.dispose();
            }),
          );
          disposableStore.add(
            file.onError((err) => {
              reject(err);
              // File likely doesn't exist, so we remove it from the registry
              this.registry.delete(cacheKey);
              disposableStore.dispose();
            }),
          );
        });
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  watch(
    _uri: vscode.Uri,
    _options: {
      readonly recursive: boolean;
      readonly excludes: readonly string[];
    },
  ): vscode.Disposable {
    return new vscode.Disposable(() => {});
  }

  async stat(uri: vscode.Uri): Promise<vscode.FileStat> {
    const file = await this.getGitFile(uri).catch(() => {
      // no-op
    });
    return {
      type: vscode.FileType.File,
      ctime: 0,
      mtime: 0,
      size: (1 + (file?.content.length ?? 0)) * 8,
      permissions: vscode.FilePermission.Readonly,
    };
  }

  async readFile(uri: vscode.Uri): Promise<Uint8Array> {
    const file = await this.getGitFile(uri).catch(() => {
      // no-op
    });
    return new TextEncoder().encode(file?.content ?? "");
  }

  async readDirectory(
    _uri: vscode.Uri,
  ): Promise<Array<[string, vscode.FileType]>> {
    throw new Error("Method not implemented.");
  }

  createDirectory(_uri: vscode.Uri): void | Thenable<void> {
    throw new Error("Method not implemented.");
  }

  writeFile(
    _uri: vscode.Uri,
    _content: Uint8Array,
    _options: { readonly create: boolean; readonly overwrite: boolean },
  ): void | Thenable<void> {
    throw new Error("Method not implemented.");
  }

  delete(
    _uri: vscode.Uri,
    _options: { readonly recursive: boolean },
  ): void | Thenable<void> {
    throw new Error("Method not implemented.");
  }

  rename(
    _oldUri: vscode.Uri,
    _newUri: vscode.Uri,
    _options: { readonly overwrite: boolean },
  ): void | Thenable<void> {
    throw new Error("Method not implemented.");
  }
}

export async function initializeGitFs() {
  vscode.workspace.registerFileSystemProvider(
    "git",
    new GitFileSystemProvider(),
  );
}
