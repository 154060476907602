import { Emitter } from "@codesandbox/pitcher-common";
export const PICKER_BROWSER_PACKAGE_MANAGER = "__BROWSER_PACKAGE_MANAGER__";
export class BrowserTaskClient {
    constructor(filesState, fsClient, fileClient) {
        this.filesState = filesState;
        this.fsClient = fsClient;
        this.fileClient = fileClient;
        this.taskListUpdateEmitter = new Emitter();
        this.onTaskListUpdate = this.taskListUpdateEmitter.event;
        this.taskUpdateEmitter = new Emitter();
        this.onTaskUpdate = this.taskUpdateEmitter.event;
        this.taskPortOpenedEmitter = new Emitter();
        this.onTaskPortOpened = this.taskPortOpenedEmitter.event;
        this.taskStartedEmitter = new Emitter();
        this.onTaskStarted = this.taskStartedEmitter.event;
        this.unassignedPortOpenedEmitter = new Emitter();
        this.onUnassignedPortOpened = this.unassignedPortOpenedEmitter.event;
        this.unassignedPortClosedEmitter = new Emitter();
        this.onUnassignedPortClosed = this.unassignedPortClosedEmitter.event;
        this.configParseErrorEmitter = new Emitter();
        this.onConfigParseError = this.configParseErrorEmitter.event;
        this.readyPromise = undefined;
    }
    getTasks() {
        return {
            tasks: {},
            setupTasks: [],
            validationErrors: [],
        };
    }
    getTask() {
        return undefined;
    }
    resync() {
        return;
    }
    runTask() {
        return Promise.reject(new Error("Not implemented"));
    }
    async runCommand(command) {
        const commandParts = command.split(" ");
        const packageJsonId = this.fsClient.getIdFromPath("package.json");
        if (!packageJsonId || commandParts[0] !== PICKER_BROWSER_PACKAGE_MANAGER) {
            return Promise.reject(new Error("Not implemented"));
        }
        const type = commandParts[1];
        let packageName = commandParts[2];
        let packageVersion = undefined;
        const isDev = commandParts[3] === "--dev";
        let isScopedDep = false;
        // This includes package scope, eg @babel/runtime
        if (commandParts[2].startsWith("@")) {
            packageName = packageName.slice(1);
            isScopedDep = true;
        }
        // This contains specific version, eg babel/runtime@latest
        if (packageName.indexOf("@") !== -1) {
            // Get package version
            packageVersion = packageName.match(/@.+/)?.[0].replace("@", "");
            // Remove version from package name
            packageName = packageName.replace(/@.+/, "");
        }
        // Add back the original scope to the package name
        if (isScopedDep) {
            packageName = "@" + packageName;
        }
        if (packageJsonId) {
            const fileRef = await this.fileClient.openFile(packageJsonId);
            const content = fileRef.object.content;
            let parsedPackageJson = JSON.parse(content);
            if (type === "add") {
                parsedPackageJson = isDev
                    ? {
                        ...parsedPackageJson,
                        devDependencies: {
                            ...parsedPackageJson.devDependencies,
                            [packageName]: packageVersion,
                        },
                    }
                    : {
                        ...parsedPackageJson,
                        dependencies: {
                            ...parsedPackageJson.dependencies,
                            [packageName]: packageVersion,
                        },
                    };
            }
            else if (isDev) {
                delete parsedPackageJson.devDependencies[packageName];
            }
            else {
                delete parsedPackageJson.dependencies[packageName];
            }
            fileRef.object.updateContent(JSON.stringify(parsedPackageJson, null, 2));
            fileRef.object.save();
            fileRef.dispose();
        }
        return Promise.resolve({
            id: "",
            command,
            name: "Install dependency",
            shell: null,
            ports: [],
        });
    }
    stopTask() {
        return Promise.reject(new Error("Not implemented"));
    }
    updateTask() {
        return Promise.reject(new Error("Not implemented"));
    }
    createTask() {
        return Promise.reject(new Error("Not implemented"));
    }
    saveToConfig() {
        return Promise.reject(new Error("Not implemented"));
    }
    generateConfigFile() {
        return Promise.reject(new Error("Not implemented"));
    }
    createSetupTasks() {
        return Promise.reject(new Error("Not implemented"));
    }
}
