import { bedrockFS } from "@codesandbox/pitcher-common";
export class MemoryFS {
    constructor() {
        /** Map of outgoing operations that have not yet been confirmed by the server */
        this.pendingOperations = new Map();
        this.revertOperations = new Map();
        this.messageId = 0;
        /** operations coming from the server */
        this.incomingOperations = new Set();
        this.remoteClock = 0;
        this.tree = new bedrockFS.Tree();
    }
    populateTreeFromJSON(payload) {
        this.remoteClock = payload.clock;
        this.tree = bedrockFS.Tree.fromJSON(payload.treeNodes);
        this.syncFSTree([]);
    }
    /** Applies an operation and returns the inverse operation */
    applyOperation(op) {
        switch (op.type) {
            case "create": {
                const parentNode = this.tree.getNodeById(op.parentId);
                if (!parentNode?.isDirNode())
                    return false;
                switch (op.newEntry.type) {
                    case bedrockFS.NodeType.File:
                        parentNode.createFileWithId(op.newEntry.id, op.newEntry.name);
                        break;
                    case bedrockFS.NodeType.Directory:
                        parentNode.createDirectoryWithId(op.newEntry.id, op.newEntry.name);
                        break;
                }
                return {
                    type: "delete",
                    id: op.newEntry.id,
                };
            }
            case "delete": {
                const nodeToDelete = this.tree.getNodeById(op.id);
                if (!nodeToDelete?.parentId)
                    return false;
                this.tree.deleteNode(op.id);
                return {
                    type: "create",
                    parentId: nodeToDelete.parentId,
                    newEntry: {
                        id: nodeToDelete.id,
                        type: nodeToDelete.type,
                        // Typescript complains otherwise... but this should be fine
                        name: nodeToDelete.name ?? "",
                    },
                };
            }
            case "move": {
                const nodeToMove = this.tree.getNodeById(op.id);
                if (!nodeToMove)
                    return false;
                const reverseOp = {
                    type: "move",
                    id: op.id,
                };
                reverseOp.parentId = nodeToMove.parentId;
                if (op.name) {
                    reverseOp.name = nodeToMove.name;
                }
                const parentId = op.parentId ?? nodeToMove.parentId;
                if (!parentId) {
                    throw new Error("Move requires a parentId");
                }
                this.tree.moveNode(op.id, parentId, op.name);
                return reverseOp;
            }
        }
    }
    /** Apply a client-side created operation, these get added to a queue and synced to pitcher */
    applyPendingOperation(op) {
        this.messageId++;
        this.pendingOperations.set(this.messageId, op);
        const reverseOp = this.applyOperation(op);
        if (!reverseOp) {
            this.pendingOperations.delete(this.messageId);
            return false;
        }
        this.revertOperations.set(this.messageId, reverseOp);
        return this.messageId;
    }
    /**
     * received an operation notification from the server
     *
     * This can be:
     * - Operation created by other client
     * - Confirmation for a pending operation this client created
     */
    receiveNotification(evt) {
        this.incomingOperations.add(evt);
    }
    /**
     * sync the fs tree, dropping operations that have been processed by the server
     * @param pendingOpsToDrop the list of operations that have been processed by the server
     * @returns the amount of operations that have been applied
     */
    syncFSTree(pendingOpsToDrop) {
        let opCount = 0;
        const sortedEvents = [...this.incomingOperations].sort((a, b) => a.clock - b.clock);
        // Revert pending operations
        for (const op of this.revertOperations.values()) {
            this.applyOperation(op);
        }
        // apply pending events
        let nextClock = this.remoteClock + 1;
        for (const fsEvent of sortedEvents) {
            if (fsEvent.clock < nextClock) {
                this.incomingOperations.delete(fsEvent);
            }
            else if (fsEvent.clock === nextClock) {
                this.applyOperation(fsEvent.operation);
                this.incomingOperations.delete(fsEvent);
                this.remoteClock = fsEvent.clock;
                opCount++;
            }
            nextClock++;
        }
        for (const msgIdx of pendingOpsToDrop) {
            this.pendingOperations.delete(msgIdx);
            this.revertOperations.delete(msgIdx);
        }
        // apply pending operations
        for (const msgId of this.pendingOperations.keys()) {
            const op = this.pendingOperations.get(msgId);
            if (op) {
                const reverseOp = this.applyOperation(op);
                if (!reverseOp) {
                    this.revertOperations.delete(msgId);
                    this.pendingOperations.delete(msgId);
                }
                else {
                    this.revertOperations.set(msgId, reverseOp);
                    opCount++;
                }
            }
        }
        return opCount;
    }
    getPathFromId(id) {
        const node = this.tree.getNodeById(id);
        if (node) {
            return node.path;
        }
    }
    getIdFromPath(path) {
        return this.tree.getIdFromPath(path);
    }
    getNodeById(id) {
        return this.tree.getNodeById(id);
    }
}
