import { Barrier, Emitter, GlobCache } from "@codesandbox/pitcher-common";
import _debug from "debug";
import { AsyncValueStore } from "../../common/AsyncValueStore";
const debug = _debug("pitcher:language-client");
export class LanguageClient {
    constructor(messageHandler, fileClient) {
        this.messageHandler = messageHandler;
        this.fileClient = fileClient;
        this.globCache = new GlobCache();
        // Because Pitcher requires the clients to resend the initialize request when reconnecting
        // we take care of this from PitcherClient by tracking what initialize messages has been sent.
        // The key in this record is the language id
        this.initializeMessages = {};
        this.lspNotificationEmitter = new Emitter();
        this.onLspNotification = this.lspNotificationEmitter.event;
        this.lspServerRequestEmitter = new Emitter();
        this.onLspServerRequest = this.lspServerRequestEmitter.event;
        this.languagesUpdatedEmitter = new Emitter();
        this.onLanguagesUpdated = this.languagesUpdatedEmitter.event;
        this.languagesErrorEmitter = new Emitter();
        this.onLanguagesError = this.languagesErrorEmitter.event;
        this.languages = this.createLanguagesValue();
        messageHandler.onNotification("language/lspNotification", (msg) => {
            this.lspNotificationEmitter.fire(msg);
        });
        messageHandler.onNotification("language/lspServerRequest", (msg) => {
            this.lspServerRequestEmitter.fire(msg);
        });
    }
    createLanguagesValue() {
        const languages = new AsyncValueStore([], () => this.messageHandler
            .request({
            method: "language/list",
            params: {},
        })
            .then(({ languages }) => languages));
        languages.onChange(({ value }) => {
            this.globCache.clear();
            this.languagesUpdatedEmitter.fire(value);
        });
        languages.onError((error) => this.languagesErrorEmitter.fire(error));
        return languages;
    }
    getLanguages() {
        return this.languages.get();
    }
    resync() {
        this.languages.refresh();
        for (const serverId in this.initializeMessages) {
            this.sendLSPRequest(this.initializeMessages[serverId]);
        }
    }
    // eslint-disable-next-line
    async sendLSPRequest(params) {
        const now = Date.now();
        const file = this.getLSPRelatedFile(params.message);
        // We send a lot of LSP messages and they are typically immutable, so we avoid queueing them for reconnect
        if (params.message.method === "initialize") {
            this.initializeMessages[params.languageId] = params;
        }
        // We only want to delay sending the request if there are pending operations
        // not yet sent to Pitcher
        if (file?.document?.otClient.hasQueuedOperation) {
            const barrier = new Barrier();
            const disposable = file.document.onSendOperation(() => {
                if (!file?.document?.otClient.hasQueuedOperation) {
                    disposable.dispose();
                    barrier.open();
                }
            });
            await barrier.wait();
        }
        try {
            const result = await this.messageHandler.request({
                method: "language/lspRequest",
                params,
            }, {
                queueForReconnect: params.message.method === "initialize",
                // We use a less aggressive timeout as LSPs can sometimes take very long to respond, and sometimes
                // we even need to wait for the container to be built before we get a response.
                timeoutMs: 10 * 60 * 1000,
                seamlessForkStrategy: params.message.method === "textDocument/formatting"
                    ? "queue"
                    : "queueDuringFork",
            });
            debug('LSP request "%s" took %dms', params.message.method, Date.now() - now);
            return {
                type: "ok",
                // @ts-expect-error unknown to any
                result,
            };
        }
        catch (e) {
            // @ts-expect-error unknown to any
            return { type: "error", error: e };
        }
    }
    sendLSPServerResponse(params) {
        return new Promise((resolve, reject) => {
            this.messageHandler
                .request({
                method: "language/lspServerResponse",
                params,
            })
                .then(resolve)
                .catch(reject);
        });
    }
    getLanguageId(filepath) {
        for (const language of this.languages.get()) {
            for (const pattern of language.globs) {
                const regex = this.globCache.get(pattern);
                if (filepath.match(regex)) {
                    return language.id;
                }
            }
        }
        // fallback to text
        return "text";
    }
    isLSPDocumentMessage(message) {
        return Boolean(message.params &&
            message.params.textDocument);
    }
    getLSPRelatedFile(message) {
        if (!this.isLSPDocumentMessage(message)) {
            return;
        }
        const id = this.fileClient.getIdFromWorkspaceUriString(message.params.textDocument.uri);
        if (!id) {
            return;
        }
        const file = this.fileClient.getOpenedFile(id);
        if (!file) {
            return;
        }
        return file;
    }
}
