import React from "react";

import { Dropdown } from "../Dropdown";
import { Icon } from "../Icon";
import { Stack } from "../Stack";

import { StyledButton } from "./elements";

type ButtonProps = React.ComponentProps<typeof StyledButton>;

export type ComboButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  // If true, the main button also triggers the dropdown.
  selfTrigger?: boolean;
  options: NonNullable<React.ReactNode>;
} & ButtonProps;

const ComboButton = ({
  children,
  options,
  selfTrigger = false,
  variant = "warning",
  ...props
}: ComboButtonProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Stack gap={0} horizontal>
      <StyledButton
        css={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          border: variant === "warning" ? "1px solid $neutral-bg-high" : "none",
          borderRight: "none",

          fontWeight: 500,
          fontSize: "12px",
          lineHeight: 1,
          letterSpacing: "-0.02em",
          whiteSpace: "nowrap",
        }}
        variant={variant}
        {...(selfTrigger
          ? {
              ...props,
              onClick: () => setOpen(!open),
            }
          : props)}
      >
        {children}
      </StyledButton>
      {options ? (
        <Dropdown
          rootProps={
            selfTrigger ? { onOpenChange: (open) => setOpen(open), open } : {}
          }
          trigger={
            <StyledButton
              css={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                border:
                  variant === "warning" ? "1px solid $neutral-bg-high" : "none",

                "> *": {
                  transform: "translateY(0)",
                  transition: "transform .2s ease",
                },

                '&:hover > *, &[aria-expanded="true"] > *': {
                  transform: "translateY(2px)",
                  transition: "transform .2s ease",
                },
              }}
              variant={variant}
            >
              <Icon size={3} type="chevronDown" />
            </StyledButton>
          }
        >
          {options}
        </Dropdown>
      ) : null}
    </Stack>
  );
};

const ComboButtonItem = ({
  children,
  ...props
}: {
  children: NonNullable<React.ReactNode>;
  disabled?: boolean;
  onSelect: () => void;
}) => {
  return (
    <Dropdown.Item {...props}>
      <Stack
        css={{
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.005em",
        }}
      >
        {children}
      </Stack>
    </Dropdown.Item>
  );
};

const ComboButtonSeparator = () => {
  return <Dropdown.Separator />;
};

/**
 * Re-export the Menu components to make it easer
 * to use the ComboButton.
 */
ComboButton.Item = ComboButtonItem;
ComboButton.Divider = ComboButtonSeparator;

export { ComboButton };
