import type { CSS, VariantProps } from "@stitches/react";
import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";

import { styled } from "../../theme";

interface HeadingProps
  extends VariantProps<typeof StyledHeading>,
    Omit<ComponentPropsWithoutRef<"h1">, "color"> {
  // Allow everything for children
  children: React.ReactNode;
  // The HTML element to render, limited to heading tags
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  css?: CSS;
}

// Default to `h1` element
export const StyledHeading = styled("h1", {
  // Reset
  margin: 0,
  // Set
  fontWeight: "$medium",

  variants: {
    size: {
      xs: {
        typography: "$xs",
        fontFamily: "$base",
      },
      base: {
        typography: "$base",
        fontFamily: "$base",
      },
      sm: {
        typography: "$sm",
        fontFamily: "$base",
      },
      md: {
        typography: "$md",
        fontFamily: "$base",
      },
      lg: {
        typography: "$lg",
        fontFamily: "$base",
      },
      xl: {
        typography: "$xl",
        fontFamily: "$display",
      },
      xxl: {
        typography: "$xxl",
        fontFamily: "$display",
      },
    },
    color: {
      base: {
        color: "$neutral-fg-base",
      },
      highlight: {
        color: "$neutral-fg-high",
      },
      neutralHigh: {
        color: "$neutral-fg-high",
      },
    },
    /**
     * With the `variant` prop you're able to control the font family
     */
    variant: {
      base: {
        fontFamily: "$base",
      },
      expressive: {
        fontFamily: "$display1",
      },
    },
  },
});

/**
 * @deprecated, use Text
 */
export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ children, as, size, ...restProps }, ref) => {
    return (
      <StyledHeading ref={ref} as={as} size={size} {...restProps}>
        {children}
      </StyledHeading>
    );
  },
);
