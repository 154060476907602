import { Disposable, Emitter } from "@codesandbox/pitcher-common";
export class BrowserPortClient extends Disposable {
    constructor(sandpack, url) {
        super();
        this.portsUpdatedEmitter = this.addDisposable(new Emitter());
        this.onPortsUpdated = this.portsUpdatedEmitter.event;
        this.portsErrorEmitter = this.addDisposable(new Emitter());
        this.onPortsError = this.portsErrorEmitter.event;
        this.readyPromise = undefined;
        this.ports = [
            {
                url,
                port: 0,
                mountSandpack(iframe) {
                    return sandpack.mount(iframe, this.url);
                },
                onMountSandpack(cb) {
                    sandpack.onMount(cb);
                },
                getSandpackClient() {
                    return sandpack.client;
                },
            },
        ];
    }
    resync() {
        return;
    }
    getPorts() {
        return this.ports;
    }
    changeUrl(newUrl) {
        const port = this.ports[0];
        this.ports[0] = {
            ...port,
            url: newUrl,
        };
        this.portsUpdatedEmitter.fire(this.ports);
    }
}
