import type { CSS } from "@stitches/react/types/css-util";
import type { ChangeEventHandler } from "react";
import { useEffect, useRef, useState } from "react";

import { useClickOutside } from "hooks/useClickOutside";
import { useKeyboardShortcuts } from "hooks/useKeyboardShortcuts";

import { styled } from "../../theme";
import { Box } from "../Box";
import type { IconType } from "../Icon";
import { Input } from "../Input/Input";
import { Stack } from "../Stack";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InputWithSuggestions = <Data extends any>({
  items = [] as unknown as Data[],
  value,
  onSuggestionSelected,
  onInputValueChange,
  disabled,
  placeholder = "Search...",
  children,
  icon,
  inputCss,
  appendElement,
}: {
  onInputValueChange: (value: string) => void;
  onSuggestionSelected: (value: Data) => void;
  value: string;
  disabled?: boolean;
  items: Data[];
  placeholder?: string;
  children: (item: Data) => React.ReactNode;
  icon?: IconType;
  inputCss?: CSS;
  appendElement?: React.ReactNode;
}) => {
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const debounceTimer = useRef<NodeJS.Timeout>();

  useClickOutside(resultsContainerRef, () => setDropDownOpened(false));

  useEffect(() => {
    setActiveItemIndex(0);
  }, [dropDownOpened]);

  useEffect(() => {
    setDropDownOpened(value !== "" && items.length > 0);
  }, [value, items]);

  const handleDependencySelect = (item: Data) => {
    onSuggestionSelected(item);
    setDropDownOpened(false);
  };

  useKeyboardShortcuts(
    dropDownOpened
      ? {
          Enter: () => {
            handleDependencySelect(items[activeItemIndex]);
          },
          ArrowDown: () => {
            setActiveItemIndex((prev) => {
              return Math.min(prev + 1, items.length - 1);
            });
          },
          ArrowUp: () => {
            setActiveItemIndex((prev) => {
              return Math.max(prev - 1, 0);
            });
          },
        }
      : {},
    [dropDownOpened, activeItemIndex],
  );

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const query = event.target.value;

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    onInputValueChange(query);
  };

  return (
    <Box css={{ position: "relative" }}>
      <Input
        css={inputCss}
        disabled={disabled}
        icon={icon}
        onChange={onInputChange}
        onFocus={() => {
          if (items.length > 0) {
            setDropDownOpened(true);
          }
        }}
        onKeyDown={(event) => {
          // Don't conflict with the dropdow navigation
          if (event.code === "ArrowUp" || event.code === "ArrowDown") {
            event.preventDefault();
          }
        }}
        placeholder={placeholder}
        type="search"
        value={value}
      />

      {appendElement}

      <StyledAutoCompleteContainer
        ref={resultsContainerRef}
        open={items.length > 0 && dropDownOpened}
      >
        {items.map((item, index) => {
          return (
            <StyledAutoCompleteItem
              key={JSON.stringify(item)}
              active={index === activeItemIndex}
              onClick={() => {
                handleDependencySelect(item);
              }}
              horizontal
            >
              {children(item)}
            </StyledAutoCompleteItem>
          );
        })}
      </StyledAutoCompleteContainer>
    </Box>
  );
};

const StyledAutoCompleteContainer = styled("div", {
  backgroundColor: "$neutral-bg-medium",
  paddingTop: "$1",
  paddingBottom: "$1",
  borderRadius: "$2",
  boxShadow: "$popup",
  position: "absolute",
  zIndex: "$menu",
  left: 4,
  right: 4,
  display: "none",

  variants: { open: { true: { display: "block" } } },
});

const StyledAutoCompleteItem = styled(Stack, {
  px: "$3",
  py: "$2",
  outline: "none",
  alignItems: "center",
  textDecoration: "none",
  display: "block",

  "&:hover": {
    background: "$neutral-bg-focus",
  },

  variants: {
    active: {
      true: {
        background: "$neutral-bg-focus",
      },
    },
  },
});
