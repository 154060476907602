import * as monaco from "monaco-editor";

const keycodeTranslationMap: Record<string, number> = {
  a: monaco.KeyCode.KeyA,
  b: monaco.KeyCode.KeyB,
  c: monaco.KeyCode.KeyC,
  d: monaco.KeyCode.KeyD,
  e: monaco.KeyCode.KeyE,
  f: monaco.KeyCode.KeyF,
  g: monaco.KeyCode.KeyG,
  h: monaco.KeyCode.KeyH,
  i: monaco.KeyCode.KeyI,
  j: monaco.KeyCode.KeyJ,
  k: monaco.KeyCode.KeyK,
  l: monaco.KeyCode.KeyL,
  m: monaco.KeyCode.KeyM,
  n: monaco.KeyCode.KeyN,
  o: monaco.KeyCode.KeyO,
  p: monaco.KeyCode.KeyP,
  q: monaco.KeyCode.KeyQ,
  r: monaco.KeyCode.KeyR,
  s: monaco.KeyCode.KeyS,
  t: monaco.KeyCode.KeyT,
  u: monaco.KeyCode.KeyU,
  v: monaco.KeyCode.KeyV,
  w: monaco.KeyCode.KeyW,
  x: monaco.KeyCode.KeyX,
  y: monaco.KeyCode.KeyY,
  z: monaco.KeyCode.KeyZ,
  "`": monaco.KeyCode.Backquote,
  "'": monaco.KeyCode.Quote,
  backspace: monaco.KeyCode.Backspace,
  delete: monaco.KeyCode.Delete,
  enter: monaco.KeyCode.Enter,
  escape: monaco.KeyCode.Escape,
  tab: monaco.KeyCode.Tab,
  shift: monaco.KeyMod.Shift,
  ctrl: monaco.KeyMod.WinCtrl,
  control: monaco.KeyMod.WinCtrl,
  up: monaco.KeyCode.UpArrow,
  down: monaco.KeyCode.DownArrow,
  left: monaco.KeyCode.LeftArrow,
  right: monaco.KeyCode.RightArrow,
  $mod: monaco.KeyMod.CtrlCmd,
  alt: monaco.KeyMod.Alt,
};

export function kbarBindingToVSCodeBinding(binding: string): number {
  return binding.split("+").reduce((acc, curr) => {
    const keyNumber = keycodeTranslationMap[curr.toLocaleLowerCase()];

    if (keyNumber == null) {
      throw new Error("unknown keybinding key: " + curr);
    }

    return acc | keyNumber;
  }, 0);
}
