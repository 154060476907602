import { Emitter } from "@codesandbox/pitcher-common";
/**
 * This is an abstraction that guarantees that whatever Pitcher
 * state we want to keep in sync, is in sync. Creating a synchronous
 * API to get "latest known state of a Pitcher value"
 *
 * - It does not overfetch, either in the situation where the value
 * is "in flight" or if you were to try to get the value later
 * - Whenever you change the value, it fires a change event
 * - Keeping the value up to date is not the responsbility of this
 * abstraction, but other external events using this API
 *
 * Currently you are can not really "reconnect" to a Pitcher instance,
 * as it requires a new "clientId", but we can keep reference to
 * clients on Pitcher for a certain amount of time, allowing a reconnect.
 * In that case you would use "refresh" to ensure you have the latest
 * value after reconnecting
 *
 * @deprecated
 * This was a bad decision. Some values can not have a reliable initial default value, like
 * git status, it needs to come from the VM. If a default value is required, the editor itself
 * is much better at setting this value
 */
export class AsyncValueStore {
    constructor(initialValue, getValue, opts = {}) {
        this.changeEmitter = new Emitter();
        this.onChange = this.changeEmitter.event;
        this.errorEmitter = new Emitter();
        this.onError = this.errorEmitter.event;
        this.value = initialValue;
        this.getValue = getValue;
        if (opts.fetchEagerly) {
            this.get();
        }
    }
    get() {
        if (!this.initialValuePromise) {
            this.initialValuePromise = this.getValue()
                .then((initialValue) => {
                this.value = initialValue;
                this.changeEmitter.fire({
                    value: this.value,
                    prevValue: this.value,
                });
            })
                .catch((error) => {
                this.errorEmitter.fire(error.message);
            });
        }
        return this.value;
    }
    getInitPromise() {
        return this.initialValuePromise;
    }
    set(newValue) {
        const prevValue = this.value;
        this.value = newValue;
        this.changeEmitter.fire({
            value: this.value,
            prevValue,
        });
        return this.value;
    }
    update(cb) {
        const prevValue = this.value;
        this.value = cb(this.value);
        this.changeEmitter.fire({
            value: this.value,
            prevValue,
        });
        return this.value;
    }
    refresh() {
        this.getValue().then((value) => this.set(value));
    }
}
