import { transition } from "@codesandbox/states";

import { getClientPreference } from "../utils/debug";

import type { Action, State } from "./types";

export const reducer = (prevState: State, action: Action) =>
  transition(prevState, action, {
    ON: {
      SET_MODE: (_, { enabled }) => {
        if (!enabled) {
          return {
            state: "OFF",
          };
        }

        return {
          showStateDevtools:
            getClientPreference("CSB_STATE_DEVTOOLS") === "ENABLED",
          state: "ON",
        };
      },
    },
    OFF: {
      SET_MODE: (state, { enabled }) => {
        if (!enabled) return state;

        return {
          showStateDevtools:
            getClientPreference("CSB_STATE_DEVTOOLS") === "ENABLED",
          state: "ON",
        };
      },
    },
  });
