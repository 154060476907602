import type { Event } from "@codesandbox/pitcher-common";

export function onceEvent<T>(
  event: Event<T>,
  condition?: (data: T) => boolean,
): Promise<T> {
  return new Promise((resolve) => {
    const disposable = event((data) => {
      if (condition && !condition(data)) return;
      disposable.dispose();
      resolve(data);
    });
  });
}
