import type { ComponentProps } from "react";
import React from "react";

import type { CSS } from "../../theme";
import { styled } from "../../theme";
import { Box } from "../Box";
import { Button } from "../Button";
import type { ButtonProps } from "../Button";
import { Dropdown } from "../Dropdown";
import { Tooltip } from "../Tooltip";

type DropdownProps = ComponentProps<typeof Dropdown>;
type InheritedProps = ButtonProps;

export type DropdownButtonProps = InheritedProps & {
  css?: CSS;
  buttonContent?: ButtonProps["children"];
  buttonTooltip?: string;
  dropdownTrigger?: DropdownProps["trigger"];
  // If true, the main button also triggers the dropdown.
  selfTrigger?: boolean;
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  buttonContent,
  buttonTooltip,
  children,
  css,
  disabled,
  loading = false,
  selfTrigger = false,
  variant = "ghost",
  ...buttonProps
}) => {
  const [open, setOpen] = React.useState(false);
  const validChildren = React.Children.count(children) > 0;

  const button = (
    <Button
      {...(selfTrigger
        ? {
            ...buttonProps,
            onClick: () => setOpen(!open),
          }
        : buttonProps)}
      css={
        validChildren
          ? {
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              flex: 1,
              borderRight: "0",
            }
          : {}
      }
      data-testid="DropdownButton-Button"
      disabled={disabled}
      // loading is set to false because we override the `loading` state within this component
      loading={loading}
      variant={variant}
    >
      {buttonContent}
    </Button>
  );

  return (
    <StyledDropdownButtonWrapper css={css}>
      {buttonTooltip ? (
        <Tooltip content={buttonTooltip}>{button}</Tooltip>
      ) : (
        button
      )}

      {validChildren && (
        <Dropdown
          rootProps={
            selfTrigger ? { onOpenChange: (open) => setOpen(open), open } : {}
          }
          trigger={
            <Button
              css={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                minWidth: "$4",
                padding: "2px !important",
                "& svg": {
                  transition: "translate $fast",
                },
                "&:hover svg": {
                  translate: "0px 2px",
                },
              }}
              data-testid="DropdownButton-Dropdown"
              disabled={loading}
              icon="chevronSmallDown"
              variant={variant}
            />
          }
        >
          {children}
        </Dropdown>
      )}
    </StyledDropdownButtonWrapper>
  );
};

const StyledDropdownButtonWrapper = styled(Box, {
  display: "flex",
  position: "relative",
});
