import { Root, Scrollbar, Thumb, Viewport } from "@radix-ui/react-scroll-area";
import React, { forwardRef, useEffect, useRef } from "react";
import type { RefObject } from "react";

import type { CSS } from "../../theme";
import { styled } from "../../theme";
import { onGrid } from "../../tokens/space";

interface Props {
  children: React.ReactNode;
  css?: CSS;
  id?: string;
  horizontalFade?: boolean;
  horizontalFadeSize?: number;
  verticalFade?: boolean;
  verticalFadeSize?: number;
}

const SCROLLBAR_SIZE = onGrid(1.5);
const DEFAULT_HORIZONTAL_FADE = 1;
const DEFAULT_VERTICAL_FADE = 2;

const StyledScrollArea = styled(Root, {
  position: "relative",
  zIndex: 0,
  width: "100%",
  height: "inherit",

  "& [data-radix-scroll-area-viewport-position]::-webkit-scrollbar": {
    display: "none",
  },
});

const StyledViewport = styled(Viewport, {
  width: "100%",
  height: "100%",
  position: "relative",
});

const StyledThumb = styled(Thumb, {
  backgroundColor: "$support-system-scrollbar",
  borderRadius: "$2",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 1,

  "&:hover": {
    backgroundColor: "$neutral-fg-base",
  },
});

const ScrollBar = styled("div", {});

const OverflowFade = styled("div", {
  position: "absolute",
  pointerEvents: "none",
  opacity: 0,
});

const HorizontalOverflowFade = styled(OverflowFade, {
  width: "$1",
  height: "100%",
  top: 0,
  bottom: 0,
});

const VerticalOverflowFade = styled(OverflowFade, {
  height: "$1",
  width: "100%",
  left: 0,
  right: 0,
});

// small hack below for Safari, instead of transparent,
// you need to use the opacity 0 version of that color
const LeftOverflowFade = styled(HorizontalOverflowFade, {
  left: 0,
  background:
    "linear-gradient(90deg, var(--scrollbar-fade-color, $neutral-bg-base), $neutral-bg-base_transparent)",
});

const RightOverflowFade = styled(HorizontalOverflowFade, {
  right: 0,
  background:
    "linear-gradient(90deg, $neutral-bg-base_transparent, var(--scrollbar-fade-color, $neutral-bg-base))",
});

const TopOverflowFade = styled(VerticalOverflowFade, {
  top: 0,
  background:
    "linear-gradient(var(--scrollbar-fade-color, $neutral-bg-base), $neutral-bg-base_transparent)",
});

const BottomOverflowFade = styled(VerticalOverflowFade, {
  bottom: 0,
  background:
    "linear-gradient($neutral-bg-base_transparent, var(--scrollbar-fade-color, $neutral-bg-base))",
});

const HorizontalScrollBar: React.FC<{ children: React.ReactNode }> = (
  props,
) => (
  <ScrollBar
    {...props}
    as={Scrollbar}
    css={{
      height: SCROLLBAR_SIZE,
      top: "auto",
      right: 0,
      bottom: 0 - SCROLLBAR_SIZE,
      left: 0,
    }}
    orientation="horizontal"
  />
);

const applyStyles = (
  element: RefObject<HTMLDivElement>,
  isVisible: boolean,
) => {
  if (!element.current) return;
  element.current.style.opacity = `${isVisible ? 0 : 1}`;
};

const VerticalScrollBar: React.FC<{ children: React.ReactNode }> = (props) => (
  <ScrollBar
    {...props}
    as={Scrollbar}
    css={{
      width: SCROLLBAR_SIZE,
      top: 0,
      right: 0 - SCROLLBAR_SIZE,
      bottom: 0,
      left: "auto",
    }}
    orientation="vertical"
  />
);

export const ScrollArea = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      css,
      id,
      horizontalFade,
      horizontalFadeSize = DEFAULT_HORIZONTAL_FADE,
      verticalFade,
      verticalFadeSize = DEFAULT_VERTICAL_FADE,
    },
    ref,
  ) => {
    const viewportRef = useRef<HTMLDivElement>(null);

    const leftOverflowFade = useRef<HTMLDivElement>(null!);
    const rightOverflowFade = useRef<HTMLDivElement>(null!);
    const bottomOverflowFade = useRef<HTMLDivElement>(null!);
    const topOverflowFade = useRef<HTMLDivElement>(null!);

    // Handle scroll edges for the verical scroll (top-bottom)
    useEffect(() => {
      if (!viewportRef.current || !verticalFade) {
        return;
      }

      const node = viewportRef.current;

      const handleScroll = () => {
        applyStyles(topOverflowFade, node.scrollTop === 0);
        applyStyles(
          bottomOverflowFade,
          node.scrollTop + node.clientHeight >= node.scrollHeight,
        );
      };

      handleScroll();

      node.addEventListener("scroll", handleScroll);

      return () => {
        node.removeEventListener("scroll", handleScroll);
      };
    }, [verticalFade]);

    // Handle scroll edges for the horizontal scroll (left-right)
    useEffect(() => {
      if (!viewportRef.current || !horizontalFade) {
        return;
      }
      const node = viewportRef.current;

      const handleScroll = () => {
        applyStyles(leftOverflowFade, node.scrollLeft === 0);
        applyStyles(
          rightOverflowFade,
          node.scrollLeft + node.clientWidth >= node.scrollWidth,
        );
      };
      handleScroll();

      node.addEventListener("scroll", handleScroll);

      return () => {
        node.removeEventListener("scroll", handleScroll);
      };
    }, [horizontalFade]);

    return (
      <StyledScrollArea ref={ref} css={css} id={id}>
        <StyledViewport ref={viewportRef}>{children}</StyledViewport>

        <VerticalScrollBar>
          <StyledThumb />
        </VerticalScrollBar>

        <HorizontalScrollBar>
          <StyledThumb />
        </HorizontalScrollBar>

        <LeftOverflowFade
          ref={leftOverflowFade}
          css={{ width: onGrid(horizontalFadeSize) }}
        />
        <RightOverflowFade
          ref={rightOverflowFade}
          css={{ width: onGrid(horizontalFadeSize) }}
        />

        <BottomOverflowFade
          ref={bottomOverflowFade}
          css={{ height: onGrid(verticalFadeSize) }}
        />
        <TopOverflowFade
          ref={topOverflowFade}
          css={{ height: onGrid(verticalFadeSize) }}
        />
      </StyledScrollArea>
    );
  },
);
