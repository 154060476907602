import { Disposable } from "@codesandbox/pitcher-common";
/**
 * RcRef is created by an Rc to ensure consumers of this api can only drop their reference once
 */
export class RcRef extends Disposable {
    constructor(rc, onDrop) {
        super();
        this.rc = rc;
        this.onWillDispose(() => {
            // Being extra careful here to ensure Dispose doesn't get called twice
            if (!this.isDisposed) {
                onDrop();
            }
        });
    }
    get object() {
        return this.rc.object;
    }
}
let shouldTrace = false;
try {
    shouldTrace =
        typeof window !== "undefined" &&
            localStorage.getItem("CSB_RC_DEBUG") === "ENABLED";
}
catch {
    // Do not have access to localStorage
}
if (shouldTrace) {
    // eslint-disable-next-line
    // @ts-ignore
    window.RC_DEBUG = {
        traceLog: [],
        counters: new Map(),
    };
}
let nextRefId = 0;
/**
 * Rc keeps a reference to a value and a counter
 * Once all references using Rc.acquire() has been dropped, the underlying value is destroyed
 **/
export class Rc extends Disposable {
    static trace(event, ref) {
        if (!shouldTrace) {
            return;
        }
        try {
            throw new Error("RC_TRACE");
        }
        catch (error) {
            if (error instanceof Error) {
                const stack = (error.stack || "")
                    .split("\n")
                    .filter((entry) => !entry.includes("node_modules") &&
                    !entry.includes("Rc.js") &&
                    !entry.includes("RC_TRACE") &&
                    entry.match(/[a-zA-Z0-9_-]+/));
                const location = stack[0] || "";
                // eslint-disable-next-line
                // @ts-ignore
                const debug = window.RC_DEBUG;
                const logItem = {
                    id: ref instanceof Rc ? ref.id : null,
                    event,
                    ref: ref instanceof Rc ? ref.object : ref,
                    count: ref instanceof Rc ? ref.count : 0,
                    location: "..." + location.substring(location.length - 50),
                    stack,
                };
                debug.traceLog.push(logItem);
                if (!(ref instanceof Rc)) {
                    return;
                }
                const counter = debug.counters.get(ref.object);
                if (counter) {
                    counter.push(logItem);
                }
                else {
                    debug.counters.set(ref.object, [logItem]);
                }
            }
        }
    }
    constructor(object, handleDispose) {
        super();
        this.object = object;
        this.count = 0;
        this.id = `REF-` + nextRefId++;
        this.onWillDispose(handleDispose);
        Rc.trace("rc.create", this);
    }
    acquire() {
        if (this.isDisposed) {
            throw new Error("Cannot re-acquire a destroyed Rc");
        }
        this.count += 1;
        Rc.trace("rc.acquire", this);
        return new RcRef(this, () => {
            this.count -= 1;
            Rc.trace("rc.drop", this);
            if (this.count === 0) {
                Rc.trace("rc.dispose", this);
                return super.dispose();
            }
        });
    }
    dispose() {
        throw new Error("Cannot call dispose directly, need to use the RcRef");
    }
}
