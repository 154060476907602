import type { Tokens } from "../tokens/themes/base";
import { base } from "../tokens/themes/base";
import { light } from "../tokens/themes/light";

export const DEFAULT_THEME_NAME = "dark" as const;
export const baseTheme = base;
export type ThemeColorSchema = {
  type: string;
  name: string;
  colors?: Partial<Tokens["colors"]>;
};
export const THEME_LOCAL_STORAGE_KEY = "CSB/THEME/0.1";
export type { Tokens };

export interface ThemeContextT {
  className: string;
  tokens: Tokens;
  theme: ThemeColorSchema;
  setTheme: (theme: ThemeColorSchema) => void;
}

export const BUILT_IN_THEMES: Record<string, Tokens> = {
  dark: baseTheme,
  light: { ...baseTheme, ...light },
};

export const BUILT_IN_DARK_COLOR_SCHEMA: ThemeColorSchema = {
  type: "dark",
  name: "CodeSandbox Dark",
};

export const BUILT_IN_LIGHT_COLOR_SCHEMA: ThemeColorSchema = {
  type: "light",
  name: "CodeSandbox Light",
};

export const DEFAULT_COLOR_SCHEMA = BUILT_IN_DARK_COLOR_SCHEMA;
