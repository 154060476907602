import type { CodeSandboxApi, rest } from "@codesandbox/api";
import { gql } from "@codesandbox/api";
import type { SandboxEditor } from "features/types/editor";

import { hasSandboxPermission } from "./permission";
import { getSandboxTitleAndDescription } from "./utils";

export const projectFragment = gql.fragments.Project({
  id: true,
  appInstalled: true,
  authorization: true,
  team: {
    id: true,
    name: true,
    avatarUrl: true,
    subscription: [
      { includeCancelled: false },
      {
        type: true,
      },
    ],
    settings: {
      aiConsent: {
        privateRepositories: true,
        privateSandboxes: true,
        publicRepositories: true,
        publicSandboxes: true,
      },
    },
  },
  resources: {
    cpu: true,
    memory: true,
    storage: true,
  },
  repository: gql.inlineFragments.Repository({
    GitHubRepository: {
      permission: true,
      defaultBranch: true,
      parent: {
        owner: true,
        name: true,
        defaultBranch: true,
      },
      source: {
        name: true,
      },
      private: true,
    },
  }),
});

export const pullRequestFragment = gql.fragments.PullRequest({
  number: true,
  title: true,
  draft: true,
  baseBranch: true,
  creator: {
    avatarUrl: true,
    name: true,
    username: true,
  },
  creatorUsername: true,
  reviews: {
    id: true,
    user: {
      name: true,
      avatarUrl: true,
    },
    username: true,
    state: true,
    submittedAt: true,
    body: true,
    comments: {
      body: true,
      commitId: true,
      createdAt: true,
      id: true,
      username: true,
      htmlUrl: true,
      user: {
        name: true,
        avatarUrl: true,
      },
      line: true,
      path: true,
      side: true,
    },
  },
  requestedReviewers: {
    user: {
      name: true,
      avatarUrl: true,
    },
    username: true,
    requestedAt: true,
  },
  state: true,
  body: true,
});

export type PullRequestData = gql.FragmentData<typeof pullRequestFragment>;

export const branchFragment = gql.fragments.Branch({
  id: true,
  name: true,
  contribution: true,
  contributionOwner: true,
  pullRequests: pullRequestFragment,
  sourceBranch: true,
  aiConsent: true,
  settings: {
    protected: true,
  },
});

export const projectFragmentWithBranch = (branch: string) =>
  gql.fragments.Project({
    ...projectFragment,
    "branch:branchByName": [{ name: branch }, branchFragment],
  });

export type ProjectDataWithBranch = gql.FragmentData<
  ReturnType<typeof projectFragmentWithBranch>
>;

type ProjectsWithBranchArgs = {
  owner: string;
  repo: string;
};

const projectsWithBranchQuery = gql.createQuery(
  "ProjectWithBranch",
  ({ owner, repo, branch }: ProjectsWithBranchArgs & { branch: string }) => ({
    projects: [
      { owner, name: repo, provider: gql.GitProvider.GITHUB },
      projectFragmentWithBranch(branch),
    ],
  }),
);

export const projectFragmentWithDefaultBranch = gql.fragments.Project({
  ...projectFragment,
  "branch:defaultBranch": branchFragment,
});

const projectsWithDefaultBranchQuery = gql.createQuery(
  "ProjectWithDefaultBranch",
  ({ owner, repo }: ProjectsWithBranchArgs) => ({
    projects: [
      { owner, name: repo, provider: gql.GitProvider.GITHUB },
      projectFragmentWithDefaultBranch,
    ],
  }),
);

const queryProjectsWithBranch = async (
  csbApi: CodeSandboxApi,
  {
    owner,
    repo,
    branch,
  }: ProjectsWithBranchArgs & {
    branch: string | null;
  },
  serverHeaders?: Record<string, string>,
) => {
  return branch
    ? csbApi.gql
        .query(projectsWithBranchQuery, { owner, repo, branch }, serverHeaders)
        .then((response) => response.projects)
    : csbApi.gql
        .query(projectsWithDefaultBranchQuery, { owner, repo }, serverHeaders)
        .then((response) => response.projects);
};

/**
 * This retrieves the projects for the branch, or default branch when no branch is provided.
 * It will return an array of project matches, but they do not necessarily have a branch match.
 * The consuming logic needs to decide how to make it a fully valid Project with a branch
 */
export async function inferProjectForBranch(
  csbApi: CodeSandboxApi,
  args: ProjectsWithBranchArgs & {
    branch: string | null;
    workspaceId: string | null;
  },
  serverHeaders?: Record<string, string>,
) {
  // We can pass a workspaceId as sorting priority. It might be part of the queried response or not
  const workspaceId = args.workspaceId;

  // We ask for all workspaces even though you pass a workspaceId. The reason is that we are not
  // sure you have access, the branch exists or that you navigated to a public workspace and want to open the branch
  // in a different workspace
  const projectsWithBranch = await queryProjectsWithBranch(
    csbApi,
    args,
    serverHeaders,
  );

  // We infer the most likely workspace you want to use by checking its subscription
  const inferredProjects = projectsWithBranch.sort((projectA, projectB) => {
    // We'll move projectA up if matching workspaceId
    if (workspaceId && projectA.team && projectA.team.id === workspaceId) {
      return -1;
    }

    // We'll move projectA down if projectB matches workspaceId
    if (workspaceId && projectB.team && projectB.team.id === workspaceId) {
      return 1;
    }

    if (projectA.team?.subscription?.type === gql.SubscriptionType.TEAM_PRO) {
      return -1;
    }

    if (projectB.team?.subscription?.type === gql.SubscriptionType.TEAM_PRO) {
      return 1;
    }

    if (
      projectA.team?.subscription?.type === gql.SubscriptionType.PERSONAL_PRO
    ) {
      return -1;
    }

    if (
      projectB.team?.subscription?.type === gql.SubscriptionType.PERSONAL_PRO
    ) {
      return 1;
    }

    // We'll move projectA up if we do not pass workspaceId and it has a team
    if (!workspaceId && projectA.team) {
      return -1;
    }

    // We'll move projectA down if do not pass workspaceId and projectB has a team
    if (!workspaceId && projectB.team) {
      return 1;
    }

    return 0;
  });

  // We see if any of your workspaces has the branch, except for the public workspace. This ensures scenarios where
  // you have the repo in two workspaces and created a branch in a not prioritised workspace
  const projectMatchingBranch = inferredProjects.find(
    (project) => project.branch && project.team,
  );

  const inferredProject = projectMatchingBranch || inferredProjects[0];

  if (inferredProject) {
    return inferredProject;
  }

  return null;
}

export function convertSandboxToProject(sandbox: rest.Sandbox): SandboxEditor {
  const canChange = hasSandboxPermission(sandbox.authorization, "write_code");

  // This permission is given if you can edit the title/description of a sandbox, but you
  // cannot edit the code. This can be given, for instance, to synced templates where you are
  // the owner on GitHub.
  const hasProjectPermission = hasSandboxPermission(
    sandbox.authorization,
    "write_project",
  );

  const { title, description } = getSandboxTitleAndDescription(sandbox);
  const isCloud = sandbox.v2 === true;

  return {
    type: "sandbox",
    alias: sandbox.alias || sandbox.id,
    defaultActiveFile: sandbox.entry,
    git: sandbox.git,
    insertedAt: sandbox.insertedAt,
    title,
    updatedAt: sandbox.updatedAt,
    description,
    author: sandbox.author,
    forkedTemplate: sandbox.forkedTemplate,
    team: sandbox.team,
    privacy: sandbox.privacy,
    customTemplate: sandbox.customTemplate,
    previewSecret: sandbox.previewSecret,
    template: sandbox.template,
    isCloud: sandbox.v2,
    isFrozen: sandbox.isFrozen,
    canDownload: !sandbox.permissions.preventSandboxExport,
    collection: sandbox.collection,
    aiConsent: sandbox.aiConsent,
    allowedCapabilities: {
      branch: false,
      sandbox: canChange,
      restartContainer: (canChange || hasProjectPermission) && isCloud,
      secrets: (canChange || hasProjectPermission) && isCloud,
      vscode: canChange && isCloud,
      renameProject: canChange || hasProjectPermission,
      createLiveSession: isCloud && (canChange || hasProjectPermission),
    },
    permissions: sandbox.permissions,
    subscriptionType: null,
    authorization: sandbox.authorization,
    forkCount: sandbox.forkCount,
    forkedFromSandbox: sandbox.forkedFromSandbox,
    forkedTemplateSandbox: sandbox.forkedTemplateSandbox,
    entry: sandbox.entry,
    npmDependencies: sandbox.npmDependencies,
    viewCount: sandbox.viewCount,
    isPrivate: sandbox.privacy > 0,
    id: sandbox.id,
    workspace: sandbox.team || null,
    restricted: sandbox.restricted,
    draft: sandbox.draft,
    npmRegistries: sandbox.npmRegistries,
  };
}

export async function fetchSandbox(
  csbApi: CodeSandboxApi,
  id: string,
  headers: Record<string, string>,
): Promise<SandboxEditor> {
  const sandbox: rest.Sandbox = await csbApi
    .get<{ data: rest.Sandbox }>({
      path: `/v1/sandboxes/${id}`,
      headers,
    })
    .then((response) => response.data);

  return convertSandboxToProject(sandbox);
}
