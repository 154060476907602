import { LANGUAGE_SERVER_MAPPINGS } from "./BrowserLSP";
function getLanguageConfig(id, extensions, globs = []) {
    const langServerId = LANGUAGE_SERVER_MAPPINGS.get(id);
    return {
        id,
        extensions,
        globs: [...globs, ...extensions.map((e) => `**/*${e}`)],
        hasLanguageServer: !!langServerId,
        languageServerIds: langServerId ? [langServerId] : [],
    };
}
export const LANGUAGES = [
    getLanguageConfig("typescript", [".ts", ".mts", ".cts"]),
    getLanguageConfig("typescriptreact", [".tsx", ".mtsx", ".ctsx"]),
    getLanguageConfig("javascriptreact", [".js", ".jsx", ".mjs", ".cjs"]),
    getLanguageConfig("css", [".css"]),
    getLanguageConfig("scss", [".scss", ".sass"]),
    getLanguageConfig("less", [".less"]),
    getLanguageConfig("jsonc", [], [
        "**/.parcelrc",
        "**/.parcelrc.json",
        "**/tsconfig.json",
        "**/jsconfig.json",
        "**/.vscode/*.json",
        "**/.vscode/*.jsonc",
        "**/.codesandbox/*.json",
    ]),
    getLanguageConfig("json", [".json"], [
        "**/.bowerrc",
        "**/.jshintrc",
        "**/.jscsrc",
        "**/.eslintrc",
        "**/.babelrc",
    ]),
    getLanguageConfig("html", [".html", ".htm"]),
    getLanguageConfig("markdown", [".md"]),
    getLanguageConfig("rust", [".rs"]),
    getLanguageConfig("yaml", [".yml", ".yaml"]),
    getLanguageConfig("sql", [".sql"]),
    getLanguageConfig("python", [".py"]),
    getLanguageConfig("prisma", [".prisma"]),
    getLanguageConfig("graphql", [".gql"]),
    getLanguageConfig("svelte", [".svelte"]),
    getLanguageConfig("vue", [".vue"]),
    getLanguageConfig("xml", [".svg", ".xml"]),
    getLanguageConfig("astro", [".astro"]),
    getLanguageConfig("dotenv", [".env"], [".env", ".env.*"]),
    getLanguageConfig("markdownreact", [".mdx"]),
    getLanguageConfig("php", [".php"]),
    getLanguageConfig("lua", [".lua"]),
    getLanguageConfig("shell", [".sh", ".bash", ".zsh"]),
    getLanguageConfig("dockerfile", [], ["**/Dockerfile", "**/*.Dockerfile", "**/Dockerfile.*"]),
    getLanguageConfig("elixir", [".ex", ".exs"]),
    getLanguageConfig("nix", [".nix"]),
    getLanguageConfig("ruby", [".rb", ".ru", ".erb"]),
    getLanguageConfig("go", [".go"]),
];
