import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden";
import React, { useLayoutEffect } from "react";

import { keyframes, styled } from "../../theme";
import { toStitchesToken } from "../../theme/utils";
import type { space } from "../../tokens/space";
import { Box } from "../Box";

const VIEWBOX_SIZE = 100;
const VIEWBOX = `0 0 ${VIEWBOX_SIZE} ${VIEWBOX_SIZE}`;
const BOX_START = 0;
const BOX_END = VIEWBOX_SIZE;
const STROKE = 15;
const BASE_DURATION = 1.2;
const COLORS = 4;
const COLOR_DURATION = BASE_DURATION * COLORS;

type Coordinate = [number, number];
type Points = Coordinate[];

type CornerName = "topLeft" | "topRight" | "bottomLeft" | "bottomRight";

const corner: Record<CornerName, Coordinate> = {
  topLeft: [BOX_START, BOX_START],
  topRight: [BOX_END, BOX_START],
  bottomLeft: [BOX_START, BOX_END],
  bottomRight: [BOX_END, BOX_END],
};

const toPoint = (point: Coordinate): string => `${point[0]}, ${point[1]}`;
const toPoints = (points: Points): string => points.map(toPoint).join(", ");

const TOP_RIGHT_CORNER_POINTS = toPoints([
  corner.topLeft,
  corner.topRight,
  corner.bottomRight,
]);

const BOTTOM_LEFT_CORNER_POINTS = toPoints([
  corner.topLeft,
  corner.bottomLeft,
  corner.bottomRight,
]);

const spacing = keyframes({
  "0%": {
    strokeDasharray: "0 200",
  },
  "45%": {
    strokeDashoffset: "0",
    strokeDasharray: "200 200",
  },
  "90%": {
    strokeDashoffset: "-200",
    strokeDasharray: "200 200",
  },
  "100%": {
    strokeDashoffset: "-200",
    strokeDasharray: "200 200",
  },
});

const color = keyframes({
  "0%": {
    stroke: "$accent-secondary-base",
  },
  "24%": {
    stroke: "$accent-secondary-base",
  },
  "25%": {
    stroke: "$accent-secondary-base",
  },
  "49%": {
    stroke: "$accent-secondary-base",
  },
  "50%": {
    stroke: "$informative-error-base",
  },
  "74%": {
    stroke: "$informative-error-base",
  },
  "75%": {
    stroke: "$informative-alert-base",
  },
  "99%": {
    stroke: "$informative-alert-base",
  },
});

const Line = styled("polyline", {
  stroke: "$neutral-fg-low",
});

const AnimatedLine = styled("polyline", {
  animation: `${spacing} ${BASE_DURATION}s ease-in, ${color} ${COLOR_DURATION}s linear`,
  animationIterationCount: "infinite",
  animationDirection: "normal",
  animationFillMode: "forwards",
  transformOrigin: "center center",
});

const Square: React.FC<{ variant: "animated" | "still" }> = ({ variant }) => {
  const Component = variant === "animated" ? AnimatedLine : Line;

  return (
    <>
      <Component
        fill="none"
        points={TOP_RIGHT_CORNER_POINTS}
        strokeWidth={STROKE}
      />
      <Component
        fill="none"
        points={BOTTOM_LEFT_CORNER_POINTS}
        strokeWidth={STROKE}
      />
    </>
  );
};

const logoGradientAnimation = keyframes({
  "0%": {
    backgroundPosition: "0% 0%",
  },
  "100%": {
    backgroundPosition: "0% -200%",
  },
});

interface LoadingProps {
  background?: string;
  size: keyof typeof space;
  theme?: "default" | "pride";
}

export const Loading: React.FC<LoadingProps> = ({
  background = "$neutral-bg-base",
  size = 8,
  theme = "default",
}) => {
  const sizeToken = toStitchesToken(size);

  useLayoutEffect(() => {
    document.querySelector("#ssr-loading")?.remove();
  }, []);
  return (
    <>
      <VisuallyHidden>Loading</VisuallyHidden>
      {theme === "pride" ? (
        <Box
          css={{
            position: "relative",
            width: sizeToken,
            height: sizeToken,
            background:
              "linear-gradient(0deg, #cc0000, #ff6600, #ffa800, #33b400, #0066cd, #6600cc, #cc0000, #ff6600, #ffa800, #33b400, #0066cd, #6600cc, #cc0000)",
            backgroundSize: "400% 400%",
            animation: `${logoGradientAnimation} 4s linear infinite`,

            "&::before": {
              position: "absolute",
              content: "",
              width: "80%",
              height: "80%",
              margin: "10%",
              display: "block",
              background,
            },
          }}
        />
      ) : (
        <Box
          as="svg"
          css={{
            width: sizeToken,
            height: sizeToken,
          }}
          viewBox={VIEWBOX}
        >
          <Square variant="still" />
          <Square variant="animated" />
        </Box>
      )}
    </>
  );
};
