import * as vscode from "vscode";

export function transformUri(uri: vscode.Uri): vscode.Uri {
  if (uri.path === "/vscode-resource" && uri.query) {
    const requestResourcePath = JSON.parse(uri.query).requestResourcePath;
    return vscode.Uri.from({
      scheme: "vscode-remote",
      path: requestResourcePath,
    });
  }

  return uri;
}
