import type { CSS } from "../theme";
import { keyframes } from "../theme";

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 0.8 },
});

export const overlay: CSS = {
  animation: `${fadeIn} 100ms ease-in-out`,
  backgroundColor: "$neutral-bg-base",
  opacity: "0.9",
  position: "fixed",
  inset: "0px",
  zIndex: "$dialog",
};

export const content: CSS = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  width: "90vw",
  maxHeight: "85vh",
  px: "$6",
  py: "$6",

  backgroundColor: "$neutral-bg-subtle",
  borderRadius: "$2",

  color: "$neutral-fg-subtle",

  animation: `${fadeIn} 100ms ease-in-out`,

  zIndex: "$dialog",
};

export const title: CSS = {
  color: "$neutral-fg-high",
  fontSize: "medium",
  fontWeight: "$regular",
  my: 0,
};

export const description: CSS = {
  color: "$neutral-fg-base",
  marginTop: "$1",
  paddingBottom: "$6",
  lineHeight: 1.4,

  a: {
    color: "inherit",
    textDecoration: "underline",

    "&:hover": {
      textDecoration: "none",
    },
  },
};
