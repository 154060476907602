import { safeLocalStorage } from "utils/safeLocalStorage";

type Prefix = "CSB";
type Preference =
  | "DEBUG"
  | "STATE_DEVTOOLS"
  | "NON_BETA_USER"
  | "READ_USER"
  | "DISMISS_PROJECT_SETUP"
  | "RC_DEBUG";
type DebugPreferenceKey = `${Prefix}_${Preference}`;

export const getClientPreference = (key: DebugPreferenceKey): string => {
  if (typeof window === "undefined") return "";
  const preference = safeLocalStorage.get(key);

  return preference || "";
};

export const setClientPreference = (
  key: DebugPreferenceKey,
  value: "ENABLED" | "DISABLED",
): void => {
  if (typeof window === "undefined") return;
  /* eslint-disable-next-line no-restricted-properties */
  window.localStorage.setItem(key, value);
};

const isDebugModePreferred = (): boolean => {
  // Always disable debug mode during SSR
  if (typeof window === "undefined") return false;

  const preference = getClientPreference("CSB_DEBUG");

  /**
   * ENABLED by default in development
   * Can be DISABLED by setting CSB_DEBUG to "DISABLED"
   * DISABLED by default everywhere else unless CSB_DEBUG is "ENABLED"
   *
   * TODO: make it ENABLED when config.ENVIRONMENT is not production
   * (i.e. enable it on staging by default). This is more involved
   * because this function doesn't care about config (an application concern)
   */
  return process.env.NODE_ENV === "development"
    ? preference !== "DISABLED"
    : preference === "ENABLED";
};

export const isDebugMode = isDebugModePreferred();
