import * as radixPopover from "@radix-ui/react-popover";

import { styled } from "../../theme";

const { Content } = radixPopover;

export const PopoverContent = styled(Content, {
  boxShadow: "$popup",
  color: "$neutral-fg-subtle",

  variants: {
    padding: {
      none: {
        px: "$0",
        py: "$0",
      },
      default: {
        px: "$4",
        py: "$3",
      },
    },
    variant: {
      illustration: {
        background: "$neutral-bg-low",
      },
      default: {
        background: "$neutral-bg-medium",
        borderRadius: "$2",
        boxShadow: "$popup",
      },
    },
  },
});
