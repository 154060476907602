import { Disposable, Emitter } from "@codesandbox/pitcher-common";
export class BrowserClientsClient extends Disposable {
    constructor(currentClient) {
        super();
        this.clientConnectedEmitter = this.addDisposable(new Emitter());
        this.onClientConnected = this.clientConnectedEmitter.event;
        this.clientDisconnectedEmitter = this.addDisposable(new Emitter());
        this.onClientDisconnected = this.clientDisconnectedEmitter.event;
        this.clientUpdatedEmitter = this.addDisposable(new Emitter());
        this.onClientUpdated = this.clientUpdatedEmitter.event;
        this.clientPermissionsEmitter = this.addDisposable(new Emitter());
        this.onClientPermissionsUpdated = this.clientPermissionsEmitter.event;
        this.clientsUpdatedEmitter = this.addDisposable(new Emitter());
        this.onClientsUpdated = this.clientsUpdatedEmitter.event;
        this.clientsErrorEmitter = this.addDisposable(new Emitter());
        this.onClientsError = this.clientsErrorEmitter.event;
        this.clients = [];
        // TODO: Sync live users with api?
        this.clients = [currentClient];
    }
    getClients() {
        return this.clients;
    }
    resync() {
        return;
    }
    updateCurrentClient(currentClient) {
        this.clients = [currentClient];
        this.clientsUpdatedEmitter.fire(this.clients);
    }
}
