import type {
  PopoverProps as RootProps,
  PopoverContentProps as ContentProps,
} from "@radix-ui/react-popover";
import * as radixPopover from "@radix-ui/react-popover";
import React, { forwardRef } from "react";

import type { CSS } from "../../theme";
import { useTheme } from "../../theme";
import { onGrid } from "../../tokens/space";

import { PopoverContent } from "./PopoverContent";

const { Trigger, Root, Portal, Arrow } = radixPopover;

export interface PopoverProps {
  /** Should be a button */
  trigger: JSX.Element;
  /** Content shown inside the popover */
  children: React.ReactChild;
  /** Passed to the radix root component */
  rootProps?: RootProps;
  /** Passed to the radix content component */
  contentProps?: ContentProps & { css?: CSS };

  padding?: "none" | "default";
  variant?: "illustration" | "default";
  showArrow?: boolean;
}

export const Popover = forwardRef<HTMLButtonElement, PopoverProps>(
  (
    {
      children,
      trigger,
      contentProps,
      rootProps,
      padding = "default",
      variant = "default",
      showArrow = false,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();

    return (
      <Root {...rootProps}>
        <Trigger ref={ref} asChild {...rest}>
          {trigger}
        </Trigger>
        {/* PopoverContent renders a portal. We need to explicitly set the theme inside it. */}
        <Portal>
          <PopoverContent
            className={theme.className}
            padding={padding}
            sideOffset={onGrid(1)}
            variant={variant}
            {...contentProps}
          >
            {children}
            {showArrow && <Arrow height={10} width={40} />}
          </PopoverContent>
        </Portal>
      </Root>
    );
  },
);
