/* eslint-disable no-restricted-properties */

export type SafeLocalStorage = {
  get(key: string): string | null;
  set(key: string, value: string): void;
  delete(key: string): void;
};

let safeLocalStorage: SafeLocalStorage;

try {
  // We test if we can use it
  window.localStorage.getItem("test");

  safeLocalStorage = {
    get(key) {
      return window.localStorage.getItem(key);
    },
    set(key, value) {
      return window.localStorage.setItem(key, value);
    },
    delete(key) {
      return window.localStorage.removeItem(key);
    },
  };
} catch {
  safeLocalStorage = {
    get() {
      return null;
    },
    set() {},
    delete() {},
  };
}

export { safeLocalStorage };
