import type { CSS } from "@stitches/react";
import React from "react";

import { styled } from "../../theme";
import { toStitchesToken } from "../../theme/utils";

import * as paths from "./paths";

export type IconType = keyof typeof paths;
export type IconSize = 1 | 2 | 3 | 4 | 5 | 6 | 8 | 10 | 12 | 14 | 16;

interface IconProps {
  type: IconType;
  title?: string;
  size?: IconSize;
  css?: CSS;
}

const SvgElement = styled("svg", {
  width: "$6",
  height: "$6",
  flexShrink: 0,
});

export const Icon: (
  props: IconProps & React.ParamHTMLAttributes<HTMLOrSVGElement>,
) => React.ReactElement = ({ type, title, size = 6, css, ...props }) => {
  // eslint-disable-next-line import/namespace -- Allow for computed path values
  const path = paths[type];
  const sizeToken = toStitchesToken(size);

  return (
    // @ts-ignore
    <SvgElement
      css={{
        width: sizeToken,
        height: sizeToken,
        fill: "transparent",
        ...css,
      }}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {path}
      {title && <title>{title}</title>}
    </SvgElement>
  );
};
