import { useEnvironmentInterface } from "environment-interface";

import { useAddStatusNotification } from "../notifications/hooks";

type UseCopyToClipboard = (
  onCopyMessage?: string,
  skipNotification?: boolean,
) => (textToCopy: string) => void;

export const useCopyToClipboard: UseCopyToClipboard = (
  onCopyMessage = "Copied",
  skipNotification,
) => {
  const { clipboard } = useEnvironmentInterface();
  const addNotification = useAddStatusNotification();

  return (textToCopy) => {
    clipboard.write(textToCopy);

    if (!skipNotification) {
      addNotification({
        message: onCopyMessage,
        uniqueName: "copy-to-clipboard",
      });
    }
  };
};
