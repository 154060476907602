import React from "react";
import type { ComponentProps } from "react";

import { TabContent } from "./TabContent";
import { TabTrigger } from "./TabTrigger";
import { Tabs as TabsDefault } from "./Tabs";
import { TabsList } from "./TabsList";

interface ITabs extends React.FC<ComponentProps<typeof TabsDefault>> {
  List: typeof TabsList;
  Trigger: typeof TabTrigger;
  Content: typeof TabContent;
}

const Tabs: ITabs = (props) => <TabsDefault {...props} />;

Tabs.List = TabsList;
Tabs.Trigger = TabTrigger;
Tabs.Content = TabContent;

export { Tabs };
