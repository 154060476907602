import { Emitter } from "@codesandbox/pitcher-common";
export class BrowserSetupClient {
    constructor() {
        this.readyPromise = undefined;
        this.setupProgressUpdateEmitter = new Emitter();
        this.onSetupProgressUpdate = this.setupProgressUpdateEmitter.event;
        this.setupProgressErrorEmitter = new Emitter();
        this.onSetupProgressError = this.setupProgressErrorEmitter.event;
    }
    getProgress() {
        return {
            state: "FINISHED",
            steps: [],
            currentStepIndex: 0,
        };
    }
    resync() {
        return;
    }
    skipStep() {
        return Promise.reject(new Error("Not implemented"));
    }
    skipAll() {
        return Promise.reject(new Error("Not implemented"));
    }
    setStep() {
        return Promise.reject(new Error("Not implemented"));
    }
    disable() {
        new Error("Not implemented");
    }
    enable() {
        new Error("Not implemented");
    }
    init() {
        return Promise.reject("Not implemented");
    }
}
