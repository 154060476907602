import { Barrier, Disposable, Emitter } from "@codesandbox/pitcher-common";
import { createRpcConnection } from "./utils";
export class LanguageServer extends Disposable {
    constructor(emulator, fsClient, fileClient, config) {
        super();
        this.emulator = emulator;
        this.fsClient = fsClient;
        this.fileClient = fileClient;
        this.config = config;
        // eslint-disable-next-line
        this.onLspNotificationEmitter = this.addDisposable(new Emitter());
        this.onNotification = this.onLspNotificationEmitter.event;
        // eslint-disable-next-line
        this.onLspRequestEmitter = this.addDisposable(new Emitter());
        this.onRequest = this.onLspRequestEmitter.event;
        this.initializeBarrier = this.addDisposable(new Barrier());
        this.lastReqId = 0;
        this.pendingRequests = new Map();
        this.connectionPromise = this.runServer();
    }
    async runServer() {
        const shellProcess = this.emulator.shell.create();
        this.onDidDispose(() => {
            try {
                shellProcess.kill();
            }
            catch (e) {
                // Nodebox might already be disposed, so we ignore any errors that can occur here
            }
        });
        const commandRunningPromise = this.addDisposable(new Barrier());
        shellProcess.on("exit", (exitCode, error) => {
            // eslint-disable-next-line
            console.error("CSS Server exited", { exitCode, error });
        });
        shellProcess.on("progress", (status) => {
            if (status.state === "command_running") {
                commandRunningPromise.open(null);
            }
        });
        await shellProcess.runCommand("node", [this.config.lib, "--stdio"], {});
        const result = await commandRunningPromise.wait();
        if (result.status === "disposed") {
            throw new Error("Disposed");
        }
        const rpcConnection = this.addDisposable(createRpcConnection(shellProcess));
        this.addDisposable(rpcConnection.onError((err) => {
            // eslint-disable-next-line
            console.error("LSP Error", err);
        }));
        this.addDisposable(rpcConnection.onNotification((method, params) => {
            this.onLspNotificationEmitter.fire({
                languageId: this.config.languageId,
                message: { jsonrpc: "2.0", method, params },
                serverId: this.config.serverId,
            });
        }));
        this.addDisposable(rpcConnection.onUnhandledNotification((msg) => {
            // eslint-disable-next-line
            console.error("Unhandled LSP notification", msg);
        }));
        this.addDisposable(rpcConnection.onRequest((method, params) => {
            this.lastReqId += 1;
            const id = this.lastReqId;
            return new Promise((resolve, reject) => {
                this.pendingRequests.set(id, (msg) => {
                    if (msg.result) {
                        resolve(msg.result);
                    }
                    else {
                        reject(msg.error);
                    }
                });
                this.onLspRequestEmitter.fire({
                    id,
                    method,
                    params,
                });
            });
        }));
        rpcConnection.listen();
        return { rpcConnection, shellProcess };
    }
    async sendRequest(message) {
        if (this.isDisposed) {
            return;
        }
        const method = message.method;
        // eslint-disable-next-line
        const params = message.params;
        if (method !== "initialize") {
            await this.initializeBarrier.wait();
        }
        if (this.isDisposed) {
            return;
        }
        const { rpcConnection } = await this.connectionPromise;
        const response = (await rpcConnection
            .sendRequest(method, params)
            .finally(() => {
            if (method === "initialize") {
                this.initializeBarrier.open();
            }
            // eslint-disable-next-line
        }));
        if (method === "initialize") {
            response.capabilities.documentRangeFormattingProvider = false;
            response.capabilities.documentFormattingProvider = true;
        }
        return response;
    }
    async sendLSPServerResponse(message) {
        if (this.isDisposed) {
            return;
        }
        const id = message.id;
        const req = this.pendingRequests.get(id);
        if (req) {
            req(message);
            this.pendingRequests.delete(id);
        }
    }
    async sendNotification(message) {
        if (this.isDisposed) {
            return;
        }
        await this.initializeBarrier.wait();
        const { rpcConnection } = await this.connectionPromise;
        await rpcConnection.sendNotification(message.method, message.params);
    }
}
