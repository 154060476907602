import { Disposable, Emitter } from "@codesandbox/pitcher-common";
export class AiMessageStream extends Disposable {
    constructor(id, aiClient) {
        super();
        this.id = id;
        this.progressEmitter = new Emitter();
        this.onProgress = this.progressEmitter.event;
        this.doneEmitter = new Emitter();
        this.onDone = this.doneEmitter.event;
        const disposable = aiClient.subscribeToMessage(id, (newContent, isFinished) => {
            this.progressEmitter.fire(newContent);
            if (isFinished) {
                this.doneEmitter.fire();
            }
        });
        this.addDisposable(disposable);
    }
}
